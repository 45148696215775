import { Box, GlobalStyles, Typography, Divider } from '@mui/material';
import moment from 'moment';
import React, { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { formatPhoneNumber } from '../../../utils';
import AnswerFormatter from './AnswerFormatter';

const PrintableAnswers = forwardRef(({ answers, questionnaire, patient }, ref) => {
  const { t } = useTranslation();
  const answeredQuestions = questionnaire.questions;
  const orderedItems = [];
  const seenSections = new Set();

  answeredQuestions.forEach((question) => {
    const sectionName = question.section?.name;

    if (sectionName) {
      if (!seenSections.has(sectionName)) {
        orderedItems.push({ type: 'section', name: sectionName, questions: [] });
        seenSections.add(sectionName);
      }
      orderedItems.find((item) => item.name === sectionName)?.questions.push(question);
    } else {
      orderedItems.push({ type: 'standalone', question });
    }
  });

  if (!answers || !questionnaire || !patient) return null;

  return (
    <>
      <GlobalStyles
        styles={{
          '@media print': {
            '@page': {
              size: 'A4',
              margin: '1.5cm',
            },
            body: {
              WebkitPrintColorAdjust: 'exact',
              fontSize: '11pt',
              lineHeight: 1.4,
            },
            h1: { fontSize: '16pt' },
            h2: { fontSize: '14pt' },
            h3: { fontSize: '12pt' },
            '.no-print': { display: 'none' },
          },
        }}
      />

      <Box
        ref={ref}
        sx={{
          maxWidth: '700px',
          margin: '0 auto',
          p: 3,
          backgroundColor: 'white',
          color: 'black',
          '@media print': {
            padding: '0',
            maxWidth: '100%',
          },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            mb: 2,
            '@media print': {
              flexDirection: 'column',
              gap: 0.5,
              marginBottom: '0.5cm',
            },
          }}
        >
          <Typography variant="caption" component="div">
            {moment(answers.answer_timestamp).format('lll')}
            {answers.created_by && patient.user.id !== answers.created_by?.id && (
              <div>
                {t('By')}: {answers.created_by.first_name} {answers.created_by.last_name}
              </div>
            )}
          </Typography>

          {/* Compact Header */}
          <Box
            sx={{
              display: 'grid',
              gap: 0.5,
              '@media print': {
                borderBottom: '0.5px solid #ddd',
                paddingBottom: '0.25cm',
              },
            }}
          >
            <Typography variant="body2" sx={{ fontWeight: 500 }}>
              {`${patient.firstName} ${patient.lastName}`}
            </Typography>
            {patient.birthDate && (
              <Typography variant="caption" component="div">
                {`${t('Birth Date')}: ${moment(patient.birthDate).format('ll')}`}
              </Typography>
            )}
            {patient.phoneNumber && (
              <Typography variant="caption" component="div">
                {`${t('Phone')}: ${formatPhoneNumber(patient.phoneNumber)}`}
              </Typography>
            )}
            {patient.hin?.hin_number && (
              <Typography variant="caption" component="div">
                {`${t('Health Insurance Number')}: ${patient.hin.hin_number}`}
              </Typography>
            )}
          </Box>
        </Box>

        {/* Main Content - Grouped and Standalone Questions */}
        {orderedItems.map((item, index) => (
          <Box 
            key={index} 
            sx={{ 
              border: '2px solid #ddd', 
              borderRadius: 2, 
              p: 2, 
              mb: 3,
              '@media print': {
                pageBreakInside: 'avoid',
                breakInside: 'avoid',
              }
            }}
          >
            {/* Section Header */}
            {item.type === 'section' && (
              <>
                <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 2, pb: 1, borderBottom: '2px solid #000' }}>
                  {item.name}
                </Typography>

                {/* Grouped Questions */}
                {item.questions.map((question) => (
                  <Box 
                    key={question.id}
                    sx={{
                      '@media print': {
                        pageBreakInside: 'avoid',
                        breakInside: 'avoid',
                      }
                    }}
                  >
                    <AnswerFormatter question={question} data={answers} />
                  </Box>
                ))}
              </>
            )}

            {/* Standalone Questions */}
            {item.type === 'standalone' && (
              <>
                <Box 
                  key={item.question.id} 
                  sx={{ 
                    mb: 2,
                    '@media print': {
                      pageBreakInside: 'avoid',
                      breakInside: 'avoid',
                    }
                  }}
                >
                  <AnswerFormatter question={item.question} data={answers} />
                </Box>
              </>
            )}
          </Box>
        ))}
      </Box>
    </>
  );
});

export default PrintableAnswers;
