import ClearIcon from '@mui/icons-material/Clear';
import FilterListIcon from '@mui/icons-material/FilterList';
import SearchIcon from '@mui/icons-material/Search';
import {
  Badge, Box, Chip, IconButton, InputAdornment, Menu,
  MenuItem, Skeleton, TextField, Tooltip, Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React, { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { greyboxApiActions } from '../redux/api';

const FilterButton = (props) => {
  const { filters, setFilters, showCareTeams = false } = props;
  const { careTeam, label } = greyboxApiActions;
  const { t } = useTranslation();
  const { accountId } = useSelector((state) => state.user);
  const { clinic } = useSelector((state) => state.clinic);
  const [filterMenuAnchorEl, setFilterMenuAnchorEl] = useState(null);
  const [labelSearchQuery, setLabelSearchQuery] = useState('');
  const isFilterMenuOpen = Boolean(filterMenuAnchorEl);
  const theme = useTheme();
  const careTeamSelector = careTeam.list({ participant: `Practitioner/${accountId}`, clinic: clinic.id }, { skip: !isFilterMenuOpen });
  const labelSelector = label.list({
    is_root_node: true, clinic: clinic.id, system: false,
  }, { skip: !isFilterMenuOpen });
  const hasFilter = filters.length > 0;

  const filteredLabels = useMemo(() => {
    if (!labelSelector.data) return [];
    if (!labelSearchQuery.trim()) return labelSelector.data;
    
    return labelSelector.data.filter(label => 
      label.name.toLowerCase().includes(labelSearchQuery.toLowerCase())
    );
  }, [labelSelector.data, labelSearchQuery]);

  return (
    <>
      <Box>
        <Badge badgeContent={filters.length} invisible={!hasFilter} color="primary">
          <Tooltip title={t('Filter')}>
            <IconButton
              sx={{ backgroundColor: theme.palette.background.paper, ml: 1 }}
              onClick={(e) => setFilterMenuAnchorEl(e.currentTarget)}
            >
              <FilterListIcon />
            </IconButton>
          </Tooltip>
        </Badge>
      </Box>
      <Menu
        anchorEl={filterMenuAnchorEl}
        open={isFilterMenuOpen}
        onClose={() => setFilterMenuAnchorEl(null)}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          sx={{ p: 1 }}
        >
          <Typography variant="h6">
            {t('Filter')}
          </Typography>
          <IconButton onClick={() => setFilterMenuAnchorEl(null)}>
            <ClearIcon />
          </IconButton>
        </Box>
        {hasFilter && (
          <>
            <MenuItem
              onClick={() => { setFilters([]); setFilterMenuAnchorEl(null); }}
              sx={{ textDecoration: 'underline' }}
            >
              {t('Clear all filters')}
            </MenuItem>
            <Box
              sx={{
                p: 1,
                display: 'flex',
                flexWrap: 'wrap',
                minHeight: '70px',
                borderBottom: `1px solid ${theme.palette.divider}`,
              }}
            >
              {filters.map((f) => (
                <Chip
                  onDelete={() => setFilters(filters.filter((r) => r.id !== f.id))}
                  label={f.name}
                  sx={{ m: 0.5 }}
                />
              ))}
            </Box>
          </>
        )}
        {showCareTeams && careTeamSelector.data?.entry?.length > 0 && (
          <Box minWidth="350px" sx={{ borderTop: `1px solid ${theme.palette.divider}` }}>
            <Typography sx={{ my: 1, ml: 1 }}>
              {t('Care Team')}
            </Typography>
            <Box sx={{ maxHeight: '200px', overflowY: 'auto' }}>
              {careTeamSelector.isLoading ? (
                Array.from(new Array(3)).map((_, idx) => (
                  <MenuItem key={idx}>
                    <Skeleton variant="text" width="100px" />
                  </MenuItem>
                ))
              ) : (
                  careTeamSelector.data?.entry?.map((r) => (
                    <MenuItem
                      key={r.resource.id}
                      value={r.resource.id}
                      selected={filters.some((f) => f.id === r.resource.id)}
                      onClick={() => {
                        if (filters.some((f) => f.id === r.resource.id)) {
                          setFilters(filters.filter((f) => f.id !== r.resource.id));
                        } else {
                          setFilters([...filters, { id: r.resource.id, type: 'careTeam', name: r.resource.name }]);
                        }
                      }}
                    >
                      {r.resource.name}
                    </MenuItem>
                  ))
              )}
            </Box>
          </Box>
        )}
        <Box minWidth="350px" sx={{ borderTop: `1px solid ${theme.palette.divider}` }}>
          <Typography sx={{ my: 1, ml: 1 }}>
            {t('Labels')}
          </Typography>
          
          <Box sx={{ px: 1, mb: 1 }}>
            <TextField
              placeholder={t('Search labels...')}
              variant="outlined"
              fullWidth
              size="small"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon fontSize="small" />
                  </InputAdornment>
                ),
                endAdornment: labelSearchQuery ? (
                  <InputAdornment position="end">
                    <IconButton
                      edge="end"
                      onClick={() => setLabelSearchQuery('')}
                      size="small"
                    >
                      <ClearIcon fontSize="small" />
                    </IconButton>
                  </InputAdornment>
                ) : null
              }}
              value={labelSearchQuery}
              onChange={(e) => setLabelSearchQuery(e.target.value)}
            />
          </Box>
          
          <Box sx={{ maxHeight: '200px', overflowY: 'auto' }}>
            {labelSelector.isLoading ? (
              Array.from(new Array(3)).map((_, idx) => (
                <MenuItem key={idx}>
                  <Skeleton variant="text" width="100px" />
                </MenuItem>
              ))
            ) : (
              filteredLabels.length > 0 ? (
                filteredLabels.map((l) => (
                  <MenuItem
                    key={l.id}
                    value={l.id}
                    selected={filters.some((f) => f.id === l.id)}
                    onClick={() => {
                      if (filters.some((f) => f.id === l.id)) {
                        setFilters(filters.filter((f) => f.id !== l.id));
                      } else {
                        setFilters([...filters, { id: l.id, type: 'label', name: l.name }]);
                      }
                    }}
                  >
                    {l.name}
                  </MenuItem>
                ))
              ) : (
                <Box sx={{ p: 2, textAlign: 'center' }}>
                  <Typography variant="body2" color="text.secondary">
                    {labelSearchQuery ? t('No matching labels found') : t('No labels available')}
                  </Typography>
                </Box>
              )
            )}
          </Box>
        </Box>
      </Menu>
    </>
  );
};

export default FilterButton;
