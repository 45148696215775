import { Box, Paper, Tab, Tabs } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import BasicInfoTab from './informations/BasicInfoTab';
import ContactTab from './informations/ContactTab';
import ThresholdTab from './informations/ThresholdTab';
import ExtraInfoTab from './informations/ExtraInfoTab';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} {...other}>
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

export default function BasicTabs() {
  const [value, setValue] = React.useState(0);
  const { access } = useSelector((state) => state.user);
  const { clinic } = useSelector((state) => state.clinic);
  const { t } = useTranslation();
  const extraInfosTabs = clinic?.config.patient_extra_infos || []; 

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const isThresholdFeatureEnabled = clinic?.config?.features_enable?.threshold || false;

  // Fixed tabs count
  let fixedTabsCount = 2;
  if (access === 'PT' && isThresholdFeatureEnabled) {
    fixedTabsCount += 1;
  }

  return (
    <Paper sx={{ width: '100%', height: 'fit-content' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab label={t('Informations')} />
          <Tab label={t('Contacts')} />
          {access === 'PT' && isThresholdFeatureEnabled && <Tab label={t('Thresholds')} />}
          {access === 'PT' && extraInfosTabs.map((item) => <Tab label={t(item.title)} key={item.key} />)}
        </Tabs>
      </Box>
      {/* Fixed panels */}
      <CustomTabPanel value={value} index={0}>
        <BasicInfoTab />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <ContactTab />
      </CustomTabPanel>
      {access === 'PT' && isThresholdFeatureEnabled && (
        <CustomTabPanel value={value} index={2}>
          <ThresholdTab />
        </CustomTabPanel>
      )}
      {/* Dynamically rendered panels for extra infos */}
      {access === 'PT' &&
        extraInfosTabs.map((item, idx) => {
          return (
            <CustomTabPanel value={value} index={fixedTabsCount + idx} key={item.key}>
              <ExtraInfoTab schemaKey={item.key} />
            </CustomTabPanel>
          );
        })}
    </Paper>
  );
}
