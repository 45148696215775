import { Box, Typography, useMediaQuery } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { greyboxApiActions } from '../../redux/api';
import ChartContainer from './ChartContainer';
import i18n from '../../i18n';

function answerFormatterText({ question, data, hasTitle = true }) {
  const t = i18n.t; // Simplified translation function

  if (!data) {
    return t('No answer');
  }

  if (question.type_of_q === 'MC') {
    const answer = data.answers.find(
      (item) => item.question === question.mc_question.id && item.type === 'MC'
    );

    if (answer) {
      let text = '';
      if (hasTitle) {
        text += question.mc_question.mc_question_text + ': ';
      }
      if (Array.isArray(answer.answer)) {
        answer.answer.forEach((ans) => {
          const choiceText = question.mc_question[`mc_question_choice_${ans.toLowerCase()}`];
          text += '- ' + choiceText + '\n';
        });
      } else {
        const choiceText = question.mc_question[`mc_question_choice_${answer.answer.toLowerCase()}`];
        text += choiceText;
      }
      return text.trim();
    }
  } else if (question.type_of_q === 'TF') {
    const answer = data.answers.find(
      (item) => item.question === question.tf_question.id && item.type === 'TF'
    );

    if (answer) {
      let text = '';
      if (hasTitle) {
        text += question.tf_question.tf_question_text + ': ';
      }
      text += answer.answer ? t('Yes') : t('No');
      return text.trim();
    }
  } else if (question.type_of_q === 'SA') {
    const answer = data.answers.find(
      (item) => item.question === question.sa_question.id && item.type === 'SA'
    );

    if (answer) {
      let text = '';
      if (hasTitle) {
        text += question.sa_question.sa_question_text + ': ';
      }
      text += answer.answer;
      return text.trim();
    }
  } else if (question.type_of_q === 'RA') {
    const answer = data.answers.find(
      (item) => item.question === question.ra_question.id && item.type === 'RA'
    );

    if (answer) {
      let text = '';
      if (hasTitle) {
        text += question.ra_question.ra_question_text + ': ';
      }
      text += answer.answer;
      return text.trim();
    }
  }

  return null;
}


/**
 * A horizontal bar chart showing the answers of the latest questionnaire.
 * Each bar represents a question, and the length represents the numeric value.
 */
const LatestQuestionnaireResultChart = (props) => {
  const { t } = useTranslation();
  const { clinic } = useSelector((state) => state.clinic);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const { questionnaireId, patientUuid, detailed = false, autoHeight = false } = props;
  const { questionnaireAnswers, questionnaire } = greyboxApiActions;
  const questionnaireSelector = questionnaire.get(questionnaireId);
  
  // Get the list to find the latest ID
  const { data: listData, isFetching: isListFetching } = questionnaireAnswers.list({
    patient: patientUuid,
    questionnaire: questionnaireId,
    clinic: clinic.id,
    completed: true,
    page_size: 1
  });
  
  // Get the latest ID - we must ensure this is a stable value or null
  const latestId = listData?.results?.[0]?.id || null;
  
  // Always call the hook, but with skipQuery if no ID is available
  const { data, isFetching } = questionnaireAnswers.get(latestId, {
    skip: !latestId
  });
  
  const getHeights = () => {
    if (isMobile) {
      return '280px';
    } if (autoHeight) {
      return '400px';
    }
    return '100%';
  };

  if (isFetching || isListFetching || questionnaireSelector.isFetching) {
    return <Skeleton variant="rect" width="100%" height="420px" />;
  }

  if (!data || !listData || listData.count === 0) {
    return (
      <ChartContainer
        patientUuid={patientUuid}
        url={`/patient-profile/${patientUuid}/questionnaire/${questionnaireId}`}
        name={questionnaireSelector.data.name}
        detailed={detailed}
      >
        <Box display="flex" justifyContent="center" alignItems="center" height={getHeights()}>
          <Typography variant="h6" color="textSecondary">
            {t('No data available for this questionnaire.')}
          </Typography>
        </Box>
      </ChartContainer>
    );
  }

  // Use the data from get() API call
  // Prepare the chart data dynamically
  const chartData = data.answers.map((answer, index) => ({
    value: parseFloat(answer.score) ? answer.score : "0.20",
    question: t('Question {{index}}', { index: index + 1 }),
  }));
  
  const series = [{
    name: '',
    data: chartData.map((item) => item.value),
  }];

  const options = {
    chart: {
      type: 'bar',
      zoom: {
        enabled: true,
        type: 'x',
        autoScaleYaxis: true,
        allowMouseWheelZoom: false,
      }
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        borderRadiusApplication: 'end',
        horizontal: true,
      }
    },
    xaxis: {
      categories: chartData.map((item) => item.question),
    },
    tooltip: {
      y: {
        formatter: (value, { series, seriesIndex, dataPointIndex, w }) => {
          const answer = data.answers[dataPointIndex];
          const actualValue = chartData[dataPointIndex].value;
          
          if (answer) {
            const question = questionnaireSelector.data.questions.find(q => q.id == answer.base_question);
            return answerFormatterText({ question, data });
          }
          else {
            // Show actual value even when it's 0
            return actualValue.toString();
          }
        }
      }
    },
    dataLabels: {
      enabled: false,
    }
  };

  return (
    <ChartContainer
      patientUuid={patientUuid}
      url={`/patient-profile/${patientUuid}/questionnaire/${questionnaireId}`}
      name={questionnaireSelector.data.name}
      detailed={detailed}
    >
      <ReactApexChart
        options={options}
        series={series}
        type="bar"
        height={getHeights()}
      />
    </ChartContainer>
  );
};

export default LatestQuestionnaireResultChart;
