import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  Checkbox,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  Box,
  Typography,
  RadioGroup,
  Radio,
  Slider,
  FormLabel,
  FormGroup,
} from '@mui/material';
import { DatePicker, TimePicker, DateTimePicker } from '../../form-inputs';

// Field type options based on Python schema
const FIELD_TYPES = {
  TEXT: 'text',
  TEXTAREA: 'textarea',
  NUMBER: 'number',
  RANGE: 'range',
  DATE: 'date',
  DATETIME: 'datetime',
  TIME: 'time',
  CHECKBOX: 'checkbox',
  RADIO: 'radio',
  SELECT: 'select',
};

/**
 * Renders a form field based on the PatientReportClinicParam schema
 */
const DynamicFormField = ({ param, formik, fullWidth = true }) => {
  const { t } = useTranslation();
  const { key, label, type, options, required, placeholder } = param;
  
  const fieldLabel = label || t(key.replace(/_/g, ' '));
  const isFieldRequired = required || false;

  const getFormattedHelperText = () => {
    if (formik.touched[key] && formik.errors[key]) {
      return formik.errors[key];
    }
    return '';
  };

  // Check if field has error
  const hasError = formik.touched[key] && Boolean(formik.errors[key]);
  
  switch (type) {
    case FIELD_TYPES.DATE:
      return (
        <DatePicker
          label={fieldLabel}
          name={key}
          formik={formik}
          fullWidth={fullWidth}
          required={isFieldRequired}
          helperText={getFormattedHelperText()}
          placeholder={placeholder}
        />
      );
      
    case FIELD_TYPES.DATETIME:
      return (
        <DateTimePicker
          label={fieldLabel}
          name={key}
          formik={formik}
          fullWidth={fullWidth}
          required={isFieldRequired}
          helperText={getFormattedHelperText()}
          placeholder={placeholder}
        />
      );
      
    case FIELD_TYPES.TIME:
      return (
        <TimePicker
          label={fieldLabel}
          name={key}
          formik={formik}
          fullWidth={fullWidth}
          required={isFieldRequired}
          helperText={getFormattedHelperText()}
          placeholder={placeholder}
        />
      );
      
    case FIELD_TYPES.CHECKBOX:
      return (
        <FormControl 
          component="fieldset" 
          fullWidth={fullWidth} 
          sx={{ my: 1 }}
          required={isFieldRequired}
          error={hasError}
        >
          <FormControlLabel
            control={
              <Checkbox
                name={key}
                checked={formik.values[key] ?? false}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            }
            label={fieldLabel}
          />
          {getFormattedHelperText() && (
            <FormHelperText error={hasError}>
              {getFormattedHelperText()}
            </FormHelperText>
          )}
        </FormControl>
      );
      
    case FIELD_TYPES.RADIO:
      if (!options || !options.length) {
        console.warn(`Radio field "${key}" has no options defined`);
        return null;
      }
      
      return (
        <FormControl 
          component="fieldset" 
          fullWidth={fullWidth} 
          sx={{ my: 1 }}
          required={isFieldRequired}
          error={hasError}
        >
          <FormLabel id={`${key}-radio-label`}>{fieldLabel}</FormLabel>
          <RadioGroup
            aria-labelledby={`${key}-radio-label`}
            name={key}
            value={formik.values[key] || ''}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          >
            {options.map((option) => (
              <FormControlLabel 
                key={option} 
                value={option} 
                control={<Radio />} 
                label={option} 
              />
            ))}
          </RadioGroup>
          {getFormattedHelperText() && (
            <FormHelperText error={hasError}>
              {getFormattedHelperText()}
            </FormHelperText>
          )}
        </FormControl>
      );
      
    case FIELD_TYPES.SELECT:
      if (!options || !options.length) {
        console.warn(`Select field "${key}" has no options defined`);
        return null;
      }
      
      return (
        <FormControl
          fullWidth={fullWidth}
          required={isFieldRequired}
          error={hasError}
          sx={{ my: 1 }}
        >
          <InputLabel id={`${key}-select-label`}>{fieldLabel}</InputLabel>
          <Select
            labelId={`${key}-select-label`}
            id={key}
            name={key}
            value={formik.values[key] || ''}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            label={fieldLabel}
            placeholder={placeholder}
          >
            {options.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>{getFormattedHelperText()}</FormHelperText>
        </FormControl>
      );
      
    case FIELD_TYPES.TEXTAREA:
      return (
        <TextField
          fullWidth={fullWidth}
          id={key}
          name={key}
          label={fieldLabel}
          value={formik.values[key] || ''}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={hasError}
          helperText={getFormattedHelperText()}
          margin="normal"
          multiline
          rows={4}
          required={isFieldRequired}
          placeholder={placeholder}
        />
      );
      
    case FIELD_TYPES.RANGE:
      // Default min/max values
      const min = 0;
      const max = 100;
      const step = 1;
      
      return (
        <Box sx={{ my: 3, width: '100%' }}>
          <FormControl
            fullWidth={fullWidth}
            required={isFieldRequired}
            error={hasError}
          >
            <Typography id={`${key}-slider-label`} gutterBottom>
              {fieldLabel}: {formik.values[key] || min}
            </Typography>
            <Slider
              name={key}
              value={formik.values[key] || min}
              onChange={(_, newValue) => {
                formik.setFieldValue(key, newValue);
              }}
              onBlur={() => formik.setFieldTouched(key, true)}
              aria-labelledby={`${key}-slider-label`}
              min={min}
              max={max}
              step={step}
              marks
              valueLabelDisplay="auto"
            />
            {getFormattedHelperText() && (
              <FormHelperText error={hasError}>
                {getFormattedHelperText()}
              </FormHelperText>
            )}
          </FormControl>
        </Box>
      );
      
    case FIELD_TYPES.NUMBER:
      return (
        <TextField
          fullWidth={fullWidth}
          id={key}
          name={key}
          label={fieldLabel}
          value={formik.values[key] || ''}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={hasError}
          helperText={getFormattedHelperText()}
          type="number"
          margin="normal"
          required={isFieldRequired}
          placeholder={placeholder}
        />
      );
      
    case FIELD_TYPES.TEXT:
    default:
      return (
        <TextField
          fullWidth={fullWidth}
          id={key}
          name={key}
          label={fieldLabel}
          value={formik.values[key] || ''}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={hasError}
          helperText={getFormattedHelperText()}
          margin="normal"
          required={isFieldRequired}
          placeholder={placeholder}
        />
      );
  }
};

/**
 * Renders a form with fields dynamically generated from the ReportParamsDefinition schema
 */
const DynamicForm = ({ schema, formik }) => {
  const { t } = useTranslation();

  // Handle both original JSON schema format and new ReportParamsDefinition format
  if (schema?.properties) {
    // Original JSON schema format
    const allFields = Object.entries(schema.properties || {});
    
    return (
      <Box sx={{ mt: 2 }}>
        <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', mt: 1 }}>
          {t('Report Parameters')}
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
          {allFields.map(([key, property]) => {
            // Convert JSON schema property to PatientReportClinicParam format
            const param = {
              key,
              label: property.title,
              type: property.format === 'date' ? 'date' : 
                    property.type === 'boolean' ? 'checkbox' :
                    property.enum ? 'select' : 
                    property.type === 'number' || property.type === 'integer' ? 'number' : 
                    'text',
              options: property.enum,
              required: schema.required?.includes(key) || property.required,
              placeholder: property.description
            };
            
            return (
              <DynamicFormField 
                key={key} 
                param={param} 
                formik={formik} 
                fullWidth
              />
            );
          })}
        </Box>
      </Box>
    );
  } else if (schema?.params) {
    // New ReportParamsDefinition format with params array
    return (
      <Box sx={{ mt: 2 }}>
        <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', mt: 1 }}>
          {t('Report Parameters')}
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
          {schema.params.map((param) => (
            <DynamicFormField 
              key={param.key} 
              param={param} 
              formik={formik} 
              fullWidth
            />
          ))}
        </Box>
      </Box>
    );
  }
  
  return null;
};

export { DynamicFormField, DynamicForm };
