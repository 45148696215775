import React, { useEffect, useState } from 'react';
import { Box, Button, Collapse, IconButton, Typography, useTheme } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import CheckIcon from '@mui/icons-material/Check';
import { useTranslation } from 'react-i18next';
import { Question } from '../../../../types';
import { useQuestionnairePanelContext } from '../../../../contexts/QuestionnairePanelContext';

const PanelWrapper: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <div
    style={{
      padding: '35px',
      borderRadius: '10px',
      border: '1px solid #ccc',
      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.5)',
    }}
  >
    {children}
  </div>
);

type Props = {
  children: React.ReactNode;
  goNext: () => void;
  hasBeenCompleted: boolean;
  setShowSummary: (showSummary: boolean) => void;
  hasAnswered: boolean;
  isCurrent: boolean;
  question: Question;
  required?: boolean;
};

const PanelNavigation: React.FC<Props> = ({
  children,
  goNext,
  hasBeenCompleted,
  setShowSummary,
  hasAnswered,
  isCurrent,
  question,
  required = true,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [expanded, setExpanded] = useState(false);
  const {isRegexValid, setIsRegexValid} = useQuestionnairePanelContext();

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Enter' && !(required && !hasAnswered) && question.type_of_q === 'SA') {
        goNext();
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [goNext, required, hasAnswered]);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        minHeight: '90vh',
        width: '90%',
        maxWidth: 900,
        margin: '0 auto',
      }}
    >
      {question.section && (
        <Box
          sx={{
            textAlign: 'left',
            mb: 3,
            width: '100%',
            maxWidth: 900,
          }}
        >
          <Typography
            variant="h4"
            sx={{
              fontWeight: 'bold',
              color: theme.palette.text.primary,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            {question.section.name}
            <IconButton onClick={() => setExpanded(!expanded)} sx={{ ml: 1 }}>
              {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
          </Typography>

          <Collapse in={expanded}>
            <Typography variant="subtitle2" sx={{ color: theme.palette.text.secondary, mt: 1 }}>
              {question.section.description}
            </Typography>
          </Collapse>
        </Box>
      )}

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          flex: 1,
          width: '100%',
        }}
      >
        {hasBeenCompleted && (
          <Button
            variant="outlined"
            sx={{ width: 'fit-content', alignSelf: 'flex-start', mb: 2, color: theme.palette.success.main }}
            startIcon={<ArrowBackIcon />}
            onClick={() => setShowSummary(true)}
          >
            {t('Back to summary')}
          </Button>
        )}

        <PanelWrapper>{children}</PanelWrapper>

        <Box sx={{ textAlign: 'center', display: 'flex', mt: 2 }}>
          <Button
            onClick={goNext}
            disabled={(required && !hasAnswered) || !isRegexValid}
            sx={{
              mt: 1,
              mb: 5,
              backgroundColor: theme.palette.secondary.main,
              color: theme.palette.getContrastText(theme.palette.secondary.main),
              '&:hover': {
                backgroundColor: theme.palette.secondary.dark,
              },
            }}
            size="large"
            data-cy={isCurrent ? 'ok-button' : ''}
            variant="contained"
            endIcon={<CheckIcon />}
          >
            {!required && !hasAnswered ? t('Skip') : 'OK'}
          </Button>
        </Box>
      </Box>
    </div>
  );
};

export default PanelNavigation;
