import { Add, MoreVert } from '@mui/icons-material';
import {
  Box,
  FormControlLabel,
  FormLabel,
  IconButton,
  Menu,
  MenuItem,
  Switch,
  Typography,
} from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FieldArray, useFormikContext } from 'formik';
import TelecomModal from '../TelecomModal';
import Row from '../../Row';
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import FaxIcon from '@mui/icons-material/Fax';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';

const getIcon = (system) => {
  switch (system) {
    case 'phone':
      return <PhoneIcon />;
    case 'fax':
      return <FaxIcon />;
    case 'email':
      return <EmailIcon />;
    default:
      return <SmartphoneIcon />;
  }
};

const ContactTelecom = ({ contactIndex, disabled }) => {
  const [editIndex, setEditIndex] = React.useState(null);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [menuIndex, setMenuIndex] = React.useState(null);
  const { t } = useTranslation();
  const { values, setFieldValue } = useFormikContext();
  const telecomPath = `contact.${contactIndex}.telecom`;

  const handleEdit = (index) => {
    if (disabled) return;
    setEditIndex(index);
    setModalOpen(true);
    handleMenuClose();
  };

  const handleRankChange = (index, rank, replace) => {
    if (disabled) return;
    const updatedTelecoms = values.contact[contactIndex].telecom.map((telecom, i) => {
      if (i === index) {
        return { ...telecom, rank: rank ? 1 : 0 };
      } else if (rank) {
        return { ...telecom, rank: 0 };
      } else {
        return telecom;
      }
    });
    setFieldValue(telecomPath, updatedTelecoms);
    replace(index, updatedTelecoms[index]);
  };

  const handleAdd = (telecom, arrayHelpers) => {
    if (disabled) return;
    if (editIndex !== null) {
      arrayHelpers.replace(editIndex, telecom);
      setEditIndex(null);
    } else {
      arrayHelpers.push(telecom);
    }
  };

  const handleRemove = (index, arrayHelpers) => {
    if (disabled) return;
    arrayHelpers.remove(index);
    handleMenuClose();
  };

  const handleMenuOpen = (event, index) => {
    if (disabled) return;
    setAnchorEl(event.currentTarget);
    setMenuIndex(index);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setMenuIndex(null);
  };

  return (
    <FieldArray
      name={telecomPath}
      render={(arrayHelpers) => (
        <>
          <Box sx={{ display: 'flex', alignItems: 'center', mx: 1 }}>
            <FormLabel sx={{ color: disabled ? 'text.disabled' : 'inherit' }}>
              {t('Contact Informations')}
            </FormLabel>
            {!disabled && (<IconButton
              size="small"
              sx={{ ml: 1 }}
              onClick={() => setModalOpen(true)}
            >
              <Add />
            </IconButton>)}
          </Box>
          <Row>
            {values.contact[contactIndex].telecom?.map((id, index) => (
              <Box
                key={index}
                sx={{
                  border: (theme) => `1px solid ${theme.palette.divider}`,
                  display: 'flex',
                  flexDirection: 'row',
                  mx: 1,
                  borderRadius: 3,
                  p: 1,
                  opacity: disabled ? 0.7 : 1,
                }}
              >
                <Box sx={{ pl: 1 }} display="flex" flexDirection="column">
                  <FormControlLabel
                    control={
                      <Switch
                        checked={id.rank === 1}
                        onChange={(event) =>
                          handleRankChange(index, event.target.checked, arrayHelpers.replace)
                        }
                        disabled={disabled}
                      />
                    }
                    label={t('Preferred')}
                    disabled={disabled}
                  />
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    {getIcon(id.system)}
                    <Typography sx={{ ml: 1, color: disabled ? 'text.disabled' : 'inherit' }}>
                      {`${id.value}${id.use ? ` (${t(id.use)})` : ''}`}
                    </Typography>
                  </Box>
                </Box>
                <Box sx={{ ml: 'auto', alignSelf: 'flex-start' }}>
                  <IconButton
                    size="small"
                    onClick={(event) => handleMenuOpen(event, index)}
                    disabled={disabled}
                  >
                    <MoreVert sx={{ transform: 'scale(0.8)' }} />
                  </IconButton>
                  <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl) && menuIndex === index}
                    onClose={handleMenuClose}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                  >
                    <MenuItem
                      onClick={() => handleEdit(index)}
                      disabled={disabled}
                    >
                      {t('Edit')}
                    </MenuItem>
                    <MenuItem
                      sx={{ color: (theme) => theme.palette.error.main }}
                      onClick={() => handleRemove(index, arrayHelpers)}
                      disabled={disabled}
                    >
                      {t('Remove')}
                    </MenuItem>
                  </Menu>
                </Box>
              </Box>
            ))}
          </Row>
          {modalOpen && (
            <TelecomModal
              open={modalOpen}
              onClose={() => setModalOpen(false)}
              onChange={(telecom) => handleAdd(telecom, arrayHelpers)}
              telecom={editIndex !== null ? values.contact[contactIndex].telecom[editIndex] : {}}
              disabled={disabled}
            />
          )}
        </>
      )}
    />
  );
};

export default ContactTelecom;