import React from 'react';
import { Box, Typography, Divider } from '@mui/material';

const PageHeader = ({ title, subtitle, action }) => {
  return (
    <Box sx={{ mb: 4 }}>
      <Box display="flex" alignItems="center" justifyContent="space-between" mb={1}>
        <Box>
          <Typography variant="h4" fontWeight="bold">
            {title}
          </Typography>
          {subtitle && (
            <Typography variant="body1" color="text.secondary">
              {subtitle}
            </Typography>
          )}
        </Box>
        {action && (
          <Box>
            {action}
          </Box>
        )}
      </Box>
      <Divider />
    </Box>
  );
};

export default PageHeader;