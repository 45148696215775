import React from 'react';
import { DateTimePicker as MuiDateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Box, SxProps, Theme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FormikProps } from 'formik';
import moment from 'moment';

interface DateTimePickerProps {
  label: string;
  name: string;
  formik: FormikProps<any>;
  fullWidth?: boolean;
  required?: boolean;
  helperText?: string;
  placeholder?: string;
  disablePast?: boolean;
  disableFuture?: boolean;
  minDate?: moment.Moment;
  disabled?: boolean;
  sx?: SxProps<Theme>;
}

const DateTimePicker: React.FC<DateTimePickerProps> = ({
  label,
  name,
  formik,
  fullWidth = true,
  required = false,
  helperText = '',
  placeholder = '',
  disablePast = false,
  disableFuture = false,
  minDate,
  disabled = false,
  sx = { my: 1 },
}) => {
  const { t, i18n } = useTranslation();
  const hasError = formik.touched[name] && Boolean(formik.errors[name]);

  return (
    <Box sx={{ ...sx, width: fullWidth ? '100%' : 'auto' }}>
      <LocalizationProvider
        dateAdapter={AdapterMoment}
        adapterLocale={i18n.language}
      >
        <MuiDateTimePicker
          label={label}
          value={formik.values[name] || null}
          onChange={(value) => {
            formik.setFieldValue(name, value);
          }}
          onClose={() => formik.setFieldTouched(name, true)}
          disabled={disabled}
          disablePast={disablePast}
          disableFuture={disableFuture}
          minDate={minDate}
          ampm={false}
          sx={{ width: '100%' }}
          slotProps={{
            textField: {
              id: name,
              fullWidth: fullWidth,
              error: hasError,
              helperText: helperText,
              placeholder: placeholder || t('Select date and time'),
              onBlur: () => formik.setFieldTouched(name, true),
              required: required,
            },
          }}
        />
      </LocalizationProvider>
    </Box>
  );
};

export default DateTimePicker;
