import {
  Box,
  Paper,
  Theme,
  useMediaQuery,
  Typography,
} from '@mui/material';
import React from 'react';
import { useParams } from 'react-router-dom';
import { ChatBox, ThreadSelector } from '../components/chat';

const MessagesDashboard = () => {
  const { threadId } = useParams();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  if (isMobile) {
    if (!threadId) {
      return (
        <Box sx={{ height: 'calc(100vh - 88px)' }}>
          <ThreadSelector />
        </Box>
      );
    }

    return (
      <Paper sx={{ height: 'calc(100vh - 88px)' }}>
        <ChatBox threadId={threadId} />
      </Paper>
    );
  }

  return (
    <Box sx={{
      display: 'flex',
      height: 'calc(100vh - 100px)',
    }}
    >
      <Box sx={{ flex: '0 0 300px' }}>
        <ThreadSelector />
      </Box>
      {threadId ? (
        <Paper sx={{ 
          width: '100%',
          ml: 2,
          display: 'flex',
          flexDirection: 'column'
        }}>
          <ChatBox threadId={threadId} />
        </Paper>
      ) : (
        <Paper sx={{
          width: '100%',
          ml: 2,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}>
          <Box sx={{ textAlign: 'center', p: 4 }}>
            <Typography variant="h5" color="textSecondary" gutterBottom>
              Select a conversation or start a new AI chat
            </Typography>
            <Typography variant="body1" color="textSecondary">
              Choose an existing thread from the left panel or click "Chat with AI" to start a new conversation with an AI agent.
            </Typography>
          </Box>
        </Paper>
      )}
    </Box>
  );
};

export default MessagesDashboard;