import { Box, Link } from '@mui/material';
import React from 'react';

type QuestionMediaProps = {
    mediaType: 'image' | 'video' | 'audio' | 'document' | 'none';
    mediaUrl?: string;
};

const QuestionMedia = ({ mediaType, mediaUrl }: QuestionMediaProps) => {
    if (mediaType === 'none' || !mediaUrl) return null;

    const mediaStyles = {
        maxWidth: '100%',
        height: 'auto',
        outline: 'none',
        border: 'none',
        borderRadius: '4px'
    };

    switch (mediaType) {
        case 'image':
            return (
                <Box sx={{ my: 2, textAlign: 'center' }}>
                    <img
                        src={mediaUrl}
                        alt="Question media"
                        style={{ ...mediaStyles, maxHeight: '400px' }}
                        data-cy="question-media-image"
                    />
                </Box>
            );
        case 'video':
            return (
                <Box sx={{ my: 2 }}>
                    <video
                        controls
                        src={mediaUrl}
                        style={{ ...mediaStyles, maxHeight: '400px' }}
                        data-cy="question-media-video"
                    />
                </Box>
            );
        case 'audio':
            return (
                <Box sx={{ my: 2 }}>
                    <audio
                        controls
                        src={mediaUrl}
                        style={{ width: '100%' }}
                        data-cy="question-media-audio"
                    />
                </Box>
            );
        case 'document':
            return (
                <Box sx={{ my: 2 }}>
                    <Link
                        href={mediaUrl}
                        download
                        target="_blank"
                        rel="noopener"
                        data-cy="question-media-document"
                    >
                        Download Document
                    </Link>
                </Box>
            );
        default:
            return null;
    }
};

export default QuestionMedia;
