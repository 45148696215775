import React, { useState, useEffect } from 'react';
import AddIcon from '@mui/icons-material/Add';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
  IconButton, Menu, MenuItem,
  Paper,
  Stack, Table,
  TableBody, TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { greyboxApiActions } from '../../../redux/api';
import { QuestionnaireAnswers } from '../../../types';
import TableWrapper from '../../TableWrapper';
import { NoData } from '../../tk-ui';
import QuestionnaireSelector from './QuestionnaireSelector';
import QuestionnaireSummaryButton from './QuestionnaireSummaryButton';
import { DateRangePicker } from '../dateRangePicker';
import CompletedQuestionnairesChart from './CompletedQuestionnairesChart';

type Data = {
  count: number;
  results: QuestionnaireAnswers[];
};

type Column = {
  id: string;
  label: string;
  minWidth: number;
  align?: 'right' | 'left' | 'center';
  format?: (value: any, row?: any, recordIndex?: number) => any;
};

type QuestionnaireAnswer = {
  data: Data;
  isFetching: boolean;
};

const ScoreCell = ({ answer }) => {
  const { description, color } = answer.score_range || {};

  if (description) {
    return (
      <TableCell>
        <Stack direction="row" spacing={1} alignItems="center">
          <Box
            sx={{
              width: '20px',
              mr: 1,
              height: '20px',
              borderRadius: '50%',
              backgroundColor: color || 'white',
            }}
          />
          {`${answer.score.value} - ${description}`}
        </Stack>
      </TableCell>
    );
  }

  return (
    <TableCell>
      {answer.score?.value !== null && answer.score?.value !== undefined ? answer.score.value : '---'}
    </TableCell>
  );
};

const CompletedQuestionnaires = ({ patientUuid }) => {
  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  const [hover, setHover] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [anchorEl, setAnchorEl] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [recordToDelete, setRecordToDelete] = useState(null);
  const [questionnaireOpen, setQuestionnaireOpen] = useState(false);
  const { questionnaireAnswers, account } = greyboxApiActions;
  const { clinic } = useSelector((state) => state.clinic);
  const { edit } = useSelector((state) => state.permissions);
  const menuOpen = Boolean(anchorEl);

  const { data = [], isFetching }: QuestionnaireAnswer = questionnaireAnswers.list(
    {
      patient: patientUuid,
      page_size: rowsPerPage,
      page: page + 1,
      clinic: clinic.id,
    },
  );

  const columns = [
    {
      id: 'answer_timestamp',
      label: t('Date'),
      minWidth: 100,
      align: 'left',
      format: (value) => moment(value).format('YYYY-MM-DD HH:mm'),
    },
    {
      id: 'status',
      label: t('Status'),
      format: (value) => t(value),
      minWidth: 100,
    },
    {
      id: 'id',
      label: t('Answers'),
      minWidth: 200,
      format: (value, row, recordIndex) => (
        <QuestionnaireSummaryButton
          recordId={value}
          date={row.answer_timestamp}
          score={row.score}
        />
      ),
    },
    {
      id: 'created_by',
      label: t('Answered by'),
      minWidth: 100,
      format: (value) => (value ? `${value.first_name} ${value.last_name}` : '---'),
    },
  ] as Column[];

  const handleDelete = (record) => {
    setRecordToDelete(record);
    setDeleteDialogOpen(true);
  };

  const confirmDelete = () => {
    if (recordToDelete) {
      questionnaireAnswers.delete(recordToDelete.id);
      setDeleteDialogOpen(false);
      setRecordToDelete(null);
    }
  };

  const cancelDelete = () => {
    setDeleteDialogOpen(false);
    setRecordToDelete(null);
  };

  const handleQuestionnaireClose = () => {
    setQuestionnaireOpen(false);
  };

  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h4" sx={{ fontWeight: 'bold', color: (theme) => theme.palette.text.secondary }}>
          {t('Completed Questionnaires')}
        </Typography>
        <DateRangePicker />
      </Box>
      <CompletedQuestionnairesChart patientUuid={patientUuid} />
      <Paper sx={{
        display: 'flex',
        alignItems: 'center',
        borderRadius: 0,
        p: 1,
        mt: 2
      }}>
        <Button
          startIcon={<AddIcon />}
          variant="outlined"
          disabled={!edit}
          data-cy="add-answer"
          onClick={() => setQuestionnaireOpen(true)}
          size="small"
        >
          {t('Add')}
        </Button>
      </Paper>

      <TableWrapper
        total={data?.count}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        isFetching={isFetching}
        maxHeight="50vh"
        colCount={columns.length + 1}
      >
        <Table>
          <TableHead>
            <TableRow>
              {columns.map((column, idx) => (
                <TableCell key={idx} align={column.align || 'center'} style={{ minWidth: column.minWidth }}>
                  {column.label}
                </TableCell>
              ))}
              <TableCell sx={{ minWidth: 100 }}>{t('Score')}</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {data.results?.length > 0 ? (
              data.results.map((record, recordIndex) => (
                <TableRow
                  key={record.id}
                  hover
                  sx={{ height: 68 }}
                  onMouseEnter={() => setHover(record.id)}
                  onMouseLeave={() => setHover(null)}
                >
                  {columns.map((column, idx) => (
                    <TableCell key={idx} align={column.align || 'center'}>
                      {column.format ? (
                        column.format(record[column.id], record, recordIndex)
                      ) : (
                        record[column.id]
                      )}
                    </TableCell>
                  ))}
                  <ScoreCell answer={record} />
                  <TableCell sx={{ minWidth: '35px' }}>
                    {(hover === record.id) && (
                      <IconButton onClick={(event) => setAnchorEl(event.currentTarget)} size="small">
                        <MoreVertIcon />
                      </IconButton>
                    )}
                  </TableCell>
                  <Menu
                    anchorEl={anchorEl}
                    open={menuOpen && hover === record.id}
                    onClose={() => setAnchorEl(null)}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                  >
                    <MenuItem onClick={() => handleDelete(record)}>
                      {t('Delete')}
                    </MenuItem>
                  </Menu>
                </TableRow>
              ))
            ) : (
              !isFetching && (
                <TableRow>
                  <TableCell colSpan={columns.length + 1} sx={{ borderBottom: 'none' }}>
                    <Box height="200px" display="flex" justifyContent="center" alignItems="center">
                      <NoData style={{ transform: 'scale(4)' }} />
                    </Box>
                  </TableCell>
                </TableRow>
              )
            )}
          </TableBody>
        </Table>
      </TableWrapper>
      <Dialog
        open={deleteDialogOpen}
        onClose={cancelDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{t('Confirm Delete')}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t('Are you sure you want to delete this answer? This action cannot be undone.')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={cancelDelete} color="primary">
            {t('Cancel')}
          </Button>
          <Button onClick={confirmDelete} color="primary" autoFocus>
            {t('Delete')}
          </Button>
        </DialogActions>
      </Dialog>
      <QuestionnaireSelector
        patientUuid={patientUuid}
        open={questionnaireOpen}
        handleClose={handleQuestionnaireClose}
      />
    </>
  );
};

export default CompletedQuestionnaires;
