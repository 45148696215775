import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin, withAITracking } from '@microsoft/applicationinsights-react-js';
import { tenantKeyConfig, tenantHeaderConfig } from 'environmentConfig';

const hostName = window.location.hostname;
const keyConfig = tenantKeyConfig[hostName];
const headerConfig = tenantHeaderConfig[hostName];

const reactPlugin = new ReactPlugin();
const ai = new ApplicationInsights({
  config: {
    connectionString: `instrumentationKey=${keyConfig}`,
    extensions: [reactPlugin],
    correlationHeaderDomains: [headerConfig],
    enableCorsCorrelation: true,
    disableFetchTracking: false,
    enableAjaxErrorStatusText: true,
    enableAjaxPerfTracking: true,
    enableAutoRouteTracking: true,
    enableSendLiveMetrics: true,
    samplingPercentage: 10,
    disableInstrumentationKeyValidation: window.location.hostname === 'localhost',
    // Enable automatic collection of unhandled exceptions
    autoTrackPageVisitTime: true,
    enableUnhandledPromiseRejectionTracking: true,
  },
});

ai.loadAppInsights();

// Set up global error handlers to capture unhandled exceptions
const setupGlobalErrorHandlers = () => {
  // Capture unhandled promise rejections
  window.addEventListener('unhandledrejection', (event) => {
    appInsights.trackException({
      exception: event.reason instanceof Error ? event.reason : new Error(String(event.reason)),
      properties: {
        type: 'UnhandledPromiseRejection',
        url: window.location.href,
        userAgent: navigator.userAgent,
        timestamp: new Date().toISOString(),
      },
      severityLevel: 3, // Error
    });
  });

  // Capture uncaught exceptions
  window.addEventListener('error', (event) => {
    // Only track JS errors (not script loading errors, etc)
    if (event.error) {
      appInsights.trackException({
        exception: event.error,
        properties: {
          type: 'UncaughtException',
          message: event.message,
          filename: event.filename,
          lineno: event.lineno,
          colno: event.colno,
          url: window.location.href,
          userAgent: navigator.userAgent,
          timestamp: new Date().toISOString(),
        },
        severityLevel: 3, // Error
      });
    }
  });
};

// Initialize global error handlers
setupGlobalErrorHandlers();

/**
 * Manually track an error to AppInsights with additional context
 * 
 * @param {Error} error - The error object
 * @param {Object} properties - Additional properties to include
 * @param {number} severityLevel - Optional severity level (1=Critical, 2=High, 3=Medium, 4=Low)
 */
export const trackError = (error, properties = {}, severityLevel = 3) => {
  appInsights.trackException({
    exception: error instanceof Error ? error : new Error(String(error)),
    properties: {
      ...properties,
      url: window.location.href,
      userAgent: navigator.userAgent,
      timestamp: new Date().toISOString(),
    },
    severityLevel,
  });
};

export default (Component) => withAITracking(reactPlugin, Component);
export const { appInsights } = ai;
export { reactPlugin };
