import { Button, IconButton, Typography } from '@mui/material';
import { Delete, CloudUpload } from '@mui/icons-material';
import React, { useEffect, useState, useCallback } from 'react';
import Markdown from 'react-markdown';
import { Answers, Question } from '../../../../types';
import QuestionMedia from './QuestionMedia';
import { useTranslation } from 'react-i18next';
import ImagePointMarker from './ImagePointMarker';

type MediaAnswerPanelProps = {
    question: Question;
    setAnswers: (id: number, answer: string) => void;
    answers: Answers;
    isCurrent: boolean;
};

const MediaAnswerPanel: React.FC<MediaAnswerPanelProps> = ({
    question,
    setAnswers,
    answers,
    isCurrent,
}) => {
    const { t } = useTranslation();
    const [previewUrl, setPreviewUrl] = useState<string>('');
    const answerType = question.ma_question?.answer_type ?? '';

    useEffect(() => {
        const answer = answers[question.id];
        setPreviewUrl(
            typeof answer === 'string' ? answer :
                answer instanceof File ? URL.createObjectURL(answer) : ''
        );
    }, [question, answers]);

    const handleFileSelect = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (!file) return;

        setPreviewUrl(URL.createObjectURL(file));
        setAnswers(question.id, file);
    }, [question.id, setAnswers]);

    const handleAnnotatedImageChange = useCallback((file: File) => {
        setPreviewUrl(URL.createObjectURL(file));
        setAnswers(question.id, file);
    }, [question.id, setAnswers]);

    const handleRemoveMedia = useCallback(() => {
        setPreviewUrl('');
        setAnswers(question.id, '');
    }, [question.id, setAnswers]);

    const getAcceptType = useCallback(() => {
        switch (answerType) {
            case 'image': return 'image/*';
            case 'audio': return 'audio/*';
            case 'video': return 'video/*';
            case 'document': return '.pdf,.doc,.docx,.txt';
            default: return '';
        }
    }, [answerType]);

    const getMediaTypeLabel = useCallback(() => {
        switch (answerType) {
            case 'image': return t('Image');
            case 'audio': return t('Audio');
            case 'video': return t('Video');
            case 'document': return t('Document');
            default: return t('File');
        }
    }, [answerType, t]);

    const renderMediaPreview = useCallback(() => {
        if (!previewUrl) return null;

        return (
            <div style={{
                marginTop: 16,
                position: 'relative',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column'
            }}>
                {answerType === 'annotated_image' && (
                    <img
                        src={previewUrl}
                        alt={t('Annotated image')}
                        style={{ maxWidth: '100%', maxHeight: 300 }}
                    />
                )}

                {answerType === 'image' && (
                    <img
                        src={previewUrl}
                        alt={t('Uploaded image')}
                        style={{ maxWidth: '100%', maxHeight: 300 }}
                    />
                )}

                {answerType === 'audio' && (
                    <audio controls style={{ width: '100%' }}>
                        <source src={previewUrl} />
                        {t('Your browser does not support the audio element')}
                    </audio>
                )}

                {answerType === 'video' && (
                    <video
                        controls
                        style={{ maxWidth: '100%', maxHeight: 300 }}
                        aria-label={t('Uploaded video')}
                    >
                        <source src={previewUrl} />
                        {t('Your browser does not support the video element')}
                    </video>
                )}

                {answerType === 'document' && (
                    <Button
                        variant="contained"
                        href={previewUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        download={answers[question.id] instanceof File ? answers[question.id].name : undefined}
                    >
                        {answers[question.id] instanceof File ? answers[question.id].name : t('View Document')}
                    </Button>
                )}

                <div style={{ display: 'flex', alignItems: 'center', gap: 8, marginTop: 8 }}>
                    <IconButton
                        onClick={handleRemoveMedia}
                        sx={{ color: 'text.secondary' }}
                        disabled={!isCurrent}
                        aria-label={t('Remove media')}
                    >
                        <Delete />
                    </IconButton>
                    <Typography variant="body2" color="text.secondary">
                        {t('Remove')}
                    </Typography>
                </div>
            </div>
        );
    }, [previewUrl, answerType, isCurrent, handleRemoveMedia, t]);

    return (
        <>
            <Typography component="div" sx={{ width: '100%', mb: 1 }}>
                <Markdown>{question.ma_question.ma_question_text}</Markdown>
            </Typography>

            {answerType === 'annotated_image' && !previewUrl ? (
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: 16
                }}>
                    <ImagePointMarker
                        imageUrl={question.ma_question.question_media}
                        onChange={handleAnnotatedImageChange}
                        isEditable={isCurrent}
                    />
                </div>
            ) : (
                <>
                    {answerType !== 'annotated_image' && (
                        <QuestionMedia
                            mediaType={question.ma_question.question_media_type}
                            mediaUrl={question.ma_question.question_media}
                        />
                    )}
                    {!previewUrl && (
                        <div style={{
                            display: 'flex',
                            justifyContent: 'center',
                            marginTop: 2
                        }}>
                            <input
                                accept={getAcceptType()}
                                style={{ display: 'none' }}
                                id={`media-upload-${question.id}`}
                                type="file"
                                onChange={handleFileSelect}
                                disabled={!isCurrent}
                                data-testid="file-input"
                            />
                            <label htmlFor={`media-upload-${question.id}`}>
                                <Button
                                    component="span"
                                    variant="contained"
                                    startIcon={<CloudUpload />}
                                    disabled={!isCurrent}
                                >
                                    {t('Upload {{type}}', { type: getMediaTypeLabel() })}
                                </Button>
                            </label>
                        </div>
                    )}
                </>
            )}

            {renderMediaPreview()}
        </>
    );
};

export default MediaAnswerPanel;
