import {
  Box, Paper, Typography, Menu, IconButton, useMediaQuery,
} from '@mui/material';
import 'moment/locale/fr';
import React from 'react';
import { useTranslation } from 'react-i18next';
import MenuIcon from '@mui/icons-material/Menu';
import { useTheme } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import NavigationMenu from '../navigation/NavigationMenu';

const ClinicAdminNavigation = () => {
  const { t } = useTranslation();
  const url = '/clinic-settings';
  const { clinic } = useSelector((state: RootState) => state.clinic as any);
  const config = clinic?.config?.features_enable || {};
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const nav = [
    {
      id: 'clinicInfo',
      label: t('Clinic Settings'),
      url: `${url}/clinic-info`,
    },
    {
      id: 'accounts',
      label: t('Accounts'),
      url: `${url}/accounts`,
      subPaths: [
        {
          id: 'PT',
          label: t('Practitioners'),
          url: `${url}/accounts/PT`,
        },
        {
          id: 'P',
          label: t('Patients'),
          url: `${url}/accounts/P`,
        },
        {
          id: 'CP',
          label: t('Caregivers'),
          url: `${url}/accounts/CP`,
        },
      ],
    },
    {
      id: 'careTeam',
      label: t('Care Team'),
      url: `${url}/care-team`,
    },
  ];

  // Check for questionnaire permission
  if (config.questionnaire && clinic?.permissions && Array.isArray(clinic.permissions) && clinic.permissions.includes('change_questionnaire')) {
    nav.push({
      id: 'questionnaires',
      label: t('Questionnaires'),
      url: `${url}/questionnaires`,
    });
  }

  // Check for education permission
  if (config.education && clinic?.permissions && Array.isArray(clinic.permissions) && clinic.permissions.includes('add_education_library_section')) {
    nav.push({
      id: 'documentation',
      label: t('Education'),
      url: `${url}/documentation`,
    });
  }

  if (config.medical_acts) {
    nav.push({
      id: 'medicalActs',
      label: t('Follow-ups'),
      url: `${url}/medical-acts`,
    });
  }

  if (config.workflow) {
    nav.push({
      id: 'workflow',
      label: t('Workflow'),
      url: `${url}/workflow`,
    });
  }

  if (config.device) {
    nav.push({
      id: 'device',
      label: t('Devices'),
      url: `${url}/device`,
    });
  }

  // Dashboard columns configuration
  nav.push({
    id: 'dashboardColumns',
    label: t('Dashboard Columns'),
    url: `${url}/dashboard-columns`,
  });

  if (isMobile) {
    return (
      <Box width="100%" display="flex">
        <IconButton
          color="primary"
          onClick={(event) => setAnchorEl(event.currentTarget)}
          sx={{ ml: 'auto', mr: 1, my: 1 }}
        >
          <MenuIcon />
        </IconButton>
        <Menu open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)} anchorEl={anchorEl}>
          <NavigationMenu navConfig={nav} />
        </Menu>
      </Box>
    );
  }
  return (
    <Box sx={{ minWidth: '250px', mr: 1, maxWidth: '270px' }}>
      <NavigationMenu navConfig={nav} />
    </Box>
  );
};

export default ClinicAdminNavigation;
