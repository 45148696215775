import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import TranslateIcon from '@mui/icons-material/Translate';
import ReplayIcon from '@mui/icons-material/Replay';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  TextField,
  Tooltip,
  Typography,
  CircularProgress,
} from '@mui/material';
import moment from 'moment';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { greyboxApiActions } from '../../redux/api';
import Media from './Media';
import MuiMarkdown from 'mui-markdown';

const MessageBox = ({ 
  message, 
  previousMessage, 
  threadId,
  isPending = false,
  hasFailed = false,
  onRetry
}) => {
  const { userId } = useSelector((state) => state.user);
  const { t, i18n } = useTranslation();
  const { messageThread } = greyboxApiActions;
  const [deleteMessage] = messageThread.delete();
  const [updateMessage] = messageThread.update();
  
  // For pending messages, we need to handle the user ID differently as message.created_by might not exist
  const messageUserId = message.created_by?.id || message.sender;
  const alignRight = userId === messageUserId;
  
  // Format timestamp based on message age
  const messageTime = message.created || message.created_at;
  const time =
    moment().diff(messageTime, 'days') > 0
      ? moment(messageTime).format('MMM DD, YYYY')
      : moment(messageTime).format('h:mm A');
      
  // For pending messages, we might not have the full created_by object
  const senderName = message.created_by 
    ? `${message.created_by.first_name} ${message.created_by.last_name}` 
    : message.sender_name || "You";
    
  const toolTipText = `${senderName} ${time}`;
  
  const isNewInstance =
    !previousMessage || moment(messageTime).diff(previousMessage.created || previousMessage.created_at, 'minutes') > 5;
    
  const isNewPerson =
    !previousMessage || (previousMessage.created_by?.id || previousMessage.sender) !== messageUserId;
    
  const isScheduled = message.scheduled_time
    ? moment(message.scheduled_time).isAfter(moment())
    : false;
    
  const [hover, setHover] = useState(false);
  const [translation, setTranslation] = useState('');
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [isEditMode, setEditMode] = useState(false);
  const [editedMessage, setEditedMessage] = useState(message.message);

  const translate = async (text) => {
    const apiKey = '61568b414d3c4ecb9dbe4c4304753536';
    const baseUrl =
      'https://api.cognitive.microsofttranslator.com/translate?api-version=3.0';
    const url = `${baseUrl}&to=${i18n.language}`;
    const headers = {
      'Ocp-Apim-Subscription-Key': apiKey,
      'Content-Type': 'application/json',
      Accept: 'application/json',
    };

    const body = [{ text: text }];

    const response = await fetch(url, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(body),
    });
    const result = await response.json();
    setTranslation(result[0].translations[0].text);
  };

  const handleDeleteMessage = () => {
    deleteMessage(`${threadId}/message/${message.id}`);
    setDeleteDialogOpen(false);
  };

  const handleUpdateMessage = () => {
    updateMessage({
      id: `${threadId}/message/${message.id}`,
      body: { message: editedMessage },
    });
    setEditMode(false);
  };

  const scheduledToolTipText =
    isScheduled && userId === messageUserId
      ? t('Scheduled for', { scheduled_time: moment(message.scheduled_time).format('h:mm A') })
      : toolTipText;

  const getBackgroundColor = () => {
    if (alignRight) {
      if (isScheduled) return '#FFD700'; // Gold for scheduled messages
      if (hasFailed) return '#FFEBEE'; // Light red for failed messages
      if (isPending) return '#E0E0E0'; // Light grey for pending messages
      return '#e5f5ff'; // Original blue for user's messages
    }
    return '#f5f5f5'; // Original grey for others' messages
  };

  // Don't show scheduled messages from other users
  if (isScheduled && userId !== messageUserId) {
    return null;
  }

  // Don't show edit/delete options for pending or failed messages
  const showActionButtons = !isPending && !hasFailed && hover && !isEditMode;

  // Determine if we should show media
  const hasMedia = message.media || message.attachedFile;
  const mediaObject = message.media || (message.attachedFile ? {
    url: message.attachedFile.url,
    name: message.attachedFile.name,
    mime_type: message.attachedFile.type
  } : null);

  return (
    <>
      {(isNewPerson || isNewInstance) && (
        <Box display="flex" justifyContent={alignRight ? 'flex-end' : 'flex-start'} sx={{ mb: 1, mx: 2 }}>
          <Typography variant="caption" color="textSecondary">
            {senderName}
          </Typography>
        </Box>
      )}
      <Box
        display="flex"
        alignItems="center"
        mb={1}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        justifyContent={alignRight ? 'flex-end' : 'flex-start'}
        sx={{ mx: 2 }}
      >
        {/* Action buttons for the current user's messages */}
        {alignRight && showActionButtons && (
          <Box display="flex" alignItems="center" mr={1}>
            {!translation && !hasMedia && (
              <IconButton
                size="small"
                sx={{ color: 'primary.main' }}
                onClick={() => translate(message.message)}
              >
                <TranslateIcon fontSize="small" />
              </IconButton>
            )}
            {!hasMedia && (
              <IconButton
                size="small"
                sx={{ color: 'primary.main' }}
                onClick={() => setEditMode(true)}
              >
                <EditIcon fontSize="small" />
              </IconButton>
            )}
            <IconButton
              size="small"
              sx={{ color: 'primary.main' }}
              onClick={() => setDeleteDialogOpen(true)}
            >
              <DeleteIcon fontSize="small" />
            </IconButton>
          </Box>
        )}
        
        {/* Message bubble */}
        <Box sx={{ position: 'relative', maxWidth: '80%' }}>
          <Tooltip title={scheduledToolTipText} placement={alignRight ? 'left' : 'right'}>
            <Box
              sx={{
                backgroundColor: getBackgroundColor(),
                display: 'flex',
                borderRadius: 2,
                padding: 1,
                overflowWrap: 'break-word',
                wordBreak: 'break-word',
                flexDirection: 'column',
                boxShadow: 1,
                opacity: isPending ? 0.85 : 1,
                border: hasFailed ? '1px solid #f44336' : 'none'
              }}
            >
              {/* Message content */}
              {hasMedia ? (
                // Media content (image, file, etc.)
                <Media media={mediaObject} isPending={isPending} />
              ) : (
                // Text content
                <>
                  {isEditMode ? (
                    // Edit mode
                    <Box>
                      <TextField
                        fullWidth
                        multiline
                        size="small"
                        value={editedMessage}
                        onChange={(e) => setEditedMessage(e.target.value)}
                        sx={{
                          backgroundColor: '#ffffff',
                          borderRadius: '4px',
                          mb: 1,
                        }}
                      />
                      <Box display="flex" justifyContent="flex-end">
                        <IconButton
                          size="small"
                          sx={{ color: 'primary.main' }}
                          onClick={handleUpdateMessage}
                        >
                          <CheckIcon fontSize="small" />
                        </IconButton>
                        <IconButton
                          size="small"
                          sx={{ color: 'primary.main' }}
                          onClick={() => setEditMode(false)}
                        >
                          <CloseIcon fontSize="small" />
                        </IconButton>
                      </Box>
                    </Box>
                  ) : (
                    // Normal display mode
                    <MuiMarkdown
                      overrides={{
                        a: {
                          component: 'a',
                          props: {
                            target: '_blank',
                            rel: 'noopener noreferrer',
                          },
                        },
                      }}
                    >
                      {message.message}
                    </MuiMarkdown>
                  )}
                </>
              )}
              
              {/* Translation section */}
              {translation && (
                <>
                  <Typography variant="caption" color="textSecondary" mt={1}>
                    {`(${t('Auto-translated')})`}
                  </Typography>
                  <Box mt={0.5}>
                    <MuiMarkdown
                      overrides={{
                        a: {
                          component: 'a',
                          props: {
                            target: '_blank',
                            rel: 'noopener noreferrer',
                          },
                        },
                      }}
                    >
                      {translation}
                    </MuiMarkdown>
                  </Box>
                </>
              )}
            </Box>
          </Tooltip>
        </Box>
        
        {/* Status indicators for pending/failed messages */}
        {isPending && !hasFailed && (
          <Box display="flex" alignItems="center" ml={1}>
            <CircularProgress size={14} thickness={5} />
          </Box>
        )}
        
        {hasFailed && (
          <Box display="flex" alignItems="center" ml={1}>
            <Tooltip title={t('Message failed to send. Click to retry.')}>
              <IconButton
                size="small"
                color="error"
                onClick={onRetry}
              >
                <ReplayIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </Box>
        )}
        
        {/* Translation button for other users' messages */}
        {!alignRight && hover && !translation && !hasMedia && (
          <Box display="flex" alignItems="center" ml={1}>
            <IconButton
              size="small"
              sx={{ color: 'primary.main' }}
              onClick={() => translate(message.message)}
            >
              <TranslateIcon fontSize="small" />
            </IconButton>
          </Box>
        )}
      </Box>
      
      {/* Timestamp display */}
      {isNewInstance && (
        <Box sx={{ width: '100%', textAlign: 'center', mt: -1 }}>
          <Typography variant="caption" color="textSecondary">
            {time}
          </Typography>
        </Box>
      )}

      {/* Delete confirmation dialog */}
      <Dialog open={isDeleteDialogOpen} onClose={() => setDeleteDialogOpen(false)}>
        <DialogTitle>{t('Delete Message')}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t('Are you sure you want to delete this message? This action cannot be undone.')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)} color="primary">
            {t('Cancel')}
          </Button>
          <Button onClick={handleDeleteMessage} color="primary">
            {t('Delete')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default MessageBox;