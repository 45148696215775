import { MenuItem } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { languages } from '../../helpers/languagesConfig';
import FormSelect from './FormSelect';

interface PropsType {
  formik: any;
  sx?: any;
  required?: boolean;
  name?: string;  // Add optional name prop
}

const Language = ({
  formik,
  name = 'language',  // Set default value here
  sx = { mx: 1, minWidth: 120, fullWidth: true },
  required = false,
  ...props
}: PropsType) => {
  const { t } = useTranslation();

  return (
    <FormSelect
      name={name}  // Use the name prop
      label={t('Language')}
      required={required}
      sx={sx}
      formik={formik}
      {...props}
    >
      {languages.map((language) => (
        <MenuItem key={language.code} value={language.code}>
          {t(language.labelKey)}
        </MenuItem>
      ))}
    </FormSelect>
  );
};

export default Language;