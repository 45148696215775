import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Typography,
  Box,
  Paper,
  CircularProgress,
  Divider,
  Alert,
  Avatar,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { useSelector } from 'react-redux';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { greyboxApiActions } from '../../../redux/api';
import { Description as DescriptionIcon } from '@mui/icons-material';
import { usePatientWebSocket } from '../../../hooks';

// Component to Select Report Type
const ReportTypeSelector = ({ reportType, setReportType, data, selectedReport, t }) => (
  <>
    <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', mt: 1 }}>
      {t('Select Report Type')}
    </Typography>
    <FormControl variant="outlined" fullWidth sx={{ mb: 3 }}>
      <InputLabel id="report-type-label">{t('Report Type')}</InputLabel>
      <Select
        labelId="report-type-label"
        id="report-type-select"
        value={reportType}
        onChange={(e) => setReportType(e.target.value)}
        label={t('Report Type')}
      >
        {data.map((report) => (
          <MenuItem key={report.id} value={report.id}>
            {report.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
    {selectedReport && (
      <>
        <Divider sx={{ my: 2 }} />
        <Box sx={{ mb: 2 }}>
          <Typography variant="h6" gutterBottom>
            {t('Report Details')}
          </Typography>
          <Paper variant="outlined" sx={{ p: 2, bgcolor: 'background.default' }}>
            <Typography variant="body1">
              {selectedReport.description || t('No description available for this report template.')}
            </Typography>
          </Paper>
        </Box>
      </>
    )}
  </>
);

// Component to Show Generating Status
const ReportGeneratingStatus = ({ t }) => (
  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', textAlign: 'center', py: 4 }}>
    <CircularProgress size={50} sx={{ color: 'primary.main', mb: 2 }} />
    <Typography variant="h6" sx={{ fontWeight: 'medium', mb: 1 }}>
      {t('Generating Report...')}
    </Typography>
    <Typography variant="body2" color="textSecondary">
      {t('Please wait while we process your request.')}
    </Typography>
  </Box>
);

const GenerateReportDialog = ({ open, handleClose, patientUuid, resource, setOpenGeneratedReportMessage, setSnackbar, setIframeUrl }) => {
  const { i18n, t } = useTranslation();
  const [reportType, setReportType] = useState('');
  const { patientReport, patientReportClinic } = greyboxApiActions;
  const { clinic } = useSelector((state) => state.clinic);
  const [postReport] = patientReport.add();
  const [currentOperationId, setCurrentOperationId] = useState(null);
  const [processedReports, setProcessedReports] = useState(new Set());
  const [isGenerating, setIsGenerating] = useState(false);
  const [isCancelled, setIsCancelled] = useState(false);
  
  const { data: patientReportData, refetch } = patientReport.list({ patient: patientUuid, clinic: clinic.id });
  const { data, isLoading, isError } = patientReportClinic.list({ clinic: clinic.id, role: 'clinical_note' });
  const { lastJsonMessage, readyState } = usePatientWebSocket();
  const selectedReport = data?.find((report) => report.id === reportType) || null;
  
  useEffect(() => {
    if (open && data && data.length > 0) {
      setReportType(data[0].id);
    } else {
      setReportType('');
    }
  }, [open, data]);
  
  useEffect(() => {
    if (isCancelled) return;
    if (readyState !== 1) return;
    if (!lastJsonMessage?.entity || lastJsonMessage.entity !== 'PatientReport') return;
    const { id, status } = lastJsonMessage.data;
    if (id !== currentOperationId || processedReports.has(id)) return;

    if (status === 'completed') {
      refetch();
      const matchingReport = patientReportData?.find((report) => report.id === id);
      if (matchingReport?.file) {
        setIframeUrl(matchingReport.file);
        setSnackbar({ message: t('Report is ready!'), severity: 'success' });
        setOpenGeneratedReportMessage(true);
        setProcessedReports(new Set([...processedReports, id]));
        setIsGenerating(false);
        handleClose();
      }
    } else if (status === 'in-progress') {
      setIsGenerating(true);
    } else if (status === 'error') {
      setSnackbar({ message: t('Failed to generate report. Please try again.'), severity: 'error' });
      setOpenGeneratedReportMessage(true);
      setIsGenerating(false);
      handleClose();
    }
  }, [lastJsonMessage, patientReportData, isCancelled, readyState]);

  const handleSubmit = async () => {
    setIsCancelled(false);
    setIsGenerating(true);
    const body = {
      type: 'pdf',
      report_clinic: reportType,
      patient: patientUuid,
      language: i18n.resolvedLanguage,
      data: { params_values: { "clinical-impression": resource.id } }
    };
    try {
      const response = await postReport({ body });
      const reportId = response?.data?.id;
      if (reportId) setCurrentOperationId(reportId);
    } catch (error) {
      setSnackbar({ message: t('Failed to generate report. Please try again.'), severity: 'error' });
      setIsGenerating(false);
    }
  };
  
  const handleCancel = () => {
    handleClose();
    if (isGenerating) {
      setCurrentOperationId(null);
      setIsCancelled(true);
      setOpenGeneratedReportMessage(false);
      setTimeout(() => {
        setIsGenerating(false);
      }, 300);
    }
  };
  
  return (
    <Dialog open={open} onClose={handleCancel} fullWidth maxWidth="sm">
      <DialogTitle sx={{ pb: 1 }}>{t('Generate Clinical Note Report')}</DialogTitle>
      <DialogContent sx={{ pb: 1 }}>
        {isGenerating ? <ReportGeneratingStatus t={t} /> : <ReportTypeSelector reportType={reportType} setReportType={setReportType} data={data} selectedReport={selectedReport} t={t} />}
      </DialogContent>
      <DialogActions sx={{ px: 3, py: 2 }}>
        <Button onClick={handleCancel} variant={isGenerating ? "contained" : "outlined"}>{isGenerating ? t('Close') : t('Cancel')}</Button>
        {!isGenerating && <LoadingButton onClick={handleSubmit} loading={isGenerating} variant="contained" disabled={!reportType || isGenerating || isLoading || isError}>{t('Generate')}</LoadingButton>}
      </DialogActions>
    </Dialog>
  );
};

export default GenerateReportDialog;
