/**
 * @param {object} value one of the value of the jonObject
 * @param {[object]} answers list of answers
 * @returns Parsed value
 * Takes a value from the jsonObject and parse it:
 * - If the value is a string starting with '$', it is following a convention meaning that value
 *   is a pointer to an answer of a previous question.
 * - If the value is an object, it mean that it's another jsonObject and must be parsed
 *   recursively.
 */

export const parseValue = (value, answers) => {
  if (typeof value === 'string' && value.charAt(0) === '$') {
    const ansId = value.substring(1);
    const answer = answers[ansId];

    if (answer === 'T') return 'true';
    if (answer === 'F') return 'false';

    return answer;
  }
  if (Array.isArray(value)) {
    return value.map(item => parseValue(item, answers));
  }
  if (typeof value === 'object' && value !== null) {
    return conditionParser(value, answers);
  }

  return value;
};

/**
 * @param {*} jsonObject json code inside a question
 * @param {*} answers list of answers
 * Will recursively parse the jsonObject and return new value depending on their type.
 */
export const conditionParser = (jsonObject, answers) => {
  let { var1, var2 } = jsonObject;

  var1 = parseValue(var1, answers);
  var2 = parseValue(var2, answers);

  return {
    var1: var1,
    var2: var2,
    op: jsonObject.op,
  };
};

// Look for keys inside the text and replace them with the corresponding answer of
// a previous question
export const parseAnswerText = (text, otherQuestions, answers) => {
  // check if string contain other question key inside {{ }}
  const matches = text.match(/{{\s*([^}]+?)\s*}}/g);
  if (!matches) {
    return text;
  }

  let parsedText = '';
  matches.forEach((match) => {
    const key = match.replace(/{{\s*|\s*}}/g, '');
    const question = otherQuestions.find((q) => q.key === key);
    if (question) {
      parsedText = answers[question.id];
    }
  });

  return parsedText;
};
