import {
  Code as CodeIcon,
  Description as DescriptionIcon,
  Download as DownloadIcon,
  TableChart as TableChartIcon,
  TextSnippet as TextSnippetIcon,
} from '@mui/icons-material';
import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Typography
} from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useExportTrainingDataQuery } from '../../redux/api';

const ExportDialog = ({ open, onClose, agentId, agentName, trainingCount }) => {
  const { t } = useTranslation();
  const [exportFormat, setExportFormat] = useState('json');
  const [exportFilter, setExportFilter] = useState('all');
  const [isExporting, setIsExporting] = useState(false);
  const [skipQuery, setSkipQuery] = useState(true);

  // Use the exported hook with skip option to control when it runs
  const { data: exportData, isLoading, isError, error } = useExportTrainingDataQuery(
    { agentId, format: exportFormat, filter: exportFilter },
    { skip: skipQuery }
  );

  const handleExport = async () => {
    setIsExporting(true);

    try {
      // Trigger the API call by setting skipQuery to false
      setSkipQuery(false);

      // Note: The actual download will happen in the useEffect below
      // This keeps the button in loading state until we complete the process
    } catch (error) {
      console.error('Export failed:', error);
      setIsExporting(false);
    }
  };

  // Handle the export data once it's loaded
  React.useEffect(() => {
    if (!skipQuery && !isLoading && exportData) {
      // Create a Blob from the response data
      const blob = new Blob([exportData], {
        type: getContentType(exportFormat)
      });

      // Create a download link
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = `${agentName || 'agent'}_training_data.${exportFormat}`;
      document.body.appendChild(link);
      link.click();

      // Clean up
      URL.revokeObjectURL(url);
      document.body.removeChild(link);

      // Reset state
      setTimeout(() => {
        setIsExporting(false);
        setSkipQuery(true); // Reset to skip for next time
        onClose();
      }, 1000);
    }

    if (!skipQuery && isError) {
      console.error('Export error:', error);
      setIsExporting(false);
      setSkipQuery(true); // Reset to skip
    }
  }, [skipQuery, isLoading, exportData, isError, error, exportFormat, agentName, onClose]);

  // Helper to determine the content type based on format
  const getContentType = (format) => {
    switch (format) {
      case 'json': return 'application/json';
      case 'csv': return 'text/csv';
      case 'xlsx': return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      case 'txt': return 'text/plain';
      default: return 'application/octet-stream';
    }
  };

  const formatOptions = [
    {
      value: 'json',
      label: 'JSON',
      icon: <CodeIcon />,
      description: t('Best for programmatic use or importing to other systems.')
    },
    {
      value: 'csv',
      label: 'CSV',
      icon: <TableChartIcon />,
      description: t('Good for spreadsheet applications like Google Sheets.')
    },
    {
      value: 'xlsx',
      label: 'Excel',
      icon: <DescriptionIcon />,
      description: t('Microsoft Excel format with formatting preserved.')
    },
    {
      value: 'txt',
      label: 'Text',
      icon: <TextSnippetIcon />,
      description: t('Simple Q&A format in plain text.')
    },
  ];

  const filterOptions = [
    {
      value: 'all',
      label: t('All Data'),
      description: t('Export all {{count}} training items', { count: trainingCount })
    },
    {
      value: 'trained',
      label: t('Trained Only'),
      description: t('Export only items added to agent memory')
    },
    {
      value: 'untrained',
      label: t('Untrained Only'),
      description: t('Export only items not yet added to memory')
    }
  ];

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>{t('Export Training Data')}</DialogTitle>
      <DialogContent>
        <Typography variant="body2" paragraph>
          {t('Export your training data in various formats for backup or use in other systems.')}
        </Typography>

        <Box sx={{ mb: 4 }}>
          <Typography variant="subtitle1" gutterBottom fontWeight="medium">
            {t('1. Select Data to Export')}
          </Typography>
          <Grid container spacing={2}>
            {filterOptions.map((option) => (
              <Grid item xs={12} sm={4} key={option.value}>
                <Card
                  variant={exportFilter === option.value ? "elevation" : "outlined"}
                  elevation={exportFilter === option.value ? 4 : 0}
                  sx={{
                    height: '100%',
                    borderColor: exportFilter === option.value ? 'primary.main' : 'divider',
                    bgcolor: exportFilter === option.value ? 'primary.lighter' : 'background.paper',
                  }}
                >
                  <CardActionArea
                    onClick={() => setExportFilter(option.value)}
                    sx={{ height: '100%' }}
                  >
                    <CardContent>
                      <Typography variant="h6" component="div" gutterBottom>
                        {option.label}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {option.description}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>

        <Divider sx={{ my: 3 }} />

        <Box>
          <Typography variant="subtitle1" gutterBottom fontWeight="medium">
            {t('2. Choose Export Format')}
          </Typography>
          <Grid container spacing={2}>
            {formatOptions.map((format) => (
              <Grid item xs={12} sm={3} key={format.value}>
                <Card
                  variant={exportFormat === format.value ? "elevation" : "outlined"}
                  elevation={exportFormat === format.value ? 4 : 0}
                  sx={{
                    height: '100%',
                    borderColor: exportFormat === format.value ? 'primary.main' : 'divider',
                    bgcolor: exportFormat === format.value ? 'primary.lighter' : 'background.paper',
                  }}
                >
                  <CardActionArea
                    onClick={() => setExportFormat(format.value)}
                    sx={{ height: '100%' }}
                  >
                    <CardContent>
                      <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                        <Box sx={{ mr: 1, color: 'primary.main' }}>
                          {format.icon}
                        </Box>
                        <Typography variant="h6" component="div">
                          {format.label}
                        </Typography>
                      </Box>
                      <Typography variant="body2" color="text.secondary">
                        {format.description}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>

        <Box sx={{ mt: 4, p: 2, bgcolor: 'background.default', borderRadius: 1, border: '1px dashed', borderColor: 'divider' }}>
          <Typography variant="body2">
            {t(
              'You are exporting {{type}} training data in {{format}} format.',
              {
                type: exportFilter === 'all'
                  ? t('all')
                  : exportFilter === 'trained'
                    ? t('only trained')
                    : t('only untrained'),
                format: exportFormat.toUpperCase()
              }
            )}
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t('Cancel')}</Button>
        <Button
          variant="contained"
          startIcon={isExporting ? <CircularProgress size={20} color="inherit" /> : <DownloadIcon />}
          onClick={handleExport}
          disabled={isExporting}
        >
          {isExporting ? t('Exporting...') : t('Export Data')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ExportDialog;