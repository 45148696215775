import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import ClearIcon from '@mui/icons-material/Clear';
import CommentIcon from '@mui/icons-material/Comment';
import CommentsDisabledIcon from '@mui/icons-material/CommentsDisabled';
import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticon';
import SendIcon from '@mui/icons-material/Send';
import SmartToyIcon from '@mui/icons-material/SmartToy'; // Add AI icon
import {
  Box, Button, CircularProgress, IconButton,
  Popover,
  ToggleButton, Tooltip,
  Typography,
  useMediaQuery,
  Chip,
} from '@mui/material';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import EmojiPicker from 'emoji-picker-react';
import moment from 'moment';
import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useThreadWebSocket } from '../../hooks';
import { greyboxApiActions } from '../../redux/api';
import { getMediaTypeFromFileType } from '../../utils';
import MessageBox from './MessageBox';
import MessageTextField from './MessageTextField';
import { useNavigate } from 'react-router-dom';
import AutoSizer from 'react-virtualized-auto-sizer';
import { v4 as uuidv4 } from 'uuid'; // Add UUID for generating temporary IDs

const PAGE_SIZE = 20;

/**
 * Used to display messages from clinic to patient or AI agent.
 * It can toggle if the patient can answer or not.
 * The previous message (if any) will load when the user scrolls to the top.
 */
const ChatBox = ({
  threadId,
}) => {
  const { messageThread, user, media } = greyboxApiActions;
  const { access , userId } = useSelector((state) => state.user);
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [sendMessage] = messageThread.add();
  const [sendMedia] = media.add();
  const [currentMessage, setCurrentMessage] = useState('');
  const { clinic } = useSelector((state) => state.clinic);
  const [updateThread] = messageThread.update();
  const threadRequest = messageThread.get(threadId);
  const [messages, setMessages] = useState([]);
  const [pendingMessages, setPendingMessages] = useState([]);
  const [page, setPage] = useState(1);
  const [attachedFile, setAttachedFile] = useState(null);
  const [anchorEmote, setAnchorEmote] = useState(null);
  const [sendLaterAnchorEl, setSendLaterAnchorEl] = useState(null);
  const [sendLaterDate, setSendLaterDate] = useState(moment().add(1, 'hours'));
  const [loading, setLoading] = useState(false);
  const [isTyping, setIsTyping] = useState(false);
  const [isSending, setIsSending] = useState(false);

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const { lastJsonMessage, readyState } = useThreadWebSocket(threadId);

  const thread = threadRequest.data;
  const isAiChat = !!thread?.agent;

  const scrollableDivRef = useRef(null);

  useEffect(() => {
    if (lastJsonMessage !== null) {
      if (lastJsonMessage.entity === 'Message') {
        // When a new message is received via websocket, clear
        if (pendingMessages.length > 0) {
          setPendingMessages([]);
        }

        setIsTyping(false);
        
        if (page === 1) {
          refetch();
        } else {
          setPage(1);
        }
      } else if (lastJsonMessage.event === 'typing_event') {
        setIsTyping(lastJsonMessage.is_typing);
      }
    }
  }, [lastJsonMessage]);

  const { data, isFetching, refetch } = messageThread.list(
    {
      id: `${threadId}/message`,
      page_size: PAGE_SIZE,
      page: page,
    },
  );

  useEffect(() => {
    if (threadId) {
      setMessages([]);
      setPendingMessages([]);
      setPage(1);
    }
  }, [threadId]);

  const handleSendMessage = async () => {
    if (!currentMessage && !attachedFile) return;
    
    setIsSending(true);
    
    // Create a temporary ID for optimistic update
    const tempId = uuidv4();
    
    // Create pending message for immediate display
    const pendingMessage = {
      id: tempId,
      message: currentMessage,
      sender: userId,
      sender_name: user?.info?.name || "You",
      created_at: new Date().toISOString(),
      isPending: true,
      attachedFile: attachedFile ? {
        name: attachedFile.name,
        type: attachedFile.type,
        url: URL.createObjectURL(attachedFile)
      } : null,
    };
    
    // Add to pending messages for immediate display
    setPendingMessages(prev => [pendingMessage, ...prev]);
    
    // Prepare API request
    const body = { 
      message: currentMessage,
    };

    // Clear input state
    setCurrentMessage('');
    const fileToUpload = attachedFile;
    setAttachedFile(null);

    try {
      // Handle file upload if present
      if (fileToUpload) {
        const newMedia = new FormData();
        const mediaType = getMediaTypeFromFileType(fileToUpload.type);
        newMedia.append('file', fileToUpload);
        newMedia.append('clinic', clinic.id);
        newMedia.append('type', mediaType);
        
        if (thread.patient) {
          newMedia.append('patient', thread.patient);
        }
        
        const mediaData = await sendMedia({ body: newMedia });
        body.media = mediaData.data.id;
      }

      if (sendLaterAnchorEl) {
        body.scheduled_time = sendLaterDate.toISOString();
        setSendLaterAnchorEl(null);
      }
      
      // Send message to server
      await sendMessage({ body, id: `${threadId}/message` });
      
      // The message will be added to the regular messages via WebSocket or refetch
      // No need to remove from pendingMessages here as the WebSocket handler will do that
    } catch (error) {
      console.error('Error sending message:', error);
      
      // Update the pending message to show it failed
      setPendingMessages(prev => 
        prev.map(msg => 
          msg.id === tempId 
            ? { ...msg, isPending: false, hasFailed: true } 
            : msg
        )
      );
    } finally {
      setIsSending(false);
    }
  };

  useEffect(() => {
    if (readyState === 1) { // 1 corresponds to OPEN state
      refetch();
    }
  }, [readyState, refetch]);

  const handleSwitch = async () => {
    setLoading(true); // Start loading state
    const accept = !thread?.accept_patient_message;

    try {
      await updateThread({
        id: threadId,
        body: { accept_patient_message: accept },
      });
    } catch (error) {
      console.error(error); // Handle any errors
    } finally {
      setLoading(false); // End loading state
    }
  };

  useEffect(() => {
    if (data && data.results) {
      setMessages(prevMessages => {
        const allMessages = page === 1 ? data.results : [...prevMessages, ...data.results];

        // Create a Map to ensure uniqueness by message.id and preserve order
        const uniqueMessages = Array.from(new Map(allMessages.map(msg => [msg.id, msg])).values());

        return uniqueMessages;
      });
    }
  }, [data]);

  const handleScroll = () => {
    const scrollableDiv = scrollableDivRef.current;
    if (scrollableDiv) {
      const { scrollTop } = scrollableDiv;
      const threshold = 100; // Adjust as needed
      if (scrollTop <= threshold) {
        if (data?.next && !isFetching) {
          setPage(prevPage => prevPage + 1);
        } 
      } 
    }
  };

  // Determine if the user can send a message
  const canSendMessage = () => {
    if (isAiChat) {
      return true; // Always allow sending messages to AI
    }
    
    // Follow existing rules for non-AI chats
    return !(access === 'P' && !thread?.accept_patient_message);
  };

  // Retry sending a failed message
  const handleRetryMessage = (tempId) => {
    const failedMessage = pendingMessages.find(msg => msg.tempId === tempId);
    if (failedMessage) {
      setCurrentMessage(failedMessage.message);
      if (failedMessage.attachedFile) {
        // Need to convert back from URL to File - this is simplified
        // In a real implementation, you might need to store the original File object
        fetch(failedMessage.attachedFile.url)
          .then(r => r.blob())
          .then(blob => {
            const file = new File([blob], failedMessage.attachedFile.name, { type: failedMessage.attachedFile.type });
            setAttachedFile(file);
          });
      }
      
      // Remove the failed message
      setPendingMessages(prev => prev.filter(msg => msg.tempId !== tempId));
    }
  };

  // Combine pending and actual messages for display
  const allMessages = [...pendingMessages, ...messages];

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      {/* Header area with back button and AI indicator if applicable */}
      <Box sx={{ 
        display: 'flex', 
        alignItems: 'center', 
        p: 1, 
      }}>
        {isMobile && (
          <IconButton
            onClick={() => navigate(-1)}
          >
            <ArrowBackIcon />
          </IconButton>
        )}
        
        {isAiChat && (
          <Chip
            icon={<SmartToyIcon fontSize="small" />}
            label={t('AI Agent')}
            color="secondary"
            size="small"
            sx={{ ml: isMobile ? 1 : 0 }}
          />
        )}
      </Box>
      
      <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
        <AutoSizer>
          {({ height, width }) => (
            <Box
              id="scrollableDiv"
              ref={scrollableDivRef}
              onScroll={handleScroll}
              sx={{
                height: height,
                width: width,
                overflow: 'auto',
                display: 'flex',
                flexDirection: 'column-reverse',
                position: 'relative',
                maskImage: `linear-gradient(
                  to bottom,
                  rgba(0,0,0,0) 0px, 
                  rgba(0,0,0,1) 20px, 
                  rgba(0,0,0,1) 100%
                )`,
                WebkitMaskImage: `linear-gradient(
                  to bottom,
                  rgba(0,0,0,0) 0px, 
                  rgba(0,0,0,1) 20px, 
                  rgba(0,0,0,1) 100%
                )`,
              }}
            >
              {/* AI Typing indicator */}
              {isTyping && isAiChat && (
                <Box sx={{ p: 2, display: 'flex', alignItems: 'center' }}>
                  <CircularProgress size={16} sx={{ mr: 1 }} />
                  <Typography variant="body2" color="textSecondary">
                    {t('AI is typing...')}
                  </Typography>
                </Box>
              )}
              
              {/* Placeholder for no messages */}
              {!isFetching && allMessages.length === 0 && (
                <Box sx={{ textAlign: 'center', my: 2 }}>
                  <Typography variant="body1" color="textSecondary">
                    {isAiChat 
                      ? t('Start chatting with the AI assistant!')
                      : t('No messages yet. Start the conversation!')}
                  </Typography>
                </Box>
              )}

              {/* Display both pending and real messages */}
              {allMessages.map((msg, index) => (
                <MessageBox
                  key={msg.id}
                  message={msg}
                  previousMessage={allMessages[index + 1]}
                  threadId={threadId}
                  isPending={msg.isPending}
                  hasFailed={msg.hasFailed}
                  onRetry={() => handleRetryMessage(msg.tempId)}
                />
              ))}

              {isFetching && !isSending && (
                <div style={{ alignSelf: 'center' }}><CircularProgress size={30} /></div>
              )}

              {/* Message indicating the top of the conversation */}
              {messages.length > 0 && !data.next && (
                <Box sx={{ textAlign: 'center', my: 3 }}>
                  <Typography variant="body2" color="textSecondary">
                    {t('You have reached the beginning of the conversation')}
                  </Typography>
                </Box>
              )}
            </Box>
          )}
        </AutoSizer>
      </Box>

      {canSendMessage() && (
        <Box sx={{ p: 1 }}>
          {isMobile && (
            <Box display="flex">
              <MessageTextField
                isFetching={isFetching || isSending}
                attachedFile={attachedFile}
                setAttachedFile={setAttachedFile}
                currentMessage={currentMessage}
                setCurrentMessage={setCurrentMessage}
              />
              <IconButton
                size="small"
                color="primary"
                sx={{ ml: 1 }}
                disabled={(!currentMessage && !attachedFile) || isSending}
                onClick={handleSendMessage}
              >
                {isSending ? <CircularProgress size={24} /> : <SendIcon />}
              </IconButton>
            </Box>
          )}
          <Box sx={{
            display: 'flex',
            alignItems: 'center',
            mt: 1,
          }}
          >
            {/* Only show the toggle for patient messaging if this is not an AI chat */}
            {access === 'PT' && !isAiChat && (
              <Tooltip title={!thread?.accept_patient_message ? t('Allow patient to answer') : t('Do not allow patient to answer')}>
                <ToggleButton
                  selected={thread?.accept_patient_message}
                  value={thread?.accept_patient_message}
                  color="success"
                  onChange={handleSwitch}
                  disabled={loading}
                >
                  {loading ? (
                    <CircularProgress size={24} color="inherit" />
                  ) : (
                    thread?.accept_patient_message ? <CommentIcon /> : <CommentsDisabledIcon />
                  )}
                </ToggleButton>
              </Tooltip>
            )}
            <input
              style={{ display: 'none' }}
              onChange={(e) => setAttachedFile(e.target.files[0])}
              type="file"
              id="raised-button-file"
              disabled={isSending}
            />
            <label htmlFor="raised-button-file">
              <IconButton
                size="small"
                component="span"
                color="primary"
                sx={{ ml: 1 }}
                disabled={isSending}
              >
                <AttachFileIcon />
              </IconButton>
            </label>
            {!isMobile && (
              <MessageTextField
                isFetching={isFetching}
                isSending={isSending}
                attachedFile={attachedFile}
                setAttachedFile={setAttachedFile}
                currentMessage={currentMessage}
                setCurrentMessage={setCurrentMessage}
                isAiChat={isAiChat}
                placeholder={isAiChat ? t('Ask the AI assistant...') : t('Enter your message')}
              />
            )}
            <IconButton
              size="small"
              sx={{ ml: 1 }}
              onClick={(e) => setAnchorEmote(e.currentTarget)}
              disabled={isSending}
            >
              <InsertEmoticonIcon />
            </IconButton>
            <Popover
              open={Boolean(anchorEmote)}
              onClose={() => setAnchorEmote(null)}
              anchorEl={anchorEmote}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
            >
              <EmojiPicker
                onEmojiClick={(emojiObject) => {
                  setCurrentMessage(currentMessage + emojiObject.emoji);
                  setAnchorEmote(null);
                }}
                lazyLoadEmojis
                searchDisabled
                autoFocusSearch={false}
                suggestedEmojisMode="recent"
                skinTonesDisabled
              />
            </Popover>
            {!isMobile && (
              <IconButton
                size="small"
                color="primary"
                disabled={(!currentMessage && !attachedFile) || isSending}
                onClick={handleSendMessage}
                onContextMenu={(e) => {
                  // Don't allow scheduled messages for AI chats
                  if (!isAiChat) {
                    setSendLaterAnchorEl(e.currentTarget);
                    e.preventDefault();
                  }
                }}
              >
                {isSending ? <CircularProgress size={24} /> : <SendIcon />}
              </IconButton>
            )}
            <Popover
              open={Boolean(sendLaterAnchorEl)}
              onClose={() => setSendLaterAnchorEl(null)}
              anchorEl={sendLaterAnchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
            >
              <Box sx={{ p: 2 }}>
                <LocalizationProvider dateAdapter={AdapterMoment} locale={i18n.resolvedLanguage}>
                  <DateTimePicker
                    value={moment(sendLaterDate)}
                    label={t('Send message at')}
                    onChange={(newValue) => setSendLaterDate(newValue)}
                    disablePast
                  />
                </LocalizationProvider>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 1 }}>
                  <Button color="secondary" size="small" onClick={() => setSendLaterAnchorEl(null)}>
                    {t('Close')}
                  </Button>
                  <Button 
                    size="small" 
                    onClick={handleSendMessage}
                    disabled={isSending}
                  >
                    {t('Send')}
                  </Button>
                </Box>
              </Box>
            </Popover>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default ChatBox;