import React, { useState } from 'react';
import { Box, IconButton, Typography, AppBar, Toolbar } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DownloadIcon from '@mui/icons-material/Download';
import { useTranslation } from 'react-i18next';

/**
 * A generic document viewer for various file types
 * 
 * @param {Object} props
 * @param {string} props.link - URL to the document
 * @param {string} props.type - MIME type of the document
 * @param {string} props.title - Document title
 * @param {Function} props.onClose - Function to call when closing the viewer
 */
const DocumentViewer = ({ link, type, title, onClose }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);

  const handleDownload = () => {
    const a = document.createElement('a');
    a.href = link;
    a.download = title || 'document';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const renderContent = () => {
    // Handle image types
    if (type.startsWith('image/')) {
      return (
        <Box 
          display="flex" 
          justifyContent="center" 
          alignItems="center" 
          height="100%" 
          bgcolor="#333"
          padding={2}
        >
          <img 
            src={link} 
            alt={title || 'Image'} 
            style={{ 
              maxWidth: '100%', 
              maxHeight: 'calc(100vh - 64px)', 
              objectFit: 'contain' 
            }} 
            onLoad={() => setLoading(false)}
          />
        </Box>
      );
    }
    
    // Handle text types
    if (type.startsWith('text/') || type === 'application/json') {
      return (
        <Box 
          display="flex" 
          flexDirection="column" 
          height="100%" 
          bgcolor="#f5f5f5"
          overflow="auto"
          padding={2}
        >
          <iframe 
            src={link} 
            title={title || 'Text document'} 
            style={{ 
              width: '100%', 
              height: '100%', 
              border: 'none',
              backgroundColor: 'white'
            }}
            onLoad={() => setLoading(false)}
          />
        </Box>
      );
    }
    
    // Handle audio
    if (type.startsWith('audio/')) {
      return (
        <Box 
          display="flex" 
          justifyContent="center" 
          alignItems="center" 
          height="100%" 
          bgcolor="#333"
          padding={2}
        >
          <audio 
            src={link} 
            controls 
            style={{ width: '80%' }}
            onLoadedData={() => setLoading(false)}
          />
        </Box>
      );
    }
    
    // Handle video
    if (type.startsWith('video/')) {
      return (
        <Box 
          display="flex" 
          justifyContent="center" 
          alignItems="center" 
          height="100%" 
          bgcolor="#333"
          padding={2}
        >
          <video 
            src={link} 
            controls 
            style={{ 
              maxWidth: '100%', 
              maxHeight: 'calc(100vh - 64px)' 
            }}
            onLoadedData={() => setLoading(false)}
          />
        </Box>
      );
    }

    // Handle CSV files
    if (type === 'text/csv') {
      return (
        <Box 
          display="flex" 
          flexDirection="column" 
          height="100%" 
          bgcolor="#f5f5f5"
          overflow="auto"
          padding={2}
        >
          <iframe 
            src={link} 
            title={title || 'CSV document'} 
            style={{ 
              width: '100%', 
              height: '100%', 
              border: 'none',
              backgroundColor: 'white' 
            }}
            onLoad={() => setLoading(false)}
          />
        </Box>
      );
    }
    
    // For unsupported types, offer download
    return (
      <Box 
        display="flex" 
        flexDirection="column" 
        justifyContent="center" 
        alignItems="center" 
        height="100%" 
        bgcolor="#f5f5f5"
        padding={2}
        gap={2}
      >
        <Typography variant="h6">
          {t('This file type cannot be previewed')}
        </Typography>
        <Typography>
          {title || type}
        </Typography>
        <IconButton 
          color="primary" 
          onClick={handleDownload}
          size="large"
        >
          <DownloadIcon fontSize="large" />
        </IconButton>
        <Typography variant="body2">
          {t('Click to download')}
        </Typography>
      </Box>
    );
  };

  return (
    <Box 
      sx={{ 
        width: '100%', 
        height: '100vh', 
        display: 'flex', 
        flexDirection: 'column',
        bgcolor: 'background.paper'
      }}
    >
      <AppBar position="static" color="default" elevation={1}>
        <Toolbar>
          <Typography variant="h6" sx={{ flexGrow: 1 }}>
            {title || t('Document')}
          </Typography>
          <IconButton edge="end" color="inherit" onClick={handleDownload} aria-label={t('Download')}>
            <DownloadIcon />
          </IconButton>
          <IconButton edge="end" color="inherit" onClick={onClose} aria-label={t('Close')}>
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Box 
        sx={{ 
          flexGrow: 1, 
          overflow: 'auto',
          position: 'relative'
        }}
      >
        {loading && (
          <Box 
            position="absolute" 
            top="50%" 
            left="50%" 
            sx={{ 
              transform: 'translate(-50%, -50%)'
            }}
          >
            <Typography>{t('Loading...')}</Typography>
          </Box>
        )}
        {renderContent()}
      </Box>
    </Box>
  );
};

export default DocumentViewer;
