import AddIcon from '@mui/icons-material/Add';
import TrashIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import DescriptionIcon from '@mui/icons-material/Description';
import {
  Box, 
  IconButton, 
  Paper, 
  Stack, 
  Typography, 
  Tooltip, 
  Dialog,
  CircularProgress,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { format } from 'date-fns';
import React, { useState, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { greyboxApiActions } from '../../../redux/api';
import { setSnackbarStatus } from '../../../redux/snackbarStatus';
import { documentReferenceType } from '../../../types';
import { getDateFnsLocale } from '../../../utils';
import DataGrid from '../../DataGrid';
import DocumentIconByType from '../../DocumentIconByType';
import OpenDocumentPdfViewerButton from '../../pdf-viewer/OpenDocumentPdfViewerButton';
import DocumentPdfViewer from '../../pdf-viewer/DocumentPdfViewer';
import OpenDocumentViewerButton from '../../document-viewer/OpenDocumentViewerButton';
import DocumentViewer from '../../document-viewer/DocumentViewer';

/**
 * Documents component displays a patient's documents in a grid with actions
 * for uploading, downloading, viewing and deleting documents.
 */
const Documents = () => {
  const { uuid } = useParams();
  const { t, i18n } = useTranslation();
  const { documentReference } = greyboxApiActions as any;
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  
  // Grid state
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selected, setSelected] = useState<string[]>([]);
  
  // Document state
  const [isUploading, setIsUploading] = useState(false);
  
  // Viewer state - simplified to a single document state object
  const [selectedDocument, setSelectedDocument] = useState<{
    url: string;
    id?: string;
    type: string;
    title: string;
    isOpen: boolean;
  }>({
    url: '',
    id: '',
    type: '',
    title: '',
    isOpen: false
  });
  
  // API hooks
  const [addDocument] = documentReference.add();
  const [deleteDocument] = documentReference.delete();

  const { data, isLoading } = documentReference.list({
    subject: 'Patient/' + uuid,
    page: page + 1,
    _count: rowsPerPage,
  }) as { data: documentReferenceType; isLoading: boolean };

  /**
   * Deletes selected documents and clears selection
   */
  const handleDelete = useCallback(() => {
    Promise.all(selected.map((id) => deleteDocument(id)))
      .then(() => {
        dispatch(setSnackbarStatus({
          open: true,
          message: t('Documents deleted successfully'),
          severity: 'success',
        }));
        setSelected([]);
      })
      .catch(() => {
        dispatch(setSnackbarStatus({
          open: true,
          message: t('Error deleting documents'),
          severity: 'error',
        }));
      });
  }, [selected, deleteDocument, dispatch, t]);

  /**
   * Downloads selected documents in new tabs
   */
  const handleDownload = useCallback(() => {
    selected.forEach((id) => {
      const url = data?.entry.find(
        (item) => item.resource.id === id,
      )?.resource.content[0].attachment.data;

      if (url) {
        window.open(url, '_blank');
      }
    });
  }, [selected, data?.entry]);

  /**
   * Handles file upload from the file input
   */
  const handleFileChange = useCallback(async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files || event.target.files.length === 0) {
      return;
    }
    const file = event.target.files[0];
    
    // Show uploading notification with spinner
    setIsUploading(true);
    dispatch(setSnackbarStatus({
      open: true,
      message: t('Uploading document...'),
      severity: 'info',
      showSpinner: true,
    }));

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      // Construct the FHIR DocumentReference resource
      const documentReferenceResource = {
        resourceType: 'DocumentReference',
        status: 'current',
        type: {
          coding: [
            {
              system: 'http://loinc.org',
              code: '68603-1',
            },
          ],
        },
        subject: {
          reference: `Patient/${uuid}`,
        },
        content: [
          {
            attachment: {
              contentType: file.type,
              data: (reader.result as string).split(',')[1],
              title: file.name,
            },
          },
        ],
      };

      // Send the document
      addDocument({
        body: documentReferenceResource,
        feedback: {
          success: t('Document added successfully'),
          error: t('An error occurred while adding the document'),
        },
      }).finally(() => {
        setIsUploading(false);
      });
    };
    reader.onerror = (error) => {
      console.error('Error reading file:', error);
      setIsUploading(false);
      dispatch(setSnackbarStatus({
        open: true,
        message: t('Error reading file'),
        severity: 'error',
        showSpinner: false,
      }));
    };
  }, [addDocument, dispatch, t, uuid]);

  /**
   * Opens a document in the appropriate viewer
   */
  const handleOpenDocument = useCallback((rowData: any) => {
    const documentData = data?.entry.find(item => item.resource.id === rowData.id);
    if (documentData) {
      const docData = documentData.resource.content[0].attachment;
      
      setSelectedDocument({
        url: docData.data,
        id: rowData.id,
        type: rowData.type,
        title: docData.title,
        isOpen: true
      });
    }
  }, [data?.entry]);
  
  /**
   * Closes the document viewer
   */
  const handleCloseDocument = useCallback(() => {
    setSelectedDocument(prev => ({
      ...prev,
      isOpen: false
    }));
  }, []);

  /**
   * Grid column definitions with improved formatting and layout
   */
  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: 'type',
        headerName: t('Type'),
        width: 70,
        align: 'center',
        headerAlign: 'center',
        renderCell: (params: GridRenderCellParams) => (
          <Box 
            display="flex" 
            alignItems="center" 
            justifyContent="center" 
            height="100%" 
            sx={{ 
              color: theme.palette.primary.main,
              '& svg': {
                fontSize: '1.5rem'
              }
            }}
          >
            <DocumentIconByType type={params.value} />
          </Box>
        ),
      },
      { 
        field: 'title', 
        headerName: t('Title'), 
        flex: 2,
        minWidth: 200,
        renderCell: (params: GridRenderCellParams) => (
          <Box 
            sx={{ 
              overflow: 'hidden', 
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              width: '100%',
              fontWeight: 500
            }}
          >
            {params.value}
          </Box>
        )
      },
      { 
        field: 'date', 
        headerName: t('Created at'), 
        flex: 1,
        minWidth: 150,
        renderCell: (params: GridRenderCellParams) => (
          <Box sx={{ color: theme.palette.text.secondary }}>
            {params.value}
          </Box>
        )
      },
      {
        field: 'actions',
        headerName: t('Actions'),
        width: 100,
        sortable: false,
        align: 'center',
        headerAlign: 'center',
        renderCell: (params: GridRenderCellParams) => {
          const documentData = data?.entry.find(item => item.resource.id === params.row.id);
          if (!documentData) return null;
          
          // Create a view button that opens the document without interfering with row selection
          return (
            <Tooltip 
              title={params.row.type === 'application/pdf' 
                ? t('View PDF') 
                : t('View document')
              }
            >
              <IconButton 
                size="small"
                onClick={(e) => {
                  e.stopPropagation(); // Prevent row selection
                  handleOpenDocument(params.row);
                }}
                sx={{ 
                  color: theme.palette.primary.main,
                }}
              >
                {params.row.type === 'application/pdf' 
                  ? <PictureAsPdfIcon /> 
                  : <DescriptionIcon />
                }
              </IconButton>
            </Tooltip>
          );
        },
      },
    ],
    [t, theme, data?.entry, handleOpenDocument],
  );

  /**
   * Processed row data from API response
   */
  const rows = useMemo(
    () => data?.entry?.map((item) => ({
      id: item.resource.id,
      date: format(new Date(item.resource.meta.lastUpdated), 'Pp', { locale: getDateFnsLocale(i18n.language) }),
      type: item.resource.content[0].attachment.contentType,
      title: item.resource.content[0].attachment.title,
      language: item.resource.content[0].attachment.language,
      dataUrl: item.resource.content[0].attachment.data,
    })) || [],
    [data, i18n.language],
  );

  return (
    <Box width="100%">
      {/* Unified document viewer dialog */}
      <Dialog
        open={selectedDocument.isOpen}
        onClose={handleCloseDocument}
        fullScreen
        sx={{
          '& .MuiDialog-paper': { backgroundColor: 'rgba(0, 0, 0, 0)' },
        }}
      >
        {selectedDocument.isOpen && (
          selectedDocument.type === 'application/pdf' ? (
            <DocumentPdfViewer 
              link={selectedDocument.url} 
              setIframeUrl={() => handleCloseDocument()} 
              id={selectedDocument.id}
              title={selectedDocument.title}
            />
          ) : (
            <DocumentViewer 
              link={selectedDocument.url}
              type={selectedDocument.type}
              title={selectedDocument.title}
              onClose={handleCloseDocument}
            />
          )
        )}
      </Dialog>
      
      {/* Page header */}
      <Typography 
        variant={isMobile ? "h5" : "h4"} 
        sx={{ 
          fontWeight: 'bold', 
          color: theme.palette.text.secondary,
          mb: 2
        }}
      >
        {t('Documents')}
      </Typography>
      
      {/* Action toolbar */}
      <Paper 
        sx={{ 
          p: 1.5, 
          mb: 2,
          borderRadius: 2,
          boxShadow: (theme) => theme.shadows[2]
        }}
      >
        <Stack 
          direction="row" 
          spacing={1} 
          alignItems="center"
          justifyContent="space-between"
        >
          <Box>
            <input 
              style={{ display: 'none' }} 
              onChange={handleFileChange} 
              id="raised-button-file" 
              type="file" 
              disabled={isUploading}
            />
            <label htmlFor="raised-button-file">
              <Tooltip title={isUploading ? t('Uploading...') : t('Upload document')}>
                <span>
                  <IconButton 
                    component="span" 
                    color="primary"
                    disabled={isUploading}
                    sx={{ 
                      backgroundColor: 'rgba(25, 118, 210, 0.04)',
                      '&:hover': {
                        backgroundColor: 'rgba(25, 118, 210, 0.08)',
                      }
                    }}
                  >
                    {isUploading ? <CircularProgress size={24} /> : <AddIcon />}
                  </IconButton>
                </span>
              </Tooltip>
            </label>
          </Box>
          
          {selected.length > 0 && (
            <Stack direction="row" spacing={1}>
              <Tooltip title={t('Download selected')}>
                <IconButton 
                  onClick={handleDownload} 
                  color="primary"
                  sx={{ 
                    backgroundColor: 'rgba(25, 118, 210, 0.04)',
                    '&:hover': {
                      backgroundColor: 'rgba(25, 118, 210, 0.08)',
                    }
                  }}
                >
                  <DownloadIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title={t('Delete selected')}>
                <IconButton 
                  onClick={handleDelete} 
                  color="error"
                  sx={{ 
                    backgroundColor: 'rgba(211, 47, 47, 0.04)',
                    '&:hover': {
                      backgroundColor: 'rgba(211, 47, 47, 0.08)',
                    }
                  }}
                >
                  <TrashIcon />
                </IconButton>
              </Tooltip>
            </Stack>
          )}
        </Stack>
      </Paper>
      
      {/* Documents grid */}
      <DataGrid
        columns={columns}
        rows={rows}
        loading={isLoading}
        totalCount={data?.total || 0}
        page={page}
        setSelectedRows={(rows) => setSelected(rows as string[])}
        pageSize={rowsPerPage}
        onPageSizeChange={(pageSize) => setRowsPerPage(pageSize)}
        onPageChange={(newPage) => setPage(newPage)}
        onRowClick={(params) => handleOpenDocument(params.row)}
      />
    </Box>
  );
};

export default Documents;
