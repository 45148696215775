import { useMsal } from '@azure/msal-react';
import {
  Avatar,
  Button,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
  styled,
} from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
// Icon imports
import AssignmentIcon from '@mui/icons-material/Assignment';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import LightModeIcon from '@mui/icons-material/LightMode';
import LogoutIcon from '@mui/icons-material/Logout';
import PrivacyTipIcon from '@mui/icons-material/PrivacyTip';
import SettingsIcon from '@mui/icons-material/Settings';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
// Redux imports
import { greyboxApiActions } from '../redux/api';
import { logout } from '../redux/authorization';
import { toggleTheme } from '../redux/theme';

const ProfileButton = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { instance } = useMsal();
  const { style } = useSelector((state) => state.theme);
  const { user } = greyboxApiActions;
  const { data, isLoading } = user.get();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const userName = React.useMemo(() => {
    if (isLoading) return '';
    const firstInitial = data?.first_name?.[0] || '';
    const lastInitial = data?.last_name?.[0] || '';
    return `${firstInitial}${lastInitial}`;
  }, [isLoading, data]);

  const handleNavigation = React.useCallback(
    (path) => {
      setAnchorEl(null);
      navigate(path);
    },
    [navigate]
  );

  const handleLogout = React.useCallback(() => {
    dispatch(logout());
    instance.logoutRedirect({ postLogoutRedirectUri: '/' });
  }, [dispatch, instance]);

  const handleToggleTheme = React.useCallback(() => {
    dispatch(toggleTheme());
    setAnchorEl(null);
  }, [dispatch]);

  const openExternalLink = React.useCallback((url) => {
    window.open(url, '_blank');
    setAnchorEl(null);
  }, []);

  const menuItems = React.useMemo(
    () => [
      {
        text: t('Your Information'),
        icon: <SettingsIcon fontSize="small" />,
        onClick: () => handleNavigation('/your-information'),
      },
      {
        text: t('Terms of Use'),
        icon: <AssignmentIcon fontSize="small" />,
        onClick: () => openExternalLink('https://greybox.ca/terms'),
      },
      {
        text: t('Privacy Policy'),
        icon: <PrivacyTipIcon fontSize="small" />,
        onClick: () => openExternalLink('https://greybox.ca/privacy'),
      },
      {
        text: style === 'light' ? t('Dark theme') : t('Light theme'),
        icon: style === 'light' ? <DarkModeIcon fontSize="small" /> : <LightModeIcon fontSize="small" />,
        onClick: handleToggleTheme,
      },
      {
        text: t('Support Portal'),
        icon: <SupportAgentIcon fontSize="small" />,
        onClick: () => openExternalLink('https://greybox.atlassian.net/servicedesk/customer/portals'),
      },
      {
        text: t('Logout'),
        icon: <LogoutIcon fontSize="small" />,
        onClick: handleLogout,
      },
    ],
    [t, style, handleNavigation, handleToggleTheme, handleLogout, openExternalLink]
  );

  return (
    <>
      <StyledButton
        onClick={(e) => setAnchorEl(e.currentTarget)}
        variant="contained"
      >
        <StyledAvatar src={data?.profile_picture_url}>
          {userName}
        </StyledAvatar>
        <SettingsIcon sx={{ mr: 1 }} />
      </StyledButton>
      
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <MenuList dense>
          {menuItems.map((item, index) => (
            <MenuItem key={index} onClick={item.onClick}>
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.text} />
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    </>
  );
};

const StyledButton = styled(Button)(({ theme }) => ({
  borderRadius: '50px',
  padding: theme.spacing(0.5),
  '& .MuiSvgIcon-root': {
    transition: 'transform 0.2s ease-in-out',
  },
  '&:hover .MuiSvgIcon-root': {
    transform: 'rotate(180deg)',
  },
}));

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  marginRight: theme.spacing(1),
  fontWeight: theme.typography.fontWeightRegular,
  fontSize: '0.9rem',
  backgroundColor: theme.palette.secondary.main,
  color: theme.palette.secondary.contrastText,
  height: 30,
  width: 30,
  transition: 'transform 0.2s ease-in-out',
  '&:hover': {
    transform: 'scale(1.1)',
  },
}));

export default ProfileButton;