import React, { useState, useRef } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Tabs,
  Tab,
  Box,
  Paper,
  useTheme,
  CircularProgress,
  Alert,
  Stepper,
  Step,
  StepLabel,
  IconButton,
  LinearProgress,
  Chip,
} from '@mui/material';
import {
  Upload as UploadIcon,
  Code as CodeIcon,
  TableChart as TableChartIcon,
  Description as DescriptionIcon,
  TextSnippet as TextSnippetIcon,
  Close as CloseIcon,
  CloudUpload as CloudUploadIcon,
  DeleteOutline as DeleteOutlineIcon,
} from '@mui/icons-material';
import { useImportTrainingDataMutation } from '../../redux/api';
import { useDispatch } from 'react-redux';
import { setSnackbarStatus } from '../../redux/snackbarStatus';
import { useTranslation } from 'react-i18next';

const ImportDialog = ({ open, onClose, agentId }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const dispatch = useDispatch();
  const fileInputRef = useRef(null);
  const [importTrainingData, { isLoading: isImporting }] = useImportTrainingDataMutation();
  
  const [activeStep, setActiveStep] = useState(0);
  const [importTab, setImportTab] = useState(0);
  const [file, setFile] = useState(null);
  const [errors, setErrors] = useState({});
  const [previewContent, setPreviewContent] = useState(null);
  const [importedCount, setImportedCount] = useState(0);

  const formats = [
    { value: 0, label: 'JSON', icon: <CodeIcon />, extension: 'json', 
      mimeType: 'application/json',
      description: t('A structured data format using JavaScript Object Notation.') },
    { value: 1, label: 'CSV', icon: <TableChartIcon />, extension: 'csv', 
      mimeType: 'text/csv',
      description: t('Comma-separated values file that can be opened in spreadsheet apps.') },
    { value: 2, label: 'Excel', icon: <DescriptionIcon />, extension: 'xlsx', 
      mimeType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      description: t('Microsoft Excel file format with support for formatting.') },
    { value: 3, label: 'Text', icon: <TextSnippetIcon />, extension: 'txt', 
      mimeType: 'text/plain',
      description: t('Simple text file with Q/A formatting.') },
  ];

  const steps = [
    t('Select format'),
    t('Upload file'),
    t('Review & import')
  ];

  const handleImportTabChange = (event, newValue) => {
    setImportTab(newValue);
    setFile(null);
    setPreviewContent(null);
    setErrors({});
  };

  const handleFileChange = (e) => {
    if (e.target.files?.[0]) {
      const selectedFile = e.target.files[0];
      setFile(selectedFile);
      
      // Create a preview if possible
      if (formats[importTab].extension === 'json' || formats[importTab].extension === 'txt' || formats[importTab].extension === 'csv') {
        const reader = new FileReader();
        reader.onload = (event) => {
          try {
            let content = event.target.result;
            
            // For JSON, try to format it nicely
            if (formats[importTab].extension === 'json') {
              try {
                const parsedJson = JSON.parse(content);
                content = JSON.stringify(parsedJson, null, 2);
              } catch (e) {
                // If parsing fails, show as is
              }
            }
            
            // Limit preview size
            if (content.length > 5000) {
              content = content.substring(0, 5000) + '...\n\n' + t('[Content truncated for preview]');
            }
            
            setPreviewContent(content);
            setErrors({});
          } catch (error) {
            setErrors({ file: t('Could not preview file: {{message}}', { message: error.message }) });
          }
        };
        reader.readAsText(selectedFile);
      } else {
        setPreviewContent(t('[Preview not available for {{format}} files]', { format: formats[importTab].extension.toUpperCase() }));
      }
      
      if (errors.file) {
        setErrors({});
      }
      
      // Move to next step automatically
      setActiveStep(2);
    }
  };

  const validateFile = () => {
    if (!file) {
      setErrors({ file: t('Please select a file') });
      return false;
    }

    // Get file extension
    const extension = file.name.split('.').pop().toLowerCase();
    const expectedExtension = formats[importTab].extension;
    
    if (extension !== expectedExtension && 
        !(importTab === 2 && (extension === 'xls' || extension === 'xlsx'))) {
      setErrors({ file: t('Expected {{expected}} file but got {{received}}', { expected: expectedExtension, received: extension }) });
      return false;
    }

    return true;
  };

  const handleImport = async () => {
    if (!validateFile()) return;

    const formData = new FormData();
    formData.append('file', file);
    
    // Add format parameter based on the selected tab
    let format = formats[importTab].extension;
    formData.append('format', format);

    try {
      const result = await importTrainingData({
        agentId,
        formData,
      }).unwrap();

      setImportedCount(result.count);
      
      dispatch(setSnackbarStatus({
        open: true,
        severity: 'success',
        message: t('Successfully imported {{count}} training items', { count: result.count }),
      }));

      // Reset form after successful import
      setTimeout(() => {
        handleClose();
      }, 3000);
    } catch (err) {
      dispatch(setSnackbarStatus({
        open: true,
        severity: 'error',
        message: t('Failed to import training data: {{message}}', { message: err.data?.detail || t('Unknown error') }),
      }));
    }
  };

  const handleBrowseClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleNext = () => {
    if (activeStep === 0) {
      setActiveStep(1);
    } else if (activeStep === 1 && file) {
      setActiveStep(2);
    }
  };

  const handleBack = () => {
    if (activeStep === 2 && !isImporting) {
      setActiveStep(1);
    } else if (activeStep === 1) {
      setActiveStep(0);
    }
  };

  const handleClose = () => {
    setActiveStep(0);
    setImportTab(0);
    setFile(null);
    setPreviewContent(null);
    setErrors({});
    setImportedCount(0);
    onClose();
  };

  const handleRemoveFile = () => {
    setFile(null);
    setPreviewContent(null);
    setActiveStep(1);
  };

  // Content for each step
  const renderStepContent = () => {
    switch (activeStep) {
      case 0:
        return (
          <Box sx={{ py: 2 }}>
            <Typography variant="body1" paragraph>
              {t('Choose the format of the file you want to import:')}
            </Typography>
            <Tabs
              value={importTab}
              onChange={handleImportTabChange}
              variant="fullWidth"
              sx={{ mb: 3, borderBottom: 1, borderColor: 'divider' }}
            >
              {formats.map((format) => (
                <Tab 
                  key={format.value}
                  label={format.label} 
                  icon={format.icon} 
                  iconPosition="start" 
                />
              ))}
            </Tabs>
            
            <Box sx={{ my: 2 }}>
              <Alert severity="info" sx={{ mb: 2 }}>
                <Typography variant="body2">
                  {formats[importTab].description}
                </Typography>
              </Alert>
              
              <Typography variant="subtitle1" gutterBottom>
                {t('Required Format:')}
              </Typography>
              
              <Paper variant="outlined" sx={{ p: 2, mt: 2, mb: 3, bgcolor: theme.palette.background.default }}>
                {importTab === 0 && (
                  <Typography variant="caption" component="div">
                    <pre style={{ whiteSpace: 'pre-wrap' }}>
                      {JSON.stringify([
                        {
                          "question": t('What is your return policy?'),
                          "answer": t('Our return policy allows returns within 30 days.')
                        },
                        {
                          "question": t('How do I track my order?'),
                          "answer": t('You can track your order by logging into your account.')
                        }
                      ], null, 2)}
                    </pre>
                  </Typography>
                )}
                
                {importTab === 1 && (
                  <Typography variant="caption" component="div">
                    <pre style={{ whiteSpace: 'pre-wrap' }}>
                      question,answer
                      "{t('What is your return policy?')}","{t('Our return policy allows returns within 30 days.')}"
                      "{t('How do I track my order?')}","{t('You can track your order by logging into your account.')}"
                    </pre>
                  </Typography>
                )}
                
                {importTab === 2 && (
                  <>
                    <Typography variant="body2" gutterBottom>
                      {t('Format your Excel file with these columns:')}
                    </Typography>
                    <Box sx={{ display: 'flex', border: 1, borderColor: 'divider', mb: 1 }}>
                      <Box sx={{ width: '50%', p: 1, fontWeight: 'bold', borderRight: 1, borderColor: 'divider' }}>question</Box>
                      <Box sx={{ width: '50%', p: 1, fontWeight: 'bold' }}>answer</Box>
                    </Box>
                    <Box sx={{ display: 'flex', border: 1, borderColor: 'divider' }}>
                      <Box sx={{ width: '50%', p: 1, borderRight: 1, borderColor: 'divider' }}>{t('What is your return policy?')}</Box>
                      <Box sx={{ width: '50%', p: 1 }}>{t('Our return policy allows returns within 30 days.')}</Box>
                    </Box>
                  </>
                )}
                
                {importTab === 3 && (
                  <Typography variant="caption" component="div">
                    <pre style={{ whiteSpace: 'pre-wrap' }}>
                      Q: {t('What is your return policy?')}
                      A: {t('Our return policy allows returns within 30 days.')}

                      Q: {t('How do I track my order?')}
                      A: {t('You can track your order by logging into your account.')}
                    </pre>
                  </Typography>
                )}
              </Paper>
            </Box>
          </Box>
        );
      
      case 1:
        return (
          <Box sx={{ py: 4, textAlign: 'center' }}>
            <input
              type="file"
              hidden
              ref={fileInputRef}
              accept={
                importTab === 0 ? ".json" : 
                importTab === 1 ? ".csv" : 
                importTab === 2 ? ".xlsx,.xls" : 
                ".txt"
              }
              onChange={handleFileChange}
            />
            
            <Box 
              sx={{ 
                border: '2px dashed', 
                borderColor: 'primary.main', 
                borderRadius: 2,
                p: 4,
                mb: 3,
                bgcolor: 'background.default',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
                '&:hover': {
                  bgcolor: 'action.hover',
                }
              }}
              onClick={handleBrowseClick}
            >
              <CloudUploadIcon sx={{ fontSize: 60, color: 'primary.main', mb: 2 }} />
              <Typography variant="h6" color="primary" gutterBottom>
                {t('Drag & drop or click to browse')}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {t('Select a {{format}} file to import', { format: formats[importTab].label })}
              </Typography>
            </Box>
            
            {errors.file && (
              <Alert severity="error" sx={{ mt: 2 }}>
                {errors.file}
              </Alert>
            )}
          </Box>
        );
      
      case 2:
        return (
          <Box sx={{ py: 2 }}>
            {file && (
              <>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 2 }}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    {formats[importTab].icon}
                    <Typography variant="subtitle1" sx={{ ml: 1 }}>
                      {file.name}
                    </Typography>
                    <Chip 
                      label={t('{{size}} KB', { size: (file.size / 1024).toFixed(1) })} 
                      size="small" 
                      sx={{ ml: 1 }} 
                      variant="outlined"
                    />
                  </Box>
                  {!isImporting && (
                    <IconButton onClick={handleRemoveFile} size="small" color="error">
                      <DeleteOutlineIcon />
                    </IconButton>
                  )}
                </Box>
                
                {previewContent && (
                  <Paper 
                    variant="outlined" 
                    sx={{ 
                      p: 2, 
                      mt: 2, 
                      mb: 3, 
                      bgcolor: theme.palette.background.default,
                      maxHeight: '200px',
                      overflow: 'auto'
                    }}
                  >
                    <Typography variant="caption" component="div">
                      <pre style={{ whiteSpace: 'pre-wrap' }}>
                        {previewContent}
                      </pre>
                    </Typography>
                  </Paper>
                )}
                
                {isImporting ? (
                  <Box sx={{ textAlign: 'center', py: 2 }}>
                    <CircularProgress sx={{ mb: 2 }} />
                    <Typography variant="body2">
                      {t('Importing data, please wait...')}
                    </Typography>
                    <LinearProgress sx={{ mt: 2 }} />
                  </Box>
                ) : importedCount > 0 ? (
                  <Alert severity="success" sx={{ mt: 2 }}>
                    <Typography variant="body1">
                      {t('Successfully imported {{count}} training items!', { count: importedCount })}
                    </Typography>
                  </Alert>
                ) : (
                  <Alert severity="info">
                    <Typography variant="body2">
                      {t('Ready to import. Click the Import button to proceed.')}
                    </Typography>
                  </Alert>
                )}
              </>
            )}
          </Box>
        );
        
      default:
        return null;
    }
  };

  return (
    <Dialog 
      open={open} 
      onClose={isImporting ? null : handleClose}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Typography variant="h6">{t('Import Training Data')}</Typography>
          {!isImporting && (
            <IconButton onClick={handleClose} size="small">
              <CloseIcon />
            </IconButton>
          )}
        </Box>
      </DialogTitle>
      
      <DialogContent>
        <Stepper activeStep={activeStep} sx={{ py: 3 }}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        
        {renderStepContent()}
      </DialogContent>
      
      <DialogActions>
        {activeStep > 0 && !isImporting && (
          <Button onClick={handleBack}>
            {t('Back')}
          </Button>
        )}
        
        {activeStep < 2 ? (
          <Button 
            variant="contained" 
            onClick={handleNext}
            disabled={activeStep === 1 && !file}
          >
            {t('Next')}
          </Button>
        ) : (
          <Button
            variant="contained"
            startIcon={isImporting ? <CircularProgress size={20} color="inherit" /> : <UploadIcon />}
            onClick={handleImport}
            disabled={isImporting || !file || importedCount > 0}
          >
            {isImporting ? t('Importing...') : importedCount > 0 ? t('Imported') : t('Import')}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default ImportDialog;