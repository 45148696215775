import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { apiEndpoints } from './endpointsConfig';
import { msalInstance, loginRequest, tokenRequest } from '../authConfig';
import { InteractionRequiredAuthError } from '@azure/msal-browser';
import { tenantDomainConfigs } from 'environmentConfig';

// Fhir endpoints have no slash at the end of the path
const parse = (path, id) => {
  if (path.startsWith('fhir')) {
    return `${path}/${id}`;
  }

  if (!id) return path;

  return `${path}${id}/`;
};

const domain = tenantDomainConfigs[window.location.hostname];
const webSocketUrl = `wss://${domain}/ws`;
const url = `https://${domain}`;

// Helper to build query string with pagination support
const buildQueryString = (params = {}) => {
  return Object.keys(params)
    .filter(
      (key) =>
        params[key] !== undefined &&
        params[key] !== null &&
        (!Array.isArray(params[key]) || params[key].length > 0)
    )
    .map((key) => `${key}=${encodeURIComponent(params[key])}`)
    .join('&');
};

// Utility function for transforming paginated responses
const transformPaginatedResponse = (response) => {
  // Only transform if it has the pagination structure
  if (response && typeof response === 'object' && 'results' in response) {
    return {
      data: response.results,
      meta: {
        totalCount: response.count,
        currentPage: response.next 
          ? parseInt(new URL(response.next).searchParams.get('page')) - 1
          : response.previous 
            ? parseInt(new URL(response.previous).searchParams.get('page')) + 1 
            : 1,
        totalPages: Math.ceil(response.count / (response.results.length || 1)),
        hasNextPage: !!response.next,
        hasPreviousPage: !!response.previous,
      }
    };
  }
  
  // Return original response if it's not paginated
  return response;
};

// Updated actionsMapper (unchanged from original, kept for reference)
const actionsMapper = {
  LIST: (builder, path, tag) => builder.query({
    query: ({ id, ...params }) => {
      const queryString = Object.keys(params)
        .filter(
          (key) =>
            params[key] !== undefined &&
            params[key] !== null &&
            (!Array.isArray(params[key]) || params[key].length > 0)
        )
        .map((key) => `${key}=${encodeURIComponent(params[key])}`)
        .join('&');

      return {
        url: `${path}${id ? `${id}/` : ''}?${queryString}`,
        method: 'GET',
      };
    },
    providesTags: tag,
  }),
  POST: (builder, path, tag) => (
    builder.mutation({
      query: ({ id, body }) => ({
        url: `${path}${id ? `${id}/` : ''}`,
        method: 'POST',
        body: body,
      }),
      invalidatesTags: (result, error, arg) => result ? tag : [],
    })
  ),
  PATCH: (builder, path, tag) => (
    builder.mutation({
      query: ({ id, body }) => ({
        url: parse(path, id),
        method: 'PATCH',
        body: body,
      }),
      invalidatesTags: (result, error, arg) => result ? tag : [],
    })
  ),
  PUT: (builder, path, tag) => (
    builder.mutation({
      query: ({ id, body }) => ({
        url: parse(path, id),
        method: 'PUT',
        body: body,
      }),
      invalidatesTags: (result, error, arg) => result ? tag : [],
    })
  ),
  DELETE: (builder, path, tag) => (
    builder.mutation({
      query: (id) => ({
        url: parse(path, id),
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, arg) => result ? tag : [],
      transformResponse: (response) => (response || { status: 204 }),
    })
  ),
};

const actionsNames = {
  POST: 'add',
  PATCH: 'update',
  DELETE: 'delete',
  PUT: 'put',
  LIST: 'list',
};

/**
 * All api hooks are created automatically by createApi, we normally export them directly.
 * However, we dynamically create api hooks for each endpoint and won't know the name
 * of the endpoint until it is created. The following function applies the same naming convention
 * as RTK with the given name of the endpoint. With this, we can extract given hooks to be exported.
 */
const actionHookName = (action, name) => {
  switch (action) {
    case 'POST':
      return `useAdd${name}Mutation`;
    case 'PATCH':
      return `useUpdate${name}Mutation`;
    case 'PUT':
      return `usePut${name}Mutation`;
    case 'DELETE':
      return `useDelete${name}Mutation`;
    case 'LIST':
      return `useList${name}Query`;
    default:
      return null;
  }
};

const createEndpoints = (builder) => {
  const endpoints = {
    // Agent endpoints
    getAgents: builder.query({
      query: ({ page = 1, page_size = 10, ...params } = {}) => {
        const queryString = buildQueryString({ page, page_size, ...params });
        return {
          url: `assistant/agents/${queryString ? `?${queryString}` : ''}`,
          method: 'GET',
        };
      },
      // Apply pagination transformation
      transformResponse: transformPaginatedResponse,
      providesTags: (result, error, arg) => {
        // Handle both transformed and original responses
        const data = result?.data || result || [];
        const items = Array.isArray(data) ? data : [];
        
        return [
          ...items.map(item => ({ type: 'Agent', id: item.id })),
          'Agents',
        ];
      },
    }),
    
    getAgent: builder.query({
      query: (id) => `assistant/agents/${id}/`,
      providesTags: (result, error, id) => [{ type: 'Agent', id }],
    }),
    
    createAgent: builder.mutation({
      query: (body) => ({
        url: 'assistant/agents/',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Agents'],
    }),
    
    updateAgent: builder.mutation({
      query: ({ id, ...patch }) => ({
        url: `assistant/agents/${id}/`,
        method: 'PATCH',
        body: patch,
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: 'Agent', id },
        'Agents',
      ],
    }),
    
    deleteAgent: builder.mutation({
      query: (id) => ({
        url: `assistant/agents/${id}/`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Agents'],
    }),
    
    // AI Agent creation endpoint
    createAgentWithAI: builder.mutation({
      query: (body) => ({
        url: 'assistant/agents/create_with_ai/',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Agents'],
    }),
    
    // Training data endpoints with pagination support
    getTrainingData: builder.query({
      query: ({ agentId, page = 1, page_size = 10, search = '', added_to_memory = '', sort_by = '' } = {}) => {
        const queryParams = { page, page_size };
        
        if (search) queryParams.search = search;
        if (added_to_memory !== '') queryParams.added_to_memory = added_to_memory;
        if (sort_by) queryParams.sort_by = sort_by;
        
        const queryString = buildQueryString(queryParams);
        
        return {
          url: `assistant/agents/${agentId}/training-data/${queryString ? `?${queryString}` : ''}`,
          method: 'GET',
        };
      },
      // Apply pagination transformation
      transformResponse: transformPaginatedResponse,
      providesTags: (result, error, { agentId }) => {
        // Handle both transformed and original responses
        const data = result?.data || result || [];
        const items = Array.isArray(data) ? data : [];
        
        return [
          ...items.map(item => ({ type: 'TrainingData', id: item.id })),
          { type: 'TrainingData', id: agentId },
        ];
      },
    }),
    
    // Get training stats (separate endpoint for stats)
    getTrainingStats: builder.query({
      query: (agentId) => `assistant/agents/${agentId}/training-data/stats/`,
      providesTags: (result, error, agentId) => [
        { type: 'TrainingStats', id: agentId },
      ],
    }),
    
    createTrainingData: builder.mutation({
      query: ({ agentId, ...body }) => ({
        url: `assistant/agents/${agentId}/training-data/`,
        method: 'POST',
        body,
      }),
      invalidatesTags: (result, error, { agentId }) => [
        { type: 'TrainingData', id: agentId },
        { type: 'TrainingStats', id: agentId },
        { type: 'Agent', id: agentId },
      ],
    }),
    
    updateTrainingData: builder.mutation({
      query: ({ agentId, id, body }) => ({
        url: `assistant/agents/${agentId}/training-data/${id}/`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: (result, error, { agentId, id }) => [
        { type: 'TrainingData', id },
        { type: 'TrainingData', id: agentId },
        { type: 'TrainingStats', id: agentId },
      ],
    }),
    
    deleteTrainingData: builder.mutation({
      query: ({ agentId, id }) => ({
        url: `assistant/agents/${agentId}/training-data/${id}/`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, { agentId }) => [
        { type: 'TrainingData', id: agentId },
        { type: 'TrainingStats', id: agentId },
      ],
    }),
    
    // Batch operations
    batchDeleteTrainingData: builder.mutation({
      query: ({ agentId, ids }) => ({
        url: `assistant/agents/${agentId}/training-data/batch_delete/`,
        method: 'POST',
        body: { training_data_ids: ids },
      }),
      invalidatesTags: (result, error, { agentId }) => [
        { type: 'TrainingData', id: agentId },
        { type: 'TrainingStats', id: agentId },
        { type: 'Agent', id: agentId },
      ],
    }),
    
    // Training actions
    trainAgent: builder.mutation({
      query: (agentId) => ({
        url: `assistant/agents/${agentId}/training-data/train/`,
        method: 'POST',
      }),
      invalidatesTags: (result, error, agentId) => [
        { type: 'TrainingData', id: agentId },
        { type: 'TrainingStats', id: agentId },
        { type: 'Agent', id: agentId },
      ],
    }),
    
    batchTrainTrainingData: builder.mutation({
      query: ({ agentId, ids }) => ({
        url: `assistant/agents/${agentId}/training-data/train/`,
        method: 'POST',
        body: { training_data_ids: ids },
      }),
      invalidatesTags: (result, error, { agentId }) => [
        { type: 'TrainingData', id: agentId },
        { type: 'TrainingStats', id: agentId },
        { type: 'Agent', id: agentId },
      ],
    }),
    
    resetAgentMemory: builder.mutation({
      query: (agentId) => ({
        url: `assistant/agents/${agentId}/reset_memory/`,
        method: 'POST',
      }),
      invalidatesTags: (result, error, agentId) => [
        { type: 'TrainingData', id: agentId },
        { type: 'TrainingStats', id: agentId },
        { type: 'Agent', id: agentId },
      ],
    }),
    
    generateTrainingData: builder.mutation({
      query: (agentId) => ({
        url: `assistant/agents/${agentId}/generate_training_data/`,
        method: 'POST',
      }),
      invalidatesTags: (result, error, agentId) => [
        { type: 'TrainingData', id: agentId },
        { type: 'TrainingStats', id: agentId },
        { type: 'Agent', id: agentId },
      ],
    }),
    
    // Enhanced import/export endpoints
    importTrainingData: builder.mutation({
      query: ({ agentId, formData }) => ({
        url: `assistant/agents/${agentId}/training-data/import_data/`,
        method: 'POST',
        body: formData,
        formData: true,
      }),
      invalidatesTags: (result, error, { agentId }) => [
        { type: 'TrainingData', id: agentId },
        { type: 'TrainingStats', id: agentId },
        { type: 'Agent', id: agentId },
      ],
    }),
    
    // Export endpoint - primarily used for URL construction in components
    // Direct file downloads are handled in the component with a link
    exportTrainingData: builder.query({
      query: ({ agentId, format = 'json', filter = 'all' }) => ({
        url: `assistant/agents/${agentId}/training-data/export_data/?export-format=${format}&filter=${filter}`,
        method: 'GET',
        responseHandler: (response) => response.blob(),
        cache: 'no-cache',
      }),
    }),
  };

  apiEndpoints.forEach((endpoint) => {
    const {
      name, path, actions, tags = [],
    } = endpoint;
    const capitalizedName = name.charAt(0).toUpperCase() + name.slice(1);

    actions.forEach((action) => {
      endpoints[`${actionsNames[action]}${capitalizedName}`] = actionsMapper[action](builder, path, [name, ...tags]);
    });

    // Add support for optional query params in the GET endpoint with pagination
    endpoints[`get${capitalizedName}`] = builder.query({
      query: (params) => {
        // Check if the first argument is an object containing both id and params
        const idValue = typeof params === 'object' ? params.id : params;
        const queryParams = typeof params === 'object' ? params.params || {} : {};
        
        // Add pagination parameters if provided
        if (queryParams.page) {
          queryParams.page_size = queryParams.page_size || 10;
        }

        const queryString = buildQueryString(queryParams);

        return {
          url: `${parse(path, idValue)}${queryString ? `?${queryString}` : ''}`,
          method: 'GET',
        };
      },
      providesTags: (result, error, params) => {
        const tags = [name];
        
        // Add individual item tags if we have data
        if (result) {
          // Handle both transformed and non-transformed responses
          const data = result?.data || result;
          if (Array.isArray(data)) {
            data.forEach(item => {
              if (item && item.id) {
                tags.push({ type: name, id: item.id });
              }
            });
          } else if (data && data.id) {
            tags.push({ type: name, id: data.id });
          }
        }
        
        return tags;
      },
    });
  });

  return endpoints;
};

const api = createApi({
  reducerPath: 'takecareApi',
  refetchOnFocus: true,
  refetchOnReconnect: true,
  keepUnusedDataFor: 30,
  baseQuery: async (args, api, extraOptions) => {
    const { getState, dispatch } = api;
    const state = getState();
    const { token } = state.authorization;

    const baseQuery = fetchBaseQuery({
      baseUrl: url,
      prepareHeaders: async (headers) => {
        if (token && token.startsWith('Token ')) {
          headers.set('Authorization', token);
          return headers;
        }

        const accounts = msalInstance?.getAllAccounts();
        if (accounts && accounts.length > 0) {
          try {
            const tokenResponse = await msalInstance.acquireTokenSilent({
              ...tokenRequest,
              account: accounts[0],
            });
            headers.set('Authorization', `Bearer ${tokenResponse.accessToken}`);
          } catch (error) {
            console.error('Token acquisition failed:', error);
            // Handle token acquisition failure
            if (error instanceof InteractionRequiredAuthError) {
              // Redirect to Azure login
              msalInstance.loginRedirect(loginRequest);
              return;
            }
          }
        }
        return headers;
      },
    });

    const result = await baseQuery(args, api, extraOptions);

    // Check if the response indicates an expired or invalid token
    if (result.error && result.error.status === 401) {
      // Redirect to Azure login
      msalInstance.loginRedirect(loginRequest);
    }

    return result;
  },
  tagTypes: [
    ...apiEndpoints.map((endpoint) => endpoint.name),
    'TrainingData',
    'TrainingStats',
    'Agent',
    'Agents'
  ],
  endpoints: (builder) => createEndpoints(builder),
});

const greyboxApiActions = {};

apiEndpoints.forEach((endpoint) => {
  const capitalizedName = endpoint.name.charAt(0).toUpperCase() + endpoint.name.slice(1);
  greyboxApiActions[endpoint.name] = { get: api[`useGet${capitalizedName}Query`] };
  endpoint.actions.forEach((action) => {
    greyboxApiActions[endpoint.name] = {
      ...greyboxApiActions[endpoint.name],
      [actionsNames[action]]: api[actionHookName(action, capitalizedName)],
    };
  });
});

export {
  greyboxApiActions,
  tenantDomainConfigs as domainConfigs,
  domain,
  webSocketUrl,
};

// Export all required hooks
export const {
  // Agent endpoints
  useGetAgentsQuery,
  useGetAgentQuery,
  useCreateAgentMutation,
  useUpdateAgentMutation,
  useDeleteAgentMutation,
  useCreateAgentWithAIMutation,
  
  // Training data endpoints
  useGetTrainingDataQuery,
  useGetTrainingStatsQuery,
  useCreateTrainingDataMutation,
  useUpdateTrainingDataMutation,
  useDeleteTrainingDataMutation,
  
  // Batch operations
  useBatchDeleteTrainingDataMutation,
  
  // Training actions
  useTrainAgentMutation,
  useBatchTrainTrainingDataMutation,
  useResetAgentMemoryMutation,
  useGenerateTrainingDataMutation,
  
  // Import/export
  useImportTrainingDataMutation,
  useExportTrainingDataQuery,
} = api;

export default api;