import {
  Box,
  Button,
  Stack,
  styled,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { FiArrowUp } from 'react-icons/fi';
import Markdown from 'react-markdown';
import { Answers, Question } from '../../../../types';
import QuestionMedia from './QuestionMedia';

type Props = {
  question: Question;
  setAnswers: (id: number, answer: string) => void;
  answers: Answers;
  isCurrent: boolean;
};

type SequenceItem = string;


const CompactButton = styled(Button)(({ theme }) => ({
  minWidth: 40,
  height: 40,
  fontSize: '0.75rem',
  padding: theme.spacing(0.5),
  [theme.breakpoints.up('sm')]: {
    minWidth: 48,
    height: 48,
    fontSize: '0.875rem'
  }
}));

const GradationPanel: React.FC<Props> = ({
  question, setAnswers, answers, isCurrent,
}: Props) => {
  const [selected, setSelected] = useState<string | null>(null);
  const info = question.ra_question;
  const [sequence, setSequence] = useState<SequenceItem[]>([]);
  const theme = useTheme();
  const isSmallMobile = useMediaQuery(theme.breakpoints.down('xs'));

  useEffect(() => {
    if (answers[question.id] && selected !== answers[question.id]) {
      setSelected(answers[question.id]);
    }
  }, [question, answers]);

  useEffect(() => {
    // Check if info exists before using it
    if (info) {
      const seq: SequenceItem[] = [];
      for (
        let i = Number(info.start_range); i <= Number(info.end_range); i += Number(info.increment)
      ) {
        seq.push(i.toString());
      }
      setSequence(seq);
    }
  }, [info]);

  useEffect(() => {
    // Always set the answer, even if selected is null
    // This ensures the answer is properly tracked in the answers object
    setAnswers(question.id, selected || '');
  }, [selected, question.id, setAnswers]);

  const handleSelection = (value: string) => {
    setSelected(value);
  };

  return (
    <Stack spacing={2} sx={{ width: '100%', p: 1 }}>
      <Typography variant="h6" component="div" sx={{
        fontWeight: 500,
        fontSize: { xs: '1.1rem', sm: '1.25rem' },
        lineHeight: 1.2
      }}>
        {info && <Markdown>{info.ra_question_text}</Markdown>}
      </Typography>

      {/* RA question doesn't have media properties in its type, so we'll default to 'none' */}
      <QuestionMedia
        mediaType="none"
        mediaUrl=""
      />

      <Box sx={{
        position: 'relative',
        width: '100%',
        overflowX: 'auto',
        pb: 2,
        '&::-webkit-scrollbar': { height: '2px' },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: 'divider',
          borderRadius: 2
        }
      }}>
        <Stack
          direction="row"
          spacing={0.5}
          sx={{
            width: 'max-content',
            minWidth: '100%',
            px: 0.5,
            position: 'relative'
          }}
        >
          {sequence.map((number) => (
            <Box key={number} sx={{ position: 'relative' }}>
              <CompactButton
                variant={selected === number ? 'contained' : 'outlined'}
                color={selected === number ? 'primary' : 'secondary'}
                onClick={() => handleSelection(number)}
                sx={{
                  fontWeight: selected === number ? 700 : 400,
                  boxShadow: selected === number ? 2 : 0,
                }}
              >
                {number}
              </CompactButton>

              {selected === number && (
                <FiArrowUp
                  size={isSmallMobile ? 14 : 16}
                  style={{
                    position: 'absolute',
                    left: '50%',
                    transform: 'translateX(-50%)',
                    bottom: -18,
                    filter: 'drop-shadow(0 2px 2px rgba(0,0,0,0.1))',
                    color: theme.palette.primary.main
                  }}
                />
              )}
            </Box>
          ))}
        </Stack>
      </Box>

      {/* Labels container remains same but with smaller font */}
      {info && (
        <Box sx={{
          display: 'flex',
          justifyContent: 'space-between',
          fontSize: '0.7rem',
          color: 'text.secondary',
          px: 0.5
        }}>
          {info.start_label && (
            <Typography variant="caption" component="span">
              {`${info.start_range}=${info.start_label}`}
            </Typography>
          )}
          {info.end_label && (
            <Typography variant="caption" component="span">
              {`${info.end_range}=${info.end_label}`}
            </Typography>
          )}
        </Box>
      )}
    </Stack>
  );
};

export default GradationPanel;
