import { setSnackbarStatus } from '../snackbarStatus';

/**
 * Keep tracks of status code of all API calls and dispatch snackbar state
 * accordingly:
 * - 201: If a feedback message is provided, display it.
 * - 204: If a feedback message is provided, display it.
 * - 500: Displays a generic error message.
 * - 400: Displays error message from API if provided.
 *
 * 401 is handled by the unauthenticatedMiddleware.
 */
const snackbarMiddleware = (store) => (next) => (action) => {
  // Check if snackbar already displayed to not stack them in case of looping errors.
  if (!store.getState().snackbarStatus.open) {
    if (action.meta && action.meta.baseQueryMeta) {
      if ((action.meta.baseQueryMeta.response?.status === 201
        || action.meta.baseQueryMeta.response?.status === 200)
        && action.meta.arg
        && action.meta.arg.originalArgs
        && action.meta.arg.originalArgs.feedback) {
        store.dispatch(setSnackbarStatus({
          open: true,
          message: action.meta.arg.originalArgs.feedback.success,
          severity: 'success',
          showSpinner: false,
        }));
      } else if (action.payload.status === 500) {
        store.dispatch(setSnackbarStatus({
          open: true,
          message: 'An error occurred. Please try again later.',
          severity: 'error',
          showSpinner: false,
        }));
      } else if (action.meta.baseQueryMeta.response?.status === 204
        && action.meta.arg
        && action.meta.arg.originalArgs.feedback) {
        store.dispatch(setSnackbarStatus({
          open: true,
          message: action.meta.arg.originalArgs.feedback.success,
          severity: 'success',
          showSpinner: false,
        }));
      }
    }
  }

  return next(action);
};

export default snackbarMiddleware;
