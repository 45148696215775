import React from 'react';
import { TimePicker as MuiTimepicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { FormikProps } from 'formik';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface PropsType {
  formik: FormikProps<any>;
  name: string;
  label: string;
}

const TimePicker = (props: PropsType) => {
  const { i18n } = useTranslation();
  const {
    formik, name, label,
  } = props;

  return (
    <Box sx={{ mx: 1 }}>
      <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={i18n.language}>
        <MuiTimepicker
            onChange={(date) => formik.setFieldValue(name, date)}
            sx={{ width: '100%' }}
            ampm={false}
            label={label}
            value={formik.values[name]}
            slotProps={{
                textField: {
                id: name,
                },
            }}
        />
      </LocalizationProvider>
    </Box>
  );
};

export default TimePicker;
