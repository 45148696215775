import { Alert, Box, Button, Grid, MenuItem, Paper, Skeleton, Stack, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import api, { greyboxApiActions } from '../../../../redux/api';
import MedicationFields from './MedicationFields';

// Importing Icons
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { MdEditDocument } from 'react-icons/md';
import FormikSelect from '../../../form-inputs/FormikSelect';
import FormikTextField from '../../../form-inputs/FormikTextField';
import { parseMedicationItem } from '../medicationTable/utils';
import { LoadingButton } from '@mui/lab';

const apiConfig = {
  localhost: 'dev-ca.takecareapi.com',
  'dev.greybox.ca': 'dev-ca.takecareapi.com',
  'dev-ca.greybox.fr': 'dev-ca.takecareapi.com',
  'dev.greybox.fr': 'dev-eu.takecareapi.com',
  'staging.greybox.ca': 'staging-ca.takecareapi.com',
  'hotfix.greybox.ca': 'ca.takecareapi.com',
  'staging.greybox.fr': 'staging-eu.takecareapi.com',
  'hotfix.greybox.ca': 'ca.takecareapi.com',
  'takecare.greybox.ca': 'ca.takecareapi.com',
  'takecare.greybox.fr': 'eu.takecareapi.com',
};

const PrescriptionFormPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { clinic } = useSelector((state) => state.clinic);
  const { uuid, prescriptionId } = useParams();
  const { requestOrchestration } = greyboxApiActions;
  const [postRequestOrchestration, result] = requestOrchestration.add();
  const [updateRequestOrchestration] = requestOrchestration.update();
  const [actionType, setActionType] = useState('');
  const dispatch = useDispatch();
  const hostname = apiConfig[window.location.hostname];

  const {
    data: prescriptionData,
    isFetching,
    isError,
    error,
    refetch,
  } = requestOrchestration.get(prescriptionId, { skip: !prescriptionId });

  const edit = true;

  const [initialValues, setInitialValues] = useState({
    status: 'draft',
    medications: location.state?.medications || [],
    date_issued: new Date().toISOString().substr(0, 10),
  });

  const validationSchema = Yup.object().shape({
    status: Yup.string().required(t('Status is required')),
    date_issued: Yup.date().required(t('Date is required')),
  });

  useEffect(() => {
    const fetchMedications = async () => {
      if (prescriptionData) {
        const { getMedication } = api.endpoints;

        const medications = await Promise.all(
          prescriptionData.action
            .map((action) => action.resource.reference)
            .map((reference) => {
              const id = reference.split('/').pop();
              return dispatch(getMedication.initiate(id)).unwrap().then(parseMedicationItem);
            })
        );

        setInitialValues({
          status: prescriptionData.status || 'draft',
          medications: medications || [],
          date_issued: prescriptionData.date_issued || new Date().toISOString().substr(0, 10),
        });
      }
    };
    fetchMedications();
  }, [prescriptionData, dispatch]);

  const handleSubmit = (values, { setSubmitting }) => {
    let updatedStatus = values.status;

    if (actionType === 'complete') {
      updatedStatus = 'completed';
    }

    const validMedications = values.medications.filter((med) => med.uuid || med.id);

    if (validMedications.length === 0) {
      setSubmitting(false);
      return;
    }

    const updatedFormData = {
      code: {
        text: 'Prescribe a medication',
        coding: [
          {
            system: 'http://hl7.org/fhir/action-code',
            code: 'prescribe-medication',
            display: 'Prescribe a medication',
          },
        ],
      },
      status: updatedStatus,
      subject: {
        reference: `Patient/${uuid}`,
      },
      action: validMedications.map((med) => ({
        code: {
          text: 'Prescribe a medication',
          coding: [
            {
              system: 'http://hl7.org/fhir/action-code',
              code: 'prescribe-medication',
              display: 'Prescribe a medication',
            },
          ],
        },
        resource: {
          reference: `MedicationRequest/${med.uuid || med.id}`,
        },
      })),
      extension: [
        {
          url: `https://${hostname}/fhir/StructureDefinition/request-orchestration-organization`,
          valueId: clinic.id,
        },
      ],
    };

    const actionPromise = prescriptionId
      ? updateRequestOrchestration({ id: prescriptionId, body: updatedFormData }).unwrap()
      : postRequestOrchestration({ body: updatedFormData }).unwrap();

    actionPromise
      .then((response) => {
        setSubmitting(false);
        navigate(`/patient-profile/${uuid}/medications#prescriptions`);
      })
      .catch((error) => {
        console.error('API Error:', error);
        setSubmitting(false);
      });
  };

  if (isFetching && prescriptionId) {
    return (
      <Paper sx={{ width: '100%', p: 2 }}>
        <Typography variant="h6" gutterBottom>
          {prescriptionId ? t('Edit Prescription') : t('Add Prescription')}
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Skeleton variant="rectangular" height={56} />
          </Grid>
          <Grid item xs={12} md={6}>
            <Skeleton variant="rectangular" height={56} />
          </Grid>
          <Grid item xs={12}>
            <Skeleton variant="rectangular" height={200} />
          </Grid>
        </Grid>
        <Box mt={2}>
          <Stack direction="row" spacing={1} justifyContent="flex-end">
            <Skeleton variant="rectangular" width={100} height={36} />
            <Skeleton variant="rectangular" width={100} height={36} />
            <Skeleton variant="rectangular" width={100} height={36} />
            <Skeleton variant="rectangular" width={180} height={36} />
          </Stack>
        </Box>
      </Paper>
    );
  }

  if (isError) {
    return (
      <Paper sx={{ width: '100%', p: 2 }}>
        <Alert severity="error" sx={{ mb: 2 }}>
          {t('An error occurred while fetching the prescription data.')}
        </Alert>
        <Button variant="contained" color="primary" onClick={refetch} startIcon={<MdEditDocument />}>
          {t('Retry')}
        </Button>
      </Paper>
    );
  }

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, values }) => (
        <Form style={{ flexGrow: 1 }}>
          <Paper sx={{ flexGrow: 1, p: 2 }}>
            <Typography variant="h5" gutterBottom>
              {prescriptionId ? t('Edit Prescription') : t('Add Prescription')}
            </Typography>
            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: {
                  xs: '1fr', // single column for small screens
                  md: '1fr 1fr', // two columns for medium screens and up
                },
                gap: 2, // equivalent to spacing={2}
              }}
            >
              <FormikSelect label={t('Status')} name="status" disabled={!edit} fullWidth>
                <MenuItem value={'draft'}>{t('Draft')}</MenuItem>
                <MenuItem value={'completed'}>{t('Completed')}</MenuItem>
                <MenuItem value={'entered-in-error'}>{t('Entered in Error')}</MenuItem>
              </FormikSelect>

              <FormikTextField name="date_issued" label={t('Date Issued')} type="date" disabled={!edit} />

              <Box sx={{ gridColumn: 'span 2' }}>
                {' '}
                <MedicationFields name="medications" disabled={!edit} />
              </Box>
            </Box>
            <Box mt={2}>
              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={1} justifyContent="flex-end">
                <Button onClick={() => navigate(-1)} startIcon={<CancelIcon />} variant="outlined">
                  {t('Cancel')}
                </Button>

                <LoadingButton
                  type="submit"
                  onClick={() => setActionType('draft')}
                  startIcon={<MdEditDocument />}
                  variant="contained"
                  loading={isSubmitting} 
                  disabled={isSubmitting || !edit} 
                >
                  {t('Save Draft')}
                </LoadingButton>

                <LoadingButton
                  type="submit"
                  onClick={() => setActionType('complete')}
                  startIcon={<CheckCircleIcon />}
                  variant="contained"
                  color="primary"
                  loading={isSubmitting} 
                  disabled={isSubmitting || !edit} 
                >
                  {t('Finalize')}
                </LoadingButton>
              </Stack>
            </Box>
          </Paper>
        </Form>
      )}
    </Formik>
  );
};

export default PrescriptionFormPage;
