import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
  Paper,
  Divider,
  IconButton,
  TextField,
  Chip,
  Tab,
  Tabs,
  CircularProgress,
} from '@mui/material';
import {
  Close as CloseIcon,
  Edit as EditIcon,
  Save as SaveIcon,
  Delete as DeleteIcon,
  Cancel as CancelIcon,
  Check as CheckIcon,
  QuestionAnswer as QuestionAnswerIcon,
  Info as InfoIcon,
} from '@mui/icons-material';
import { useUpdateTrainingDataMutation, useDeleteTrainingDataMutation } from '../../redux/api';
import { useDispatch } from 'react-redux';
import { setSnackbarStatus } from '../../redux/snackbarStatus';
import { useTranslation } from 'react-i18next';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`training-data-tabpanel-${index}`}
      aria-labelledby={`training-data-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ py: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

const TrainingDataDetail = ({ open, onClose, item, agentId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [tabValue, setTabValue] = useState(0);
  const [isEditing, setIsEditing] = useState(false);
  const [formData, setFormData] = useState({
    question: item?.question || '',
    answer: item?.answer || '',
  });
  
  const [updateTrainingData, { isLoading: isUpdating }] = useUpdateTrainingDataMutation();
  const [deleteTrainingData, { isLoading: isDeleting }] = useDeleteTrainingDataMutation();
  
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

  React.useEffect(() => {
    if (item) {
      setFormData({
        question: item.question || '',
        answer: item.answer || '',
      });
    }
  }, [item]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleCancel = () => {
    setIsEditing(false);
    setFormData({
      question: item?.question || '',
      answer: item?.answer || '',
    });
  };

  const handleSave = async () => {
    try {
      await updateTrainingData({
        agentId,
        id: item.id,
        body: formData,
      }).unwrap();
      
      dispatch(setSnackbarStatus({
        open: true,
        severity: 'success',
        message: t('Training data updated successfully'),
      }));
      
      setIsEditing(false);
    } catch (err) {
      dispatch(setSnackbarStatus({
        open: true,
        severity: 'error',
        message: t('Failed to update training data: {{message}}', { message: err.data?.detail || t('Unknown error') }),
      }));
    }
  };

  const handleDelete = async () => {
    try {
      await deleteTrainingData({
        agentId,
        id: item.id,
      }).unwrap();
      
      dispatch(setSnackbarStatus({
        open: true,
        severity: 'success',
        message: t('Training data deleted successfully'),
      }));
      
      onClose();
    } catch (err) {
      dispatch(setSnackbarStatus({
        open: true,
        severity: 'error',
        message: t('Failed to delete training data: {{message}}', { message: err.data?.detail || t('Unknown error') }),
      }));
    } finally {
      setShowDeleteConfirm(false);
    }
  };

  if (!item) return null;

  return (
    <Dialog 
      open={open} 
      onClose={!isUpdating && !isDeleting ? onClose : null}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Typography variant="h6">{t('Training Data Details')}</Typography>
          <Box>
            {!isEditing && !showDeleteConfirm && (
              <>
                <IconButton 
                  color="primary" 
                  onClick={handleEdit}
                  disabled={isUpdating || isDeleting}
                >
                  <EditIcon />
                </IconButton>
                <IconButton 
                  color="error" 
                  onClick={() => setShowDeleteConfirm(true)}
                  disabled={isUpdating || isDeleting}
                >
                  <DeleteIcon />
                </IconButton>
              </>
            )}
            <IconButton 
              onClick={onClose}
              disabled={isEditing || isUpdating || isDeleting}
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
      </DialogTitle>
      
      <Divider />
      
      {showDeleteConfirm ? (
        <DialogContent>
          <Box sx={{ p: 2, textAlign: 'center' }}>
            <Typography variant="h6" color="error" gutterBottom>
              {t('Delete Training Data?')}
            </Typography>
            <Typography variant="body1" paragraph>
              {t('Are you sure you want to delete this training data? This action cannot be undone.')}
            </Typography>
            <Box sx={{ mt: 3, display: 'flex', justifyContent: 'center', gap: 2 }}>
              <Button
                variant="outlined"
                startIcon={<CancelIcon />}
                onClick={() => setShowDeleteConfirm(false)}
                disabled={isDeleting}
              >
                {t('Cancel')}
              </Button>
              <Button
                variant="contained"
                color="error"
                startIcon={isDeleting ? <CircularProgress size={20} color="inherit" /> : <DeleteIcon />}
                onClick={handleDelete}
                disabled={isDeleting}
              >
                {isDeleting ? t('Deleting...') : t('Delete')}
              </Button>
            </Box>
          </Box>
        </DialogContent>
      ) : (
        <>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={tabValue} onChange={handleTabChange} aria-label="training data tabs">
              <Tab icon={<QuestionAnswerIcon />} iconPosition="start" label={t('Q&A Pair')} />
              <Tab icon={<InfoIcon />} iconPosition="start" label={t('Metadata')} />
            </Tabs>
          </Box>
          
          <DialogContent>
            <TabPanel value={tabValue} index={0}>
              <Box sx={{ mb: 4 }}>
                <Typography variant="subtitle1" gutterBottom sx={{ display: 'flex', alignItems: 'center' }}>
                  <strong>{t('Question')}</strong>
                  {item.added_to_memory && (
                    <Chip 
                      label={t('Trained')} 
                      size="small" 
                      color="success" 
                      icon={<CheckIcon />} 
                      sx={{ ml: 2 }}
                    />
                  )}
                </Typography>
                
                {isEditing ? (
                  <TextField
                    name="question"
                    value={formData.question}
                    onChange={handleChange}
                    fullWidth
                    multiline
                    rows={3}
                    variant="outlined"
                  />
                ) : (
                  <Paper variant="outlined" sx={{ p: 2, bgcolor: 'background.default' }}>
                    <Typography variant="body1">
                      {item.question}
                    </Typography>
                  </Paper>
                )}
              </Box>
              
              <Box>
                <Typography variant="subtitle1" gutterBottom>
                  <strong>{t('Answer')}</strong>
                </Typography>
                
                {isEditing ? (
                  <TextField
                    name="answer"
                    value={formData.answer}
                    onChange={handleChange}
                    fullWidth
                    multiline
                    rows={6}
                    variant="outlined"
                  />
                ) : (
                  <Paper variant="outlined" sx={{ p: 2, bgcolor: 'background.default' }}>
                    <Typography variant="body1" sx={{ whiteSpace: 'pre-wrap' }}>
                      {item.answer}
                    </Typography>
                  </Paper>
                )}
              </Box>
            </TabPanel>
            
            <TabPanel value={tabValue} index={1}>
              <Box sx={{ mb: 3 }}>
                <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                  {t('Training Status')}
                </Typography>
                <Paper variant="outlined" sx={{ p: 2 }}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    {item.added_to_memory ? (
                      <Chip 
                        label={t('Added to Agent Memory')} 
                        color="success" 
                        icon={<CheckIcon />} 
                        variant="outlined"
                      />
                    ) : (
                      <Chip 
                        label={t('Not Added to Agent Memory')} 
                        color="default" 
                        icon={<CancelIcon />} 
                        variant="outlined"
                      />
                    )}
                  </Box>
                </Paper>
              </Box>
              
              <Box sx={{ mb: 3 }}>
                <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                  {t('Created At')}
                </Typography>
                <Paper variant="outlined" sx={{ p: 2 }}>
                  <Typography>
                    {new Date(item.created_at).toLocaleString()}
                  </Typography>
                </Paper>
              </Box>
              
              {item.updated_at && (
                <Box>
                  <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                    {t('Last Updated')}
                  </Typography>
                  <Paper variant="outlined" sx={{ p: 2 }}>
                    <Typography>
                      {new Date(item.updated_at).toLocaleString()}
                    </Typography>
                  </Paper>
                </Box>
              )}
            </TabPanel>
          </DialogContent>
        </>
      )}
      
      {isEditing && (
        <DialogActions>
          <Button 
            startIcon={<CancelIcon />}
            onClick={handleCancel}
            disabled={isUpdating}
          >
            {t('Cancel')}
          </Button>
          <Button
            variant="contained"
            color="primary"
            startIcon={isUpdating ? <CircularProgress size={20} color="inherit" /> : <SaveIcon />}
            onClick={handleSave}
            disabled={isUpdating}
          >
            {isUpdating ? t('Saving...') : t('Save Changes')}
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default TrainingDataDetail;