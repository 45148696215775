import React, { useState } from 'react';
import { Box, Link, Typography } from '@mui/material';
import ImageModal from './ImageModal';
import { useTranslation } from 'react-i18next';

/**
 * Media component that displays different types of media files
 * Supports image modal view, video playback, and pending state
 */
const Media = ({ media, isPending = false }) => {
  const { t } = useTranslation();
  const [selectedImg, setSelectedImg] = useState(null);
  
  if (!media) return null;

  // Handle keyboard navigation for accessibility
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      setSelectedImg(getFileUrl());
    }
  };
  
  // Get the appropriate URL based on the media object structure
  const getFileUrl = () => {
    // Handle both structures: media.file (original) and media.url (new)
    return media.file || media.url;
  };
  
  // Get file type based on either explicit type or by inferring from mime_type/url
  const getFileType = () => {
    // If type is explicitly provided
    if (media.type) return media.type;
    
    // Otherwise infer from mime_type or URL
    if (media.mime_type?.startsWith('image/') || getFileUrl()?.match(/\.(jpeg|jpg|gif|png|webp)$/i)) {
      return 'image';
    }
    if (media.mime_type?.startsWith('video/') || getFileUrl()?.match(/\.(mp4|webm|ogg|mov)$/i)) {
      return 'video';
    }
    return 'file';
  };
  
  // Get file name
  const getFileName = () => {
    if (media.name) {
      // Get just the filename if it's a full path
      return media.name.split('/').pop();
    }
    // Extract filename from URL as fallback
    return getFileUrl()?.split('/').pop() || t('File');
  };
  
  // Apply opacity for pending state
  const pendingStyle = isPending ? { opacity: 0.7 } : {};
  
  // For image files
  if (getFileType() === 'image') {
    return (
      <>
        <Box sx={{ position: 'relative', ...pendingStyle }}>
          <div
            tabIndex="0"
            role="button"
            onClick={() => !isPending && setSelectedImg(getFileUrl())}
            onKeyDown={!isPending ? handleKeyDown : undefined}
            style={{ cursor: isPending ? 'default' : 'pointer' }}
          >
            <img
              src={getFileUrl()}
              alt={getFileName()}
              style={{
                maxHeight: '300px',
                width: '100%',
                objectFit: 'contain',
              }}
            />
          </div>
          
          {isPending && (
            <Typography variant="caption" color="textSecondary" sx={{ mt: 0.5, display: 'block' }}>
              {t('Uploading...')}
            </Typography>
          )}
        </Box>
        
        <ImageModal
          image={selectedImg}
          open={selectedImg !== null}
          handleClose={() => setSelectedImg(null)}
        />
      </>
    );
  }
  
  // For video files
  if (getFileType() === 'video') {
    return (
      <Box sx={{ position: 'relative', ...pendingStyle }}>
        <video
          src={getFileUrl()}
          controls={!isPending}
          style={{
            maxHeight: '300px',
            width: '100%',
            objectFit: 'contain',
          }}
        />
        
        {isPending && (
          <Typography variant="caption" color="textSecondary" sx={{ mt: 0.5, display: 'block' }}>
            {t('Uploading...')}
          </Typography>
        )}
      </Box>
    );
  }
  
  // For other file types
  return (
    <Box
      sx={{
        border: '1px solid #e0e0e0',
        borderRadius: 1,
        p: 1,
        backgroundColor: '#f5f5f5',
        display: 'flex',
        alignItems: 'center',
        ...pendingStyle
      }}
    >
      <Box sx={{ overflow: 'hidden', flexGrow: 1 }}>
        <Typography variant="body2" noWrap>
          {!isPending ? (
            <Link 
              href={getFileUrl()} 
              target="_blank" 
              rel="noopener noreferrer"
            >
              {getFileName()}
            </Link>
          ) : (
            <Typography variant="body2">
              {getFileName()} ({t('Uploading...')})
            </Typography>
          )}
        </Typography>
      </Box>
    </Box>
  );
};

export default Media;