import React, { createContext, useContext, useState } from 'react';

interface QuestionnairePanelContextType {
    isRegexValid: boolean;
    setIsRegexValid: React.Dispatch<React.SetStateAction<boolean>>;
  }
  

  const QuestionnairePanelContext = createContext<QuestionnairePanelContextType>(undefined);


interface Props {
  children: React.ReactNode;
}

export const QuestionnairePanelContextProvider: React.FC<Props> = ({ children }) => {
  const [isRegexValid, setIsRegexValid] = useState(true);
  return (
    <QuestionnairePanelContext.Provider value={{ isRegexValid, setIsRegexValid }}>
      {children}
    </QuestionnairePanelContext.Provider>
  );
};

export const useQuestionnairePanelContext = () => {
  const context = useContext(QuestionnairePanelContext);
  if (!context) {
    throw new Error('useQuestionnairePanelContext must be used within a QuestionnairePanelContextProvider');
  }
  return context;
};
