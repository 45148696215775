import React, { useState } from 'react';
import {
  Box,
  Drawer,
  IconButton,
  Toolbar,
  useMediaQuery,
} from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import MenuIcon from '@mui/icons-material/Menu';
import AppBarUnregistered from './AppbarUnregistered';
import AppBarLogo from './AppBarLogo';
import SearchAccountField from './accounts/SearchAccountField';
import ClinicButton from './ClinicButton';
import ProfileButton from './ProfileButton';
import NavigationList from './NavigationList'; 

// Import icons
import DashboardIcon from '@mui/icons-material/Dashboard';
import MessageIcon from '@mui/icons-material/Message';
import SchoolIcon from '@mui/icons-material/School';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import SettingsIcon from '@mui/icons-material/Settings';
import SmartToyIcon from '@mui/icons-material/SmartToy';

const AppBar = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const layoutMode = params.get('layoutMode');
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const { t } = useTranslation();

  const { authState } = useSelector((state) => state.authorization);
  const { clinic } = useSelector((state) => state.clinic);

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [searchOpen, setSearchOpen] = useState(false);

  const isCompleteQuestionnaire = authState === 'questionnaire';
  const isExternalQuestionnaireLayout = isCompleteQuestionnaire && ['minimal', 'logo-only'].includes(layoutMode);
  const isRegistration = location.pathname.includes('complete-registration');

  const handleNavigation = (path) => {
    navigate(path);
    if (isMobile) {
      setDrawerOpen(false);
    }
  };

  if (isRegistration || isExternalQuestionnaireLayout) {
    return <AppBarUnregistered layoutMode={layoutMode}>{children}</AppBarUnregistered>;
  }

  const features = clinic?.config.features_enable;

  // Navigation Items
  const baseNavigation = [
    {
      name: t('Dashboard'),
      id: 'dashboard',
      path: '/dashboard',
      icon: <DashboardIcon />,
    },
  ];

  const featureNavigation = [
    features?.message && {
      name: t('Messages'),
      id: 'messages',
      path: '/messages',
      icon: <MessageIcon />,
    },
    features?.education && {
      name: t('Education'),
      id: 'education',
      path: '/education',
      icon: <SchoolIcon />,
    },
    // Added AI Agent Hub to feature navigation
    features?.ai_agents && {
      name: t('AI Agents'),
      id: 'agent-hub',
      path: '/agent-hub',
      icon: <SmartToyIcon />,
    },
  ].filter(Boolean);

  const adminNavigation = clinic?.permissions.includes('change_company')
    ? [
        {
          name: t('Invitations'),
          id: 'invitations',
          path: '/pending-invitations',
          icon: <GroupAddIcon />,
        },
        {
          name: t('Administration'),
          id: 'clinic-settings',
          path: '/clinic-settings',
          icon: <SettingsIcon />,
        },
      ]
    : [];

  const navigationItems = [
    ...baseNavigation,
    ...featureNavigation,
    ...adminNavigation,
  ];

  return (
    <Box
      display="flex"
      sx={{
        bgcolor: (theme) => theme.palette.background.paper,
      }}
    >
      <Box
        sx={{
          bgcolor: (theme) => theme.palette.background.paper,
          width: '100%',
          zIndex: (theme) => theme.zIndex.drawer + 1,
          position: 'fixed',
        }}
      >
        {isMobile && searchOpen ? (
          <Box sx={{ width: '100%' }}>
            <SearchAccountField setOpen={setSearchOpen} />
          </Box>
        ) : (
          <Toolbar sx={{ height: 60 }}>
            {!isMobile && <AppBarLogo />}
            {!isCompleteQuestionnaire && (
              <IconButton
                data-cy="appBar-menu-button"
                onClick={() => setDrawerOpen(!drawerOpen)}
                sx={{ ml: isMobile ? 0 : 1 }}
              >
                <MenuIcon />
              </IconButton>
            )}
            <Box sx={{ flexGrow: 1 }}>
              <SearchAccountField isButton={isMobile} setOpen={setSearchOpen} />
            </Box>
            <Box sx={{ ml: 'auto', display: 'flex' }}>
              <ClinicButton />
              <ProfileButton />
            </Box>
          </Toolbar>
        )}
      </Box>
      <Drawer
        variant={isMobile ? 'temporary' : 'permanent'}
        hideBackdrop
        sx={{
          zIndex: (theme) => theme.zIndex.drawer,
          marginTop: '80px',
          '& .MuiDrawer-paper': {
            border: 'none',
            marginTop: '80px',
            width: drawerOpen ? '245px' : '65px',
            transition: (theme) =>
              theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
              }),
          },
          width: drawerOpen ? '245px' : '65px',
          transition: (theme) =>
            theme.transitions.create('width', {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.enteringScreen,
            }),
        }}
        open={drawerOpen}
        data-cy="app-bar-drawer"
      >
        <NavigationList
          navigationItems={navigationItems}
          open={drawerOpen}
          handleNavigation={handleNavigation}
        />
      </Drawer>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          mt: '64px',
          width: drawerOpen ? 'calc(100% - 245px)' : 'calc(100% - 65px)',
          overflow: 'auto',
          mr: isMobile ? 0 : 3,
          bgcolor: (theme) => theme.palette.background.default,
          px: '1rem',
          pt: 3,
          minHeight: 'calc(100vh - 90px)',
          borderTopLeftRadius: '20px',
          borderTopRightRadius: '20px',
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default AppBar;