import LoadingButton from '@mui/lab/LoadingButton';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  Box,
  Paper,
  CircularProgress,
  Divider,
  Alert,
  Avatar,
  Snackbar,
} from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import React, { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { greyboxApiActions } from '../../../redux/api';
import { Description as DescriptionIcon } from '@mui/icons-material';
import PdfViewer from '../../pdf-viewer/PdfViewer';
import { usePatientWebSocket } from '../../../hooks';
import { DynamicForm } from './DynamicFormField';

const extractPdfId = (url) => {
  const match = url.match(/\/([^\/]+)\.pdf/);
  return match ? match[1] : null;
};

const ReportSelector = ({
  isFetching,
  isError,
  data,
  t,
  reportType,
  setReportType
}) => {
  if (isFetching) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', py: 4 }}>
        <CircularProgress />
      </Box>
    );
  }
  
  if (isError) {
    return (
      <Alert severity="error" sx={{ mt: 2 }}>
        {t('Error loading report types. Please try again.')}
      </Alert>
    );
  }
  
  if (!data || data.length === 0) {
    return (
      <Box sx={{ py: 3, mt: 2, textAlign: 'center' }}>
        <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
          <Avatar sx={{ bgcolor: 'grey.200', width: 60, height: 60 }}>
            <DescriptionIcon sx={{ fontSize: 36, color: 'grey.500' }} />
          </Avatar>
        </Box>
        <Typography variant="h6" gutterBottom>
          {t('No Report Templates Available')}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {t('There are no report templates configured for this patient. Please contact your administrator.')}
        </Typography>
      </Box>
    );
  }
  
  return (
    <>
      <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', mt: 1 }}>
        {t('Select Report Type')}
      </Typography>
      <FormControl variant="outlined" fullWidth sx={{ mb: 3 }}>
        <InputLabel id="report-type-label">{t('Report Type')}</InputLabel>
        <Select
          labelId="report-type-label"
          id="report-type-select"
          value={reportType}
          onChange={(e) => setReportType(e.target.value)}
          label={t('Report Type')}
        >
          {data.map((report) => (
            <MenuItem key={report.id} value={report.id}>
              {report.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
};

const ReportGeneratingStatus = ({ t }) => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      py: 4,
    }}
  >
    <CircularProgress size={50} sx={{ color: 'primary.main', mb: 2 }} />

    <Typography variant="h6" sx={{ fontWeight: 'medium', mb: 1 }}>
      {t('Generating Report...')}
    </Typography>

    <Typography variant="body2" color="textSecondary">
      {t('Please wait while we process your request.')}
    </Typography>
  </Box>
);

const GenerateReportDialog = (props) => {
  const { open, handleClose, patientUuid } = props;
  const { i18n, t } = useTranslation();
  const [reportType, setReportType] = useState('');
  const [iframeUrl, setIframeUrl] = useState('');
  const [currentOperationId, setCurrentOperationId] = useState(null);
  const [processedReports, setProcessedReports] = useState(new Set());
  const [isGenerating, setIsGenerating] = useState(false);
  const [isCancelled, setIsCancelled] = useState(false);
  const [openGeneratedReportMessage, setOpenGeneratedReportMessage] = useState(false);
  const [snackbar, setSnackbar] = useState({ message: '', severity: 'success' });
  const { patientReport, patientReportClinic } = greyboxApiActions;
  const { clinic } = useSelector((state) => state.clinic);
  const [postReport, result] = patientReport.add();

  const { data: patientReportData, refetch } = patientReport.list({
    patient: patientUuid,
    clinic: clinic.id,
  });

  const { data, isFetching, isError } = patientReportClinic.list({
    clinic: clinic.id,
    role: 'patient',
  });

  // Reset selected report type when dialog opens or when available reports change
  useEffect(() => {
    if (open && data && data.length > 0) {
      setReportType(data[0].id);
    } else {
      setReportType('');
    }
  }, [open, data]);

  const { lastJsonMessage, readyState } = usePatientWebSocket();

  // Find the currently selected report to display details
  const selectedReport = data?.find((report) => report.id === reportType) || null;

  // WebSocket Logic to handle report status updates
  useEffect(() => {
    if (isCancelled) return;
    if (readyState !== 1) {
      console.log('⚠️ WebSocket not ready. Current state:', readyState);
      return;
    }
    if (!lastJsonMessage?.entity || lastJsonMessage.entity !== 'PatientReport') return;

    const { id, status } = lastJsonMessage.data;

    if (id !== currentOperationId) return;
    if (processedReports.has(id)) return;

    if (status === 'completed') {
      refetch();

      const matchingReport = patientReportData?.find((report) => report.id === id);

      if (matchingReport?.file) {
        setIframeUrl(matchingReport.file);
        setOpenGeneratedReportMessage(false);
        setProcessedReports(new Set([...processedReports, id]));
        setIsGenerating(false);
        handleClose();
      }
    } else if (status === 'in-progress') {
      console.log('🔄 Report is in progress:', id);
      setIsGenerating(true);
    } else if (status === 'error') {
      console.error('❌ Report generation failed:', id);
      setSnackbar({ message: t('Failed to generate report. Please try again.'), severity: 'error' });
      setOpenGeneratedReportMessage(true);
      setIsGenerating(false);
      handleClose();
    }
  }, [lastJsonMessage, patientReportData, readyState]);

  // Create formik with dynamic validation based on selected report
  const formik = useFormik({
    initialValues: useMemo(() => {
      // Initial empty default values
      const defaults = {};
      
      // Support both original JSON schema and new params format
      if (selectedReport?.schema?.properties) {
        // Original JSON schema format
        Object.entries(selectedReport.schema.properties).forEach(([key, prop]) => {
          // Set default date values for date fields if not specified
          if ((prop.format === 'date' || prop.type === 'date') && prop.default === undefined) {
            if (key.includes('start') || key === 'start_date') {
              defaults[key] = moment().subtract(1, 'months');
            } else if (key.includes('end') || key === 'end_date') {
              defaults[key] = moment();
            } else {
              defaults[key] = moment();
            }
          }
          // Set any other defaults from schema
          else if (prop.default !== undefined) {
            defaults[key] = prop.default;
          }
        });
      } else if (selectedReport?.schema?.params) {
        // New params format
        selectedReport.schema.params.forEach(param => {
          const { key, type, required } = param;
          
          // Set default values based on field type
          if (type === 'date' || type === 'datetime') {
            if (key.includes('start') || key === 'start_date') {
              defaults[key] = moment().subtract(1, 'months');
            } else if (key.includes('end') || key === 'end_date') {
              defaults[key] = moment();
            } else {
              defaults[key] = moment();
            }
          } else if (type === 'time') {
            defaults[key] = moment();
          } else if (type === 'checkbox') {
            defaults[key] = false;
          } else if (type === 'number' || type === 'range') {
            defaults[key] = 0;
          } else if ((type === 'select' || type === 'radio') && param.options?.length > 0) {
            defaults[key] = param.options[0];
          } else {
            defaults[key] = '';
          }
        });
      }
      
      return defaults;
    }, [selectedReport]),
    
    validationSchema: useMemo(() => {
      if (!selectedReport?.schema) return null;
      
      const schemaObj = {};
      
      // Support both original JSON schema and new params format
      if (selectedReport.schema.properties) {
        // Original JSON schema format
        Object.entries(selectedReport.schema.properties).forEach(([key, prop]) => {
          if (prop.format === 'date' || prop.type === 'date') {
            // Add date validation
            schemaObj[key] = Yup.date();
            if (prop.required || selectedReport.schema.required?.includes(key)) {
              schemaObj[key] = schemaObj[key].required(t('Date is required'));
            }
            
            // Add min/max validation for date ranges
            if (key.includes('end') || key === 'end_date') {
              const startDateKey = Object.keys(selectedReport.schema.properties).find(k => 
                k.includes('start') || k === 'start_date'
              );
              if (startDateKey) {
                schemaObj[key] = schemaObj[key].min(
                  Yup.ref(startDateKey), 
                  t('End date must be after start date')
                );
              }
            }
          } else if (prop.type === 'boolean') {
            schemaObj[key] = Yup.boolean();
          } else if (prop.type === 'number' || prop.type === 'integer') {
            let validation = Yup.number();
            if (prop.required || selectedReport.schema.required?.includes(key)) {
              validation = validation.required(t('Field is required'));
            }
            if (prop.minimum !== undefined) {
              validation = validation.min(prop.minimum, t(`Minimum value is ${prop.minimum}`));
            }
            if (prop.maximum !== undefined) {
              validation = validation.max(prop.maximum, t(`Maximum value is ${prop.maximum}`));
            }
            schemaObj[key] = validation;
          } else if (prop.enum) {
            schemaObj[key] = Yup.string().oneOf(prop.enum);
            if (prop.required || selectedReport.schema.required?.includes(key)) {
              schemaObj[key] = schemaObj[key].required(t('Field is required'));
            }
          } else {
            schemaObj[key] = Yup.string();
            if (prop.required || selectedReport.schema.required?.includes(key)) {
              schemaObj[key] = schemaObj[key].required(t('Field is required'));
            }
          }
        });
      } else if (selectedReport.schema.params) {
        // New params format
        selectedReport.schema.params.forEach(param => {
          const { key, type, required, options } = param;
          
          switch (type) {
            case 'date':
            case 'datetime':
              // Add date validation
              schemaObj[key] = Yup.date();
              if (required) {
                schemaObj[key] = schemaObj[key].required(t('Date is required'));
              }
              
              // Add min/max validation for date ranges
              if (key.includes('end') || key === 'end_date') {
                const startDateParam = selectedReport.schema.params.find(p => 
                  p.key.includes('start') || p.key === 'start_date'
                );
                if (startDateParam) {
                  schemaObj[key] = schemaObj[key].min(
                    Yup.ref(startDateParam.key), 
                    t('End date must be after start date')
                  );
                }
              }
              break;
              
            case 'time':
              schemaObj[key] = Yup.date();
              if (required) {
                schemaObj[key] = schemaObj[key].required(t('Time is required'));
              }
              break;
              
            case 'checkbox':
              schemaObj[key] = Yup.boolean();
              if (required) {
                schemaObj[key] = schemaObj[key].oneOf([true], t('This field is required'));
              }
              break;
              
            case 'number':
            case 'range':
              schemaObj[key] = Yup.number();
              if (required) {
                schemaObj[key] = schemaObj[key].required(t('Field is required'));
              }
              break;
              
            case 'radio':
            case 'select':
              if (options && options.length > 0) {
                schemaObj[key] = Yup.string().oneOf(options);
                if (required) {
                  schemaObj[key] = schemaObj[key].required(t('Field is required'));
                }
              }
              break;
              
            case 'textarea':
            case 'text':
            default:
              schemaObj[key] = Yup.string();
              if (required) {
                schemaObj[key] = schemaObj[key].required(t('Field is required'));
              }
              break;
          }
        });
      }
      
      return Yup.object().shape(schemaObj);
    }, [selectedReport, t]),
    
    enableReinitialize: true,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  const handleSubmit = async (values) => {
    setIsCancelled(false);
    setIsGenerating(true);
    
    // Build params_values from all form values
    const params_values = {};
    
    // Process all form values, converting dates to ISO strings
    if (selectedReport?.schema?.properties) {
      // Original JSON schema format
      Object.entries(values).forEach(([key, value]) => {
        const property = selectedReport.schema.properties[key];
        
        // Convert any date type to ISO string
        if (property && (property.format === 'date' || property.type === 'date') && value) {
          params_values[key] = moment(value).toISOString();
        } else {
          params_values[key] = value;
        }
      });
    } else if (selectedReport?.schema?.params) {
      // New params format
      Object.entries(values).forEach(([key, value]) => {
        const param = selectedReport.schema.params.find(p => p.key === key);
        
        if (!param) return;
        
        // Process value based on field type
        if ((param.type === 'date' || param.type === 'datetime' || param.type === 'time') && value) {
          params_values[key] = moment(value).toISOString();
        } else if (param.type === 'number' || param.type === 'range') {
          // Ensure numbers are sent as numbers, not strings
          params_values[key] = typeof value === 'string' ? parseFloat(value) : value;
        } else if (param.type === 'checkbox') {
          // Ensure booleans are sent as booleans
          params_values[key] = Boolean(value);
        } else {
          params_values[key] = value;
        }
      });
    } else {
      // If no schema is defined, just use all values as-is
      Object.entries(values).forEach(([key, value]) => {
        params_values[key] = value;
      });
    }
    
    const body = {
      type: 'pdf',
      report_clinic: reportType,
      patient: patientUuid,
      language: i18n.resolvedLanguage,
      status: 'pending',
      data: {
        params_values,
      },
    };
    
    try {
      const response = await postReport({ body });
      const reportId = response?.data?.id;
      
      if (reportId) {
        setCurrentOperationId(reportId);
      }

      setSnackbar({ message: t('Report request sent. Please wait...'), severity: 'info' });
    } catch (error) {
      setSnackbar({ message: t('Failed to generate report. Please try again.'), severity: 'error' });
      setIsGenerating(false);
    }
  };

  const closeIframe = () => {
    setIframeUrl('');
  };

  const handleCloseGeneratedReportMessage = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenGeneratedReportMessage(false);
  };

  const handleCancel = () => {
    handleClose();
    if (isGenerating) {
      setCurrentOperationId(null);
      setIsCancelled(true);
      setOpenGeneratedReportMessage(false);
      setTimeout(() => {
        setIsGenerating(false);
      }, 300);
    }
  };

  return (
    <>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
        <DialogTitle sx={{ pb: 1 }}>{t('Generate Patient Report')}</DialogTitle>
        <DialogContent sx={{ pb: 1 }}>
          {isGenerating ? (
            <ReportGeneratingStatus t={t} />
          ) : (
            <>
              <ReportSelector
                isFetching={isFetching}
                isError={isError}
                data={data}
                t={t}
                reportType={reportType}
                setReportType={setReportType}
              />
              
              {selectedReport && (
                <>
                  {selectedReport.description && (
                    <>
                      <Divider sx={{ my: 2 }} />
                      <Box sx={{ mb: 2 }}>
                        <Typography variant="h6" gutterBottom>
                          {t('Report Details')}
                        </Typography>
                        <Paper variant="outlined" sx={{ p: 2, bgcolor: 'background.default' }}>
                          <Typography variant="body1">
                            {selectedReport.description || t('No description available for this report template.')}
                          </Typography>
                        </Paper>
                      </Box>
                    </>
                  )}
                  
                  <DynamicForm 
                    schema={selectedReport.params}
                    formik={formik}
                  />
                </>
              )}
            </>
          )}
        </DialogContent>
        <DialogActions sx={{ px: 3, py: 2 }}>
          <Button onClick={handleCancel} variant={isGenerating ? 'contained' : 'outlined'}>
            {isGenerating ? t('Close') : t('Cancel')}
          </Button>
          {!isGenerating && (
            <LoadingButton
              onClick={formik.handleSubmit}
              loading={result.isLoading}
              variant="contained"
              disabled={!reportType || result.isLoading || isFetching || isError || (data && data.length === 0)}
            >
              {t('Generate')}
            </LoadingButton>
          )}
        </DialogActions>
      </Dialog>
      <Dialog
        open={Boolean(iframeUrl)}
        onClose={closeIframe}
        fullScreen
        sx={{
          '& .MuiDialog-paper': { backgroundColor: 'rgba(0, 0, 0, 0)' },
        }}
      >
        <PdfViewer link={iframeUrl} setIframeUrl={setIframeUrl} id={extractPdfId(iframeUrl)} />
      </Dialog>
      <Snackbar
        open={openGeneratedReportMessage}
        autoHideDuration={6000}
        onClose={handleCloseGeneratedReportMessage}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          onClose={handleCloseGeneratedReportMessage}
          severity={snackbar.severity}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default GenerateReportDialog;
