// src/pages/AgentDashboardPage.jsx
import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next'; // Import useTranslation hook
import {
  Box,
  Button,
  Card,
  CardContent,
  CardActions,
  Container,
  Grid,
  Typography,
  TextField,
  Paper,
  Tabs,
  Tab,
  Divider,
  IconButton,
  Chip,
  CircularProgress,
  LinearProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  useTheme,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Alert,
  Pagination,
  FormControl,
  InputLabel,
  Select,
} from '@mui/material';
import {
  Add as AddIcon,
  Psychology as PsychologyIcon,
  School as SchoolIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  MoreVert as MoreVertIcon,
  Refresh as RefreshIcon,
  Search as SearchIcon,
  FilterList as FilterListIcon,
  Sort as SortIcon,
  Check as CheckIcon,
  Clear as ClearIcon,
  Settings as SettingsIcon,
  Memory as MemoryIcon,
  AutoAwesome as AutoAwesomeIcon,
} from '@mui/icons-material';
import {
  useGetAgentsQuery,
  useCreateAgentWithAIMutation,
  useDeleteAgentMutation,
  useTrainAgentMutation,
  useResetAgentMemoryMutation,
  useGenerateTrainingDataMutation,
  useGetTrainingStatsQuery
} from '../redux/api';
import { setSnackbarStatus } from '../redux/snackbarStatus';
import PageHeader from '../components/common/PageHeader';
import EmptyState from '../components/common/EmptyState';
import AlertDialog from '../components/common/AlertDialog';

// Agent Type Icons Component
const AgentTypeIcons = ({ type }) => {
  const { t } = useTranslation();
  
  switch (type) {
    case 'customer-support':
      return <Chip icon={<PsychologyIcon />} label={t("Customer Support")} size="small" color="info" />;
    case 'knowledge-base':
      return <Chip icon={<SchoolIcon />} label={t("Knowledge Base")} size="small" color="success" />;
    case 'assistant':
      return <Chip icon={<AutoAwesomeIcon />} label={t("Assistant")} size="small" color="primary" />;
    default:
      return <Chip icon={<PsychologyIcon />} label={t("Generic")} size="small" />;
  }
};

// Agent Status Badge Component
const AgentStatusBadge = ({ status, trainingProgress }) => {
  const { t } = useTranslation();
  
  switch (status) {
    case 'training':
      return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <CircularProgress size={16} sx={{ mr: 1 }} />
          <Typography variant="caption">{t("Training")} ({Math.round(trainingProgress)}%)</Typography>
        </Box>
      );
    case 'ready':
      return <Chip icon={<CheckIcon />} label={t("Ready")} size="small" color="success" />;
    case 'untrained':
      return <Chip icon={<ClearIcon />} label={t("Untrained")} size="small" color="warning" />;
    default:
      return <Chip label={t("Unknown")} size="small" />;
  }
};

// Agent Card Component with its own stats query
const AgentCard = ({ agent, onMenuOpen, navigate, onStatusUpdate }) => {
  const { t } = useTranslation();
  
  // Individual stats query for this agent
  const { data: agentStats, isLoading: isStatsLoading } = useGetTrainingStatsQuery(agent.id);
  
  // Calculate training progress
  const trainingCount = agentStats?.totalCount || agent.trainingCount || 0;
  const trainedCount = agentStats?.trainedCount || agent.trainedCount || 0;
  const trainingProgress = agentStats?.trainingProgress || agent.trainingProgress || 0;
  
  // Determine status
  let status = agent.status || 'untrained';
  if (!agent.status) {
    if (trainedCount > 0) {
      status = trainedCount === trainingCount ? 'ready' : 'training';
    }
  }

  // Call onStatusUpdate when stats change
  useEffect(() => {
    if (!isStatsLoading && agentStats) {
      onStatusUpdate(agent.id, {
        status,
        trainingCount,
        trainedCount,
        trainingProgress
      });
    }
  }, [agent.id, agentStats, isStatsLoading, onStatusUpdate, status, trainedCount, trainingCount, trainingProgress]);

  return (
    <Card
      elevation={2}
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        transition: 'transform 0.2s, box-shadow 0.2s',
        cursor: 'pointer',
        '&:hover': {
          transform: 'translateY(-5px)',
          boxShadow: 6,
        }
      }}
      onClick={() => navigate(`/agents/${agent.id}`)}
    >
      <Box sx={{ p: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <AgentTypeIcons type={agent.type || 'knowledge-base'} />
        <IconButton
          size="small"
          onClick={(e) => onMenuOpen(e, agent.id)}
          aria-label={t("Agent options")}
        >
          <MoreVertIcon fontSize="small" />
        </IconButton>
      </Box>

      <CardContent sx={{ flexGrow: 1, pt: 0 }}>
        <Typography variant="h6" gutterBottom noWrap>
          {agent.name}
        </Typography>
        <Typography variant="body2" color="text.secondary" sx={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          display: '-webkit-box',
          WebkitLineClamp: 3,
          WebkitBoxOrient: 'vertical',
          mb: 2
        }}>
          {agent.description}
        </Typography>

        <Box sx={{ mt: 'auto' }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
            <Typography variant="caption" color="text.secondary">
              {t("Training Status")}
            </Typography>
            {isStatsLoading ? (
              <CircularProgress size={16} />
            ) : (
              <AgentStatusBadge status={status} trainingProgress={trainingProgress} />
            )}
          </Box>

          <LinearProgress
            variant="determinate"
            value={trainingProgress}
            sx={{ height: 6, borderRadius: 3 }}
          />

          <Typography variant="caption" color="text.secondary" sx={{ display: 'block', mt: 1 }}>
            {isStatsLoading 
              ? t("Loading training stats...") 
              : t("{{trainedCount}} of {{trainingCount}} items trained", { trainedCount, trainingCount })}
          </Typography>
        </Box>
      </CardContent>

      <Divider />

      <CardActions sx={{ justifyContent: 'flex-end', p: 1 }}>
        <Button
          size="small"
          startIcon={<SchoolIcon />}
          onClick={(e) => {
            e.stopPropagation();
            navigate(`/agents/${agent.id}/training-data`);
          }}
        >
          {t("Training Data")}
        </Button>
        <Button
          size="small"
          variant="contained"
          startIcon={<EditIcon />}
          onClick={(e) => {
            e.stopPropagation();
            navigate(`/agents/${agent.id}`);
          }}
        >
          {t("Edit")}
        </Button>
      </CardActions>
    </Card>
  );
};

// Quick Create Agent Dialog
const QuickCreateAgentDialog = ({ open, onClose, onCreate }) => {
  const { t } = useTranslation();
  
  const [formData, setFormData] = useState({
    domain_description: '',
    num_examples: 10,
    add_to_memory: true,
  });
  const [errors, setErrors] = useState({});
  const [createAgentWithAI, { isLoading }] = useCreateAgentWithAIMutation();
  const dispatch = useDispatch();

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });

    // Clear error when field is edited
    if (errors[name]) {
      setErrors({
        ...errors,
        [name]: '',
      });
    }
  };

  const validateForm = () => {
    const newErrors = {};

    if (!formData.domain_description.trim()) {
      newErrors.domain_description = t("Domain description is required");
    } else if (formData.domain_description.length < 10) {
      newErrors.domain_description = t("Please provide a more detailed description");
    }

    if (formData.num_examples < 0 || formData.num_examples > 30) {
      newErrors.num_examples = t("Number of examples must be between 0 and 30");
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async () => {
    if (!validateForm()) return;

    try {
      const response = await createAgentWithAI(formData).unwrap();

      dispatch(setSnackbarStatus({
        open: true,
        severity: 'success',
        message: t("Agent \"{{name}}\" created successfully!", { name: response.agent.name }),
      }));

      onClose();
      onCreate(response.agent.id);
    } catch (err) {
      dispatch(setSnackbarStatus({
        open: true,
        severity: 'error',
        message: t("Failed to create agent: {{error}}", { error: err.data?.detail || t("Unknown error") }),
      }));
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle>{t("Quick Create Agent")}</DialogTitle>
      <DialogContent>
        <Box sx={{ mt: 2 }}>
          <Typography variant="subtitle1" gutterBottom>
            {t("Describe what kind of AI agent you want to create")}
          </Typography>
          <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
            {t("AI will automatically generate a name, description, system prompt, and training examples based on your description.")}
          </Typography>

          <TextField
            fullWidth
            name="domain_description"
            label={t("Domain Description")}
            multiline
            rows={4}
            value={formData.domain_description}
            onChange={handleChange}
            error={!!errors.domain_description}
            helperText={errors.domain_description}
            placeholder={t("E.g., A customer support agent for a fitness app that can answer questions about workouts, nutrition plans, and subscription features")}
            sx={{ mb: 3 }}
          />

          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                name="num_examples"
                label={t("Number of Training Examples")}
                type="number"
                value={formData.num_examples}
                onChange={handleChange}
                fullWidth
                inputProps={{ min: 0, max: 30 }}
                error={!!errors.num_examples}
                helperText={errors.num_examples || t("How many training examples to generate (0-30)")}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box sx={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                <Chip
                  label={t("Auto-train agent")}
                  color={formData.add_to_memory ? "success" : "default"}
                  clickable
                  onClick={() => setFormData({ ...formData, add_to_memory: !formData.add_to_memory })}
                  icon={formData.add_to_memory ? <CheckIcon /> : <ClearIcon />}
                />
                <Typography variant="caption" sx={{ ml: 1, color: 'text.secondary' }}>
                  {formData.add_to_memory
                    ? t("Training examples will be added to memory")
                    : t("Training examples will be saved but not added to memory")}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t("Cancel")}</Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          startIcon={isLoading ? <CircularProgress size={20} color="inherit" /> : <AutoAwesomeIcon />}
          disabled={isLoading}
        >
          {isLoading ? t("Creating...") : t("Create with AI")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const AgentDashboardPage = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // State for pagination, filtering, and sorting
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(12); // 12 agents per page (3x4 grid)
  const [tabValue, setTabValue] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortOrder, setSortOrder] = useState('-created_at'); // Default sort by newest

  // Build query parameters for API
  const queryParams = {
    page,
    page_size: pageSize,
    search: searchTerm,
    status: tabValue === 1 ? 'ready' : tabValue === 2 ? 'untrained' : '',
    sort: sortOrder,
  };

  // API hooks with pagination params
  const {
    data: agentsResponse,
    isLoading: isAgentsLoading,
    isFetching,
    refetch
  } = useGetAgentsQuery(queryParams);

  const [deleteAgent] = useDeleteAgentMutation();
  const [trainAgent] = useTrainAgentMutation();
  const [resetMemory] = useResetAgentMemoryMutation();
  const [generateTrainingData] = useGenerateTrainingDataMutation();

  const [selectedAgentId, setSelectedAgentId] = useState(null);
  const [actionType, setActionType] = useState(null);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [showCreateAgentDialog, setShowCreateAgentDialog] = useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [menuAgentId, setMenuAgentId] = useState(null);
  const [sortMenuAnchor, setSortMenuAnchor] = useState(null);

  // State to track agent statuses after stats are loaded
  const [agentStatuses, setAgentStatuses] = useState({});
  
  // Derived state for tab counts
  const [statusCounts, setStatusCounts] = useState({
    total: 0,
    ready: 0,
    untrained: 0
  });

  // Handle sort menu
  const handleSortMenuOpen = (event) => {
    setSortMenuAnchor(event.currentTarget);
  };

  const handleSortMenuClose = () => {
    setSortMenuAnchor(null);
  };

  // Extract data and metadata from pagination response
  const agents = agentsResponse?.data || [];
  const metaData = agentsResponse?.meta || {
    totalCount: 0,
    totalPages: 1,
    currentPage: 1,
  };

  // Update status counts when agents or agent statuses change
  useEffect(() => {
    if (agents.length > 0) {
      // Calculate the number of agents with each status
      let readyCount = 0;
      let untrainedCount = 0;
      
      agents.forEach(agent => {
        // Check if we have detailed stats for this agent
        const agentStatus = agentStatuses[agent.id]?.status || agent.status;
        
        if (agentStatus === 'ready') {
          readyCount++;
        } else if (agentStatus === 'untrained') {
          untrainedCount++;
        }
      });
      
      setStatusCounts({
        total: metaData.totalCount || agents.length,
        ready: readyCount,
        untrained: untrainedCount,
      });
    }
  }, [agents, agentStatuses, metaData.totalCount]);

  // Handle status updates from the agent cards
  const handleAgentStatusUpdate = useCallback((agentId, statusData) => {
    setAgentStatuses(prev => ({
      ...prev,
      [agentId]: statusData
    }));
  }, []);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    setPage(1); // Reset to first page when changing tabs
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setPage(1); // Reset to first page when searching
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handlePageSizeChange = (event) => {
    setPageSize(event.target.value);
    setPage(1); // Reset to first page when changing page size
  };

  const handleSortChange = (newSortOrder) => {
    setSortOrder(newSortOrder);
    setPage(1); // Reset to first page when changing sort order
  };

  const handleAgentAction = (agentId, action) => {
    setSelectedAgentId(agentId);
    setActionType(action);

    switch (action) {
      case 'delete':
        setShowDeleteDialog(true);
        break;
      case 'train':
        handleTrain(agentId);
        break;
      case 'reset':
        handleReset(agentId);
        break;
      case 'generate':
        handleGenerate(agentId);
        break;
      default:
        break;
    }

    // Close the menu if it's open
    handleMenuClose();
  };

  const handleDelete = async () => {
    try {
      await deleteAgent(selectedAgentId).unwrap();

      dispatch(setSnackbarStatus({
        open: true,
        severity: 'success',
        message: t("Agent deleted successfully"),
      }));
      
      // Clear the agent from our statuses
      setAgentStatuses(prev => {
        const newStatuses = {...prev};
        delete newStatuses[selectedAgentId];
        return newStatuses;
      });
      
    } catch (err) {
      dispatch(setSnackbarStatus({
        open: true,
        severity: 'error',
        message: t("Failed to delete agent: {{error}}", { error: err.data?.detail || t("Unknown error") }),
      }));
    } finally {
      setShowDeleteDialog(false);
      setSelectedAgentId(null);
      setActionType(null);
    }
  };

  const handleTrain = async (agentId) => {
    try {
      await trainAgent(agentId).unwrap();

      dispatch(setSnackbarStatus({
        open: true,
        severity: 'success',
        message: t("Agent training initiated"),
      }));
    } catch (err) {
      dispatch(setSnackbarStatus({
        open: true,
        severity: 'error',
        message: t("Failed to train agent: {{error}}", { error: err.data?.detail || t("Unknown error") }),
      }));
    } finally {
      setSelectedAgentId(null);
      setActionType(null);
    }
  };

  const handleReset = async (agentId) => {
    try {
      await resetMemory(agentId).unwrap();

      dispatch(setSnackbarStatus({
        open: true,
        severity: 'success',
        message: t("Agent memory reset successfully"),
      }));
    } catch (err) {
      dispatch(setSnackbarStatus({
        open: true,
        severity: 'error',
        message: t("Failed to reset agent memory: {{error}}", { error: err.data?.detail || t("Unknown error") }),
      }));
    } finally {
      setSelectedAgentId(null);
      setActionType(null);
    }
  };

  const handleGenerate = async (agentId) => {
    try {
      await generateTrainingData(agentId).unwrap();

      dispatch(setSnackbarStatus({
        open: true,
        severity: 'success',
        message: t("Training data generation initiated"),
      }));
    } catch (err) {
      dispatch(setSnackbarStatus({
        open: true,
        severity: 'error',
        message: t("Failed to generate training data: {{error}}", { error: err.data?.detail || t("Unknown error") }),
      }));
    } finally {
      setSelectedAgentId(null);
      setActionType(null);
    }
  };

  const handleMenuOpen = (event, agentId) => {
    event.stopPropagation();
    setMenuAnchorEl(event.currentTarget);
    setMenuAgentId(agentId);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
    setMenuAgentId(null);
  };

  // When page changes or component unmounts, clear the status cache
  useEffect(() => {
    return () => {
      setAgentStatuses({});
    };
  }, [page, pageSize, tabValue, searchTerm, sortOrder]);

  return (
    <Container maxWidth="xl">
      <PageHeader
        title={t("Agent Hub")}
        subtitle={t("Manage and create your intelligent AI agents")}
        action={
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => setShowCreateAgentDialog(true)}
          >
            {t("Create Agent")}
          </Button>
        }
      />

      <Paper sx={{ mb: 4 }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={tabValue} onChange={handleTabChange} aria-label={t("agent tabs")}>
            <Tab
              label={
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <PsychologyIcon sx={{ mr: 1 }} fontSize="small" />
                  {t("All Agents")}
                  <Chip label={statusCounts.total} size="small" sx={{ ml: 1 }} />
                </Box>
              }
            />
            <Tab
              label={
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <CheckIcon sx={{ mr: 1 }} fontSize="small" />
                  {t("Ready")}
                  <Chip label={statusCounts.ready} size="small" sx={{ ml: 1 }} />
                </Box>
              }
            />
            <Tab
              label={
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <ClearIcon sx={{ mr: 1 }} fontSize="small" />
                  {t("Untrained")}
                  <Chip label={statusCounts.untrained} size="small" sx={{ ml: 1 }} />
                </Box>
              }
            />
          </Tabs>
        </Box>

        <Box sx={{ p: 2, display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexWrap: 'wrap', gap: 2 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', width: { xs: '100%', md: '60%' } }}>
            <TextField
              placeholder={t("Search agents...")}
              size="small"
              value={searchTerm}
              onChange={handleSearchChange}
              sx={{ width: '100%', maxWidth: 400 }}
              InputProps={{
                startAdornment: <SearchIcon fontSize="small" sx={{ mr: 1, color: 'text.secondary' }} />,
              }}
            />

            <IconButton
              size="small"
              sx={{ ml: 1 }}
              onClick={handleSortMenuOpen}
              aria-label={t("Sort options")}
            >
              <SortIcon fontSize="small" />
            </IconButton>

            <Menu
              id="sort-menu"
              anchorEl={sortMenuAnchor}
              open={Boolean(sortMenuAnchor)}
              onClose={handleSortMenuClose}
            >
              <MenuItem
                onClick={() => {
                  handleSortChange('-created_at');
                  handleSortMenuClose();
                }}
                selected={sortOrder === '-created_at'}
              >
                <ListItemText>{t("Newest first")}</ListItemText>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleSortChange('created_at');
                  handleSortMenuClose();
                }}
                selected={sortOrder === 'created_at'}
              >
                <ListItemText>{t("Oldest first")}</ListItemText>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleSortChange('name');
                  handleSortMenuClose();
                }}
                selected={sortOrder === 'name'}
              >
                <ListItemText>{t("Name (A-Z)")}</ListItemText>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleSortChange('-name');
                  handleSortMenuClose();
                }}
                selected={sortOrder === '-name'}
              >
                <ListItemText>{t("Name (Z-A)")}</ListItemText>
              </MenuItem>
            </Menu>
          </Box>

          <Box>
            <Button
              startIcon={<RefreshIcon />}
              size="small"
              onClick={refetch}
              disabled={isAgentsLoading || isFetching}
            >
              {t("Refresh")}
            </Button>
          </Box>
        </Box>
      </Paper>

      {isAgentsLoading ? (
        <LinearProgress sx={{ mb: 4 }} />
      ) : agents.length === 0 ? (
        <EmptyState
          icon={<PsychologyIcon fontSize="large" />}
          title={searchTerm ? t("No agents match your search") : t("No agents created yet")}
          description={searchTerm
            ? t("Try adjusting your search criteria")
            : t("Create your first intelligent agent to get started")}
          action={
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              onClick={() => setShowCreateAgentDialog(true)}
            >
              {t("Create Agent")}
            </Button>
          }
        />
      ) : (
        <>
          {isFetching && !isAgentsLoading && (
            <LinearProgress sx={{ mb: 2 }} />
          )}

          <Grid container spacing={3}>
            {agents.map((agent) => (
              <Grid item xs={12} sm={6} md={4} lg={3} key={agent.id}>
                <AgentCard 
                  agent={agent} 
                  onMenuOpen={handleMenuOpen}
                  navigate={navigate}
                  onStatusUpdate={handleAgentStatusUpdate}
                />
              </Grid>
            ))}
          </Grid>

          {/* Pagination */}
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 4, mb: 2 }}>
            <FormControl variant="outlined" size="small" sx={{ minWidth: 150 }}>
              <InputLabel id="page-size-select-label">{t("Agents per page")}</InputLabel>
              <Select
                labelId="page-size-select-label"
                id="page-size-select"
                value={pageSize}
                onChange={handlePageSizeChange}
                label={t("Agents per page")}
              >
                <MenuItem value={12}>12</MenuItem>
                <MenuItem value={24}>24</MenuItem>
                <MenuItem value={36}>36</MenuItem>
                <MenuItem value={48}>48</MenuItem>
              </Select>
            </FormControl>

            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant="body2" sx={{ mr: 2 }}>
                {t("Showing {{shown}} of {{total}} agents", { shown: agents.length, total: metaData.totalCount })}
              </Typography>
              <Pagination
                count={metaData.totalPages}
                page={page}
                onChange={handlePageChange}
                color="primary"
                disabled={isFetching}
              />
            </Box>
          </Box>
        </>
      )}

      {/* Agent Menu */}
      <Menu
        anchorEl={menuAnchorEl}
        open={Boolean(menuAnchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={() => navigate(`/agents/${menuAgentId}`)}>
          <ListItemIcon>
            <EditIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>{t("Edit Agent")}</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => navigate(`/agents/${menuAgentId}/training-data`)}>
          <ListItemIcon>
            <SchoolIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>{t("Training Data")}</ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem onClick={() => handleAgentAction(menuAgentId, 'train')}>
          <ListItemIcon>
            <MemoryIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>{t("Train Agent")}</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => handleAgentAction(menuAgentId, 'generate')}>
          <ListItemIcon>
            <AutoAwesomeIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>{t("Generate Training Data")}</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => handleAgentAction(menuAgentId, 'reset')}>
          <ListItemIcon>
            <RefreshIcon fontSize="small" color="warning" />
          </ListItemIcon>
          <ListItemText>{t("Reset Memory")}</ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem onClick={() => handleAgentAction(menuAgentId, 'delete')}>
          <ListItemIcon>
            <DeleteIcon fontSize="small" color="error" />
          </ListItemIcon>
          <ListItemText primaryTypographyProps={{ color: 'error' }}>{t("Delete Agent")}</ListItemText>
        </MenuItem>
      </Menu>

      {/* Confirmation Dialog */}
      <AlertDialog
        open={showDeleteDialog}
        title={t("Delete Agent")}
        content={t("Are you sure you want to delete this agent? All training data and memories will be permanently lost.")}
        confirmText={t("Delete")}
        confirmColor="error"
        onConfirm={handleDelete}
        onCancel={() => setShowDeleteDialog(false)}
      />

      {/* Quick Create Agent Dialog */}
      <QuickCreateAgentDialog
        open={showCreateAgentDialog}
        onClose={() => setShowCreateAgentDialog(false)}
        onCreate={(agentId) => navigate(`/agents/${agentId}`)}
      />
    </Container>
  );
};

export default AgentDashboardPage;