import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { MenuItem, SxProps, Theme } from '@mui/material';
import FormSelect from './FormSelect';

export interface SexProps {
  /** The formik instance used for form state management */
  formik: any;
  /** Custom styles to apply to the FormSelect component */
  sx?: SxProps<Theme>;
  /** Whether the field is required */
  required?: boolean;
  /** Whether to disable the select input */
  disabled?: boolean;
}

const Sex: FC<SexProps> = ({
  formik,
  sx = null,
  required = false,
  disabled = false,
}) => {
  const { t } = useTranslation();

  const genderOptions = [
    { value: 'M', label: t('Male') },
    { value: 'F', label: t('Female') },
    { value: 'X', label: t('Non-Binary') },
    { value: 'U', label: t('Not Specified') },
  ];

  return (
    <FormSelect
      name="gender"
      label={t('Sex')}
      formik={formik}
      sx={sx}
      required={required}
      disabled={disabled}
    >
      {genderOptions.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </FormSelect>
  );
};

export default Sex;
