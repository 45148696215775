import React, { useState, useEffect } from 'react';
import { setCurrentClinic } from '../../../redux/clinic';
import {
  Box,
  Paper,
  Typography,
  Button,
  Switch,
  FormControlLabel,
  Grid,
  Divider,
  IconButton,
  Stack,
  TextField,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  useTheme,
  Tooltip,
  Alert,
  CircularProgress,
  Snackbar,
  Fade,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import InfoIcon from '@mui/icons-material/Info';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';
import SaveIcon from '@mui/icons-material/Save';
import { greyboxApiActions } from '../../../redux/api';
import { columnsConfigMapping } from '../../dashboard/utils';
import { RootState } from '../../../redux/store';

// Type for the vitals config
interface VitalsConfigType {
  [key: string]: {
    name: string;
    unit?: string;
    // Include other properties as needed
  };
}

// Define types for column configuration
interface ColumnSummary {
  combined: boolean;
  value: number;
  unit: 'days' | 'weeks' | 'months';
  filter_type: 'range' | 'exact';
  aggregate: 'avg' | 'min' | 'max' | 'count' | 'last';
}

interface ColumnConfig {
  code: string;
  enabled: boolean;
  order: number;
  summary: ColumnSummary | null;
}

interface MessageState {
  type: 'success' | 'error' | '';
  text: string;
  visible: boolean;
}

interface MainConfig {
  [key: string]: {
    enable: boolean;
    order?: number;
  };
}

interface LegacyDashboardConfig {
  main: MainConfig;
}

interface ClinicConfig {
  dashboard: ColumnConfig[] | LegacyDashboardConfig;
  [key: string]: any;
}

interface ClinicType {
  id: number;
  name: string;
  email: string;
  config: ClinicConfig;
  permissions: string[];
  [key: string]: any;
}

const DashboardColumnsConfig = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const dispatch = useDispatch();
  const { clinic, vitalsConfig } = useSelector((state: RootState) => state.clinic);
  // Type assertion for API actions to access company endpoints
  const { company } = greyboxApiActions as any;
  const [updateClinic] = company.update();

  const [columns, setColumns] = useState<ColumnConfig[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState<MessageState>({ type: '', text: '', visible: false });

  // Summary/aggregate options
  const aggregateFunctions = [
    { value: 'avg', label: t('Average') },
    { value: 'min', label: t('Minimum') },
    { value: 'max', label: t('Maximum') },
    { value: 'count', label: t('Count') },
    { value: 'last', label: t('Latest') },
  ];

  const timeUnits = [
    { value: 'days', label: t('Days') },
    { value: 'weeks', label: t('Weeks') },
    { value: 'months', label: t('Months') },
  ];

  const filterTypes = [
    { value: 'range', label: t('Range') },
    { value: 'exact', label: t('Exact') },
  ];

  useEffect(() => {
    // Initialize columns from clinic config
    if (clinic?.config?.dashboard) {
      const dashboardConfig = clinic.config.dashboard as any;
      if (Array.isArray(dashboardConfig)) {
        // New format
        const sortedColumns = [...dashboardConfig].sort((a, b) => a.order - b.order);
        setColumns(sortedColumns);
      } else if (dashboardConfig.main) {
        // Legacy format - convert to new format
        const legacyColumns = Object.entries(dashboardConfig.main)
          .map(([code, config]: [string, any], index) => ({
            code,
            enabled: config.enable,
            order: config.order || index,
            summary: null, // Legacy format doesn't support summaries
          }))
          .sort((a, b) => a.order - b.order);
        setColumns(legacyColumns);
      }
    }
  }, [clinic]);

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) return;
    
    const items: ColumnConfig[] = Array.from(columns);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    
    // Update order property
    const reorderedItems = items.map((item, index) => ({
      ...item,
      order: index,
    }));
    
    setColumns(reorderedItems);
  };

  const handleToggleColumn = (index: number) => {
    const updatedColumns = columns.map((column, i) => {
      if (i === index) {
        // Create a new object with the updated enabled property
        return { ...column, enabled: !column.enabled };
      }
      return column;
    });
    setColumns(updatedColumns);
  };

  // Check if a column can have summary based on its code
  const canHaveSummary = (code: string): boolean => {
    // In the utils.js implementation, observation values are custom vitals
    // that aren't in the predefined mapping but exist in vitalsConfig
    const predefinedColumns = [
      'alert', 'bp', 'bg', 'hr', 'inactive', 'birth_date', 
      'questionnire_score', 'questionnaire_score', 'reminder', 
      'spo2', 'steps', 'temperature', 'wt', 'message', 'ppi', 
      'label', 'restingHeartRate', 'rr', 'medical_act', 
      'created_date', 'created_period'
    ];
    
    // If it's not a predefined column and exists in vitalsConfig,
    // it's an observation value that can have summary
    return !predefinedColumns.includes(code) && 
           vitalsConfig !== undefined && 
           typeof vitalsConfig === 'object' && 
           (vitalsConfig as Record<string, any>)[code] !== undefined;
  };

  const handleToggleSummary = (index: number) => {
    const updatedColumns = [...columns];
    const column = updatedColumns[index];
    
    // Check if this column type can have a summary
    if (!canHaveSummary(column.code)) {
      setMessage({ 
        type: 'error', 
        text: t('Summary is only available for observation values'),
        visible: true
      });
      return;
    }
    
    if (!column.summary) {
      // Initialize summary if it doesn't exist
      updatedColumns[index].summary = {
        combined: false,
        value: 7,
        unit: 'days',
        filter_type: 'range',
        aggregate: 'avg',
      };
    } else {
      // Remove summary if it exists
      updatedColumns[index].summary = null;
    }
    setColumns(updatedColumns);
  };

  const handleUpdateSummary = (index: number, field: keyof ColumnSummary, value: any) => {
    const updatedColumns = [...columns];
    if (!updatedColumns[index].summary) return;
    
    updatedColumns[index].summary = {
      ...updatedColumns[index].summary,
      [field]: value,
    };
    
    setColumns(updatedColumns);
  };

  // Handle closing the snackbar message
  const handleCloseMessage = () => {
    setMessage((prev) => ({ ...prev, visible: false }));
  };

  const handleSaveConfig = () => {
    if (!clinic) return;
    
    // Clear any existing messages and set loading state
    setMessage({ type: '', text: '', visible: false });
    setIsLoading(true);
    
    // Prepare the updated config
    const updatedConfig = {
      ...clinic.config,
      dashboard: columns,
    };
    
    // Create updated clinic object with new config
    const updatedClinic = {
      ...clinic,
      config: updatedConfig
    };
    
    // Use the RTK mutation hook
    updateClinic({ 
      id: clinic.id, 
      body: { 
        config: updatedConfig 
      }
    })
      .unwrap()
      .then(() => {
        // Update local state with success message and show it
        setMessage({ 
          type: 'success', 
          text: t('Dashboard columns configuration saved successfully'), 
          visible: true
        });
        setIsLoading(false);
        
        // Auto-hide the inline success message after 5 seconds
        setTimeout(() => {
          setMessage((prev) => prev.type === 'success' ? { ...prev, visible: false } : prev);
        }, 5000);
        
        // Update Redux store with the new clinic config
        // This ensures all components using the clinic state will reflect the changes
        dispatch(setCurrentClinic(updatedClinic));
      })
      .catch((error: Error) => {
        // Show detailed error message
        const errorMessage = error.message || t('Failed to save dashboard columns configuration');
        setMessage({ 
          type: 'error', 
          text: errorMessage,
          visible: true
        });
        console.error('Failed to update clinic config:', error);
        setIsLoading(false);
      });
  };

  const getColumnDisplay = (code: string) => {
    const columnConfig = columnsConfigMapping(code, vitalsConfig as any);
    if (!columnConfig) return code;
    
    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {columnConfig.logo}
        <Typography variant="body1" sx={{ ml: 1 }}>
          {t(columnConfig.header[0])} {columnConfig.header[1] && columnConfig.header[1]}
        </Typography>
      </Box>
    );
  };

  return (
    <Box sx={{ p: 2, width: '100%' }}>
      <Paper sx={{ p: 3, mb: 3 }}>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
          <Typography variant="h5">{t('Dashboard Columns Configuration')}</Typography>
          <Button
            variant="contained"
            color="primary"
            startIcon={isLoading ? <CircularProgress size={20} color="inherit" /> : <SaveIcon />}
            onClick={handleSaveConfig}
            disabled={isLoading}
          >
            {isLoading ? t('Saving...') : t('Save Configuration')}
          </Button>
        </Box>
        
        {message.type && message.visible && (
          <Box mb={2}>
            <Alert 
              severity={message.type === 'success' ? 'success' : 'error'}
              sx={{ 
                mb: 2, 
                display: 'flex', 
                alignItems: 'center',
                animation: 'fadeIn 0.5s'
              }}
              icon={message.type === 'success' ? <CheckCircleIcon /> : <ErrorIcon />}
              onClose={handleCloseMessage}
            >
              {message.text}
            </Alert>
          </Box>
        )}
        
        <Typography variant="body2" color="textSecondary" paragraph>
          {t('Configure which columns appear in the patient dashboard and their order. Drag columns to reorder them.')}
        </Typography>
        
        <Divider sx={{ my: 2 }} />
        
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="columns">
            {(provided: any) => (
              <Box
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {columns.map((column, index) => (
                  <Draggable 
                    key={column.code} 
                    draggableId={column.code} 
                    index={index}
                  >
                    {(provided: any) => (
                      <Paper
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        elevation={1}
                        sx={{ 
                          p: 2, 
                          mb: 2, 
                          borderLeft: column.enabled 
                            ? `4px solid ${theme.palette.primary.main}` 
                            : `4px solid ${theme.palette.grey[300]}`,
                        }}
                      >
                        <Grid container spacing={2} alignItems="center">
                          <Grid item {...provided.dragHandleProps}>
                            <DragIndicatorIcon color="action" />
                          </Grid>
                          
                          <Grid item xs={5}>
                            {getColumnDisplay(column.code)}
                          </Grid>
                          
                          <Grid item xs>
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={column.enabled || false}
                                  onChange={() => handleToggleColumn(index)}
                                  color="primary"
                                />
                              }
                              label={t('Enable')}
                            />
                          </Grid>
                          
                          <Grid item xs={12} sm={6} md={4}>
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={!!column.summary}
                                  onChange={() => handleToggleSummary(index)}
                                  color="primary"
                                  disabled={!column.enabled}
                                />
                              }
                              label={
                                <Box display="flex" alignItems="center">
                                  {t('Summary')}
                                  <Tooltip title={t('Show aggregated data instead of latest value')}>
                                    <IconButton size="small">
                                      <InfoIcon fontSize="small" />
                                    </IconButton>
                                  </Tooltip>
                                </Box>
                              }
                            />
                          </Grid>
                        </Grid>
                        
                        {column.summary && (
                          <Box sx={{ mt: 2, pl: 4 }}>
                            <Typography variant="subtitle2" gutterBottom>
                              {t('Summary Configuration')}
                            </Typography>
                            
                            <Grid container spacing={2} alignItems="center">
                              <Grid item xs={12} sm={6} md={3}>
                                <FormControl fullWidth size="small">
                                  <InputLabel>{t('Aggregate')}</InputLabel>
                                  <Select
                                    value={column.summary.aggregate || 'avg'}
                                    label={t('Aggregate')}
                                    onChange={(e) => handleUpdateSummary(index, 'aggregate', e.target.value)}
                                  >
                                    {aggregateFunctions.map((func) => (
                                      <MenuItem key={func.value} value={func.value}>
                                        {func.label}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              </Grid>
                              
                              <Grid item xs={12} sm={6} md={3}>
                                <Stack direction="row" spacing={1} alignItems="center">
                                  <TextField
                                    label={t('Period')}
                                    type="number"
                                    size="small"
                                    value={column.summary.value || 7}
                                    onChange={(e) => handleUpdateSummary(index, 'value', parseInt(e.target.value))}
                                    InputProps={{ inputProps: { min: 1 } }}
                                  />
                                  
                                  <FormControl sx={{ minWidth: 80 }} size="small">
                                    <InputLabel>{t('Unit')}</InputLabel>
                                    <Select
                                      value={column.summary.unit || 'days'}
                                      label={t('Unit')}
                                      onChange={(e) => handleUpdateSummary(index, 'unit', e.target.value)}
                                    >
                                      {timeUnits.map((unit) => (
                                        <MenuItem key={unit.value} value={unit.value}>
                                          {unit.label}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                </Stack>
                              </Grid>
                              
                              <Grid item xs={12} sm={6} md={3}>
                                <FormControl fullWidth size="small">
                                  <InputLabel>{t('Filter Type')}</InputLabel>
                                  <Select
                                    value={column.summary.filter_type || 'range'}
                                    label={t('Filter Type')}
                                    onChange={(e) => handleUpdateSummary(index, 'filter_type', e.target.value)}
                                  >
                                    {filterTypes.map((type) => (
                                      <MenuItem key={type.value} value={type.value}>
                                        {type.label}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              </Grid>
                              
                              <Grid item xs={12} sm={6} md={3}>
                                <FormControlLabel
                                  control={
                                    <Switch
                                      checked={column.summary.combined || false}
                                      onChange={(e) => handleUpdateSummary(index, 'combined', e.target.checked)}
                                      color="primary"
                                    />
                                  }
                                  label={t('Combined')}
                                />
                              </Grid>
                            </Grid>
                          </Box>
                        )}
                      </Paper>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </Box>
            )}
          </Droppable>
        </DragDropContext>
      </Paper>

      {/* Snackbar notification for success or error */}
      <Snackbar
        open={message.visible}
        autoHideDuration={message.type === 'success' ? 4000 : 6000}
        onClose={handleCloseMessage}
        TransitionComponent={Fade}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={handleCloseMessage}
          severity={message.type === 'success' ? 'success' : 'error'}
          sx={{ width: '100%' }}
          icon={message.type === 'success' ? <CheckCircleIcon /> : <ErrorIcon />}
        >
          {message.text}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default DashboardColumnsConfig;
