import {
  Box,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Markdown from 'react-markdown';
import { Answers, Question } from '../../../../types';
import AnswerSelect from './AnswerSelect';
import QuestionMedia from './QuestionMedia';

type TrueFalsePanelProps = {
  question: Question;
  setAnswers: (id: number, answer: string) => void;
  answers: Answers;
  isCurrent: boolean;
};

const TrueFalsePanel = ({
  question, setAnswers, answers, isCurrent,
}: TrueFalsePanelProps) => {
  const { t } = useTranslation();
  const [selected, setSelected] = useState<string | null>(null);

  useEffect(() => {
    if (answers[question.id] && selected !== answers[question.id]) {
      setSelected(answers[question.id]);
    }
  }, [question]);

  const handleClick = (value: string) => {
    if (value !== selected) {
      setSelected(value);
    } else {
      setSelected(null);
    }
  };

  useEffect(() => {
    setAnswers(question.id, selected);
  }, [selected]);

  return (
    <Box sx={{
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
    }}
    >
      <Typography sx={{ width: '100%', mb: 1 }}>
        <Markdown>
          {question.tf_question.tf_question_text}
        </Markdown>
      </Typography>
      <QuestionMedia
        mediaType={question.tf_question.question_media_type}
        mediaUrl={question.tf_question.question_media}
      />
      <Box sx={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
      }}
      >
        <AnswerSelect selected={selected === 'T'} setSelected={handleClick} value="T" index={0} isCurrent={isCurrent}>
          {t('Yes')}
        </AnswerSelect>
        <AnswerSelect selected={selected === 'F'} setSelected={handleClick} value="F" index={1} isCurrent={isCurrent}>
          {t('No')}
        </AnswerSelect>
      </Box>
    </Box>
  );
};

export default TrueFalsePanel;
