import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Card, CardActions, CardContent, CardHeader,
  Checkbox,
  FormControl,
  FormControlLabel,
  Link,
  TextField,
  Typography
} from '@mui/material';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import EthnicGroup from '../components/form-inputs/EthnicGroup';
import FormTextField from '../components/form-inputs/FormTextField';
import Language from '../components/form-inputs/Language';
import PhoneNumber from '../components/form-inputs/PhoneNumber';
import Sex from '../components/form-inputs/Sex';
import Layout from '../components/Layout';
import Row from '../components/Row';
import { greyboxApiActions } from '../redux/api';
import { setTempAuthorization } from '../redux/authorization';

const SelfRegistration = () => {
  const { t, i18n } = useTranslation();
  const { patientRegistration } = greyboxApiActions;
  const [createAccount, { isLoading }] = patientRegistration.add();
  const { search } = useLocation();
  const code = new URLSearchParams(search).get('code');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState('');

  const formik = useFormik({
    initialValues: {
      user: {
        firstName: '',
        lastName: '',
        email: '',
        mobile: null,
        language: i18n.resolvedLanguage,
        password: '',
      },
      birthDate: null,
      zipCode: '',
      gender: 'U',
      passwordConfirm: '',
      ethnic_group: 'U',
      mailOptIn: false
    },
    validationSchema: Yup.object({
      user: Yup.object({
        first_name: Yup.string().required(t('First name is required')),
        last_name: Yup.string().required(t('Last name is required')),
        email: Yup.string().required(t('Email is required')).email(t('Invalid email address')),
        mobile: Yup.string().nullable(),
        password: Yup.string()
          .required(t('Password is required'))
          .min(8, t('Password must be at least 8 characters'))
          .matches(/[A-Z]/, t('Password must contain at least one uppercase letter'))
          .matches(/[a-z]/, t('Password must contain at least one lowercase letter'))
          .matches(/[0-9]/, t('Password must contain at least one number'))
          .matches(/[^A-Za-z0-9]/, t('Password must contain at least one special character'))
          .test('no-common-passwords', t('Password cannot be a commonly used password'), value => {
            const commonPasswords = ['password', '12345678', 'qwerty', '123456789', 'baseball', 'football', 'letmein', 'welcome'];
            return !commonPasswords.includes(value);
          })
      }),
      birthDate: Yup.date().nullable(),
      zipCode: Yup.string().nullable(),
      passwordConfirm: Yup.string().required(t('Password confirmation is required')).oneOf([Yup.ref('user.password'), null], t('Passwords must match')),
      mailOptIn: Yup.boolean(),
    }),
    onSubmit: (values) => {
      const body = {
        registration_codes: [code],
        accepted_terms_on: new Date().toISOString(),
        ...values
      };

      createAccount({ body: body })
        .unwrap()
        .then((data) => {
          dispatch(setTempAuthorization({ token: `Token ${data.access_token}` }));
          navigate(`/patient-profile/${data.id}`);
        })
        .catch((error) => {
          formik.setSubmitting(false);
          const errors = error.data;

          // Check if the error is related to registration_codes
          if (errors.registration_codes) {
            setErrorMessage(t('This clinic cannot accept account registrations.'));
          } else if (errors.error) {
            setErrorMessage(errors.error);
          }

          formik.setErrors(errors);
        });

    },
    enableReinitialize: true,
  });

  return (
    <Layout>
      <Card>
        <CardHeader title={t('Register Your Account')} />
        <form onSubmit={formik.handleSubmit}>
          <CardContent>
            {errorMessage && <Alert severity="error" sx={{ mb: 2 }}>{errorMessage}</Alert>}
            <Row>
              <FormTextField formik={formik} name="user.first_name" required label={t('First Name')} />
              <FormTextField formik={formik} name="user.last_name" required label={t('Last Name')} />
            </Row>
            <Row>
              <FormTextField formik={formik} name="user.email" required label={t('Email Address')} />
              <PhoneNumber formik={formik} name="user.mobile" label={t('Mobile Number')} />
            </Row>
            <Row>
              <Language formik={formik} name="user.language" label={t('Preferred Language')} />
              <TextField
                label={t('Date of Birth')}
                type="date"
                name="birthDate"
                InputLabelProps={{
                  shrink: true,
                }}
                value={formik.values.birthDate}
                onChange={formik.handleChange}
                error={formik.touched.birthDate && Boolean(formik.errors.birthDate)}
                helperText={formik.touched.birthDate && formik.errors.birthDate}
                sx={{ mx: 1 }}
              />
            </Row>
            <Row>
              <TextField
                label={t('Postal/Zip Code')}
                name="zipCode"
                value={formik.values.zipCode}
                onChange={formik.handleChange}
                error={formik.touched.zipCode && Boolean(formik.errors.zipCode)}
                helperText={formik.touched.zipCode && formik.errors.zipCode}
                sx={{ mx: 1 }}
              />
              <Sex formik={formik} label={t('Gender')} />
            </Row>
            <Row>
              <EthnicGroup formik={formik} label={t('Ethnic Group')} />
            </Row>
            <Row>
              <FormTextField required formik={formik} type="password" name="user.password" label={t('Password')} />
              <FormTextField required formik={formik} type="password" name="passwordConfirm" label={t('Confirm Password')} />
            </Row>
            <FormControlLabel
              sx={{ m: 0 }}
              onChange={formik.handleChange}
              control={<Checkbox name="mailOptIn" />}
              label={t('I would like to receive newsletters and updates')}
            />
            <FormControl
              component="fieldset"
              sx={{ mt: 2 }}
            >
              <Typography variant="body2" sx={{ mt: 2 }}>
                <Trans i18nKey="By registering your account, you agree to the Terms of Use and Privacy Policy">
                  By registering your account, you agree to the{' '}
                  <Link target='_blank' href='https://greybox.ca/terms'>
                    Terms of Use
                  </Link>{' '}
                  and{' '}
                  <Link target='_blank' href='https://greybox.ca/privacy'>
                    Privacy Policy
                  </Link>.
                </Trans>
              </Typography>
            </FormControl>
          </CardContent>
          <CardActions sx={{ justifyContent: 'end' }}>
            <LoadingButton
              loading={isLoading}
              type="submit"
              variant="contained"
              color="primary"
            >
              {t('Register')}
            </LoadingButton>
          </CardActions>
        </form>
      </Card>
    </Layout>
  );
};

export default SelfRegistration;
