import React, { useState, useEffect, useMemo } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    List,
    ListItem,
    ListItemText,
    ListItemAvatar,
    Avatar,
    Typography,
    CircularProgress,
    TextField,
    InputAdornment,
    IconButton,
    Box,
    Divider,
    Paper,
    Grid,
    Chip,
    LinearProgress,
} from '@mui/material';
import {
    Search as SearchIcon,
    Clear as ClearIcon,
    SmartToy as AgentIcon,
    Psychology as PsychologyIcon,
    School as SchoolIcon,
    AutoAwesome as AutoAwesomeIcon,
    Check as CheckIcon
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useGetAgentsQuery } from '../../redux/api';
import { useDebounce } from 'use-debounce';

// Agent Type Icons Component
const AgentTypeIcons = ({ type }) => {
    const { t } = useTranslation();
    switch (type) {
        case 'customer-support':
            return <Chip icon={<PsychologyIcon />} label={t("Customer Support")} size="small" color="info" />;
        case 'knowledge-base':
            return <Chip icon={<SchoolIcon />} label={t("Knowledge Base")} size="small" color="success" />;
        case 'assistant':
            return <Chip icon={<AutoAwesomeIcon />} label={t("Assistant")} size="small" color="primary" />;
        default:
            return <Chip icon={<PsychologyIcon />} label={t("Generic")} size="small" />;
    }
};

// Agent Status Badge Component
const AgentStatusBadge = ({ status }) => {
    const { t } = useTranslation();
    switch (status) {
        case 'training':
            return (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <CircularProgress size={16} sx={{ mr: 1 }} />
                    <Typography variant="caption">{t("Training")}</Typography>
                </Box>
            );
        case 'ready':
            return <Chip icon={<CheckIcon />} label={t("Ready")} size="small" color="success" />;
        case 'untrained':
            return <Chip icon={<ClearIcon />} label={t("Untrained")} size="small" color="warning" />;
        default:
            return <Chip label={t("Unknown")} size="small" />;
    }
};

const AgentSelector = ({ open, onClose, onSelectAgent }) => {
    const { t } = useTranslation();
    const { clinic } = useSelector((state) => state.clinic);
    const [searchTerm, setSearchTerm] = useState('');
    const [debouncedSearchTerm] = useDebounce(searchTerm, 300);
    const [selectedAgent, setSelectedAgent] = useState(null);
    // Track whether initial selection has been made
    const [initialSelectionMade, setInitialSelectionMade] = useState(false);

    // Fetch the list of agents using RTK Query
    const { data: agentsResponse, isLoading, isError } = useGetAgentsQuery({
        clinic: clinic?.id,
        search: debouncedSearchTerm,
        page: 1,
        page_size: 20,
    });

    // Extract agents array from paginated response
    const agents = agentsResponse?.data || [];
    const metaData = agentsResponse?.meta || {
        totalCount: 0,
        totalPages: 1,
        currentPage: 1,
    };

    // Process agents with status information if not provided by API
    const agentsWithStatus = useMemo(() =>
        agents.map(agent => {
            if (agent.status) return agent;

            const trainingCount = agent.training_count || 0;
            const trainedCount = agent.trained_count || 0;
            const trainingProgress = trainingCount > 0 ? (trainedCount / trainingCount) * 100 : 0;
            let status = 'untrained';

            if (trainedCount > 0) {
                status = trainedCount === trainingCount ? 'ready' : 'training';
            }

            return {
                ...agent,
                status,
                trainingProgress,
                type: agent.type || 'knowledge-base'
            };
        }),
        [agents]); // Dependency array - only recompute when agents changes

    // Select the first agent by default when data loads (only once)
    useEffect(() => {
        if (agentsWithStatus.length > 0 && !initialSelectionMade) {
            setSelectedAgent(agentsWithStatus[0]);
            setInitialSelectionMade(true);
        } else if (agentsWithStatus.length === 0) {
            setSelectedAgent(null);
        }
    }, [agentsWithStatus, initialSelectionMade]);

    // Reset selected agent and initialSelectionMade flag when dialog opens
    useEffect(() => {
        if (open) {
            // Only set initial selection if there are agents and we haven't made a selection yet
            if (agentsWithStatus.length > 0 && !initialSelectionMade) {
                setSelectedAgent(agentsWithStatus[0]);
                setInitialSelectionMade(true);
            }
        } else {
            // Reset the initialSelectionMade flag when dialog closes
            setInitialSelectionMade(false);
        }
    }, [open, agentsWithStatus, initialSelectionMade]);

    // Reset initialSelectionMade when search term changes
    useEffect(() => {
        setInitialSelectionMade(false);
    }, [debouncedSearchTerm]);

    const handleSelectAgent = () => {
        if (selectedAgent) {
            onSelectAgent(selectedAgent);
            onClose();
        }
    };

    const handleAgentClick = (agent) => {
        setSelectedAgent(agent);
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="md"
            fullWidth
            PaperProps={{
                sx: { height: '600px' }
            }}
        >
            <DialogTitle sx={{ pb: 1 }}>{t('Select an AI Agent')}</DialogTitle>
            <DialogContent sx={{ p: 0, overflow: 'hidden' }}>
                <Grid container sx={{ height: '100%' }}>
                    {/* Left column - Agent List with independent scrolling */}
                    <Grid item xs={5} sx={{
                        borderRight: 1,
                        borderColor: 'divider',
                        display: 'flex',
                        flexDirection: 'column',
                        height: '100%'
                    }}>
                        {/* Search box - fixed at top */}
                        <Box sx={{ p: 2, pb: 1 }}>
                            <TextField
                                fullWidth
                                placeholder={t('Search agents')}
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                                variant="outlined"
                                margin="dense"
                                size="small"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon />
                                        </InputAdornment>
                                    ),
                                    endAdornment: searchTerm && (
                                        <InputAdornment position="end">
                                            <IconButton onClick={() => setSearchTerm('')} edge="end" size="small">
                                                <ClearIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Box>

                        {/* Scrollable agents list */}
                        <Box sx={{
                            flexGrow: 1,
                            overflowY: 'auto',
                            overflowX: 'hidden'
                        }}>
                            {isLoading ? (
                                <Box sx={{ display: 'flex', justifyContent: 'center', p: 4 }}>
                                    <CircularProgress />
                                </Box>
                            ) : isError ? (
                                <Typography color="error" align="center" sx={{ p: 2 }}>
                                    {t('Error loading agents. Please try again.')}
                                </Typography>
                            ) : agentsWithStatus.length === 0 ? (
                                <Typography align="center" color="textSecondary" sx={{ p: 2 }}>
                                    {t('No agents found')}
                                </Typography>
                            ) : (
                                <List sx={{ pt: 0 }}>
                                    {agentsWithStatus.map((agent) => (
                                        <ListItem
                                            button
                                            onClick={() => handleAgentClick(agent)}
                                            key={agent.id}
                                            selected={selectedAgent?.id === agent.id}
                                            sx={{
                                                borderRadius: 0,
                                                py: 1.5,
                                                '&.Mui-selected': {
                                                    backgroundColor: 'action.selected',
                                                    borderLeft: 3,
                                                    borderColor: 'primary.main',
                                                },
                                                '&:hover': {
                                                    backgroundColor: 'action.hover',
                                                },
                                            }}
                                        >
                                            <ListItemAvatar>
                                                <Avatar sx={{ bgcolor: selectedAgent?.id === agent.id ? 'primary.main' : 'secondary.light' }}>
                                                    <AgentIcon />
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary={agent.name}
                                                primaryTypographyProps={{
                                                    fontWeight: selectedAgent?.id === agent.id ? 'bold' : 'normal',
                                                }}
                                                secondary={
                                                    <Typography
                                                        variant="body2"
                                                        color="textSecondary"
                                                        sx={{
                                                            overflow: 'hidden',
                                                            textOverflow: 'ellipsis',
                                                            display: '-webkit-box',
                                                            WebkitLineClamp: 1,
                                                            WebkitBoxOrient: 'vertical',
                                                        }}
                                                    >
                                                        {agent.description ? `${agent.description.substring(0, 30)}...` : t('No description')}
                                                    </Typography>
                                                }
                                            />
                                        </ListItem>
                                    ))}
                                </List>
                            )}
                        </Box>
                    </Grid>

                    {/* Right column - Agent Details with independent scrolling */}
                    <Grid item xs={7} sx={{
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                    }}>
                        {selectedAgent ? (
                            <Box sx={{
                                height: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                overflow: 'hidden'
                            }}>
                                {/* Agent header - fixed at top */}
                                <Box sx={{ p: 3, pb: 2 }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                        <Avatar
                                            sx={{
                                                bgcolor: 'primary.main',
                                                width: 60,
                                                height: 60,
                                                mr: 2
                                            }}
                                        >
                                            <AgentIcon sx={{ fontSize: 36 }} />
                                        </Avatar>
                                        <Box>
                                            <Typography variant="h5" fontWeight="bold">
                                                {selectedAgent.name}
                                            </Typography>
                                            <Box sx={{ display: 'flex', mt: 0.5 }}>
                                                <AgentTypeIcons type={selectedAgent.type} />
                                                {/* <Box sx={{ ml: 1 }}>
                          <AgentStatusBadge 
                            status={selectedAgent.status}
                          />
                        </Box> */}
                                            </Box>
                                        </Box>
                                    </Box>

                                    <Divider />
                                </Box>

                                {/* Scrollable content area */}
                                <Box sx={{
                                    flexGrow: 1,
                                    overflowY: 'auto',
                                    px: 3,
                                    pb: 2
                                }}>
                                    <Typography variant="h6" gutterBottom>
                                        {t('Description')}
                                    </Typography>
                                    <Paper
                                        variant="outlined"
                                        sx={{
                                            p: 2,
                                            bgcolor: 'background.default'
                                        }}
                                    >
                                        <Typography variant="body1">
                                            {selectedAgent.description || t('No description available for this agent.')}
                                        </Typography>
                                    </Paper>
                                </Box>

                                {/* Chat with agent button - fixed at bottom */}
                                <Box sx={{
                                    p: 3,
                                    pt: 2,
                                    borderTop: 1,
                                    borderColor: 'divider'
                                }}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        size="large"
                                        startIcon={<AutoAwesomeIcon />}
                                        onClick={handleSelectAgent}
                                        fullWidth
                                    >
                                        {t('Chat with')} {selectedAgent.name}
                                    </Button>
                                </Box>
                            </Box>
                        ) : (
                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '100%',
                                p: 3
                            }}>
                                <Typography color="textSecondary" align="center">
                                    {isLoading ? t('Loading agents...') :
                                        agents.length === 0 ? t('No agents available') :
                                            t('Select an agent to see details')}
                                </Typography>
                            </Box>
                        )}
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions sx={{ px: 3, py: 2 }}>
                <Button onClick={onClose} variant="outlined">
                    {t('Close')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default AgentSelector;