import AddIcon from '@mui/icons-material/Add';
import {
  Box, FormControl,
  FormLabel, IconButton,
} from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ChipSection from './ChipSection';
import DiagnosisSelectionDialog from './DiagnosisSelectionDialog';
import StaffSelectionDialog from './StaffSelectionDialog';

interface PropsType {
  formik: any;
  label: string;
  type: string;
  name: string;
  required?: boolean;
  level?: string;
  disabled?: boolean;
}

interface Selection {
  id: string;
  name: string;
  label: string;
  type: string;
}

const DialogType = (props) => {
  const { type } = props;
  switch (type) {
    case 'diagnosis':
      return <DiagnosisSelectionDialog {...props} />;
    case 'staffs':
      return <StaffSelectionDialog {...props} />;
    default:
      return null;
  }
};

const MultiSelect = (props: PropsType) => {
  const { t } = useTranslation();
  const [dialogOpen, SetDialogOpen] = React.useState(false);
  const {
    formik, type, label, required, name, disabled
  } = props;

  const handleSelection = (item: Selection) => {
    const value = formik.values[name];

    if (value.find((v: Selection) => v.id === item.id)) {
      return formik.setFieldValue(name, value.filter((v: Selection) => v.id !== item.id));
    }
    return formik.setFieldValue(name, [...value, item]);
  };

  return (
    <FormControl sx={{ m: 1, width: '100%' }}>
      <Box sx={{
        display: 'flex',
        alignItems: 'center',
      }}
      >
        <FormLabel required={required}>
          {label}
        </FormLabel>
        <IconButton
          size="small"
          data-cy={`${name}-add`}
          disabled={disabled}
          sx={{ ml: 1 }}
          onClick={() => {
            SetDialogOpen(true);
          }}
        >
          <AddIcon />
        </IconButton>
      </Box>
      <ChipSection
        selection={formik.values[name]}
        type={type}
        handleRemove={handleSelection}
        disabled={disabled}
      />
      <DialogType
        open={dialogOpen}
        selected={formik.values[name]}
        onClose={() => SetDialogOpen(false)}
        setSelected={handleSelection}
        {...props}
      />
    </FormControl>
  );
};

MultiSelect.defaultProps = {
  required: false,
  level: null,
};

export default MultiSelect;
