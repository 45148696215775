import React, { useEffect, useState, useMemo } from 'react';
import {
  Box,
  Divider,
  Typography,
  useMediaQuery,
  Skeleton,
  Popper
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import moment from 'moment';
import ReactApexChart from 'react-apexcharts';
import ReactDOMServer from 'react-dom/server';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { usePatientWebSocket } from '../../hooks';
import { greyboxApiActions } from '../../redux/api';
import DialogQuestionnaire from '../patient-profile/questionnaire/filler/DialogQuestionnaire';
import QuestionnaireAnswers from '../patient-profile/questionnaire/QuestionnaireAnswers';
import ChartContainer from './ChartContainer';
import useFeatureEnabled from '../../hooks/useFeatureEnabled';
import QuestionnaireAnswersFullscreen from '../patient-profile/questionnaire/QuestionnaireAnswersFullscreen';

/**
 * Parses questionnaire records to extract score data for charting.
 * @param {Array} records - Data from questionnaireRecord API call
 * @returns {Array} Parsed data to be used in the graph
 */
export const parseQuestionnaireRecordScore = (records) => records.map((record) => ({
  x: moment(record.answer_timestamp).local(),
  y: parseInt(record.score?.value || 0, 10),
  alert: record.score?.threshold_status || false,
  color: record.score_range?.color || '#4F46E5',
  description: record.score_range?.description || '',
  id: record.id,
}));

/**
 * Generates custom markers including alert markers with their specific colors.
 * @param {Array} data - Parsed data from parseQuestionnaireRecordScore
 * @returns {Array} Custom markers for the graph
 */
export const generateCustomMarkers = (data) => data.map((item, idx) => ({
  size: item.alert ? 6 : 5, // Slightly larger size for alerts
  seriesIndex: 0,
  dataPointIndex: idx,
  strokeColor: item.color,
  fillColor: item.color,
  strokeWidth: 3,
  shape: 'circle',
}));



const CustomTooltip = ({ date, description }) => {
  const { t } = useTranslation();

  return (
    <div style={{
      padding: '8px'
    }} >
      <Box sx={{
        bgcolor: 'background.paper',
        color: 'text.primary',
        border: 1,
        borderColor: 'divider',
        borderRadius: 1.5,
        boxShadow: 3,
        maxWidth: 300,
        transition: 'all 0.2s ease-in-out',
        position: 'relative',
        '&::before': {
          content: '""',
          position: 'absolute',
          bottom: '-10px',
          left: '50%',
          transform: 'translateX(-50%)',
          borderWidth: '5px',
          borderStyle: 'solid',
          borderColor: 'divider transparent transparent transparent'
        },
        '&::after': {
          content: '""',
          position: 'absolute',
          bottom: '-8px',
          left: '50%',
          transform: 'translateX(-50%)',
          borderWidth: '5px',
          borderStyle: 'solid',
          borderColor: 'background.paper transparent transparent transparent'
        }
      }}>
        <Typography variant="subtitle1" sx={{
          fontWeight: 700,
          mb: 1.5,  // Increased from 1
          color: 'primary.main',
          px: 0.5  // Added horizontal padding
        }}>
          {moment(date).format('lll')}
        </Typography>

        <Divider sx={{
          my: 2,  // Increased from 1.5
          borderColor: 'action.selected',
          mx: 1  // Added horizontal margin
        }} />

        <Typography variant="body2" sx={{
          lineHeight: 1.6,
          color: description ? 'text.primary' : 'text.secondary',
          fontStyle: description ? 'normal' : 'italic',
          px: 0.5,  // Added horizontal padding
          py: 0.25  // Added vertical padding
        }}>
          {description || t('No description available')}
        </Typography>
      </Box>
    </div>
  );
};


/**
 * A graph showcasing the scores of the selected questionnaire of the current patient through time.
 * Every occurrence that generated an alert is marked with its specific color.
 */
const QuestionnaireResultChart = ({
  questionnaireId,
  patientUuid,
  detailed = false,
  autoHeight = false,
}) => {
  const { t } = useTranslation();
  const { clinic } = useSelector((state) => state.clinic);
  const { range, startDate, endDate } = useSelector((state) => state.patientProfileDateRange);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const { questionnaireAnswers, questionnaire } = greyboxApiActions;
  const questionnaireSelector = questionnaire.list({
    id: questionnaireId,
    clinic: clinic.id,
  });

  const params = {
    patient: patientUuid,
    questionnaire: questionnaireId,
    clinic: clinic.id,
    completed: true,
  };

  if (endDate === null) {
    params.answer_timestamp__gte = moment(startDate).toISOString();
  } else {
    params.answer_timestamp__range = range;
  }

  const { data = [], isFetching, refetch } = questionnaireAnswers.list(params);
  const theme = useTheme();
  const { lastJsonMessage } = usePatientWebSocket();

  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [open, setOpen] = useState(false);
  const showThresholdLine = useFeatureEnabled("show_threshold_line", true);

  const series = useMemo(() => {
    if (data.length > 0) {
      return [
        {
          name: 'Score',
          data: parseQuestionnaireRecordScore(data),
        },
      ];
    }
    return [];
  }, [data]);

  const alertMarkers = useMemo(() => {
    if (series.length > 0) {
      return generateCustomMarkers(series[0].data);
    }
    return [];
  }, [series]);

  const getHeights = () => {
    if (isMobile) return '280px';
    if (autoHeight) return '400px';
    return '100%';
  };

  useEffect(() => {
    if (
      lastJsonMessage &&
      lastJsonMessage.entity === 'Questionnaire_Reference_Record' &&
      lastJsonMessage.data.questionnaire_id === questionnaireId
    ) {
      refetch();
    }
  }, [lastJsonMessage, questionnaireId, refetch]);

  const thresholdAnnotations = useMemo(() => {
    if (!showThresholdLine) {
      return [];
    }
    const annotations = [];
    const {
      max_high_threshold,
      min_high_threshold,
      max_mod_threshold,
      min_mod_threshold,
      max_low_threshold,
      min_low_threshold,
    } = questionnaireSelector.data || {};

    const thresholds = [
      { value: max_high_threshold, color: '#EF4444', text: t('Max High') },      // Red
      { value: min_high_threshold, color: '#EF4444', text: t('Min High') },
      { value: max_mod_threshold, color: '#F59E0B', text: t('Max Moderate') },   // Amber
      { value: min_mod_threshold, color: '#F59E0B', text: t('Min Moderate') },
      { value: max_low_threshold, color: '#3B82F6', text: t('Max Low') },        // Blue
      { value: min_low_threshold, color: '#3B82F6', text: t('Min Low') },
    ];

    thresholds.forEach(({ value, color, text }) => {
      if (value !== undefined && value !== null) {
        annotations.push({
          y: parseFloat(value, 10),
          strokeDashArray: 2,
          borderColor: color,
          label: {
            borderColor: color,
            style: {
              color: '#fff',
              background: `${color}80`, // 50% transparency
            },
            text,
          },
        });
      }
    });

    return annotations;
  }, [questionnaireSelector.data, t, showThresholdLine]);

  const options = useMemo(
    () => ({
      dataLabels: {
        enabled: false,
      },
      chart: {
        foreColor: theme.palette.text.primary,
        events: {
          click: (event, chartContext, { dataPointIndex, seriesIndex }) => {
            if (
              dataPointIndex >= 0 &&
              dataPointIndex < series[seriesIndex].data.length
            ) {
              const item = series[seriesIndex].data[dataPointIndex];
              setAnchorEl(event);
              setSelectedRecord({
                recordIndex: dataPointIndex,
                recordId: item.id,
                anchorPosition: {
                  top: event.clientY,
                  left: event.clientX,
                },
              });
            }
          },
        },
        toolbar: {
          tools: {
            download: false,
            selection: false,
            zoom: false,
            zoomin: false,
            zoomout: false,
            pan: false,
            reset: false,
          },
        },
        zoom: {
          enabled: true,
          type: 'x',
          autoScaleYaxis: true,
          allowMouseWheelZoom: false,
        }
      },
      xaxis: {
        type: 'datetime',
        labels: {
          datetimeUTC: false,
        },
        tooltip: {
          enabled: false,
        },
        axisBorder: {
          show: true,
          color: theme.palette.divider
        },
      },
      yaxis: {
        labels: {
          formatter: (val) => val.toFixed(0),
        },
        min: questionnaireSelector.data?.config?.y_origin || 0,
        ...(typeof questionnaireSelector.data?.config?.max_score === 'number' && {
          max: questionnaireSelector.data.config.max_score
        }),
        axisBorder: {
          show: true,
          color: theme.palette.divider
        },
      },
      colors: ['#4F46E5'], // Vibrant indigo for the main line
      stroke: {
        curve: 'smooth', // Smoother line
        width: 3
      },
      markers: {
        size: 4,
        strokeColors: '#ffffff',
        colors: ['#4F46E5'], // Match main line color
        strokeWidth: 2,
        shape: 'circle',
        hover: {
          size: 6
        },
        discrete: alertMarkers,
      },
      legend: {
        horizontalAlign: 'left',
      },
      tooltip: {
        shared: true,
        intersect: false,
        enabledOnSeries: [0],
        x: {
          format: 'dd MMM HH:mm',
        },
        custom: ({ seriesIndex, dataPointIndex, w }) => {
          const data = w.config.series[seriesIndex].data[dataPointIndex];
          return ReactDOMServer.renderToString(
            <CustomTooltip date={data.x} description={data.description} />
          );
        },
      },
      annotations: {
        position: 'back',
        yaxis: thresholdAnnotations,
      },
    }),
    [
      theme.palette.text.primary,
      series,
      alertMarkers,
      thresholdAnnotations,
      questionnaireSelector.data,
    ]
  );

  if (questionnaireSelector.isFetching) {
    return <Skeleton variant="rect" width="100%" height={getHeights()} />;
  }

  return (
    <ChartContainer
      patientUuid={patientUuid}
      url={`/patient-profile/${patientUuid}/questionnaire/${questionnaireId}`}
      name={questionnaireSelector.data?.name}
      detailed={detailed}
      setOpen={setOpen}
    >
      {isFetching ? (
        <Skeleton variant="rect" width="100%" height={getHeights()} />
      ) : !questionnaireSelector.data?.has_score ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100%"
        >
          <Typography variant="h6">
            {t('No score available for this questionnaire')}
          </Typography>
        </Box>
      ) : (
        <>
          <ReactApexChart
            options={options}
            series={series}
            height={getHeights()}
            type={series[0]?.data?.length > 1 ? 'line' : 'scatter'}
          />
          {selectedRecord && (
            <QuestionnaireAnswersFullscreen
              recordId={selectedRecord.recordId}
              handleClose={() => setSelectedRecord(null)}
            />
          )}
        </>
      )}
      {open && (
        <DialogQuestionnaire
          patientUuid={patientUuid}
          open={open}
          onComplete={() => setOpen(false)}
          handleClose={() => setOpen(false)}
          questionnaireId={questionnaireId}
        />
      )}
    </ChartContainer>
  );
};

export default QuestionnaireResultChart;
