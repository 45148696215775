import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import DatePicker from './DatePicker';

export interface BirthDateProps {
  formik: any;
  /** Whether to disable the date picker */
  disabled?: boolean;
  /** MUI `sx` prop or custom styles */
  sx?: Record<string, unknown>;
}

/**
 * Helper to format a Date object (if present) into 'YYYY-MM-DD'
 */
export const formatBirthDateForPost = (date?: Date) =>
  date ? moment(date).format('YYYY-MM-DD') : null;

const BirthDate: FC<BirthDateProps> = ({
  formik,
  disabled = false,
  sx = { mx: 1 },
}) => {
  const { t } = useTranslation();

  return (
    <DatePicker
      name="birthDate"
      data-cy="birth-date"
      formik={formik}
      openTo="year"
      disableFuture
      label={t('Birth Date')}
      disabled={disabled}
      sx={sx}
    />
  );
};

export default BirthDate;
