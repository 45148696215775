import React, { useState, useEffect, useRef, useMemo } from 'react';
import { CardContent, CardActions, Button, useMediaQuery } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import { withTheme } from '@rjsf/core';
import { Theme as MaterialUITheme } from '@rjsf/mui';
import validator from '@rjsf/validator-ajv8';
import { greyboxApiActions } from '../../../redux/api';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

const MuiForm = withTheme(MaterialUITheme);

const ExtraInfoTab = ({ schemaKey }) => {
  const { t } = useTranslation();
  const { uuid } = useParams();
  const { account } = greyboxApiActions;
  const { clinic } = useSelector((state) => state.clinic);
  const [formData, setFormData] = useState({});
  const [initialFormData, setInitialFormData] = useState({});
  const [isDirty, setIsDirty] = useState(false);
  const { data = {} } = account.get(uuid);
  const [updateAccount, { isLoading }] = account.update();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const schemaObject = clinic.config.patient_extra_infos.find((item) => item.key === schemaKey);
  
  const schema = schemaObject ? schemaObject.json_schema.schema : {};
  const formRef = useRef(null);

  // ===== Change the value to test the disabled state =====
  const userHasPermissionToEdit = data.permissions.includes('change_localaccount');

  const reorderPropertiesByKey = (properties) => {
    return Object.entries(properties)
      .sort((a, b) => a[1].key - b[1].key)
      .reduce((acc, [key, value]) => {
        acc[key] = value;
        return acc;
      }, {});
  };
  
  const reorderSchemaProperties = (schema) => {
    const reorderedSchema = {
      ...schema,
      properties: reorderPropertiesByKey(schema.properties || {}),
    };
  
    for (const [groupKey, groupValue] of Object.entries(reorderedSchema.properties)) {
      if (groupValue.properties) {
        reorderedSchema.properties[groupKey] = {
          ...groupValue,
          properties: reorderPropertiesByKey(groupValue.properties),
        };
      }
    }
  
    return reorderedSchema;
  };
  
  const reorderedSchema = useMemo(() => {
    const sortedSchema = reorderSchemaProperties(schema);  
    return sortedSchema;
  }, [schema]);
  
  // Helper function to generate uiSchema
  const getUiSchema = (schema) => {
    return Object.keys(schema.properties || {}).reduce((acc, key) => {
      acc[key] = { 'ui:options': { classNames: 'fieldGrabber' } };
  
      if (schema.properties[key].inputStyle === 'textarea') {
        acc[key] = acc[key] || {}; 
        acc[key]['ui:widget'] = 'textarea';
        acc[key]['ui:options'] = { rows: 4, classNames: 'fieldGrabber fullWidth' };
      }
  
      if (schema.properties[key].properties) {
        console.log('schema.properties[key]', schema.properties[key].properties);
        
        Object.keys(schema.properties[key].properties || {}).forEach((nestedKey) => {
          console.log('Nested field:', nestedKey);
          
          if (schema.properties[key].properties[nestedKey].inputStyle === 'textarea') {
            acc[key] = acc[key] || {}; 
            acc[key][nestedKey] = {
              'ui:widget': 'textarea',
              'ui:options': { rows: 4, classNames: 'fieldGrabber fullWidth' }
            };
          }
        });
      }
  
      return acc;
    }, {});
  };
  

  const uiSchema = useMemo(() => getUiSchema(reorderedSchema), [reorderedSchema]);

  // Helper function for deep comparison
  const isDataEqual = (a, b) => JSON.stringify(a) === JSON.stringify(b);

  useEffect(() => {
    if (data?.extra_info) {
      setFormData(data.extra_info);
      setInitialFormData(data.extra_info);
    }

    if (formRef.current) {
      const dividers = formRef.current.querySelectorAll('.MuiDivider-root');
      dividers.forEach((divider) => divider.remove());
    }
  }, [data?.extra_info]);

  useEffect(() => {
    if (!formRef.current) return;
  
    const rootTitleElement = formRef.current.querySelector('#root__title');
    if (rootTitleElement) rootTitleElement.style.margin = '0px';
  
    // Select ALL matching elements inside any .fieldGrabber
    const gridItems = formRef.current.querySelectorAll(
      '.fieldGrabber .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12'
    );
  
    gridItems.forEach((gridItem) => {
      gridItem.style.flexBasis = isMobile ? '100%' : '48%';
    });
  
  }, [isMobile]);

  const handleResetToDefault = () => {
    setFormData(initialFormData);
    setIsDirty(false);
  };

  const handleChange = (e) => {
    setFormData((prev) => ({
      ...prev,
      [schemaKey]: e.formData,
    }));
    setIsDirty(!isDataEqual(e.formData, initialFormData[schemaKey]));
  };

  const handleSubmit = async ({ formData }) => {
    try {
      await updateAccount({
        id: uuid,
        body: { extra_info: { ...data.extra_info, [schemaKey]: formData } },
      }).unwrap();

      setFormData({ ...formData });
      setInitialFormData({ ...formData });
      setIsDirty(false);
    } catch (error) {
      console.error('Error updating account: ', error);
    }
  };

  return (
    <>
      <CardContent ref={formRef}>
        <MuiForm
          schema={reorderedSchema}
          validator={validator}
          formData={formData[schemaKey]}
          uiSchema={uiSchema}
          onChange={handleChange}
          onSubmit={handleSubmit}
          disabled={!userHasPermissionToEdit}
        >
          <CardActions sx={{ justifyContent: 'flex-end', mt: 2 }}>
            <Button
              variant="contained"
              onClick={handleResetToDefault}
              disabled={!isDirty || isLoading || !userHasPermissionToEdit}
            >
              {t('Reset')}
            </Button>
            <LoadingButton
              type="submit"
              variant="contained"
              loading={isLoading}
              disabled={!isDirty || isLoading || !userHasPermissionToEdit}
            >
              {t('Save')}
            </LoadingButton>
          </CardActions>
        </MuiForm>
      </CardContent>
    </>
  );
};

export default ExtraInfoTab;
