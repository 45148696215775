import CareTeamIcon from '@mui/icons-material/Group';
import PatientIcon from '@mui/icons-material/Person';
import SmartToyIcon from '@mui/icons-material/SmartToy'; // Add AI icon
import AddIcon from '@mui/icons-material/Add'; // Add icon for new chat
import {
  Avatar,
  Badge,
  Box,
  Button,
  CircularProgress,
  MenuItem,
  Paper,
  Skeleton,
  Typography,
  useMediaQuery,
  useTheme,
  Tooltip,
  Divider,
} from '@mui/material';
import moment from 'moment';
import MuiMarkdown from 'mui-markdown';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { greyboxApiActions } from '../../redux/api';
import SearchField from './SearchField';
import AgentSelector from './AgentSelector';
import useFeatureEnabled from '../../hooks/useFeatureEnabled';

const formatTime = (time) => {
  if (moment().diff(moment(time), 'days') > 0) {
    return moment(time).format('ll');
  }
  return moment(time).format('LT');
};

const ThreadSelector = () => {
  const { threadId } = useParams();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { messageThread } = greyboxApiActions;
  const { t } = useTranslation();
  const { clinic } = useSelector((state) => state.clinic);
  const [threads, setThreads] = useState([]);
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const navigate = useNavigate();
  const [isAgentSelectorOpen, setIsAgentSelectorOpen] = useState(false);
  const [isCreatingThread, setIsCreatingThread] = useState(false);
  const aiAgents = useFeatureEnabled('ai_agents');
  
  // Add API hooks for creating thread with agent
  const [createThreadWithAgent] = messageThread.add();

  const { data, isFetching, isError, refetch } = messageThread.list({
    clinic: clinic.id,
    page: page,
    page_size: 25,
    search,
  });

  useEffect(() => {
    if (search !== '') {
      setPage(1);
    }
  }, [search]);

  useEffect(() => {
    if (data && data.results) {
      if (page === 1) {
        setThreads(data.results);
        // if (!isMobile && !threadId && data.results.length > 0) {
        //   navigate(data.results[0].id);
        // }
      } else {
        setThreads((prevThreads) => [...prevThreads, ...data.results]);
      }
    } else if (page === 1 && !data?.results?.length) {
      setThreads([]); // Clear threads if no data results found for the first page
    }
  }, [data, isMobile, navigate, page, threadId]);

  const fetchMoreThreads = () => {
    if (!isFetching) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  const getMessagePreview = (message, maxLength = 50) => {
    if (message?.message) {
      // Limit text preview to maxLength characters and add ellipsis if needed
      const text = message.message;
      if (text.length <= maxLength) {
        return text;
      } else {
        return text.substring(0, maxLength) + '...';
      }
    } else if (message?.media) {
      if (message.media === 'image') {
        return t('[Image]');
      } else if (message.media === 'video') {
        return t('[Video]');
      } else if (message.media === 'audio') {
        return t('[Audio]');
      } else {
        return t('[Attachment]');
      }
    } else {
      return t('[No Content]');
    }
  };

  
  const handleOpenAgentSelector = () => {
    setIsAgentSelectorOpen(true);
  };

  const handleCloseAgentSelector = () => {
    setIsAgentSelectorOpen(false);
  };

  const handleSelectAgent = async (agent) => {
    try {
      setIsCreatingThread(true);
      
      // Create a new thread with the selected agent
      const response = await createThreadWithAgent({
        body: {
          title: `Chat with ${agent.name}`,
          clinic: clinic.id,
          patient: null,
          care_team: null,
          agent: agent.id,
        }
      });
      
      // Navigate to the new thread
      if (response.data && response.data.id) {
        navigate(`/messages/${response.data.id}`);
        refetch(); // Refresh the thread list
      }
    } catch (error) {
      console.error('Error creating thread with agent:', error);
    } finally {
      setIsCreatingThread(false);
    }
  };

  return (
    <Paper
      sx={{
        height: '100%',
        minHeight: '400px',
        display: 'flex',
        flexDirection: 'column',
        width: isMobile ? '100%' : '300px',
        bgcolor: theme.palette.background.paper,
        borderRadius: 2,
        mb: 2,
        mr: 2,
      }}
    >
      {!isMobile && (
        <Box
          sx={{
            p: 1,
            pl: 2,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <Typography variant="h6" sx={{ flexGrow: 1 }}>
            {t('Conversations')}
          </Typography>
        </Box>
      )}
      
      <Box
        sx={{
          p: 2,
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
        }}
      >
        <SearchField setSearch={setSearch} />
        
        {/* Chat with AI button - only shown if aiAgents feature is enabled */}
        {aiAgents && (
          <Button
            variant="contained"
            color="primary"
            startIcon={<SmartToyIcon />}
            onClick={handleOpenAgentSelector}
            disabled={isCreatingThread}
            fullWidth
            sx={{ borderRadius: '25px' }}
          >
            {isCreatingThread ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              t('Chat with AI')
            )}
          </Button>
        )}
      </Box>
      
      <Divider sx={{ mx: 2 }} />
      
      <Box
        id="scrollableDiv"
        sx={{
          flexGrow: 1,
          overflow: 'auto',
          height: isMobile ? 'calc(100vh - 170px)' : '500px',
          p: 1,
        }}
      >
        {isError && (
          <Typography color="error" sx={{ p: 2 }}>
            {t('Error fetching threads')}
          </Typography>
        )}
        {!isError && (
          <InfiniteScroll
            dataLength={threads.length}
            next={fetchMoreThreads}
            hasMore={data?.next}
            loader={isFetching && <CircularProgress sx={{ display: 'block', m: 'auto' }} />}
            scrollableTarget="scrollableDiv"
          >
            {threads.map((thread) => (
              <MenuItem
                key={thread.id}
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  height: '65px',
                  overflowX: 'hidden',
                  whiteSpace: 'nowrap',
                  padding: theme.spacing(1, 2),
                  borderRadius: 2,
                  mb: 1,
                  '&:hover': {
                    bgcolor: theme.palette.action.hover,
                  },
                }}
                component={Link}
                to={`/messages/${thread.id}`}
                selected={threadId && threadId === thread.id}
              >
                <Badge
                  color="primary"
                  variant="dot"
                  invisible={thread.latest_message?.clinic_read || true}
                  sx={{ mr: 2 }}
                >
                  <Avatar
                    sx={{
                      bgcolor: thread.agent 
                        ? theme.palette.secondary.main
                        : thread.patient
                          ? theme.palette.primary.light
                          : theme.palette.secondary.light,
                    }}
                  >
                    {thread.agent ? (
                      <SmartToyIcon sx={{ color: theme.palette.background.paper }} />
                    ) : thread.patient ? (
                      <PatientIcon sx={{ color: theme.palette.primary.main }} />
                    ) : (
                      <CareTeamIcon sx={{ color: theme.palette.secondary.main }} />
                    )}
                  </Avatar>
                </Badge>
                <Box sx={{ flexGrow: 1, overflow: 'hidden' }}>
                  <Typography
                    fontWeight={
                      thread.latest_message === null || thread.latest_message?.clinic_read
                        ? 'normal'
                        : 'bold'
                    }
                  >
                    {thread.title}
                  </Typography>
                  <Typography variant="caption" color="textSecondary" noWrap>
                    {thread.latest_message ? (
                      <MuiMarkdown
                        options={{
                          overrides: {
                            p: {
                              component: Typography,
                              props: {
                                variant: 'caption',
                                color: 'textSecondary',
                              },
                            },
                          },
                        }}
                      >
                        {`${thread.latest_message?.created_by?.first_name}: ${getMessagePreview(
                          thread.latest_message
                        )}`}
                      </MuiMarkdown>
                    ) : null}
                  </Typography>
                </Box>
                <Typography variant="caption" color="textSecondary">
                  {thread.latest_message?.created ? formatTime(thread.latest_message.created) : ''}
                </Typography>
              </MenuItem>
            ))}
            {isFetching && (
              <Box sx={{ p: 2 }}>
                <Skeleton variant="rectangular" height={65} sx={{ mb: 2, borderRadius: 2 }} />
                <Skeleton variant="rectangular" height={65} sx={{ mb: 2, borderRadius: 2 }} />
                <Skeleton variant="rectangular" height={65} sx={{ mb: 2, borderRadius: 2 }} />
              </Box>
            )}
          </InfiniteScroll>
        )}
      </Box>
      
      {/* Agent Selector Dialog - only render if aiAgents feature is enabled */}
      {aiAgents && (
        <AgentSelector
          open={isAgentSelectorOpen}
          onClose={handleCloseAgentSelector}
          onSelectAgent={handleSelectAgent}
        />
      )}
    </Paper>
  );
};

export default ThreadSelector;