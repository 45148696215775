import BallotIcon from '@mui/icons-material/Ballot';
import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import LabelIcon from '@mui/icons-material/Label';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import { BiMessageDots } from "react-icons/bi";
import { Box } from '@mui/material';
import moment from 'moment';
import React from 'react';
import {
  BloodGlucoseIcon,
  BloodPressureIcon,
  HealthScore,
  HeartRateIcon,
  InactiveIcon,
  StepCountIcon,
  TemperatureIcon,
  WeightIcon
} from '../tk-ui';

export const directionsMapping = {
  asc: '',
  desc: '-',
};

export const Circle = ({ color, children }) => (
  <Box sx={{
    backgroundColor: color,
    display: 'flex',
    justifyContent: 'center',
    color: 'white',
    width: '28px',
    height: '28px',
    minWidth: '28px',
    alignItems: 'center',
    borderRadius: '50%',
  }}
  >
    {children}
  </Box>
);

export const columnsConfigMapping = (header, vitalsConfig, summary = null) => {
  const mapping = {
    alert: {
      id: 'alert',
      header: ['Alert'],
      logo: (
        <Circle color="#dc3545">
          <PriorityHighIcon />
        </Circle>
      ),
      minWidth: 50,
      align: 'center',
      skeleton: { variant: 'circle', width: 35, height: 35 },
    },
    bp: {
      id: 'bp',
      header: ['Blood Pressure', '(mmHg)'],
      logo: (
        <Circle>
          <BloodPressureIcon style={{ width: '28px', height: '28px' }} />
        </Circle>
      ),
      minWidth: 50,
      align: 'center',
      skeleton: { variant: 'text' },
    },
    bg: {
      id: 'bg',
      header: ['Blood Glucose', '(mmol/L)'],
      logo: (
        <Circle>
          <BloodGlucoseIcon style={{ width: '28px', height: '28px' }} />
        </Circle>
      ),
      minWidth: 50,
      align: 'center',
      skeleton: { variant: 'text' },
    },
    hr: {
      id: 'hr',
      header: ['Heart rate', '(bpm)'],
      logo: (
        <Circle>
          <HeartRateIcon style={{ width: '28px', height: '28px' }} />
        </Circle>
      ),
      minWidth: 50,
      align: 'center',
      skeleton: { variant: 'text' },
    },
    inactive: {
      id: 'inactive',
      header: ['Inactive', '(Days)'],
      logo: (
        <Circle>
          <InactiveIcon style={{ width: '28px', height: '28px' }} />
        </Circle>
      ),
      minWidth: 50,
      align: 'center',
      skeleton: { variant: 'text' },
    },
    birth_date: {
      id: 'birth_date',
      header: ['Age', '(Days)'],
      logo: (
        <Circle>
          <InactiveIcon style={{ width: '28px', height: '28px' }} />
        </Circle>
      ),
      minWidth: 50,
      align: 'center',
      skeleton: { variant: 'text' },
    },
    // Legacy questionnaire name
    questionnire_score: {
      id: 'dq',
      header: ['Questionnaire'],
      logo: (
        <Circle>
          <HealthScore style={{ width: '28px', height: '28px' }} />
        </Circle>
      ),
      minWidth: 120,
      align: 'center',
      skeleton: { variant: 'rect', width: 90, height: 25 },
    },
    questionnaire_score: {
      id: 'dq',
      header: ['Questionnaire'],
      logo: (
        <Circle color="#1E145F">
          <BallotIcon sx={{ transform: 'scale(0.8)' }} />
        </Circle>
      ),
      minWidth: 120,
      align: 'center',
      skeleton: { variant: 'rect', width: 90, height: 25 },
    },
    reminder: {
      id: 'reminder',
      header: ['Reminder'],
      logo: (
        <Circle color="#FFC107">
          <NotificationsActiveIcon />
        </Circle>
      ),
      minWidth: 50,
      align: 'center',
      skeleton: { variant: 'circle', width: 35, height: 35 },
    },
    spo2: {
      id: 'spo2',
      header: ['Oxygen Saturation', '(%)'],
      ordering: 'spo2',
      logo: (
        <Circle>
          <BloodGlucoseIcon style={{ width: '28px', height: '28px' }} />
        </Circle>
      ),
      minWidth: 50,
      align: 'center',
      skeleton: { variant: 'text' },
    },
    steps: {
      id: 'steps',
      header: ['Steps'],
      logo: (
        <Circle>
          <StepCountIcon style={{ width: '28px', height: '28px' }} />
        </Circle>
      ),
      minWidth: 50,
      align: 'center',
      skeleton: { variant: 'text' },
    },
    temperature: {
      id: 'temperature',
      header: ['Temperature', '(°C)'],
      logo: (
        <Circle>
          <TemperatureIcon style={{ width: '28px', height: '28px' }} />
        </Circle>
      ),
      minWidth: 50,
      align: 'center',
      skeleton: { variant: 'text' },
    },
    wt: {
      id: 'weight',
      header: ['Weight', '(kg)'],
      logo: (
        <Circle>
          <WeightIcon style={{ width: '28px', height: '28px' }} />
        </Circle>
      ),
      minWidth: 50,
      align: 'center',
      skeleton: { variant: 'text' },
    },
    message: {
      id: 'message',
      header: ['Messages'],
      logo: (
        <Circle color="#0288D1">
          <BiMessageDots />
        </Circle>
      ),
      minWidth: 50,
      align: 'center',
      skeleton: { variant: 'circle', width: 35, height: 35 },
    },
    ppi: {
      id: 'ppi',
      header: ['Peripheral Perfusion Index', '(%)'],
      logo: (
        <Circle>
          <HeartRateIcon style={{ width: '28px', height: '28px' }} />
        </Circle>
      ),
      minWidth: 50,
      align: 'center',
      skeleton: { variant: 'text' },
    },
    label: {
      id: 'label',
      header: ['Labels'],
      logo: (
        <Circle color="#424B54">
          <LabelIcon sx={{ transform: 'scale(0.8)' }} />
        </Circle>
      ),
      minWidth: 50,
      align: 'center',
      skeleton: { variant: 'text' },
    },
    restingHeartRate: {
      id: 'restingHeartRate',
      header: ['Resting Heart Rate', '(bpm)'],
      logo: (
        <Circle>
          <HeartRateIcon style={{ width: '28px', height: '28px' }} />
        </Circle>
      ),
      minWidth: 50,
      align: 'center',
      skeleton: { variant: 'text' },
    },
    rr: {
      id: 'rr',
      header: ['Respiratory Rate', '(rpm)'],
      logo: (
        <Circle>
          <HeartRateIcon style={{ width: '28px', height: '28px' }} />
        </Circle>
      ),
      minWidth: 50,
      align: 'center',
      skeleton: { variant: 'text' },
    },
    medical_act: {
      id: 'medical_act',
      header: ['Follow-up'],
      logo: (
        <Circle color="#008080">
          <ConnectWithoutContactIcon />
        </Circle>
      ),
      minWidth: 50,
      align: 'center',
      skeleton: { variant: 'circle', width: 35, height: 35 },
    },
    created_date: {
      id: 'createdDate',
      header: ['Registered on'],
      logo: (
        <Circle color="#424B54">
          <EventAvailableIcon />
        </Circle>
      ),
      minWidth: 50,
      align: 'center',
      skeleton: { variant: 'circle', width: 35, height: 35 },
    },
    created_period: {
      id: 'createdPeriod',
      header: ['Treatment period'],
      logo: (
        <Circle color="#424B54">
          <EventAvailableIcon />
        </Circle>
      ),
      minWidth: 50,
      align: 'center',
      skeleton: { variant: 'circle', width: 35, height: 35 },
    },
  };

  // Custom vitals created with observation API
  if (!mapping[header]) {
    const custom = vitalsConfig[header];
    const agg = summary?.aggregate ? `(${summary.aggregate})` : '';

    if (!custom) {
      return {
        id: header,
        header: ['custom data'],
        minWidth: 50,
        align: 'center',
        logo: (
          <Circle color="#C5C5C5">
            <FavoriteBorderIcon />
          </Circle>
        ),
        skeleton: { variant: 'text' },
      };
    }
    return {
      id: header,
      header: [custom.name, custom.unit ? `(${custom.unit}) ${agg}` : agg],
      minWidth: 50,
      align: 'center',
      logo: (
        <Circle color="#C5C5C5">
          <FavoriteBorderIcon />
        </Circle>
      ),
      skeleton: { variant: 'text' },
    };
  }

  return mapping[header];
};

export const apiMapping = (key) => {
  const mapping = {
    alert: 'alert',
    bp: 'blood_pressure',
    BP: 'blood_pressure',
    bg: 'blood_glucose',
    BG: 'blood_glucose',
    bloodGlucose: 'blood_glucose',
    bloodPressure: 'blood_pressure',
    questionnaire: 'dq_answer',
    restingHeartRate: 'heart_rate',
    hr: 'hr',
    HR: 'hr',
    questionnire_score: 'dq_answer',
    questionnaire_score: 'dq_answer',
    dq: 'dq_answer',
    reminder: 'reminder',
    message: 'message',
    spo2: 'spo2',
    steps: 'steps',
    temperature: 'temperature',
    inactive: 'last_active',
    wt: 'weight',
    weight: 'weight',
    peripheralPerfusionIndex: 'ppi',
    respiratoryRate: 'rr',
    heartRate: 'heart_rate',
    medical_act: 'medical_act',
    createdDate: 'created',
    createdPeriod: 'created',
    name: 'name',
  };

  return key in mapping ? mapping[key] : key;
};

export const dateIsOutdated = (date) => moment().diff(moment(date), 'hour') > 24;
