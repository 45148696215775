import { TextField, Typography } from '@mui/material';
import React, { useEffect, useState, useRef } from 'react';
import Markdown from 'react-markdown';
import { Answers, Question } from '../../../../types';
import QuestionMedia from './QuestionMedia';
import InputMask from 'react-input-mask';
import { useQuestionnairePanelContext } from '../../../../contexts/QuestionnairePanelContext';

type SimpleAnswerPanelProps = {
  question: Question;
  setAnswers: (id: number, answer: string) => void;
  answers: Answers;
  isCurrent: boolean;
};

const SimpleAnswerPanel = (props: SimpleAnswerPanelProps) => {
  const { question, setAnswers, answers, isCurrent } = props;
  const { isRegexValid, setIsRegexValid } = useQuestionnairePanelContext();

  const [textValue, setTextValue] = useState<string>(answers[question.id] || '');
  const textFieldRef = useRef<HTMLInputElement | null>(null);

  const maskPattern = question.sa_question?.mask ?? '';
  const placeHolder = question.sa_question?.place_holder ?? '';
  const regexPattern = question.sa_question?.regex ? new RegExp(question.sa_question.regex) : null;

  // Sync answers with state
  useEffect(() => {
    setAnswers(question.id, textValue);
  }, [textValue, question.id]);

  useEffect(() => {
    if (question.sa_question?.regex && regexPattern) {
      if (regexPattern.test(textValue)) {
        setIsRegexValid(true);
      } else {
        setIsRegexValid(false);
      }
    } else {
      setIsRegexValid(true);
      // console.log('✅ No regex required. Input is valid by default.');
    }
  }, [textValue, question.sa_question?.regex]);

  useEffect(() => {
    if (isCurrent && !answers[question.id]) {
      setTimeout(() => {
        textFieldRef.current?.focus();
      }, 300);
    }
  }, [isCurrent, answers, question.id]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTextValue(e.target.value);
  };

  return (
    <>
      <Typography component="div" sx={{ width: '100%', mb: 1 }}>
        <Markdown>{question.sa_question?.sa_question_text || ''}</Markdown>
      </Typography>

      <QuestionMedia
        mediaType={(question.sa_question?.question_media_type as 'image' | 'video' | 'audio' | 'document' | 'none') || 'none'}
        mediaUrl={question.sa_question?.question_media || undefined}
      />

{question.sa_question && maskPattern ? (
  <InputMask
    mask={maskPattern}
    value={textValue}
    onChange={handleChange}
    maskChar={null}
  >
    {(inputProps) => (
      <TextField
        {...inputProps}
        placeholder={placeHolder}
        fullWidth
        variant="standard"
        error={!isRegexValid}
        helperText={!isRegexValid ? `Invalid format! Use ${placeHolder}` : ''}
        inputRef={(el) => {
          textFieldRef.current = el;
        }}
      />
    )}
  </InputMask>
) : (
  <TextField
    type={question.sa_question?.ans_type || 'text'}
    value={textValue}
    onChange={handleChange}
    placeholder={placeHolder}
    fullWidth
    variant="standard"
    inputRef={(el) => {
      textFieldRef.current = el;
    }}
  />
)}
    </>
  );
};

export default SimpleAnswerPanel;
