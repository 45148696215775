import React, { useEffect } from 'react';
import { MenuItem, TextField, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

type DurationProps = {
  formik: any;
  name?: string;
  label: string;
  shortOptions?: boolean;
  startField?: string;
  endField?: string;
  /**
   * If provided, this duration is used to pre-fill either the `name` field
   * or (startField, endField) if they are empty.
   */
  defaultDuration?: moment.Duration;
  /**
   * If true, whenever the user selects a duration in the dropdown,
   * end date is set to now, and start date = now - duration.
   */
  useNowAsEnd?: boolean;
};

const Duration = (props: DurationProps) => {
  const {
    formik,
    name,
    label,
    shortOptions = false,
    startField,
    endField,
    defaultDuration,
    useNowAsEnd = false,
  } = props;
  const { t } = useTranslation();

  const DURATION_OPTIONS_SHORT = [
    { label: t('None'), value: null },
    { label: t('15 minutes'), value: moment.duration(15, 'minutes') },
    { label: t('30 minutes'), value: moment.duration(30, 'minutes') },
    { label: t('45 minutes'), value: moment.duration(45, 'minutes') },
    { label: t('1 hour'), value: moment.duration(1, 'hours') },
    { label: t('1 hour 15 minutes'), value: moment.duration(1, 'hours').add(15, 'minutes') },
    { label: t('1 hour 30 minutes'), value: moment.duration(1, 'hours').add(30, 'minutes') },
    { label: t('1 hour 45 minutes'), value: moment.duration(1, 'hours').add(45, 'minutes') },
    { label: t('2 hours'), value: moment.duration(2, 'hours') },
  ];

  const DURATION_OPTIONS = [
    { label: t('None'), value: null },
    { label: t('15 minutes'), value: moment.duration(15, 'minutes') },
    { label: t('30 minutes'), value: moment.duration(30, 'minutes') },
    { label: t('45 minutes'), value: moment.duration(45, 'minutes') },
    { label: t('1 hour'), value: moment.duration(1, 'hours') },
    { label: t('4 hours'), value: moment.duration(4, 'hours') },
    { label: t('1 day'), value: moment.duration(1, 'days') },
    { label: t('1 week'), value: moment.duration(1, 'weeks') },
  ];

  const options = shortOptions ? DURATION_OPTIONS_SHORT : DURATION_OPTIONS;
  const optionsWithKeys = options.map((option, idx) => ({ ...option, key: idx }));

  /**
   * On mount or whenever `defaultDuration` changes, if we have not
   * set values in formik yet, fill them in.
   */
  useEffect(() => {
    if (startField && endField) {
      const startVal = formik.values[startField];
      const endVal = formik.values[endField];
      // If both are empty and defaultDuration is provided, initialize them
      if (!startVal && !endVal && defaultDuration) {
        const endTime = useNowAsEnd ? moment() : moment().add(defaultDuration);
        const startTime = endTime.clone().subtract(defaultDuration);

        formik.setFieldValue(startField, startTime.format('YYYY-MM-DDTHH:mm'));
        formik.setFieldValue(endField, endTime.format('YYYY-MM-DDTHH:mm'));
      }
    } else if (name && defaultDuration) {
      // If the standalone field is empty, initialize it to defaultDuration
      if (!formik.values[name]) {
        formik.setFieldValue(name, defaultDuration);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultDuration, startField, endField, name, useNowAsEnd]);

  // Determine selected option based on current state
  let selectedOption;
  if (startField && endField) {
    const start = formik.values[startField];
    const end = formik.values[endField];
    if (start && end) {
      const durationMs = moment(end).diff(moment(start));
      const currentDuration = moment.duration(durationMs);
      selectedOption =
        optionsWithKeys.find(
          (option) =>
            option.value?.asMilliseconds() === currentDuration.asMilliseconds()
        ) || optionsWithKeys[0]; // Default to "None"
    } else {
      selectedOption = optionsWithKeys[0]; // Default to "None"
    }
  } else if (name) {
    selectedOption =
      optionsWithKeys.find((option) => {
        if (option.value === null) return !formik.values[name];
        // If the field is moment.duration, compare asMilliseconds()
        if (moment.isDuration(formik.values[name])) {
          return (
            option.value?.asMilliseconds() ===
            (formik.values[name] as moment.Duration).asMilliseconds()
          );
        }
        // Otherwise, try parsing string
        if (typeof formik.values[name] === 'string') {
          const fieldDuration = moment.duration(formik.values[name]);
          return (
            option.value?.asMilliseconds() ===
            fieldDuration.asMilliseconds()
          );
        }
        return false;
      }) || optionsWithKeys[0];
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedKey = parseInt(event.target.value, 10);
    const selected = optionsWithKeys.find((option) => option.key === selectedKey);

    if (!selected) return;

    if (startField && endField) {
      if (selected.value) {
        if (useNowAsEnd) {
          // end = now, start = now - duration
          const newEnd = moment();
          const newStart = newEnd.clone().subtract(selected.value);
          formik.setFieldValue(startField, newStart.format('YYYY-MM-DDTHH:mm'));
          formik.setFieldValue(endField, newEnd.format('YYYY-MM-DDTHH:mm'));
        } else {
          // Original logic: start remains as is, end = start + duration
          const existingStart = formik.values[startField]
            ? moment(formik.values[startField])
            : moment();
          const newEnd = existingStart.clone().add(selected.value);
          formik.setFieldValue(startField, existingStart.format('YYYY-MM-DDTHH:mm'));
          formik.setFieldValue(endField, newEnd.format('YYYY-MM-DDTHH:mm'));
        }
      } else {
        // Selected "None"
        formik.setFieldValue(startField, '');
        formik.setFieldValue(endField, '');
      }
    } else if (name) {
      // Standalone field
      formik.setFieldValue(name, selected.value ? selected.value.toISOString() : null);
    }
  };

  return (
    <Box sx={{ width: '100%', mx: 0, my: 1 }}>
      <TextField
        select
        fullWidth
        sx={{ mb: 2 }}
        label={label}
        value={selectedOption?.key ?? ''}
        onChange={handleChange}
        name={name} // Only relevant in standalone mode
      >
        {optionsWithKeys.map((option) => (
          <MenuItem key={option.key} value={option.key}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
    </Box>
  );
};

export default Duration;
