import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  Alert,
  Backdrop,
  Box,
  CircularProgress,
  Container,
  IconButton,
  Paper,
  Skeleton,
  Snackbar,
  Tooltip,
  Typography,
  useMediaQuery,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Formik } from 'formik';
import { debounce } from 'lodash';
import { useTranslation } from 'react-i18next';
import {
  IoArrowBackOutline,
  IoCheckmarkDoneOutline,
  IoCloudDoneOutline,
  IoCloudOfflineOutline,
  IoCloudUploadOutline,
  IoDocumentTextOutline,
  IoMicOutline,
  IoPrintOutline,
  IoSaveOutline,
  IoTrashOutline,
} from 'react-icons/io5';
import Markdown from 'react-markdown';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import remarkGfm from 'remark-gfm';
import * as yup from 'yup';
import { greyboxApiActions } from '../../../redux/api';
import AudioPickerDialog from '../../audio/AudioPickerDialog';
import FormikTextField from '../../form-inputs/FormikTextField';
import MarkdownEditor from '../components/MarkdownEditor';
import ConfirmationDialog from './ConfirmationDialog';
import GenerateClinicalNoteReportDialog from './GenerateClinicalNoteReportDialog';
import PrintableNote from './PrintableNote';

const useStyles = makeStyles((theme) => ({
  spacer: {
    marginBottom: theme.spacing(2),
  },
  editorContainer: {
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: '4px',
    padding: theme.spacing(2),
    minHeight: '100px',
    marginBottom: theme.spacing(2),
  },
  title: {
    display: 'flex',
    alignItems: 'center',
  },
  readOnlySummary: {
    minHeight: '20px',
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: '4px',
    width: '100%',
    fontFamily: theme.typography.fontFamily,
    position: 'relative',
  },
  markdown: {
    padding: '0 18px',
    overflow: 'auto',
  },
  readOnlyLabel: {
    position: 'absolute',
    top: '-8px',
    left: '10px',
    backgroundColor: theme.palette.background.paper,
    padding: '0 4px',
    color: theme.palette.text.secondary,
    fontSize: '0.75rem',
  },
  readOnlyDescription: {
    minHeight: '50vh',
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: '4px',
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
    overflow: 'auto',
    fontFamily: theme.typography.fontFamily,
  },
  saveIndicator: {
    padding: theme.spacing(0.5, 1),
    borderRadius: theme.shape.borderRadius,
    display: 'flex',
    alignItems: 'center',
    fontSize: '0.75rem',
    transition: 'all 0.3s ease',
  },
  successIndicator: {
    backgroundColor: theme.palette.success.light,
    color: theme.palette.success.contrastText,
  },
  errorIndicator: {
    backgroundColor: theme.palette.error.light,
    color: theme.palette.error.contrastText,
  },
  pendingIndicator: {
    backgroundColor: theme.palette.grey[200],
    color: theme.palette.text.secondary,
  },
}));

const ClinicalNotePage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { uuid, clinicalNoteId } = useParams();
  const patientUuid = uuid;
  const classes = useStyles();
  const { clinicalImpression, transcribeAudio } = greyboxApiActions;
  const [updateImpression, { isLoading: isUpdating }] = clinicalImpression.update();
  const [createImpression, { isLoading: isCreating }] = clinicalImpression.add();
  const [postTranscribeAudio] = transcribeAudio.add();
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [currentDialog, setCurrentDialog] = useState('');
  const { accountId } = useSelector((state) => state.user);
  const [iframeUrl, setIframeUrl] = useState('');
  const [iframeOpen, setIframeOpen] = useState(false);
  const [documentToPrint, setDocumentToPrint] = useState(null);
  const [openGenerateClinicalReportDialog, setOpenGenerateClinicalReportDialog] = useState(false);
  const [openGeneratedReportMessage, setOpenGeneratedReportMessage] = useState(false);
  const [snackbar, setSnackbar] = useState({ message: '', severity: 'success' });
  const [openAudioPicker, setOpenAudioPicker] = useState(false);
  const [isWarningOpen, setIsWarningOpen] = useState(false);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const printRef = useRef();
  const [isTranscribing, setIsTranscribing] = useState(false);
  
  // New state variables for improved auto-save UX
  const [saving, setSaving] = useState(false);
  const [saveStatus, setSaveStatus] = useState('idle'); // 'idle', 'saving', 'success', 'error'
  const [openSaveNotification, setOpenSaveNotification] = useState(false);
  const [contentChanged, setContentChanged] = useState(false);
  const previousValues = useRef({ summary: '', description: '' });
  const saveTimeoutRef = useRef(null);
  const lastSaveRequestRef = useRef(null);
  const saveAttemptRef = useRef(0);

  const { data, isFetching, refetch } = clinicalImpression.get(clinicalNoteId, {
    skip: clinicalNoteId === 'create-new-note',
  });

  const isNewNote = clinicalNoteId === 'create-new-note';
  const firstName = data?.assessor?.display?.firstName;
  const lastName = data?.assessor?.display?.lastName;
  const formattedName = firstName ? (isMobile ? `${firstName.charAt(0).toUpperCase()}.` : firstName) : '';
  const lastUpdated = data?.meta?.lastUpdated 
    ? new Date(data.meta.lastUpdated).toLocaleString([], {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        hour12: true,
      })
    : '';

  const handleGoBack = (dirty) => {
    if (data?.status === 'completed') {
      navigate(`/patient-profile/${patientUuid}/clinical-note`);
    } else if (dirty && !documentToPrint) {
      setConfirmationOpen(true);
      setCurrentDialog('leave');
    } else {
      navigate(`/patient-profile/${patientUuid}/clinical-note`);
    }
  };

  const handleOpenAudioPicker = () => {
    setIsWarningOpen(true);
  };

  const handleCloseAudioPicker = () => {
    setOpenAudioPicker(false);
  };

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    documentTitle: documentToPrint?.summary,
    onAfterPrint: () => setDocumentToPrint(null),
    ignoreGlobalStyles: true,
  });

  useEffect(() => {
    if (documentToPrint) {
      handlePrint();
    }
  }, [documentToPrint, handlePrint]);

  const handleWarningAccept = () => {
    setIsWarningOpen(false);
    setOpenAudioPicker(true);
  };

  const handleWarningClose = () => {
    setIsWarningOpen(false);
  };

  // Improved debounced submit function
  const debouncedSubmit = useCallback(
    debounce((values, isNewNote, dirty, isValid) => {
      if (contentChanged && isValid && dirty && !saving) {
        // Mark that we're handling these changes
        setContentChanged(false);
        
        // Show saving indicator
        setSaving(true);
        setSaveStatus('saving');
        
        const currentAttempt = ++saveAttemptRef.current;
        
        const savePromise = isNewNote
          ? createImpression({
              body: {
                ...values,
                subject: { reference: `Patient/${patientUuid}` },
                assessor: { reference: `Practitioner/${accountId}` },
              },
            })
          : updateImpression({
              id: clinicalNoteId,
              body: {
                ...values,
                assessor: { reference: `Practitioner/${accountId}` },
              },
            });
        
        lastSaveRequestRef.current = savePromise;
        
        savePromise
          .unwrap()
          .then((response) => {
            // Only update UI if this was the most recent save attempt
            if (currentAttempt === saveAttemptRef.current) {
              setSaveStatus('success');
              
              if (isNewNote && response?.data?.id) {
                navigate(`/patient-profile/${patientUuid}/clinical-note/${response.data.id}`, { replace: true });
              }
              
              // Reset success status after delay
              if (saveTimeoutRef.current) {
                clearTimeout(saveTimeoutRef.current);
              }
              saveTimeoutRef.current = setTimeout(() => {
                if (saveStatus === 'success') {
                  setSaveStatus('idle');
                }
              }, 3000);
            }
          })
          .catch((error) => {
            console.error('Auto-save failed:', error);
            
            // Only update UI if this was the most recent save attempt
            if (currentAttempt === saveAttemptRef.current) {
              setSaveStatus('error');
              setSnackbar({
                message: t('Auto-save failed. Will retry shortly.'),
                severity: 'error'
              });
              setOpenSaveNotification(true);
              
              // Schedule retry after 5 seconds
              setTimeout(() => {
                if (dirty && isValid) {
                  setContentChanged(true);
                  debouncedSubmit(values, isNewNote, dirty, isValid);
                }
              }, 5000);
            }
          })
          .finally(() => {
            if (currentAttempt === saveAttemptRef.current) {
              setSaving(false);
            }
          });
      }
    }, 2000),
    [clinicalNoteId, patientUuid, accountId]
  );

  // Function to manually save
  const handleManualSubmit = async (values, { setSubmitting }) => {
    // Cancel any pending auto-saves
    if (saveTimeoutRef.current) {
      clearTimeout(saveTimeoutRef.current);
      saveTimeoutRef.current = null;
    }
    
    debouncedSubmit.cancel();
    
    // If already saving, don't start a new save
    if (saving) {
      setSnackbar({
        message: t('Already saving your changes...'),
        severity: 'info',
      });
      setOpenSaveNotification(true);
      setSubmitting(false);
      return;
    }
    
    // Set saving state immediately for UI feedback
    setSaving(true);
    setSaveStatus('saving');
    
    try {
      const currentAttempt = ++saveAttemptRef.current;
      
      const savePromise = isNewNote
        ? createImpression({
            body: {
              ...values,
              subject: { reference: `Patient/${patientUuid}` },
              assessor: { reference: `Practitioner/${accountId}` },
            },
          })
        : updateImpression({
            id: clinicalNoteId,
            body: {
              ...values,
              assessor: { reference: `Practitioner/${accountId}` },
            },
          });
      
      // Store the current save request reference
      lastSaveRequestRef.current = savePromise;
      
      const response = await savePromise.unwrap();
      
      // Only update UI if this was the most recent save attempt
      if (currentAttempt === saveAttemptRef.current) {
        setSaveStatus('success');
        setSnackbar({
          message: t('Note saved successfully'),
          severity: 'success',
        });
        setOpenSaveNotification(true);
        
        if (isNewNote && response?.data?.id) {
          navigate(`/patient-profile/${patientUuid}/clinical-note/${response.data.id}`, { replace: true });
        }
        
        // Reset success status after delay
        if (saveTimeoutRef.current) {
          clearTimeout(saveTimeoutRef.current);
        }
        saveTimeoutRef.current = setTimeout(() => {
          if (saveStatus === 'success') {
            setSaveStatus('idle');
          }
        }, 3000);
      }
    } catch (error) {
      setSaveStatus('error');
      setSnackbar({
        message: t('Error saving note. Please try again.'),
        severity: 'error',
      });
      setOpenSaveNotification(true);
      console.error('Error saving note:', error);
    } finally {
      setSaving(false);
      setSubmitting(false);
    }
  };

  const handleFormAction = async (values, status) => {
    try {
      const body = {
        ...values,
        status: status,
        assessor: {
          reference: `Practitioner/${accountId}`,
        }
      };
      
      if (isNewNote) {
        body.subject = { reference: `Patient/${patientUuid}` };
        await createImpression({ body });
      } else {
        await updateImpression({ id: clinicalNoteId, body });
      }
      
      if (status === 'entered-in-error') {
        navigate(-1);
      } else {
        // Refresh data after status change
        refetch();
      }
    } catch (error) {
      console.error(`Error during ${status} action:`, error);
      setSnackbar({
        message: t(`Error during ${status === 'completed' ? 'finalization' : 'deletion'}.`),
        severity: 'error',
      });
      setOpenSaveNotification(true);
    }
  };

  const handleConfirmDialog = async (values) => {
    switch (currentDialog) {
      case 'finalize':
        await handleFormAction(values, 'completed');
        break;
      case 'delete':
        if (isNewNote) {
          navigate(-1);
        } else {
          await handleFormAction(values, 'entered-in-error');
        }
        break;
      case 'leave':
        navigate(-1);
        break;
      default:
        break;
    }
    setConfirmationOpen(false);
  };

  return (
    <Formik
      initialValues={{
        summary: data?.summary || '',
        description: data?.description || '',
        status: data?.status || 'in-progress',
      }}
      enableReinitialize
      validationSchema={yup.object({
        summary: yup.string().required(t('Summary is required')),
        description: yup.string().required(t('Description is required')),
      })}
      onSubmit={handleManualSubmit}
    >
      {({ values, handleSubmit, isSubmitting, dirty, isValid, setFieldValue }) => {
        const handleAudioSelected = async (audioBlob) => {
          handleCloseAudioPicker();
          const formData = new FormData();
          if (!values.summary) {
            formData.append('include_generated_title', String(true));
          }

          formData.append('audio_file', audioBlob, 'recording.wav');

          setIsTranscribing(true);
          try {
            const result = await postTranscribeAudio({ body: formData });
            if (result.data.generated_title) {
              setFieldValue('summary', result.data.generated_title);
            }
            setFieldValue('description', result.data.transcription);
          } catch (error) {
            console.error('Error during audio transcription:', error);
            setSnackbar({
              message: t('Error during audio transcription.'),
              severity: 'error',
            });
            setOpenSaveNotification(true);
          } finally {
            setIsTranscribing(false);
          }
        };

        // Track content changes and trigger debounced save
        useEffect(() => {
          // Check if values have actually changed
          if (
            values.summary !== previousValues.current.summary ||
            values.description !== previousValues.current.description
          ) {
            // Mark that content has changed
            setContentChanged(true);
            
            // Update previous values
            previousValues.current = {
              summary: values.summary,
              description: values.description,
            };
            
            // Only trigger debounced save if form is valid and dirty
            if (isValid && dirty) {
              // Cancel any pending auto-save
              debouncedSubmit.cancel();
              
              // Set up new debounced save
              debouncedSubmit(values, isNewNote, dirty, isValid);
            }
          }
        }, [values.summary, values.description, isValid, dirty, debouncedSubmit]);
        
        // Cleanup effect
        useEffect(() => {
          return () => {
            debouncedSubmit.cancel();
            if (saveTimeoutRef.current) {
              clearTimeout(saveTimeoutRef.current);
            }
          };
        }, [debouncedSubmit]);

        return (
          <Container maxWidth="md">
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
              <Tooltip title={t('Go back')}>
                <IconButton onClick={() => handleGoBack(dirty)} sx={{ mr: 1 }}>
                  <IoArrowBackOutline size={24} />
                </IconButton>
              </Tooltip>
              {isFetching ? (
                <Skeleton variant="text" width="60%" />
              ) : (
                <Typography
                  variant="h6"
                  className={classes.title}
                  sx={{
                    fontSize: '1.25rem',
                    fontWeight: 'bold',
                    color: (theme) => theme.palette.text.primary,
                  }}
                >
                  {isNewNote
                    ? t('Create note')
                    : data?.status === 'completed'
                    ? t('Clinical Note')
                    : t('Edit note')}
                </Typography>
              )}
              <Box sx={{ ml: 'auto', display: 'flex', alignItems: 'center' }}>
                {data?.status !== 'completed' && (
                  <>
                    <Tooltip title={t('Transcribe Audio')}>
                      <IconButton onClick={handleOpenAudioPicker} aria-label="Transcribe Audio" disabled={isFetching || saving}>
                        <IoMicOutline size={24} />
                      </IconButton>
                    </Tooltip>
                    {!isNewNote && (
                      <Tooltip title={t('Delete Draft')}>
                        <IconButton
                          onClick={() => {
                            setConfirmationOpen(true);
                            setCurrentDialog('delete');
                          }}
                          disabled={isFetching || saving}
                          aria-label="Delete Draft"
                        >
                          <IoTrashOutline size={24} />
                        </IconButton>
                      </Tooltip>
                    )}
                    <Tooltip title={t('Save Draft')}>
                      <span>
                        <IconButton
                          disabled={isSubmitting || isFetching || saving || !dirty}
                          aria-label="Save Draft"
                          onClick={handleSubmit}
                          color={saveStatus === 'error' ? 'error' : 'default'}
                        >
                          {isSubmitting || saving ? (
                            <CircularProgress size={24} />
                          ) : (
                            <IoSaveOutline size={24} />
                          )}
                        </IconButton>
                      </span>
                    </Tooltip>
                    <Tooltip title={t('Finalize')}>
                      <IconButton
                        onClick={() => {
                          setConfirmationOpen(true);
                          setCurrentDialog('finalize');
                        }}
                        disabled={isSubmitting || isFetching || saving || !isValid}
                        aria-label="Finalize"
                      >
                        <IoCheckmarkDoneOutline size={24} />
                      </IconButton>
                    </Tooltip>
                  </>
                )}
                {data?.status === 'completed' && (
                  <>
                    <Tooltip title={t('Print Note')}>
                      <IconButton
                        onClick={() => setDocumentToPrint(data)}
                        disabled={isFetching}
                        aria-label="Print Note"
                      >
                        <IoPrintOutline size={24} />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title={t('Generate Report')}>
                      <IconButton
                        onClick={() => setOpenGenerateClinicalReportDialog(true)}
                        disabled={isFetching}
                        aria-label="Generate Report"
                      >
                        <IoDocumentTextOutline size={24} />
                      </IconButton>
                    </Tooltip>
                  </>
                )}
                {isFetching ? (
                  <Skeleton variant="text" width="100%" />
                ) : (
                  <Tooltip
                    title={
                      formattedName && lastName && lastUpdated
                        ? t('Last save made by {{formattedName}} {{lastName}} at {{lastUpdated}}', {
                            formattedName,
                            lastName,
                            lastUpdated,
                          })
                        : saveStatus === 'saving'
                        ? t('Saving...')
                        : saveStatus === 'success'
                        ? t('Saved successfully')
                        : saveStatus === 'error'
                        ? t('Save failed. Retrying soon...')
                        : t('Not saved yet')
                    }
                  >
                    <Box sx={{ ml: 2, display: 'flex', alignItems: 'center' }}>
                      {saveStatus === 'saving' || isSubmitting ? (
                        <CircularProgress size={16} color="primary" sx={{ mr: 1 }} />
                      ) : null}
                      {saveStatus === 'saving' ? (
                        <IoCloudUploadOutline color="#1976d2" size={24} />
                      ) : saveStatus === 'error' ? (
                        <IoCloudOfflineOutline color="#d32f2f" size={24} />
                      ) : saveStatus === 'success' ? (
                        <IoCloudDoneOutline color="#2e7d32" size={24} />
                      ) : dirty || isNewNote ? (
                        <IoCloudOfflineOutline color="grey" size={24} />
                      ) : (
                        <IoCloudDoneOutline color="green" size={24} />
                      )}
                    </Box>
                  </Tooltip>
                )}
              </Box>
            </Box>
            <Paper className={classes.editorContainer}>
              <form onSubmit={handleSubmit}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: { xs: 'column', sm: 'row' },
                    alignItems: 'flex-end',
                    justifyContent: 'space-between',
                    gap: 2,
                  }}
                >
                  {isFetching ? (
                    <Skeleton variant="rectangular" height={56} width="100%" />
                  ) : !isNewNote && data?.status === 'completed' ? (
                    <div className={classes.readOnlySummary}>
                      <label className={classes.readOnlyLabel}>{t('Summary')}</label>
                      <Markdown className={classes.markdown}>{values.summary}</Markdown>
                    </div>
                  ) : (
                    <FormikTextField name="summary" label={t('Summary')} fullWidth required />
                  )}
                </Box>
                <div className={classes.spacer} style={{ position: 'relative' }}>
                  {isFetching ? (
                    <Skeleton variant="rectangular" height={420} width="100%" />
                  ) : !isNewNote && data?.status === 'completed' ? (
                    <div className={classes.readOnlyDescription} ref={printRef}>
                      <label className={classes.readOnlyLabel}>{t('Description')}</label>
                      <Markdown remarkPlugins={[remarkGfm]}>{data?.description}</Markdown>
                    </div>
                  ) : (
                    <Box sx={{ mb: 2 }}>
                      <MarkdownEditor name="description" />
                    </Box>
                  )}
                </div>
              </form>
            </Paper>
            <ConfirmationDialog
              isOpen={confirmationOpen}
              dialogActionType={currentDialog}
              onConfirm={() => handleConfirmDialog(values)}
              onClose={() => setConfirmationOpen(false)}
            />
            <Box sx={{ display: 'none' }}>
              <PrintableNote ref={printRef} note={documentToPrint} />
            </Box>
            
            {/* Auto-save notifications */}
            <Snackbar
              open={openSaveNotification}
              autoHideDuration={3000}
              onClose={() => setOpenSaveNotification(false)}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            >
              <Alert
                onClose={() => setOpenSaveNotification(false)}
                severity={snackbar.severity}
                variant="filled"
                sx={{ 
                  width: '100%', 
                  '& .MuiAlert-icon': { 
                    fontSize: '1rem' 
                  }, 
                  '& .MuiAlert-message': { 
                    fontSize: '0.875rem' 
                  } 
                }}
              >
                {snackbar.message}
              </Alert>
            </Snackbar>
            
            {/* Floating status indicator */}
            <Box
              sx={{
                position: 'fixed',
                bottom: 16,
                right: 16,
                zIndex: 1000,
                display: (saveStatus === 'idle' && !dirty) ? 'none' : 'flex',
                alignItems: 'center',
                padding: '6px 12px',
                borderRadius: '16px',
                backgroundColor: (theme) => 
                  saveStatus === 'error' 
                    ? theme.palette.error.light 
                    : saveStatus === 'success' 
                      ? theme.palette.success.light 
                      : theme.palette.grey[200],
                boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
                transition: 'all 0.3s ease',
                opacity: saveStatus === 'success' ? 0.8 : 1,
              }}
            >
              {saveStatus === 'saving' && (
                <CircularProgress size={16} sx={{ mr: 1 }} color="inherit" />
              )}
              <Typography variant="caption" color="textSecondary">
                {saveStatus === 'saving' 
                  ? t('Saving...') 
                  : saveStatus === 'success' 
                    ? t('Saved') 
                    : saveStatus === 'error' 
                      ? t('Save failed') 
                      : dirty 
                        ? t('Unsaved changes') 
                        : ''}
              </Typography>
            </Box>
            
            {/* Other dialogs */}
            <Snackbar
              open={openGeneratedReportMessage}
              autoHideDuration={6000}
              onClose={() => setOpenGeneratedReportMessage(false)}
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
              <Alert
                onClose={() => setOpenGeneratedReportMessage(false)}
                severity={snackbar.severity}
                variant="filled"
                sx={{ width: '100%' }}
              >
                {snackbar.message}
              </Alert>
            </Snackbar>
            <GenerateClinicalNoteReportDialog
              open={openGenerateClinicalReportDialog}
              handleClose={() => setOpenGenerateClinicalReportDialog(false)}
              resource={data}
              patientUuid={uuid}
              setOpenGeneratedReportMessage={setOpenGeneratedReportMessage}
              setSnackbar={setSnackbar}
              setIframeUrl={setIframeUrl}
            />
            <AudioPickerDialog
              open={openAudioPicker}
              onClose={handleCloseAudioPicker}
              onAudioSelected={handleAudioSelected}
            />
            <Dialog
              open={isWarningOpen}
              onClose={handleWarningClose}
              aria-labelledby="warning-dialog-title"
              aria-describedby="warning-dialog-description"
            >
              <DialogTitle id="warning-dialog-title">{t('Feature under development')}</DialogTitle>
              <DialogContent>
                <DialogContentText id="warning-dialog-description">
                  {t('The audio transcription feature is under development. Please carefully validate the entry before saving. Do you wish to continue?')}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleWarningClose} color="primary">
                  {t('No')}
                </Button>
                <Button onClick={handleWarningAccept} color="primary" autoFocus>
                  {t('Yes')}
                </Button>
              </DialogActions>
            </Dialog>
            <Backdrop
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={isTranscribing}
            >
              <CircularProgress color="inherit" />
              <Typography variant="h6" sx={{ ml: 2 }}>
                {t('Transcribing audio, please wait...')}
              </Typography>
            </Backdrop>
          </Container>
        );
      }}
    </Formik>
  );
};

export default ClinicalNotePage;