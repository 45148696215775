import {
  IconButton,
  Menu,
  MenuItem,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  Box,
  Typography,
  Snackbar,
  Alert,
  Skeleton,
  Dialog,
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { useReactToPrint } from 'react-to-print';
import { useSelector } from 'react-redux';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import moment from 'moment';
import AddIcon from '@mui/icons-material/Add';
import React, {
  useEffect, useState, useRef,
} from 'react';
import { useTranslation } from 'react-i18next';
import { NoData } from '../../tk-ui';
import { greyboxApiActions } from '../../../redux/api';
import TableWrapper from '../../TableWrapper';
import GenerateClinicalNoteReportDialog from './GenerateClinicalNoteReportDialog';
import PrintableNote from './PrintableNote';
import PdfViewer from '../../pdf-viewer/PdfViewer';

const extractPdfId = (url) => {
  const match = url.match(/\/([^\/]+)\.pdf/); 
  return match ? match[1] : null; 
};

const useStyles = makeStyles((theme) => ({
  noData: {
    transform: 'scale(4)',
  },
  container: {
    width: '100%',
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    borderBottom: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing(1),
  },
  addBtn: {
    marginRight: theme.spacing(1),
  },
}));

const ClinicalNotePanel = () => {
  const { uuid } = useParams();
  const classes = useStyles();
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [openGenerateClinicalReportDialog, setOpenGenerateClinicalReportDialog] = useState(false);
  const [selected, setSelected] = useState(null);
  const [selectedToPrint, setSelectedToPrint] = useState(null);
  const [selectedResource, setSelectedResource] = useState(null);
  const [openGeneratedReportMessage, setOpenGeneratedReportMessage] = useState(false);
  const [snackbar, setSnackbar] = useState({ message: '', severity: 'success' });
  const { t } = useTranslation();
  const { clinicalImpression } = greyboxApiActions;
  const { edit } = useSelector((state) => state.permissions);
  const printRef = useRef();
  const [iframeUrl, setIframeUrl] = useState('');
  const {
    data, isFetching,
  } = clinicalImpression.list({
    subject: `Patient/${uuid}`,
    status: 'in-progress,completed',
    page: page + 1,
    _sort: '-date',
    _count: rowsPerPage,
  });

  const statusOptions = [
    { value: 'in-progress', label: t('In Progress') },
    { value: 'completed', label: t('Completed') },
    { value: 'entered-in-error', label: t('Entered in Error') },
  ];

  const columns = [
    {
      id: 'summary',
      label: t('Summary'),
    },
    {
      id: 'status',
      label: t('Status'),
      format: (value) => {
        const statusOption = statusOptions.find(option => option.value === value);
        return statusOption ? statusOption.label : value;
      },
    },
    {
      id: 'assessor',
      label: t('Entered by'),
      format: (value) => (value.display ? (
        `${value.display.firstName} ${value.display.lastName}`
      ) : '---'),
    },
    {
      id: 'date',
      label: t('Date'),
      format: (value) => moment(value).format('YYYY-MM-DD HH:mm'),
    },

    {
      id: 'meta',
      label: t('Last updated'),
      format: (value) => moment(value.lastUpdated).format('YYYY-MM-DD HH:mm'),
    },

  ];

  const handleCloseGeneratedReportMessage = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenGeneratedReportMessage(false);
  };

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    documentTitle: selectedToPrint?.summary,
    onAfterPrint: () => setSelectedToPrint(null), 
  });

  const handleNavigateToNewNoteCreation = () => {
    try {
      navigate(`/patient-profile/${uuid}/clinical-note/create-new-note`);
    } catch (error) {
      console.error('Navigation error:', error);
    }
  };

  const handleNavigateToNoteId = () => {
    try {
      navigate(`/patient-profile/${uuid}/clinical-note/${selected.id}`);
    } catch (error) {
      console.error('Navigation error:', error);
    }
  };

  useEffect(() => {
    if (selectedToPrint) {
      handlePrint();
    }
  }, [selectedToPrint, handlePrint]);

  useEffect(() => {
    if (selected) {
      handleNavigateToNoteId();
    }
  }, [selected]);

  const closeIframe = () => {
    setIframeUrl('');
  };

  return (
    <Box width="100%">
      <Typography variant="h4" sx={{ fontWeight: 'bold', color: (theme) => theme.palette.text.secondary }}>
        {t('Clinical Notes')}
      </Typography>
      <Button variant="contained" disabled={!edit} sx={{ mb: 1 }} startIcon={<AddIcon />} onClick={handleNavigateToNewNoteCreation}>
        {t('Add')}
      </Button>
      <TableWrapper
        total={data?.total}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        maxHeight="80vh"
        isFetching={isFetching}
        colCount={columns.length + 1}
      >
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell key={column.id}>
                {column.label}
              </TableCell>
            ))}
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {isFetching ? (
            Array.from(new Array(rowsPerPage)).map((_, index) => (
              <TableRow key={index}>
                {columns.map((column, idx) => (
                  <TableCell key={idx}>
                    <Skeleton variant="text" />
                  </TableCell>
                ))}
                <TableCell>
                  <Skeleton variant="circular" width={24} height={24} />
                </TableCell>
              </TableRow>
            ))
          ) : data.total === 0 ? (
            <TableRow>
              <TableCell colSpan={columns.length + 1}>
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  height="200px"
                >
                  <NoData className={classes.noData} />
                </Box>
              </TableCell>
            </TableRow>
          ) : (
            data.entry.map((row) => {
              return (
                <Row
                  key={row.resource.id}
                  row={row}
                  columns={columns}
                  setSelected={setSelected}
                  setSelectedToPrint={setSelectedToPrint}
                  setOpenGenerateClinicalReportDialog={setOpenGenerateClinicalReportDialog}
                  setSelectedResource={setSelectedResource}
                  setOpenGeneratedReportMessage={setOpenGeneratedReportMessage}
                  setSnackbar={setSnackbar}
                />
              );
            })
          )}
        </TableBody>
      </TableWrapper>
      <Box sx={{ display: 'none' }}>
        <PrintableNote
          ref={printRef}
          note={selectedToPrint}
        />
      </Box>
      <Snackbar
        open={openGeneratedReportMessage}
        autoHideDuration={6000}
        onClose={handleCloseGeneratedReportMessage}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          onClose={handleCloseGeneratedReportMessage}
          severity={snackbar.severity}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
      <GenerateClinicalNoteReportDialog
        open={openGenerateClinicalReportDialog}
        handleClose={() => setOpenGenerateClinicalReportDialog(false)}
        resource={selectedResource}
        patientUuid={uuid}
        setOpenGeneratedReportMessage={setOpenGeneratedReportMessage}
        setSnackbar={setSnackbar}
        setIframeUrl={setIframeUrl}
      />
      <Dialog
        open={Boolean(iframeUrl)}
        onClose={closeIframe}
        fullScreen
        sx={{
          '& .MuiDialog-paper': { backgroundColor: 'rgba(0, 0, 0, 0)' },
        }}
      >
        <PdfViewer link={iframeUrl} setIframeUrl={setIframeUrl} id={extractPdfId(iframeUrl)} />
      </Dialog>
    </Box>
  );
};

function Row(props) {
  const {
    row,
    columns,
    setSelected,
    setSelectedToPrint,
    setSelectedResource,
    setOpenGenerateClinicalReportDialog
  } = props;
  const { t } = useTranslation();
  const [hover, setHover] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const menuOpen = Boolean(anchorEl);

  const handleMenuActions = (action) => {
    switch (action) {
      case 'edit':
        setSelected(row.resource);
        break;
      case 'print':
        setSelectedToPrint(row.resource);
        break;
      case 'generate-report':
        setSelectedResource(row.resource);
        setOpenGenerateClinicalReportDialog(true);
        break;
      default:
        break;
    }

    setAnchorEl(null);
  };

  const getEditOrViewLabel = (status) => {
    return status !== 'completed' ? t('Edit') : t('View');
  };

  return (
    <>
      <TableRow hover sx={{ height: 68 }} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
        {columns.map((column, idx) => (
          <TableCell
            key={idx}
            sx={{ maxWidth: '275px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
          >
            {column.format
              ? (() => {
                  const formattedValue = column.format(row.resource[column.id]);
                  return formattedValue.length > 200 ? `${formattedValue.slice(0, 200)}...` : formattedValue;
                })()
              : row.resource[column.id]}
          </TableCell>
        ))}
        <TableCell sx={{ minWidth: '35px' }}>
          {(hover || menuOpen) && (
            <>
              <IconButton onClick={(event) => setAnchorEl(event.currentTarget)} size="small">
                <MoreVertIcon />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                open={menuOpen}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
              >
                <MenuItem onClick={() => handleMenuActions('edit')}>{getEditOrViewLabel(row.resource.status)}</MenuItem>
                {row.resource.status === 'completed' && (
                  <MenuItem onClick={() => handleMenuActions('generate-report')}>{t('Generate report')}</MenuItem>
                )}{' '}
                <MenuItem onClick={() => handleMenuActions('print')}>{t('Print')}</MenuItem>
              </Menu>
            </>
          )}
        </TableCell>
      </TableRow>
    </>
  );
}

export default ClinicalNotePanel;
