import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import InfoIcon from '@mui/icons-material/Info';
import WarningIcon from '@mui/icons-material/Warning';
import {
  Box,
  Button,
  Fade,
  IconButton,
  Paper,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { computeJson } from '../../../../helper-functions/jsonCodeUtils';
import { conditionParser } from './utils';

export interface FeedbackData {
  level?: 'INFO' | 'ALERT' | 'SUCCESS';
  feedback: string;
  condition?: any;
  [key: string]: any;
}

/**
 * Utility function to determine if feedback should be shown based on its condition
 * @param feedback - The feedback object containing the condition
 * @param answers - The current answers to evaluate the condition against
 * @returns boolean indicating whether the feedback should be shown
 */
export const shouldShowFeedback = (
  feedback: FeedbackData,
  answers: Record<string, any>
): boolean => {
  if (!feedback) return false;
  
  // If no condition is provided, show the feedback
  if (!feedback.condition) return true;
  
  // Parse and evaluate the condition
  const parsedCondition = conditionParser(feedback.condition, answers);
  return computeJson(parsedCondition);
};

export interface FeedbackMessageProps {
  handleClose: (res?: string) => void;
  feedback: FeedbackData;
}

const FeedbackMessage: React.FC<FeedbackMessageProps> = React.memo((props) => {
  const {
    handleClose,
    feedback,
  } = props;
  const { t } = useTranslation();

  const feedbackLevel = feedback.level || 'INFO';
  const [open, setOpen] = React.useState(true);

  const handleCloseClick = (event: React.MouseEvent) => {
    event.preventDefault();
    handleClose();
  };


  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const getFeedbackStyles = (level: string) => {
    switch (level) {
      case 'SUCCESS':
        return {
          icon: <CheckCircleIcon sx={{ fontSize: 28 }} />,
          color: 'success.main',
          bgcolor: 'success.lighter',
          severity: 'success',
          buttonVariant: 'contained',
          buttonColor: 'success',
          chipColor: 'success',
          title: t('Success')
        };
      case 'ALERT':
        return {
          icon: <WarningIcon sx={{ fontSize: 28 }} />,
          color: 'warning.main',
          bgcolor: 'warning.lighter',
          severity: 'warning',
          buttonVariant: 'contained',
          buttonColor: 'warning',
          chipColor: 'warning',
          title: t('Important Notice')
        };
      case 'INFO':
      default:
        return {
          icon: <InfoIcon sx={{ fontSize: 28 }} />,
          color: 'primary.main',
          bgcolor: 'primary.lighter',
          severity: 'info',
          buttonVariant: 'contained',
          buttonColor: 'primary',
          chipColor: 'primary',
          title: t('Information')
        };
    }
  };

  const feedbackStyles = getFeedbackStyles(feedbackLevel);

  return (
    <Fade in timeout={600}>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        height="100%"
        width="100%"
        sx={{
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          backdropFilter: 'blur(5px)',
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: 1300,
          overflow: 'auto',
          p: { xs: 2, sm: 3 }
        }}
      >
        <Paper
          elevation={6}
          sx={{
            width: { xs: '95%', sm: '85%', md: '70%', lg: '60%' },
            maxWidth: 800,
            borderRadius: 3,
            overflow: 'hidden',
            animation: 'fadeInUp 0.5s ease-out',
            '@keyframes fadeInUp': {
              '0%': {
                opacity: 0,
                transform: 'translateY(20px)'
              },
              '100%': {
                opacity: 1,
                transform: 'translateY(0)'
              }
            },
            boxShadow: theme.shadows[10]
          }}
        >
          <Box
            sx={{
              p: { xs: 2, sm: 3 },
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              bgcolor: feedbackStyles.bgcolor,
              color: 'text.primary',
              borderBottom: `1px solid ${theme.palette.divider}`
            }}
          >
            <Box display="flex" alignItems="center">
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  mr: 2,
                  color: feedbackStyles.color,
                }}
              >
                {feedbackStyles.icon}
              </Box>
              <Typography variant="h6" fontWeight="600">
                {feedbackStyles.title}
              </Typography>
            </Box>
            <IconButton
              aria-label="close"
              size="medium"
              onClick={handleCloseClick}
              sx={{
                color: 'text.secondary',
                transition: 'all 0.2s',
                '&:hover': {
                  color: 'text.primary',
                  backgroundColor: 'rgba(0, 0, 0, 0.04)'
                }
              }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </Box>

          <Box
            sx={{
              p: { xs: 3, sm: 4 },
              backgroundColor: theme => theme.palette.background.paper,
            }}
          >
            <Box
              sx={{
                '& p': {
                  fontSize: { xs: '1rem', sm: '1.1rem' },
                  lineHeight: 1.7,
                  color: 'text.primary',
                  marginBottom: 2
                },
                '& h1, & h2, & h3, & h4, & h5, & h6': {
                  color: 'text.primary',
                  fontWeight: 600,
                  marginBottom: 2,
                  marginTop: 3,
                  '&:first-of-type': {
                    marginTop: 0
                  }
                },
                '& ul, & ol': {
                  paddingLeft: 3,
                  marginBottom: 2
                },
                '& li': {
                  marginBottom: 1
                },
                '& a': {
                  color: feedbackStyles.color,
                  textDecoration: 'none',
                  '&:hover': {
                    textDecoration: 'underline'
                  }
                },
                '& code': {
                  backgroundColor: theme => theme.palette.grey[100],
                  padding: '2px 4px',
                  borderRadius: 1,
                  fontFamily: 'monospace',
                  fontSize: '0.875em'
                },
                '& blockquote': {
                  borderLeft: `4px solid ${feedbackStyles.color}`,
                  paddingLeft: 2,
                  margin: '16px 0',
                  color: 'text.secondary',
                  fontStyle: 'italic'
                }
              }}
            >
              {feedback?.feedback && (
                <Markdown skipHtml remarkPlugins={[remarkGfm]}>
                  {feedback.feedback}
                </Markdown>
              )}
            </Box>

            <Box
              display="flex"
              justifyContent="flex-end"
              sx={{
                mt: 4,
                pt: 3,
                borderRadius: '0 0 16px 16px'
              }}
            >
              <Button
                variant={feedbackStyles.buttonVariant as 'contained' | 'outlined' | 'text'}
                color={feedbackStyles.buttonColor as 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning'}
                onClick={handleCloseClick}
                size="large"
                endIcon={<DoneIcon />}
                sx={{
                  minWidth: 120,
                  px: 3,
                  py: isMobile ? 1 : 1.5,
                  borderRadius: 2,
                  fontSize: { xs: '0.9rem', sm: '1rem' },
                  fontWeight: 600,
                  boxShadow: 2,
                  transition: 'all 0.2s ease-in-out',
                  '&:hover': {
                    transform: 'translateY(-2px)',
                    boxShadow: 3
                  }
                }}
              >
                {t('Close')}
              </Button>
            </Box>
          </Box>
        </Paper>
      </Box>
    </Fade>
  );
});

export default FeedbackMessage;
