import { Paper } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { useSelector } from 'react-redux';
import { greyboxApiActions } from '../../../redux/api';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import type { ApexOptions } from 'apexcharts';

interface CompletedQuestionnairesChartProps {
  patientUuid: string;
}

interface CalendarEvent {
  questionnaire?: {
    name: string;
  };
  records?: {
    records: unknown[];
    percentage: number;
  };
}

interface RootState {
  patientProfileDateRange: {
    startDate: string;
  };
  clinic: {
    id: string;
  };
  // Add other state properties as needed
}

const CompletedQuestionnairesChart = ({ patientUuid }: CompletedQuestionnairesChartProps) => {
  const { t } = useTranslation();
  const { startDate } = useSelector((state: RootState) => state.patientProfileDateRange);
  const { clinic } = useSelector((state: RootState) => state.clinic);
  const { calendarEvent } = greyboxApiActions;

  const [parsedData, setParsedData] = useState<ApexAxisChartSeries>([]);
  const [xaxisCategories, setXaxisCategories] = useState<string[]>([]);

  const { data = [] } = calendarEvent.list<CalendarEvent[]>({
    owner: patientUuid,
    start_datetime__gte: moment(startDate).toISOString(),
    clinic: clinic.id,
  });

  const parseDataPerQuestionnaire = (events: CalendarEvent[]): ApexAxisChartSeries => {
    const questionnaires = [
      { name: t('Scheduled'), data: [] as number[] },
      { name: t('Completed'), data: [] as number[] },
    ];

    const uniqueCategories = new Set<string>(xaxisCategories);

    events.forEach((item) => {
      if (!item.questionnaire?.name || !item.records) return;

      const total = item.records.records.length;
      const completed = (item.records.percentage * total) / 100;
      const remaining = total - completed;

      uniqueCategories.add(item.questionnaire.name);

      questionnaires[1].data.push(Math.round(completed));
      questionnaires[0].data.push(Math.round(remaining));
    });

    setXaxisCategories(Array.from(uniqueCategories));
    return questionnaires;
  };

  useEffect(() => {
    if (data.length > 0) {
      setParsedData(parseDataPerQuestionnaire(data));
    }
  }, [data]);

  const chartOptions: ApexOptions = useMemo(() => ({
    chart: {
      type: 'bar',
      stacked: true,
      height: 350,
      zoom: {
        enabled: true,
        type: 'x',
        autoScaleYaxis: true,
        allowMouseWheelZoom: false,
      }
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '55%',
        endingShape: 'rounded'
      },
    },
    dataLabels: {
      enabled: false,
    },
    yaxis: {
      title: {
        text: t('Number of Questionnaires'),
      },
      labels: {
        formatter: (val: number) => val.toFixed(0)
      }
    },
    xaxis: {
      categories: xaxisCategories,
    },
    tooltip: {
      y: {
        formatter: (val: number) => 
          `${val.toFixed(0)} ${val === 1 ? t('questionnaire') : t('questionnaires')}`
      }
    },
  }), [xaxisCategories, t]);

  return (
    <Paper sx={{ p: 2 }}>
      <ReactApexChart
        options={chartOptions}
        series={parsedData}
        type="bar"
        height={350}
      />
    </Paper>
  );
};

export default CompletedQuestionnairesChart;