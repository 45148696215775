import React from 'react';
import GradationPanel from './GradationPanel';
import MultipleChoicesPanel from './MultipleChoicesPanel';
import SimpleAnswerPanel from './SimpleAnswerPanel';
import SubQuestionnaire from './SubQuestionnaire';
import TrueFalsePanel from './TrueFalsePanel';
import { Question, Answers } from '../../../../types';
import MediaAnswerPanel from './MediaAnswerPanel';

type Props = {
  question: Question;
  questions: Question[];
  isCurrent: boolean;
  setAnswers: (id: number, answer: string) => void;
  setOthers: (id: number, answer: string) => void;
  setHasUnsaved: (hasUnsaved: boolean) => void;
  answers: Answers;
};

const PanelSelector = (props: Props) => {
  const {
    question, isCurrent, setOthers, questions,
  } = props;
  const type = question.type_of_q;

  if (type === 'RA') {
    return (
      <GradationPanel {...props} />
    );
  }

  if (type === 'TF') {
    return (
      <TrueFalsePanel {...props} />
    );
  }

  if (type === 'MA') {
    return (
      <MediaAnswerPanel {...props} />
    );
  }

  if (type === 'MC') {
    return (
      <MultipleChoicesPanel
        {...props}
      />
    );
  }

  if (type === 'QA') {
    if (isCurrent && question.qa_question) {
      return (
        <SubQuestionnaire
          questionnaireId={question.qa_question.questionnaire}
          {...props}
        />
      );
    }
    return null;
  }

  return (
    <SimpleAnswerPanel {...props} />
  );
};

export default PanelSelector;
