import { MsalProvider } from '@azure/msal-react';
import {
  Alert, Button, Snackbar, CircularProgress, Box,
} from '@mui/material';
import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { hot } from 'react-hot-loader/root';
import { useDispatch, useSelector } from 'react-redux';
import ErrorFallback from './pages/ErrorFallback';
import PageSelector from './pages/PageSelector';
import { setSnackbarStatus } from './redux/snackbarStatus';
import { useUpdateCheck } from 'react-update-notification';
import { useTranslation } from 'react-i18next';
import NetworkStatusModal from './components/utils/NetworkStatusModal';
import { appInsights } from './appInsights';

/**
 * Main application component. This is where we make sure the user is authenticated or not.
 * Only show Appbar and navigation bar when authenticated.
 */
const App = (props) => {
  const { instance } = props;
  const snackbarStatus = useSelector((state) => state.snackbarStatus);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleSnackBarClose = () => {
    dispatch(setSnackbarStatus({ open: false, message: '' }));
  };

  const { status, reloadPage } = useUpdateCheck({
    type: 'interval',
    interval: 30000,
  });

  return (
    <MsalProvider instance={instance}>
        <ErrorBoundary
          FallbackComponent={ErrorFallback}
          onError={(error, errorInfo) => {
            // Log to console for development debugging
            console.error({ error, errorInfo });
            
            // Track exception in AppInsights with comprehensive details
            appInsights.trackException({
              exception: error,
              properties: {
                componentStack: errorInfo?.componentStack,
                message: error.message,
                stack: error.stack,
                url: window.location.href,
                userAgent: navigator.userAgent,
                timestamp: new Date().toISOString(),
              },
              severityLevel: 3, // Error level
            });
          }}
        >
          <NetworkStatusModal />
          <PageSelector />
          {snackbarStatus.open && (
            <Snackbar
              open={snackbarStatus.open}
              autoHideDuration={5000}
              onClose={handleSnackBarClose}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            >
              <Alert onClose={handleSnackBarClose} severity={snackbarStatus.severity}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  {snackbarStatus.showSpinner && (
                    <CircularProgress size={20} sx={{ mr: 1 }} />
                  )}
                  {t(snackbarStatus.message)}
                </Box>
              </Alert>
            </Snackbar>
          )}
          {(status === 'available') && (
             <Snackbar
             open
             autoHideDuration={null}
             anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
           >
             <Alert
               severity="info"
               action={
                 <Button color="inherit" size="small" onClick={reloadPage}>
                   {t('Refresh')}
                 </Button>
               }
             >
               {t('A new version of the application is available.')}
             </Alert>
           </Snackbar>
          )}
        </ErrorBoundary>
    </MsalProvider>
  );
};

export default hot(App);
