import { createSlice } from '@reduxjs/toolkit';

const snackbarStatus = createSlice({
  name: 'snackbarStatus',
  initialState: {
    open: false,
    message: '',
    severity: 'info',
    showSpinner: false,
  },
  reducers: {
    setSnackbarStatus: (state, action) => {
      state.open = action.payload.open;
      state.message = action.payload.message;
      state.severity = action.payload.severity;
      state.showSpinner = action.payload.showSpinner !== undefined ? action.payload.showSpinner : false;
    },
  },
});

export const { setSnackbarStatus } = snackbarStatus.actions;
export default snackbarStatus.reducer;
