import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import WarningIcon from '@mui/icons-material/Warning';
import { Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import QuestionnaireAnswersFullscreen from './QuestionnaireAnswersFullscreen';

const isHex = (color) => /(^#[0-9A-F]{6}$)|(^#[0-9A-F]{3}$)/i.test(color);

const colorMapping = (colorName) => {
  switch (colorName) {
    case 'Red':
      return 'error';
    case 'Yellow':
      return 'warning';
    case 'Green':
      return 'success';
    case 'Blue':
      return 'info';
    default:
      return 'info';
  }
};

const useStyles = makeStyles(() => ({
  custom: ({ color }) => ({
    borderColor: color,
    color: color,
  }),
}));

const QuestionnaireSummaryButton = (props) => {
  const { i18n, t } = useTranslation();
  const { recordId, date, score } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const location = useLocation();

  // Safely access display color
  const displayColor = score?.display?.[0];
  const classes = displayColor ? useStyles(displayColor) : useStyles();

  let className = '';
  let color = 'secondary';
  let text = '';
  let logo = null;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    event.stopPropagation();
  };

  const handleClose = (event) => {
    setAnchorEl(null);
    event.stopPropagation();
  };

  if (!score) {
    // Handle missing score
    text = location.pathname.includes('/dashboard')
      ? moment(date).format('ll')
      : t('Show answers');
  } else if (displayColor) {
    // Handle display color configuration
    text = displayColor.value?.[i18n.resolvedLanguage] || '---';
    
    if (isHex(displayColor.color)) {
      className = classes.custom;
    } else {
      color = colorMapping(displayColor.color);
    }
  } else if (score.threshold_status) {
    // Handle threshold status
    const thresholdStatus = score.threshold_status || {};
    text = moment(date).format('ll');
    logo = <WarningIcon />;
    color = 'Range_1' in thresholdStatus 
      ? 'error' 
      : 'Range_2' in thresholdStatus 
      ? 'warning' 
      : 'info';
  } else {
    // Default case
    text = moment(date).format('ll');
    logo = <CheckCircleOutlineIcon />;
    color = 'success';
  }

  return (
    <>
      <Button
        className={className}
        color={color}
        variant="outlined"
        startIcon={logo}
        size="small"
        onClick={handleClick}
      >
        {text}
      </Button>

      {open && (
        <QuestionnaireAnswersFullscreen
          recordId={recordId}
          handleClose={handleClose}
        />
      )}
    </>
  );
};

QuestionnaireSummaryButton.propTypes = {
  recordId: PropTypes.number.isRequired,
  date: PropTypes.string.isRequired,
  score: PropTypes.object,
};

export default QuestionnaireSummaryButton;