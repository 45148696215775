import LoadingButton from '@mui/lab/LoadingButton';
import {
  Box,
  Button,
  IconButton,
  Typography,
  Skeleton
} from '@mui/material';
import { Add } from '@mui/icons-material';
import React, { useMemo } from 'react';
import { greyboxApiActions } from '../../../redux/api';
import { useTranslation } from 'react-i18next';
import ContactForm from './ContactForm';
import { Formik, Form, FieldArray } from 'formik';
import { useParams } from 'react-router-dom';

const apiToForm = (data) => ({
  id: data.id,
  firstName: data?.name?.given[0],
  lastName: data?.name?.family,
  telecom: data?.telecom,
  relationship: data.relationship ? data.relationship[0].coding[0].code : '',
});

const ContactTab = () => {
  const { uuid } = useParams();
  const { account } = greyboxApiActions;
  const { data, isLoading } = account.get(uuid);
  const { t } = useTranslation();
  const [updateContact] = account.update();

  const permissions = useMemo(() => data?.permissions || [], [data]);
  const canEdit = !isLoading && permissions.includes('change_localaccount');

  if (isLoading) {
    return (
      <Box sx={{ mx: 1 }}>
        <Skeleton variant="text" width={100} height={40} sx={{ mb: 2 }} />
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(2, 1fr)',
            gap: 2,
          }}
        >
          {[0, 1].map((i) => (
            <Skeleton key={i} variant="rounded" height={200} />
          ))}
        </Box>
      </Box>
    );
  }

  const initialValues = {
    contact: [...(data?.contact?.map(apiToForm) || [])],
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    const body = { contact: [] };
    values.contact.forEach((item, index) => {
      body.contact.push({
        name: {
          given: [item.firstName],
          family: item.lastName,
        },
        relationship: [
          {
            coding: [
              {
                code: item.relationship,
              },
            ],
          },
        ],
        telecom: item.telecom,
      });

      if (item.id) {
        body.contact[index].id = item.id;
      }
    });

    await updateContact({ id: uuid, body });
    setSubmitting(false);
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      onSubmit={handleSubmit}
    >
      {({ values, isSubmitting, resetForm, dirty }) => (
        <Form>
          <FieldArray
            name="contact"
            render={({ insert, remove, push }) => (
              <>
                <Box sx={{ display: 'flex', alignItems: 'center', mx: 1 }}>
                  <Typography variant="h5">
                    {t('Contacts')}
                  </Typography>
                  {canEdit && (
                    <IconButton
                      size="small"
                      sx={{ ml: 1 }}
                      onClick={() =>
                        push({
                          firstName: '',
                          lastName: '',
                          telecom: [],
                          relationship: '',
                        })
                      }
                    >
                      <Add />
                    </IconButton>
                  )}
                </Box>
                <Box
                  sx={{
                    mt: 2,
                    display: 'grid',
                    gridTemplateColumns: 'repeat(2, 1fr)',
                    gap: 2,
                  }}
                >
                  {values.contact.map((contact, index) => (
                    <ContactForm
                      key={index}
                      contact={contact}
                      removeContact={remove}
                      index={index}
                      disabled={!canEdit}
                    />
                  ))}
                </Box>
              </>
            )}
          />
          {canEdit && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                mt: 2,
                gap: 1,
              }}
            >
              <Button
                variant="contained"
                onClick={() => resetForm()}
                disabled={!dirty || isSubmitting}
              >
                {t('Reset')}
              </Button>
              <LoadingButton
                type="submit"
                variant="contained"
                loading={isSubmitting}
              >
                {t('Save')}
              </LoadingButton>
            </Box>
          )}
        </Form>
      )}
    </Formik>
  );
};

export default ContactTab;
