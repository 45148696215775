import { LoadingButton } from '@mui/lab';
import {
  Box, Button, Dialog, DialogActions, DialogContent, Alert,
} from '@mui/material';
import Link from '@mui/material/Link';
import { useFormik } from 'formik';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import * as Yup from 'yup';
import { greyboxApiActions } from '../../redux/api';
import { ALERT_TRIGGER, ALERT_TRIGGER_URL, ALERT_CONFIGS } from './utils';
import DialogTitle from '../DialogTitle';
import MedicalAct from '../form-inputs/MedicalAct';
import {
  AboveThreshold,
  AlertBlue,
  BelowThreshold,
  Table,
  Tooltips,
} from '../tk-ui';
import QuestionnaireSummaryButton from '../patient-profile/questionnaire/QuestionnaireSummaryButton';
import TableSkeleton from '../skeletons/TableSkeleton';
import QuestionnaireLabel from './QuestionnaireLabel';

const getLevelColor = (level) => {
  switch (level) {
    case 'High':
      return 'red';
    case 'Moderate':
      return 'orange';
    case 'Low':
      return '#17a2b8';
    default:
      return 'grey';
  }
};

const AlertModal = ({
  open,
  patient,
  handleClose,
  link = true,
}) => {
  const { t, i18n } = useTranslation();
  const { alert, medicalMeasurementConfig } = greyboxApiActions;

  const { clinic } = useSelector((state) => state.clinic);
  const { data = [], isFetching, isError, refetch } = alert.list(
    { patient: patient.uuid, status: 'active', clinic: clinic.id },
    { skip: !open },
  );
  const measurementConfig = medicalMeasurementConfig.list({ clinic: clinic.id });
  const [clearAlert] = alert.update();

  const getUrl = (trigger) => {
    const shortCode = measurementConfig.data.find(
      (item) => item.threshold_code === trigger,
    ).short_code;

    if (shortCode) {
      return `/patient-profile/${patient.uuid}/vitals/${shortCode}`;
    }
    return '';
  };

  const columns = [{
    id: 'alert_trigger',
    label: t('Alerts to be cleared'),
    minWidth: 100,
    format: (value, row) => (
      <Box display="flex" alignItems="center">
        {link && row.alert_trigger in ALERT_TRIGGER_URL ? (
            <Link
              to={getUrl(row.alert_trigger)}
              component={RouterLink}
            >
              {ALERT_TRIGGER[row.alert_trigger]}
            </Link>
          ) : (
            ALERT_TRIGGER[row.alert_trigger] || 
            (row.alert_trigger === 'RULE' && row.rule?.name) || 
            row.alert_trigger || 
            t('Unknown Trigger')
          )
        }
        {row.alert_type === 'MC'
          && row.alert_detail[`medication_history_medication_name_${i18n.resolvedLanguage}`]
          && (
            <Tooltips
              text={row.alert_detail[`medication_history_medication_name_${i18n.resolvedLanguage}`]}
              delayTime={50}
            >
              <Box marginLeft="auto" width="24px" height="24px">
                <AlertBlue viewBox="0 -5 30 40" />
              </Box>
            </Tooltips>
          )}
      </Box>
    ),
  },
  {
    id: 'alert_type',
    label: t('Type'),
    minWidth: 100,
    format: (value, row) => {
      if (value === 'HR') {
        return <QuestionnaireLabel answerId={row.alert_detail.value_reference} />;
      }
      else if(value === 'RL') {
        return row.rule.description;
      }
      else {
        return ALERT_CONFIGS.alert.types[value].label;
      }
     
    },
  },
  {
    id: 'alert_detail',
    label: t('Value'),
    minWidth: 100,
    format: (value, row) => (
      <Box display="flex" alignItems="center">
        {
          row.alert_trigger === 'DQ' ? (
            <QuestionnaireSummaryButton
              recordId={row.alert_info[0].id}
              score={row.alert_info[0].score}
              date={row.create_date}
            />
          ) : `${value.value} ${value.unit}`
        }
        {value.threshold_status && (
          <Box marginLeft="auto" display="flex" alignItems="center">
            {Object.keys(value.threshold_status).map((key) => {
              if (key.startsWith('Range_')) {
                const status = value.threshold_status[key];
                const level = key === 'Range_1' ? 'High' : key === 'Range_2' ? 'Moderate' : 'Low';
                const color = getLevelColor(level);
                return (
                  <Box key={key} display="flex" alignItems="center" sx={{ color, marginLeft: 1 }}>
                    {status === 'above' ? <AboveThreshold /> : <BelowThreshold />}
                    <Box component="span" sx={{ marginLeft: 0.5 }}>
                      {t(level)}
                    </Box>
                  </Box>
                );
              }
              return null;
            })}
          </Box>
        )}
      </Box>
    ),
  },
  {
    id: 'create_date',
    label: t('Date'),
    minWidth: 100,
    format: (value) => moment(value).format('YYYY-MM-DD hh:mm'),
  }];

  const formik = useFormik({
    initialValues: {
      medical_act_type: '',
      note: '',
      start_datetime: null,
      end_datetime: null,
      alerts: data.map((item) => item.uuid),
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      medical_act_type: Yup.string().required(t('Required')),
      note: Yup.string(),
      start_datetime: Yup.date().nullable(),
      end_datetime: Yup.date().nullable(),
      alerts: Yup.array().required(t('Required')),
    }),
    onSubmit: (values) => {
      const { alerts, ...linkedMedicalActData } = values;

      clearAlert({
        id: 'bulk',
        body: {
          alerts: alerts.map((id) => ({
            uuid: id,
            status: 'cleared',
          })),
          linked_medical_act: {
            medical_act_type: linkedMedicalActData.medical_act_type,
            patient: patient.uuid,
            note: linkedMedicalActData.note,
            clinic: clinic.id,
            ...linkedMedicalActData,
          },
        },
      }).then(() => {
        handleClose(true);
      });
    },
  });

  const handleClick = (e) => {
    e.stopPropagation();
  };

  return (
    <Dialog
      open={open}
      onClose={(e) => {
        e.stopPropagation();
        handleClose();
      }}
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle onClose={(e) => {
        e.stopPropagation();
        handleClose();
      }}
      >
        {t('Record follow-up')}
        {link && (
          <>
            :
            <Link
              underline="none"
              component={RouterLink}
              onClick={(e) => e.stopPropagation()}
              to={`/patient-profile/${patient.uuid}/overview`}
            >
              {` ${patient.firstName} ${patient.lastName}`}
            </Link>
          </>
        )}
      </DialogTitle>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent onClick={handleClick}>
          {isFetching && <TableSkeleton />}
          {isError && (
            <Alert severity="error" onClick={refetch}>
              {t('Failed to load alerts. Please try again.')}
            </Alert>
          )}
          {!isFetching && !isError && (
            <>
              <Table
                selected={formik.values.alerts}
                setSelected={(value) => formik.setFieldValue('alerts', value)}
                rowId="uuid"
                columns={columns}
                rows={data}
              />
              <MedicalAct formik={formik} />
            </>
          )}
        </DialogContent>
        <DialogActions onClick={handleClick}>
          <Button
            onClick={(e) => {
              e.stopPropagation();
              handleClose();
            }}
            disabled={formik.isSubmitting || isFetching || isError}
          >
            {t('Cancel')}
          </Button>
          <LoadingButton 
            type="submit" 
            loading={formik.isSubmitting} 
            disabled={isFetching || isError}
          >
            {t('Record')}
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

AlertModal.propTypes = {
  open: PropTypes.bool.isRequired,
  patient: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
  link: PropTypes.bool,
};

export default AlertModal;
