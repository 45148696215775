import React from 'react';
import { useTranslation } from 'react-i18next';
import { Autocomplete, TextField } from '@mui/material';
import { useSelector } from 'react-redux';
import { useDebounce } from 'use-debounce';
import { greyboxApiActions } from '../../redux/api';
import { Label } from '../../types';
import { RootState } from '../../redux/store';

// Extended Label type to include the specialOption property
interface ExtendedLabel extends Label {
  specialOption?: boolean;
}

interface PropsType {
  formik: any;
  sx?: any;
  required?: boolean;
  disabled?: boolean;
}

const Labels = (props: PropsType) => {
  const { t } = useTranslation();
  const { label } = greyboxApiActions as any;
  const { clinic } = useSelector((state: RootState) => state.clinic);
  const { formik, disabled } = props;
  const [searchInput, setSearchInput] = React.useState('');
  const [debouncedSearch] = useDebounce(searchInput, 300);
  const [addLabel, results] = label.add();
  const [selectedLabels, setSelectedLabels] = React.useState<ExtendedLabel[]>([]);
  const [open, setOpen] = React.useState(false);

  const { data, isFetching } = label.list({
    is_root_node: true,
    clinic: clinic?.id,
    name__icontains: debouncedSearch,
    system: false,
  }, { skip: debouncedSearch === '' || !clinic });

  const availableOptions = data?.filter(
    (opt: ExtendedLabel) => !selectedLabels.some((l: ExtendedLabel) => l.id === opt.id)) || [];

  const adjustedOptions = debouncedSearch && data?.length === 0 ? (
    [...availableOptions, {
      specialOption: true,
      name: debouncedSearch,
      id: `new-${debouncedSearch}`,
      children: [],
      clinic: clinic?.id || 0,
      system: false,
      parent: '',
      level: 0
    } as ExtendedLabel]
  ) : availableOptions;

  React.useEffect(() => {
    setSelectedLabels(formik.values.labels);
  }, [formik.values.labels]);

  React.useEffect(() => {
    setSearchInput('');
    setOpen(false);
  }, [formik.isSubmitting]);

  return (
    <Autocomplete
      multiple
      sx={{ mx: 1 }}
      value={selectedLabels}
      onBlur={() => setOpen(false)}
      options={adjustedOptions}
      isOptionEqualToValue={(option: ExtendedLabel, value: ExtendedLabel) => option.id === value.id}
      loading={isFetching || results.isLoading}
      open={open}
      disabled={disabled}
      onOpen={() => setOpen(true)}
      getOptionLabel={(option: any) => {
        if (!option) return '';
        if (option.specialOption) {
          return `${debouncedSearch} (${t('Create new label')})`;
        }
        return option.name;
      }}
      onChange={(event, value) => {
        const newValue = value[value.length - 1];
        if (newValue?.specialOption) {
          if (!clinic) return;
          
          addLabel({
            body: {
              name: debouncedSearch,
              clinic: clinic.id,
            },
          }).then((response: any) => {
            const newLabel: Label = response.data;
            setSearchInput('');
            setOpen(false);
            formik.setFieldValue('labels', [...value.slice(0, -1), newLabel]);
          });
        } else {
          formik.setFieldValue('labels', value.map((l) => l));
        }
      }}
      renderInput={(params) => <TextField {...params} onChange={(e) => setSearchInput(e.target.value)} label={t('Labels')} />}
    />
  );
};

Labels.defaultProps = {
  required: false,
  sx: null,
};

export default Labels;
