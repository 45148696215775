import React, { FC, useEffect, useState } from 'react';
import { Box, TextField, SxProps, Theme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import InputMask from 'react-input-mask';
import * as Yup from 'yup';
import i18n from '../../i18n';

interface HinProps {
  formik: any;
  disabled?: boolean;
  sx?: SxProps<Theme>;
}

const Hin: FC<HinProps> & { validationSchema: Yup.AnyObjectSchema } = ({ 
  formik, 
  disabled = false, 
  sx
}) => {
  const { values, errors, touched, setFieldValue } = formik;
  const { t } = useTranslation();

  const [expDateDisplay, setExpDateDisplay] = useState('');

  useEffect(() => {
    if (values.hin_exp) {
      const parts = values.hin_exp.split('-');
      if (parts.length >= 2) {
        setExpDateDisplay(`${parts[0]} ${parts[1]}`);
      }
    } else {
      setExpDateDisplay('');
    }
  }, [values.hin_exp]);

  const handleHinNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const rawValue = value.replace(/\s/g, '').toUpperCase();
    setFieldValue(name, rawValue);

    validateField(name, rawValue);
  };

  const handleHinExpChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setExpDateDisplay(value);
    validateField('hin_exp', value);
  
    const trimmedValue = value.trim();
    if (!trimmedValue) {
      setFieldValue('hin_exp', null);
      formik.setFieldError('hin_exp', getValidationMessages().datePartialInvalid);
      return;
    }
  
    const match = trimmedValue.match(/^(\d{4})\s(\d{2})$/);
    if (!match) {
      formik.setFieldError('hin_exp', getValidationMessages().datePartialInvalid);
      return;
    }
  
    const [, year, month] = match;
  
    if (!/^(0[1-9]|1[0-2])$/.test(month)) {
      formik.setFieldError('hin_exp', getValidationMessages().dateInvalid);
      return;
    }
  
    const lastDay = new Date(Number(year), Number(month), 0);
    const isoDate = lastDay.toISOString().split('T')[0];
  
    setFieldValue('hin_exp', isoDate);
    formik.setFieldTouched('hin_exp', true);
  };
  
  
  

  const validateField = (fieldName: string, value: any) => {
    try {
      const fieldSchema = Yup.reach(Hin.validationSchema, fieldName);
      fieldSchema.validateSync(value);
      formik.setFieldError(fieldName, undefined);
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        formik.setFieldError(fieldName, error.message);
      }
    }
  };

  const handleExpKeyDown = (e: React.KeyboardEvent) => {
    if (
      (e.key === 'Backspace' || e.key === 'Delete') &&
      (expDateDisplay.length === 1 || expDateDisplay.match(/^\s*$/))
    ) {
      e.preventDefault();
      setExpDateDisplay('');
      setFieldValue('hin_exp', null);
    }
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'start', width: '100%', ...sx }}>
      <Box flex={3} px={1}>
        <InputMask
          mask="aaaa 9999 9999"
          maskChar=""
          value={values.hin_number || ''}
          onChange={handleHinNumberChange}
          onFocus={(e) => e.target.select()}
          onBlur={() => formik.setFieldTouched('hin_number', true)}
          disabled={disabled}
        >
          {(inputProps: any) => (
            <TextField
              {...inputProps}
              name="hin_number"
              label={t('Health Insurance Number')}
              placeholder="ABCD 1234 5678"
              error={Boolean(touched.hin_number && errors.hin_number)}
              helperText={touched.hin_number && errors.hin_number}
              fullWidth
            />
          )}
        </InputMask>
      </Box>

      <Box flex={1} pr={1}>
        <InputMask
          mask="9999 99"
          maskChar=""
          value={expDateDisplay}
          onChange={handleHinExpChange}
          onFocus={(e) => e.target.select()}
          onBlur={() => formik.setFieldTouched('hin_exp', true)}
          onKeyDown={handleExpKeyDown}
          alwaysShowMask={false}
          disabled={disabled}
        >
          {(inputProps: any) => (
            <TextField
              {...inputProps}
              label={t('Expiration Date')}
              placeholder="YYYY MM"
              error={Boolean(touched.hin_exp && errors.hin_exp)}
              helperText={touched.hin_exp && errors.hin_exp}
              fullWidth
            />
          )}
        </InputMask>
      </Box>
    </Box>
  );
};

const HIN_REGEX = /^[A-Za-z]{4}\d{8}$/;
const DATE_REGEX = /^\d{4}-\d{2}-\d{2}$/;

const getValidationMessages = () => ({
  hinInvalid: i18n.t('Invalid health insurance number format. Must be 4 letters followed by 8 digits'),
  hinLetters: i18n.t('First 4 characters must be letters'),
  hinNumbers: i18n.t('Last 8 characters must be numbers'),
  dateInvalid: i18n.t('Invalid expiration date format. Must be YYYY-MM'),
  datePartialInvalid: i18n.t('Please enter a valid date in YYYY MM format'),
  dateFuture: i18n.t('Expiration date must be in the future'),
});

Hin.validationSchema = Yup.object({
  hin_number: Yup.string()
    .nullable()
    .test('format-check', getValidationMessages().hinInvalid, (value) => {
      if (!value || value === '') return true;
      return HIN_REGEX.test(value);
    })
    .test('letters-check', getValidationMessages().hinLetters, (value) => {
      if (!value || value === '') return true;
      return /^[A-Za-z]{4}/.test(value);
    })
    .test('numbers-check', getValidationMessages().hinNumbers, (value) => {
      if (!value || value === '') return true;
      if (value.length < 5) return true;
      return /\d{8}$/.test(value);
    }),

  hin_exp: Yup.string()
    .nullable()
    .test('is-valid-date', getValidationMessages().dateInvalid, (value) => {
      if (!value) return true;
      return DATE_REGEX.test(value) && !isNaN(Date.parse(value));
    })
    .test('is-future-date', getValidationMessages().dateFuture, (value) => {
      if (!value) return true;
      if (!DATE_REGEX.test(value) || isNaN(Date.parse(value))) return true;

      const expirationDate = new Date(value);
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      return expirationDate >= today;
    }),
});

export default Hin;