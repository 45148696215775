import {
  Box,
  FormControl,
  FormLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Grid,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { greyboxApiActions } from '../../redux/api';
import React from 'react';
import InputMask from 'react-input-mask';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

export const Identifiers = ({ informations, clinic, role, identifiers: initialIdentifiers, disabled }) => {
  const { t } = useTranslation();
  const { identifierConfig } = greyboxApiActions;
  const { data } = identifierConfig.list({
    clinic: clinic?.id,
    reference_fhir_fields: role === 'PT' ? ['Practitioner.identifier'] : ['Patient.identifier'],
  });
  const identifiers = data || initialIdentifiers;

  // Updated validation schema to make regex validation optional
  Identifiers.validationSchema = Yup.array().of(
    Yup.object().shape({
      value: Yup.string()
        .required(t('Required'))
        .test('validate-identifier', t('Invalid value format'), function (value) {
          const { path, parent } = this;

          // Check if system is provided
          if (!parent.system) {
            return this.createError({
              path,
              message: t('System is required'),
            });
          }

          // Find the selected identifier configuration
          const selectedIdentifier = identifiers?.find(
            (identifier) =>
              identifier.system === parent.system && 
              (
                // If type is null, just match by system
                !parent.type ? true : 
                // Otherwise, match by type.coding.code
                parent.type.coding?.some((coding) => coding.code === identifier.code)
              )
          );

          // If we don't find a matching identifier but have a system value, that's okay
          // We'll use a text field to display it instead
          if (!selectedIdentifier && !parent.system) {
            return this.createError({
              path,
              message: t('System is required'),
            });
          }

          // Only validate against regex if one is provided
          if (selectedIdentifier && selectedIdentifier.regex) {
            try {
              const regex = new RegExp(selectedIdentifier.regex);
              if (!regex.test(value)) {
                return this.createError({
                  path,
                  message: t('Invalid value format'),
                });
              }
            } catch (error) {
              console.error('Invalid regex pattern:', error);
              // Continue without regex validation if the regex is invalid
            }
          }

          return true;
        }),
      system: Yup.string().required(t('Required')),
      type: Yup.object().shape({
        coding: Yup.array().of(
          Yup.object().shape({
            system: Yup.string().required(t('Required')),
            code: Yup.string().required(t('Required')),
          })
        ),
      }),
    })
  );

  const handleIdentifierDropdownChange = (index, identifierObject) => {
    const updatedIdentifiers = [...informations.values.identifier];
    updatedIdentifiers[index] = {
      ...updatedIdentifiers[index],
      system: identifierObject.system,
      value: '',
      // Only set type if code is available
      ...(identifierObject.code && {
        type: {
          coding: [
            {
              system: identifierObject.system,
              code: identifierObject.code,
            },
          ],
        }
      }),
    };
    informations.setFieldValue('identifier', updatedIdentifiers);
  };

  const handleIdentifierValueChange = (index, field, value) => {
    // Convert to uppercase if it's a string value
    const updatedValue =
      field === 'value' && typeof value === 'string' ? value.replace(/[a-z]/g, (match) => match.toUpperCase()) : value;

    const updatedIdentifiers = [...informations.values.identifier];
    updatedIdentifiers[index] = {
      ...updatedIdentifiers[index],
      [field]: updatedValue,
    };

    informations.setFieldValue('identifier', updatedIdentifiers);
  };

  const handleRemoveIdentifier = (index) => {
    const updatedIdentifiers = [...informations.values.identifier];
    updatedIdentifiers.splice(index, 1);
    informations.setFieldValue('identifier', updatedIdentifiers);
  };

  const handleAddIdentifier = () => {
    const updatedIdentifiers = [...informations.values.identifier];
    updatedIdentifiers.push({
      system: '',
      value: '',
      // Type can be null - we'll set it when an identifier is selected
      type: null
    });
    informations.setFieldValue('identifier', updatedIdentifiers);
  };

  const renderValueInput = (id, index, selectedIdentifier) => {
    const hasError = Boolean(informations.errors?.identifier?.[index]?.value);
    const errorMessage = informations.errors?.identifier?.[index]?.value;
    
    // Only use InputMask if a mask is provided
    if (selectedIdentifier?.mask) {
      return (
        <InputMask
          mask={selectedIdentifier.mask}
          value={id.value || ''}
          onChange={(e) => handleIdentifierValueChange(index, 'value', e.target.value)}
          onFocus={(e) => e.target.select()}
          maskChar=""
          inputMode="text"
          required
        >
          {(inputProps) => (
            <TextField
              {...inputProps}
              label={t('ID number')}
              placeholder={selectedIdentifier.place_holder}
              error={hasError}
              helperText={errorMessage}
              fullWidth
            />
          )}
        </InputMask>
      );
    }
    
    // Regular TextField if no mask is provided
    return (
      <TextField
        label={t('ID number')}
        placeholder={selectedIdentifier?.place_holder}
        value={id.value || ''}
        onChange={(e) => handleIdentifierValueChange(index, 'value', e.target.value)}
        onFocus={(e) => e.target.select()}
        error={hasError}
        helperText={errorMessage}
        fullWidth
        required
      />
    );
  };

  return (
    <FormControl sx={{ width: '100%', mt: 2 }}>
      <Box sx={{ mx: 1, display: 'flex', alignItems: 'center' }}>
        <FormLabel>{t('Identifiers')}</FormLabel>
        <IconButton
          size="small"
          sx={{ ml: 1 }}
          onClick={handleAddIdentifier}
        >
          <AddIcon />
        </IconButton>
      </Box>

      {informations.values.identifier.map((id, index) => {
        // Find matching identifier, accounting for null id.type
        const selectedIdentifier = identifiers?.find(
          (identifier) =>
            identifier.system === id.system && 
            (
              // If type is null, just match by system
              !id.type ? true : 
              // Otherwise, match by type.coding.code
              id.type.coding?.some((coding) => coding.code === identifier.code)
            )
        );

        return (
          <Box
            key={index}
            sx={{
              position: 'relative',
              mb: 3,
              border: (theme) => `1px solid ${theme.palette.divider}`,
              borderRadius: 2,
              p: 2,
            }}
          >
            <IconButton
              aria-label="delete"
              onClick={() => handleRemoveIdentifier(index)}
              sx={{
                position: 'absolute',
                top: 8,
                right: 8,
              }}
              disabled={disabled}
            >
              <DeleteIcon />
            </IconButton>

            <Typography variant="subtitle1" sx={{ mb: 2 }}>
              {t('Identifier')} {index + 1}
            </Typography>

            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                {id.system && !Array.isArray(identifiers) ? (
                  // If system is provided but no identifiers are available, show as text field
                  <TextField
                    label={t('System')}
                    value={id.system}
                    disabled
                    fullWidth
                  />
                ) : (
                  <FormControl fullWidth>
                    <InputLabel htmlFor={`identifier-${index}`}>{t('Identifier')}</InputLabel>
                    <Select
                      id={`system-${index}`}
                      value={selectedIdentifier || ''}
                      onChange={(e) => handleIdentifierDropdownChange(index, e.target.value)}
                      label={t('Identifier')}
                      error={Boolean(informations.errors?.identifier?.[index]?.system)}
                      disabled={disabled}
                    >
                      {Array.isArray(identifiers) && identifiers.map((identifier) => (
                        <MenuItem key={identifier.id} value={identifier}>
                          {identifier.display}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              </Grid>
              <Grid item xs={12} sm={6}>
                {renderValueInput(id, index, selectedIdentifier)}
              </Grid>
            </Grid>
          </Box>
        );
      })}
    </FormControl>
  );
};