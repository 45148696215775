import React from 'react';
import { Box, Typography, Paper } from '@mui/material';
import { Psychology as PsychologyIcon } from '@mui/icons-material';

const EmptyState = ({ 
  icon, 
  title = 'No data found', 
  description = 'There are no items to display', 
  action,
  sx = {} 
}) => {
  return (
    <Paper
      elevation={0}
      variant="outlined"
      sx={{
        p: 5,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        borderRadius: 2,
        backgroundColor: 'background.default',
        ...sx
      }}
    >
      <Box sx={{ mb: 2, color: 'text.secondary' }}>
        {icon || <PsychologyIcon fontSize="large" />}
      </Box>
      <Typography variant="h6" gutterBottom>
        {title}
      </Typography>
      <Typography variant="body2" color="text.secondary" sx={{ mb: 3 }}>
        {description}
      </Typography>
      {action}
    </Paper>
  );
};

export default EmptyState;