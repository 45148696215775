import React, { useRef, useEffect, useState, useCallback } from 'react';
import { greyboxApiActions } from '../../redux/api';
import { useTranslation } from 'react-i18next';

const PdfViewer = ({ link, setIframeUrl, id }) => {
  const { t } = useTranslation();
  const { patientReport } = greyboxApiActions;
  const [updateReport] = patientReport.update();
  const iframeRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const encodedLink = encodeURIComponent(link);

  const translationTexts = {
    savePdfButton: t('Save changes'),
    closeModal: t('Close'),
    saveChanges: t('Save'),
    dialogTitle: t('Save your changes?'),
    dialogMessage: t('Your changes will be lost if you don’t save them.'),
  };

  const handleSavePdf = async () => {
    setIsLoading(true);
    iframeRef.current?.contentWindow.postMessage({ action: 'loading', isLoading: true }, '*');
    let modifiedPdf = localStorage.getItem('modifiedPdf');

    if (!modifiedPdf) {
      await new Promise((resolve) => setTimeout(resolve, 500));
      modifiedPdf = localStorage.getItem('modifiedPdf');

      if (!modifiedPdf) {
        setIsLoading(false);
        iframeRef.current?.contentWindow.postMessage({ action: 'loading', isLoading: false }, '*');
        return;
      }
    }

    try {
      const byteArray = new Uint8Array(
        atob(modifiedPdf)
          .split('')
          .map((c) => c.charCodeAt(0))
      );
      const blob = new Blob([byteArray], { type: 'application/pdf' });
      const fileName = new URL(link).pathname.split('/').pop() || 'modified.pdf';
      const formData = new FormData();
      formData.append('file', blob, fileName);
      formData.append('type', 'pdf');
      const response = await updateReport({ id: id, body: formData }).unwrap();
      localStorage.removeItem('modifiedPdf');
    } catch (error) {
      console.error('❌ Error uploading modified PDF:', error);
    } finally {
      setIsLoading(false);
      iframeRef.current?.contentWindow.postMessage({ action: 'loading', isLoading: false }, '*');
    }
  };

  const handleClosePdf = () => {
    console.log('Close PDF viewer');
    setIframeUrl('');
  };

  const handleMessage = useCallback(
    (event) => {
      if (event.origin !== window.location.origin) return;
      const { action, functionName } = event.data;

      if (action === 'callFunction') {
        if (functionName === 'handleSavePdf') {
          handleSavePdf();
        } else if (functionName === 'handleClosePdf') {
          handleClosePdf();
        }
      }
    },
    [handleSavePdf, handleClosePdf]
  );

  useEffect(() => {
    const iframeWindow = iframeRef.current?.contentWindow;
    if (iframeWindow) {
      iframeWindow.postMessage({ action: 'registerFunction', functionName: 'handleSavePdf' }, '*');
      iframeWindow.postMessage({ action: 'registerFunction', functionName: 'handleClosePdf' }, '*');
    }

    window.addEventListener('message', handleMessage);
    return () => window.removeEventListener('message', handleMessage);
  }, [handleMessage]);

  useEffect(() => {
    const handleIframeLoad = () => {
      if (iframeRef.current) {
        iframeRef.current.contentWindow.postMessage({ action: 'setTranslations', translations: translationTexts }, '*');
      }
    };

    if (iframeRef.current) {
      iframeRef.current.addEventListener('load', handleIframeLoad);
    }

    return () => {
      if (iframeRef.current) {
        iframeRef.current.removeEventListener('load', handleIframeLoad);
      }
    };
  }, [translationTexts]);

  return (
    <iframe
      ref={iframeRef}
      id="pdf-js-viewer"
      src={`/pdfjs/web/viewer.html?file=${encodedLink}`}
      title="webviewer"
      width="100%"
      height="100%"
      style={{ border: 'none', backgroundColor: 'transparent' }}
    ></iframe>
  );
};

export default PdfViewer;
