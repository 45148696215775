import LoadingButton from '@mui/lab/LoadingButton';
import {
  Alert,
  Button,
  Checkbox,
  Dialog, DialogActions, DialogContent, DialogTitle,
  FormControlLabel,
} from '@mui/material';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';
import { greyboxApiActions } from '../../redux/api';
import { AccountCreationToAccount } from '../../types';
import Row from '../Row';
import { Labels } from '../form-inputs';
import BirthDate from '../form-inputs/BirthDate';
import EthnicGroup from '../form-inputs/EthnicGroup';
import FormTextField from '../form-inputs/FormTextField';
import Hin  from '../form-inputs/Hin';
import Language from '../form-inputs/Language';
import MultiSelect from '../form-inputs/MultiSelect';
import Pharmacy from '../form-inputs/Pharmacy';
import PhoneNumber from '../form-inputs/PhoneNumber';
import Sex from '../form-inputs/Sex';
import { ZipCode, zipCodeSchema } from '../form-inputs/ZipCode';

const AccountCreationDialog = ({ open, onClose }) => {
  const { t, i18n } = useTranslation();
  const { clinic } = useSelector(state => state.clinic);
  const { account, invitation } = greyboxApiActions;
  const [createAccount] = account.add();
  const [sendInvitation, setSendInvitation] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const featureConfigs = clinic.config.features_enable;
  const [createInvitation] = invitation.add();

  const {
    handleSubmit,
    resetForm,
    isSubmitting,
    setErrors,
    setSubmitting,
    ...formik
  } = useFormik({
    initialValues: {
      user: {
        language: i18n.language,
        email: '',
        mobile: '',
      },
      gender: 'U',
      ethnic_group: 'U',
      labels: [],
      primaryDiagnosis: [],
      secondaryDiagnosis: [],
      staffs: [],
      pharmacy: '',
    },
    validate: values => {
      const errors = {};
      if (sendInvitation && !values.user.email && !values.user.mobile) {
        errors.user = {
          email: t('Must provide either email or phone number'),
          mobile: t('Must provide either email or phone number'),
        };
      }
      return errors;
    },
    validationSchema: Yup.object({
      user: Yup.object().shape({
        title: Yup.string().nullable(),
        first_name: Yup.string().required(t('required')),
        middle_name: Yup.string().nullable(),
        last_name: Yup.string().required(t('required')),
        email: Yup.string().email(t('Invalid email')).nullable(),
        mobile: Yup.string().nullable(),
      }),
      birthDate: Yup.string().nullable(),
      zipCode: zipCodeSchema,
      pharmacy: Yup.string(),
      labels: Yup.array().of(Yup.object()),
      primaryDiagnosis: Yup.array().of(Yup.object()),
      secondaryDiagnosis: Yup.array().of(Yup.object()),
      ...Hin.validationSchema.fields
    }),
    onSubmit: async values => {
      setErrorMessage('');
      values.clinic = clinic.id;
      const body = AccountCreationToAccount(values);

      try {
        const res = await createAccount({
          body,
          feedback: { success: t('Account created') }
        }).unwrap();

        if (sendInvitation) {
          await createInvitation({
            body: {
              account: res.uuid,
              email: values.user.email,
              clinic: clinic.id,
              phoneNumber: values.user.mobile,
            }
          });
        }

        resetForm();
        onClose();
      } catch (error) {
        const errors = error.data || {};
        if (errors.error) setErrorMessage(errors.error);
        setErrors(errors);
      } finally {
        setSubmitting(false);
      }
    }
  });

  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      resetForm();
      onClose();
    }
  };

  return (
    <Dialog
      open={open}
      fullWidth
      disableEscapeKeyDown
      maxWidth="md"
      onClose={handleClose}
    >
      <DialogTitle>{t('Create Patient Account')}</DialogTitle>
      <form onSubmit={handleSubmit} noValidate>
        <DialogContent>
          {errorMessage && (
            <Alert severity="error" sx={{ mb: 2 }}>{errorMessage}</Alert>
          )}

          <Row>
            <FormTextField formik={formik} name="user.first_name" required label={t('First Name')} />
            <FormTextField formik={formik} name="user.last_name" required label={t('Last Name')} />
          </Row>

          <Row>
            <FormTextField formik={formik} name="user.title" label={t('Title')} />
            <FormTextField formik={formik} name="user.middle_name" label={t('Middle Name')} />
          </Row>

          <Row>
            <FormTextField formik={formik} name="user.email" label={t('Email')} />
            <PhoneNumber formik={formik} name="user.mobile" />
          </Row>

          <Row>
            <Language formik={formik} name="user.language" />
            <ZipCode formik={formik} sx={{ mx: 1 }} />
          </Row>

          <Row>
            <BirthDate formik={formik} />
            <Sex formik={formik} />
          </Row>

          <Row>
            <EthnicGroup formik={formik} />
            <Hin formik={formik} />
          </Row>

          <Row>
            <Labels formik={formik} />
          </Row>

          <MultiSelect
            formik={formik}
            type="diagnosis"
            level="primary"
            label={t('Primary Diagnosis')}
            name="primaryDiagnosis"
          />

          {featureConfigs.secondary_diagnosis && (
            <MultiSelect
              formik={formik}
              type="diagnosis"
              level="secondary"
              name="secondaryDiagnosis"
              label={t('Secondary Diagnosis')}
            />
          )}

          <MultiSelect formik={formik} type="staffs" label={t('Assigned HCP')} name="staffs" />
          <Pharmacy formik={formik} />
        </DialogContent>

        <DialogActions sx={{ justifyContent: 'flex-end' }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={sendInvitation}
                onChange={() => setSendInvitation(!sendInvitation)}
              />
            }
            label={t('Send invitation')}
            sx={{ ml: 1 }}
          />

          <Button onClick={() => { resetForm(); onClose(); }} color="secondary">
            {t('Cancel')}
          </Button>

          <LoadingButton
            data-cy="account-creation-submit"
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            {t('Submit')}
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AccountCreationDialog;