import { useState, useEffect, useCallback } from 'react';
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  CircularProgress,
  Button,
  useMediaQuery,
  useTheme,
  Box,
  Typography
} from '@mui/material';
import { WifiOff, Speed, Wifi } from '@mui/icons-material';
import { tenantDomainConfigs } from 'environmentConfig';
import { useTranslation } from 'react-i18next';

const domain = tenantDomainConfigs[window.location.hostname];
const checkHealth = `https://${domain}/greybox-api/healthcheck/`;

const useNetworkStatus = () => {
  const [isOnline, setIsOnline] = useState(true);
  const [isSlowConnection, setIsSlowConnection] = useState(false);


  const updateNetworkStatus = () => {
    setIsOnline(navigator.onLine);
  };

  // Basic online/offline detection
  useEffect(() => {
    window.addEventListener("load", updateNetworkStatus);
    window.addEventListener("online", updateNetworkStatus);
    window.addEventListener("offline", updateNetworkStatus);

    return () => {
      window.removeEventListener("load", updateNetworkStatus);
      window.removeEventListener("online", updateNetworkStatus);
      window.removeEventListener("offline", updateNetworkStatus);
    };
  }, [navigator.onLine]);

  // Connection speed detection with manual check capability
  const checkConnectionSpeed = useCallback(async () => {
    const startTime = Date.now();
    try {
      await fetch(checkHealth + '?t=' + startTime);
      const latency = Date.now() - startTime;
      setIsSlowConnection(latency > 2000);
      return latency;
    } catch {
      setIsSlowConnection(true);
      return null;
    }
  }, []);

  useEffect(() => {
    const interval = setInterval(checkConnectionSpeed, 30000);
    checkConnectionSpeed(); // Initial check
    return () => clearInterval(interval);
  }, [checkConnectionSpeed]);

  return { isOnline, isSlowConnection, checkConnectionSpeed };
};


const NetworkStatusModal = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { isOnline, isSlowConnection, checkConnectionSpeed } = useNetworkStatus();
  const [isChecking, setIsChecking] = useState(false);

  const handleRetry = async () => {
    setIsChecking(true);
    await checkConnectionSpeed();
    setIsChecking(false);
  };

  const getStatusContent = () => {
    if (!isOnline) {
      return {
        title: t("You're Offline"),
        icon: <WifiOff sx={{ fontSize: 64, color: 'error.main' }} />,
        description: t('Please check your internet connection and try again.'),
      };
    }

    if (isSlowConnection) {
      return {
        title: t("Slow Connection Detected"),
        icon: <Speed sx={{ fontSize: 64, color: 'warning.main' }} />,
        description: t('Your internet connection is too slow. Please try to improve your network stability.'),
      };
    }

    return null;
  };

  const statusContent = getStatusContent();

  if (!statusContent) return null;

  return (
    <Dialog
      fullScreen={fullScreen}
      open={!isOnline || isSlowConnection}
      aria-labelledby="network-status-dialog"
      BackdropProps={{
        sx: {
          backgroundColor: 'rgba(0, 0, 0, 0.8)',
          backdropFilter: 'blur(4px)'
        }
      }}
      PaperProps={{
        sx: {
          maxWidth: '500px',
          textAlign: 'center',
          p: 4,
          borderRadius: 2
        }
      }}
      disableEscapeKeyDown
    >
      <DialogTitle id="network-status-dialog" sx={{ pt: 4 }}>
        <Box display="flex" justifyContent="center">
          {statusContent.icon}
        </Box>
        <Typography variant="h4" component="div" sx={{ mt: 2 }}>
          {statusContent.title}
        </Typography>
      </DialogTitle>

      <DialogContent>
        <DialogContentText sx={{ mb: 3, fontSize: '1.1rem' }}>
          {statusContent.description}
        </DialogContentText>

        <Button
          variant="contained"
          color="primary"
          onClick={handleRetry}
          disabled={isChecking}
          startIcon={isChecking ? <CircularProgress size={20} /> : <Wifi />}
          sx={{ borderRadius: 50, px: 4 }}
        >
          {isChecking ? t('Checking...') : t('Retry Connection')}
        </Button>
      </DialogContent>
    </Dialog>
  );
};


export default NetworkStatusModal;