import { useSelector } from 'react-redux';

const useFeatureEnabled = (featureName, defaultValue = false) => {
    const { clinic } = useSelector((state) => state.clinic || {});
    const features = clinic?.config?.features_enable || {};

    const featureExists = Object.prototype.hasOwnProperty.call(features, featureName);

    return featureExists ? features[featureName] : defaultValue;
};

export default useFeatureEnabled;