import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ViewListIcon from '@mui/icons-material/ViewList';
import ViewStreamIcon from '@mui/icons-material/ViewStream';
import {
  Box, Button, ButtonGroup, LinearProgress, ToggleButton, ToggleButtonGroup, Typography, useTheme, Tooltip
} from '@mui/material';
import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import AnswerSummary from './AnswerSummary';
import { computeJson } from '../../../../helper-functions/jsonCodeUtils';
import { conditionParser } from './utils';
import { Question, Answers } from '../../../../types';
import PanelNavigation from './PanelNavigation';
import PanelSelector from './PanelSelector';
import { QuestionnairePanelContextProvider } from '../../../../contexts/QuestionnairePanelContext';
import CompletionPanel from './AnswerSummary';

type QuestionnaireFillerProps = {
  patientUuid: string;
  questions: Question[];
  setHasUnsaved: (hasUnsaved: boolean) => void;
  onComplete: (answersId: string) => void;
  prefilledAnswers: Answers;
  tokenId: string
};

const QuestionnaireFiller = (props: QuestionnaireFillerProps) => {
  const {
    patientUuid, questions, setHasUnsaved, onComplete, prefilledAnswers = {}, tokenId = null
  } = props;
  const theme = useTheme();
  const { t } = useTranslation();
  const [current, setCurrent] = useState(0);
  const [answers, setAnswers] = useState<Answers>(prefilledAnswers);
  const [others, setOthers] = useState<Answers>({});
  const [direction, setDirection] = useState<number>(1);
  const [showSummary, setShowSummary] = useState(false);
  // View mode: 'single' for one question at a time, 'all' for all questions at once
  const [viewMode, setViewMode] = useState<'single' | 'all'>(() => {
    // Load saved preference from localStorage if available
    const savedViewMode = localStorage.getItem('questionnaireViewMode');
    return (savedViewMode === 'single' || savedViewMode === 'all') ? savedViewMode : 'single';
  });
  
  // Determine if animations should be disabled - animations are only enabled in single mode
  const animationsDisabled = viewMode === 'all';
  const sliderRef = useRef<HTMLDivElement>(null);
  const answersCount = Object.keys(answers).length;
  const progress = answersCount > 0 ? (answersCount / questions.length) * 100 : 0;
  const isLast = current === questions.length - 1;
  const [hasBeenCompleted, setHasBeenCompleted] = useState(false);
  const hasAnswered = questions[current].id in answers && answers[questions[current].id] !== '';

  const handlePrevious = () => {
    setDirection(-1);
    setCurrent(current - 1);
  };

  useEffect(() => {
    const nextPosition = handleConditionCheck();
    if (questions[current].type_of_q === 'SA'
      && questions[current].sa_question?.allow_empty
      && !hasAnswered) {
      setAnswers((prev) => ({
        ...prev,
        [questions[current].id]: '',
      }));
    }
    if (nextPosition !== null) {
      setCurrent(nextPosition);
    }
  }, [current]);

  const handleBackFromSummary = (index: number) => {
    setCurrent(index);
    setShowSummary(false);
    setHasBeenCompleted(true);
  };

  useEffect(() => {
    if (progress > 0 && setHasUnsaved) {
      setHasUnsaved(true);
    }
  }, [progress]);

  useEffect(() => {
    if (current >= questions.length) {
      setShowSummary(true);
    }
  }, [current, questions.length]);
  
  // Scroll to top when switching to 'all' mode
  useEffect(() => {
    if (viewMode === 'all') {
      // Use requestAnimationFrame to ensure DOM has updated before scrolling
      requestAnimationFrame(() => {
        // Find the scrollable container in 'all' mode and scroll to top
        if (sliderRef.current) {
          const scrollContainer = sliderRef.current.querySelector('[style*="overflow-y: auto"]') as HTMLElement;
          if (scrollContainer) {
            scrollContainer.scrollTop = 0;
          }
        }
      });
    }
  }, [viewMode]);

  const handleNewAnswer = (questionId: number, answer: string) => {
    // Answer is added for the first time
    if (!(questionId in answers)) {
      if (answer !== '' && answer !== null) {
        setAnswers((prev) => ({
          ...prev,
          [questionId]: answer,
        }));

        // Go directly to the next question if it is a single choice question
        if (!questions[current].mc_question?.multi_select
          && !questions[current].mc_question?.mc_question_choice_other
          && questions[current].type_of_q !== 'SA') {
          handleNext();
        }
      } else {
        // Do nothing
      }
    } else {
      // Answer is updated
      setAnswers((prev) => ({
        ...prev,
        [questionId]: answer,
      }));
    }
  };

  const handleConditionCheck = (): number | null => {
    const { condition } = questions[current];
    const nextPosition = current + (1 * direction);

    if (questions[current].type_of_q === 'QA' && questions[current].id in answers) {
      // Question is a already answered QA, skip it
      if (questions[current].id in answers) { return nextPosition; }
    }

    // If no condition simply return the question
    if (!condition) {
      return current;
    }

    const parsedCondition = conditionParser(condition, answers);

    // If condition is met show the question
    if (computeJson(parsedCondition)) {
      return current;
    }

    // So progress bar is not stuck
    setAnswers((prev) => ({
      ...prev,
      [questions[current].id]: '',
    }));

    // If next question is out of bounds, show summary
    if (nextPosition >= questions.length) {
      setShowSummary(true);
      return current;
    }

    return nextPosition;
  };

  const handleNext = () => {
    if (isLast) {
      setShowSummary(true);
    } else {
      setDirection(1);
      setCurrent(current + 1);
    }
  };

  const questionIndices = React.useMemo(() => [
    current - 1 >= 0 ? current - 1 : null,
    handleConditionCheck(),
    current + 1 < questions.length ? current + 1 : null,
  ], [current]);

  const handleNewOther = (questionId: number, answer: string) => {
    setOthers((prev) => ({
      ...prev,
      [questionId]: answer,
    }));
  };

  return (
    <Box
      sx={{
        height: '100%',
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        flexGrow: 1,
      }}
      ref={sliderRef}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 1 }}>
        <LinearProgress color="success" variant="determinate" value={progress} sx={{ flex: 1, mr: 2 }} />
        
        <Tooltip title={viewMode === 'single' ? t('Show all questions') : t('Show one question at a time')}>
          <ToggleButtonGroup
            value={viewMode}
            exclusive
            onChange={(e, newMode) => {
              if (newMode) {
                // Save preference to localStorage
                localStorage.setItem('questionnaireViewMode', newMode);
                setViewMode(newMode);
              }
            }}
            size="small"
            aria-label="view mode"
          >
            <ToggleButton value="single" aria-label="single question view">
              <ViewStreamIcon />
            </ToggleButton>
            <ToggleButton value="all" aria-label="all questions view">
              <ViewListIcon />
            </ToggleButton>
          </ToggleButtonGroup>
        </Tooltip>
      </Box>
      {showSummary ? (
        <Box
          sx={{
            height: '100%',
            display: 'flex',
            width: '100%',
            justifyContent: 'center',
          }}
        >
          <CompletionPanel
            patientUuid={patientUuid}
            questions={questions}
            handleBackFromSummary={handleBackFromSummary}
            onComplete={onComplete}
            answers={answers}
            others={others}
            tokenId={tokenId}
          />
        </Box>
      ) : viewMode === 'single' ? (
        <>
          <Box
            sx={{
              height: '100%',
              overflow: 'hidden',
              position: 'relative',
            }}
          >
            {questionIndices.map(
              (index, i) =>
                index !== null &&
                questionIndices[1] !== questionIndices[2] && (
                  <Box
                    key={questions[index].id}
                    sx={{
                      transform: `translateY(${(index - current) * 100}%)`,
                      display: 'flex',
                      flexDirection: 'column',
                      visibility: `${i !== 1 ? 'hidden' : 'visible'}`,
                      transition: animationsDisabled ? 'none' : 'transform 0.5s ease-in-out',
                      justifyContent: 'center',
                      alignItems: 'center',
                      width: '100%',
                      height: '100%',
                      pt: 2,
                      position: 'absolute',
                      overflow: 'auto',
                    }}
                  >
                    <QuestionnairePanelContextProvider>
                      <PanelNavigation
                        goNext={handleNext}
                        hasBeenCompleted={hasBeenCompleted}
                        setShowSummary={setShowSummary}
                        hasAnswered={hasAnswered}
                        isCurrent={index === current}
                        question={questions[index]}
                        required={questions[current].sa_question?.allow_empty || questions[current].required}
                      >
                        <PanelSelector
                          question={questions[index]}
                          questions={questions}
                          setAnswers={handleNewAnswer}
                          setOthers={handleNewOther}
                          answers={answers}
                          setHasUnsaved={setHasUnsaved}
                          isCurrent={index === current}
                        />
                      </PanelNavigation>
                    </QuestionnairePanelContextProvider>
                  </Box>
                )
            )}
          </Box>
          <Box display="flex" alignItems="flex-end" justifyContent="flex-end" height="5%">
            <ButtonGroup variant="contained" sx={{ m: 2 }}>
              <Button disabled={current === 0} onClick={handlePrevious}>
                <ArrowDropUpIcon />
              </Button>
              <Button disabled={isLast || !hasAnswered} onClick={handleNext}>
                <ArrowDropDownIcon />
              </Button>
            </ButtonGroup>
          </Box>
        </>
      ) : (
        // All Questions Form View - Compact Layout
        <Box
          sx={{
            height: '100%',
            overflowY: 'auto',
            overflowX: 'hidden', // Prevent horizontal scrolling
            padding: { xs: 1, sm: 2 },
            display: 'flex',
            flexDirection: 'column',
            margin: '0 auto',
            width: '100%',
            maxWidth: '900px',
          }}
        >
          <Typography variant="h5" mb={2} fontWeight="medium">
            {t('Complete the questionnaire')}
          </Typography>
          
          {/* Group questions by section */}
          {(() => {
            interface Section {
              name: string;
              description?: string;
              questions: Question[];
            }
            
            const sections: Record<string, Section> = {};
            const standaloneQuestions: Question[] = [];
            
            // TypeScript-friendly way to access section property which is not formally in the Question type
            interface QuestionWithSection extends Question {
              section?: {
                name: string;
                description?: string;
              };
            }
            
            // Organize questions by their sections
            questions.forEach((q) => {
              const question = q as QuestionWithSection;
              if (question.section) {
                const sectionName = question.section.name;
                
                if (!sections[sectionName]) {
                  sections[sectionName] = {
                    name: sectionName,
                    description: question.section.description,
                    questions: [],
                  };
                }
                sections[sectionName].questions.push(question);
              } else {
                standaloneQuestions.push(question);
              }
            });
            
            return (
              <>
                {/* Render sectioned questions */}
                {Object.values(sections).map((section: Section) => (
                  <Box 
                    key={section.name}
                    sx={{
                      mb: 3,
                      p: { xs: 1, sm: 2 },
                      border: '1px solid',
                      borderColor: theme.palette.divider,
                      borderRadius: 1,
                      backgroundColor: theme.palette.background.paper,
                    }}
                  >
                    <Typography variant="h6" gutterBottom sx={{ borderBottom: `1px solid ${theme.palette.divider}`, pb: 1 }}>
                      {section.name}
                    </Typography>
                    {section.description && (
                      <Typography variant="body2" color="text.secondary" mb={2}>
                        {section.description}
                      </Typography>
                    )}
                    
                    {section.questions.map((question: Question) => (
                      <Box 
                        key={question.id}
                        sx={{
                          mb: 2,
                          p: { xs: 1, sm: 1.5 },
                          border: '1px dashed',
                          borderColor: theme.palette.divider,
                          borderRadius: 1,
                          position: 'relative',
                          boxShadow: question.required ? `0 0 0 1px ${theme.palette.primary.light}` : 'none',
                        }}
                      >
                        {question.required && (
                          <Box 
                            sx={{
                              position: 'absolute',
                              top: 0,
                              right: 0,
                              backgroundColor: theme.palette.primary.main,
                              color: theme.palette.primary.contrastText,
                              padding: '2px 8px',
                              borderBottomLeftRadius: '4px',
                              fontSize: '0.75rem',
                              fontWeight: 'bold',
                            }}
                          >
                            {t('Required')}
                          </Box>
                        )}
                        <QuestionnairePanelContextProvider>
                          <PanelSelector
                            question={question}
                            questions={questions}
                            setAnswers={handleNewAnswer}
                            setOthers={handleNewOther}
                            answers={answers}
                            setHasUnsaved={setHasUnsaved}
                            isCurrent={question.id === questions[0].id}
                          />
                        </QuestionnairePanelContextProvider>
                      </Box>
                    ))}
                  </Box>
                ))}
                
                {/* Render standalone questions */}
                {standaloneQuestions.length > 0 && (
                  <Box
                    sx={{
                      mb: 3,
                      p: { xs: 1, sm: 2 },
                      border: '1px solid',
                      borderColor: theme.palette.divider,
                      borderRadius: 1,
                      backgroundColor: theme.palette.background.paper,
                    }}
                  >
                    {standaloneQuestions.map((question: Question) => (
                      <Box 
                        key={question.id}
                        sx={{
                          mb: 2,
                          p: { xs: 1, sm: 1.5 },
                          border: '1px dashed',
                          borderColor: theme.palette.divider,
                          borderRadius: 1,
                          position: 'relative',
                          boxShadow: question.required ? `0 0 0 1px ${theme.palette.primary.light}` : 'none',
                        }}
                      >
                        {question.required && (
                          <Box 
                            sx={{
                              position: 'absolute',
                              top: 0,
                              right: 0,
                              backgroundColor: theme.palette.primary.main,
                              color: theme.palette.primary.contrastText,
                              padding: '2px 8px',
                              borderBottomLeftRadius: '4px',
                              fontSize: '0.75rem',
                              fontWeight: 'bold',
                            }}
                          >
                            {t('Required')}
                          </Box>
                        )}
                        <QuestionnairePanelContextProvider>
                          <PanelSelector
                            question={question}
                            questions={questions}
                            setAnswers={handleNewAnswer}
                            setOthers={handleNewOther}
                            answers={answers}
                            setHasUnsaved={setHasUnsaved}
                            isCurrent={question.id === questions[0].id}
                          />
                        </QuestionnairePanelContextProvider>
                      </Box>
                    ))}
                  </Box>
                )}
              </>
            );
          })()}
          
          {/* Add extra padding at the bottom to prevent content from being hidden behind the fixed button */}
          <Box sx={{ height: '70px', mb: 7 }} />
        
          {/* Fixed position footer with review button */}
          <Box 
            sx={{ 
              position: 'fixed',
              bottom: 0,
              left: 0,
              right: 0,
              backgroundColor: theme.palette.background.paper,
              borderTop: `1px solid ${theme.palette.divider}`,
              zIndex: 1000,
              boxShadow: '0px -3px 10px rgba(0,0,0,0.1)',
              py: 2,
              px: 3,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              transition: animationsDisabled ? 'none' : 'transform 0.3s ease',
              width: '100%',
              maxWidth: '100vw',
            }}
          >
            <Box sx={{ maxWidth: '900px', width: '100%', display: 'flex', justifyContent: 'center' }}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                onClick={() => setShowSummary(true)}
                disabled={
                  Object.keys(answers).length === 0 || 
                  questions.some(q => q.required && (!answers[q.id] || answers[q.id] === ''))
                }
                sx={{ minWidth: 200 }}
              >
                {t('Review Answers')}
              </Button>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default QuestionnaireFiller;
