import React, { useState, useRef, useEffect } from 'react';
import html2canvas from 'html2canvas';
import {
    MdChevronLeft,
    MdChevronRight,
    MdDelete,
    MdSave,
    MdClose,
    MdDragIndicator,
    MdOpenWith
} from 'react-icons/md';

const ImagePointMarker = ({ imageUrl, onChange }) => {
    const [points, setPoints] = useState([]);
    const [selectedPoint, setSelectedPoint] = useState(null);
    const [hoveredPoint, setHoveredPoint] = useState(null);
    const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
    const [undoStack, setUndoStack] = useState([]);
    const [redoStack, setRedoStack] = useState([]);
    const [menuPosition, setMenuPosition] = useState({ x: 10, y: 10 });
    const containerRef = useRef(null);
    const menuDragStart = useRef({ x: 0, y: 0 });
    const isDragging = useRef(false);
    const isMenuDragging = useRef(false);
    const pointsRef = useRef(points);
    const selectedPointRef = useRef(selectedPoint);
    const menuRef = useRef(null);

    useEffect(() => {
        pointsRef.current = points;
    }, [points]);

    useEffect(() => {
        selectedPointRef.current = selectedPoint;
    }, [selectedPoint]);

    useEffect(() => {
        const img = new Image();
        img.src = imageUrl;
        img.onload = () => {
            setDimensions({ width: img.naturalWidth, height: img.naturalHeight });
        };
    }, [imageUrl]);

    const updateHistory = (newPoints) => {
        setUndoStack([...undoStack, points]);
        setRedoStack([]);
        setPoints(newPoints);
    };

    const getClientCoordinates = (event) => {
        if (event.touches && event.touches.length > 0) {
            return {
                clientX: event.touches[0].clientX,
                clientY: event.touches[0].clientY
            };
        }
        return {
            clientX: event.clientX,
            clientY: event.clientY
        };
    };

    const handleContainerClick = (e) => {
        if (e.target !== containerRef.current || isDragging.current || isMenuDragging.current) return;
        handleAddPoint(e);
    };

    const handleContainerTouchEnd = (e) => {
        if (e.target !== containerRef.current || isDragging.current || isMenuDragging.current) return;
        handleAddPoint(e.changedTouches[0]);
    };

    const handleAddPoint = (position) => {
        const rect = containerRef.current.getBoundingClientRect();
        const x = position.clientX - rect.left;
        const y = position.clientY - rect.top;
        updateHistory([...points, { x, y }]);
        setSelectedPoint(null);
    };

    const handleDotDragStart = (index, e) => {
        e.preventDefault();
        e.stopPropagation();
        setSelectedPoint(index);
        selectedPointRef.current = index;

        const { clientX: startX, clientY: startY } = getClientCoordinates(e);
        let moved = false;

        const moveEvent = e.type === 'touchstart' ? 'touchmove' : 'mousemove';
        const endEvent = e.type === 'touchstart' ? 'touchend' : 'mouseup';

        const onMove = (e) => {
            const { clientX, clientY } = getClientCoordinates(e);
            const dx = clientX - startX;
            const dy = clientY - startY;
            
            if (Math.abs(dx) > 5 || Math.abs(dy) > 5) {
                moved = true;
                isDragging.current = true;
                document.removeEventListener(moveEvent, onMove);
                document.addEventListener(moveEvent, handleDotMove);
                document.addEventListener(endEvent, onEnd);
            }
        };

        const onEnd = () => {
            document.removeEventListener(moveEvent, onMove);
            document.removeEventListener(endEvent, onEnd);
            document.removeEventListener(moveEvent, handleDotMove);
            document.removeEventListener(endEvent, onEnd);
            
            if (moved) {
                isDragging.current = false;
                updateHistory(pointsRef.current);
                setSelectedPoint(null);
            }
        };

        document.addEventListener(moveEvent, onMove);
        document.addEventListener(endEvent, onEnd);
    };

    const handleDotMove = (e) => {
        if (!isDragging.current || selectedPointRef.current === null) return;
        
        const { clientX, clientY } = getClientCoordinates(e);
        const rect = containerRef.current.getBoundingClientRect();
        const newX = clientX - rect.left;
        const newY = clientY - rect.top;

        setPoints(pointsRef.current.map((point, i) =>
            i === selectedPointRef.current ? { x: newX, y: newY } : point
        ));
    };

    const handleMenuDragStart = (e) => {
        e.stopPropagation();
        isMenuDragging.current = true;

        const { clientX, clientY } = getClientCoordinates(e);
        menuDragStart.current = {
            x: clientX - menuPosition.x,
            y: clientY - menuPosition.y
        };

        const moveEvent = e.type === 'touchstart' ? 'touchmove' : 'mousemove';
        const endEvent = e.type === 'touchstart' ? 'touchend' : 'mouseup';

        const onMove = (e) => {
            const { clientX, clientY } = getClientCoordinates(e);
            const newX = clientX - menuDragStart.current.x;
            const newY = clientY - menuDragStart.current.y;
            setMenuPosition({ x: newX, y: newY });
        };

        const onEnd = () => {
            isMenuDragging.current = false;
            document.removeEventListener(moveEvent, onMove);
            document.removeEventListener(endEvent, onEnd);
        };

        document.addEventListener(moveEvent, onMove);
        document.addEventListener(endEvent, onEnd);
    };

    const handleUndo = () => {
        if (undoStack.length === 0) return;
        const previousState = undoStack[undoStack.length - 1];
        setRedoStack([...redoStack, points]);
        setUndoStack(undoStack.slice(0, -1));
        setPoints(previousState);
        setSelectedPoint(null);
    };

    const handleRedo = () => {
        if (redoStack.length === 0) return;
        const nextState = redoStack[redoStack.length - 1];
        setUndoStack([...undoStack, points]);
        setRedoStack(redoStack.slice(0, -1));
        setPoints(nextState);
        setSelectedPoint(null);
    };

    const handleDelete = (index) => {
        const filteredPoints = points.filter((_, i) => i !== index);
        updateHistory(filteredPoints);
        setSelectedPoint(null);
    };

    const handleSave = () => {
        setSelectedPoint(null);
        setTimeout(() => {
            html2canvas(containerRef.current, {
                useCORS: true,
                backgroundColor: null,
                ignoreElements: (element) => element === menuRef.current
            }).then(canvas => {
                canvas.toBlob((blob) => {
                    if (onChange) {
                        const file = new File([blob], 'annotated-image.png', { type: 'image/png' });
                        onChange(file);
                    } else {
                        const link = document.createElement('a');
                        const url = URL.createObjectURL(blob);
                        link.download = 'annotated-image.png';
                        link.href = url;
                        link.click();
                        URL.revokeObjectURL(url);
                    }
                }, 'image/png');
            });
        }, 0);
    };

    return (
        <div style={{ position: 'relative', display: 'inline-block' }}>
            <div
                ref={containerRef}
                onClick={handleContainerClick}
                onTouchEnd={handleContainerTouchEnd}
                style={{
                    position: 'relative',
                    backgroundImage: `url(${imageUrl})`,
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    width: `${dimensions.width}px`,
                    height: `${dimensions.height}px`,
                    cursor: 'crosshair',
                    touchAction: 'none'
                }}
            >
                <div
                    ref={menuRef}
                    style={{
                        position: 'absolute',
                        left: menuPosition.x,
                        top: menuPosition.y,
                        display: 'flex',
                        gap: 8,
                        background: 'rgba(255, 255, 255, 0.9)',
                        padding: 8,
                        borderRadius: 8,
                        boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
                        cursor: isMenuDragging.current ? 'grabbing' : 'grab',
                        userSelect: 'none',
                        zIndex: 1000,
                        touchAction: 'none'
                    }}
                    onClick={(e) => e.stopPropagation()}
                >
                    <div
                        onMouseDown={handleMenuDragStart}
                        onTouchStart={handleMenuDragStart}
                        style={{
                            cursor: 'grab',
                            display: 'flex',
                            alignItems: 'center',
                            paddingRight: '8px',
                            borderRight: '1px solid #ddd'
                        }}
                    >
                        <MdDragIndicator />
                    </div>
                    <button onClick={handleUndo} disabled={undoStack.length === 0}>
                        <MdChevronLeft />
                    </button>
                    <button onClick={handleRedo} disabled={redoStack.length === 0}>
                        <MdChevronRight />
                    </button>
                    <button onClick={handleSave}>
                        <MdSave />
                    </button>
                    <button onClick={() => {
                        updateHistory([]);
                        setSelectedPoint(null);
                    }}>
                        <MdClose />
                    </button>
                </div>

                {points.map((point, index) => (
                    <div
                        key={index}
                        onMouseDown={(e) => handleDotDragStart(index, e)}
                        onTouchStart={(e) => handleDotDragStart(index, e)}
                        onMouseEnter={() => setHoveredPoint(index)}
                        onMouseLeave={() => setHoveredPoint(null)}
                        style={{
                            position: 'absolute',
                            left: point.x - 8,
                            top: point.y - 8,
                            width: '16px',
                            height: '16px',
                            borderRadius: '50%',
                            backgroundColor: selectedPoint === index
                                ? '#3b82f6'
                                : hoveredPoint === index
                                    ? '#f87171'
                                    : '#ef4444',
                            cursor: 'grab',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            transition: 'all 0.2s',
                            border: '2px solid white',
                            boxShadow: '0 2px 8px rgba(0,0,0,0.2)',
                            transform: selectedPoint === index ? 'scale(1.2)' : 'scale(1)',
                            zIndex: selectedPoint === index ? 999 : 500,
                            userSelect: 'none',
                            WebkitUserSelect: 'none',
                            touchAction: 'none'
                        }}
                    >
                        <MdOpenWith size={10} color="white" />
                        {selectedPoint === index && (
                            <div
                                style={{
                                    position: 'absolute',
                                    right: -6,
                                    top: -6,
                                    background: 'rgba(100, 116, 139, 0.7)',
                                    borderRadius: '50%',
                                    width: '14px',
                                    height: '14px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    cursor: 'pointer',
                                    boxShadow: '0 1px 2px rgba(0,0,0,0.1)',
                                    zIndex: 1001,
                                    transition: 'all 0.2s ease',
                                    ':hover': {
                                        background: 'rgba(100, 116, 139, 0.9)',
                                        boxShadow: '0 1px 3px rgba(0,0,0,0.2)'
                                    }
                                }}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleDelete(index);
                                }}
                                onMouseEnter={(e) => {
                                    e.currentTarget.style.background = 'rgba(100, 116, 139, 0.9)';
                                    e.currentTarget.style.transform = 'scale(1.1)';
                                }}
                                onMouseLeave={(e) => {
                                    e.currentTarget.style.background = 'rgba(100, 116, 139, 0.7)';
                                    e.currentTarget.style.transform = 'scale(1)';
                                }}
                                onMouseDown={(e) => e.stopPropagation()}
                            >
                                <MdDelete size={10} color="rgba(255,255,255,0.9)" />
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ImagePointMarker;