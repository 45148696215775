import {
  Paper, Box, Typography,
} from '@mui/material';
import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { greyboxApiActions } from '../../../redux/api';
import CurrentMedicationsWrapper from './medicationTable/CurrentMedicationsWrapper';
import SuggestedMedications from './suggestedMedications/SuggestedMedications';
import TitrationSection from './titration/TitrationSections';
import { statusShapes } from './titrationStatusChoices';
import TimelineChart from '../../chart/TimelineChart';
import { parseMedForTimeLine } from '../../chart/utils';
import DateRangePicker from '../dateRangePicker/DateRangePicker';
import { usePatientWebSocket } from '../../../hooks';

const MedicationTab = () => {
  const { uuid } = useParams();
  const { t } = useTranslation();
  const { clinic } = useSelector((state) => state.clinic);
  const { startDate } = useSelector((state) => state.patientProfileDateRange);
  const { medication } = greyboxApiActions;
  const { config } = clinic;
  const user = useSelector((state) => state.user);

  const { data, isFetching, refetch } = medication.list({
    account: uuid,
    // start_date__gte: moment(startDate).format('YYYY-MM-DD'),
    status: 'active,completed,intended',
  });

  const { lastJsonMessage, readyState } = usePatientWebSocket();

  useEffect(() => {
    if (lastJsonMessage?.entity === 'Medication_History') {
      refetch();
    }
  }, [lastJsonMessage]);

  const { medForTimeLine, colors } = useMemo(() => {
    if (!data) return { medForTimeLine: [], colors: [] };
    const filteredData = data
      .map((med) => {
        const actualStartDate = moment(med.start_date).valueOf();
        const adjustedStartDate = Math.max(actualStartDate, moment(startDate).valueOf());

        if (med.status === 'active') {
          return { ...med, start_date: adjustedStartDate };
        }

        if (med.status === 'completed') {
          const endDate = moment(med.end_date).valueOf();
          if (endDate >= moment(startDate).valueOf()) {
            return { ...med, start_date: adjustedStartDate };
          }
        }

        return null;
      })
      .filter(Boolean);
    const parsedData = parseMedForTimeLine(filteredData);
    return parsedData;
  }, [data, startDate]);

  // returns every status in an Obj - see titrationStatusChoices.js
  const titration_status_choices = statusShapes();

  return (
    <Box width="100%">
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
        <Typography variant="h4" sx={{ fontWeight: 'bold', color: (theme) => theme.palette.text.secondary }}>
          {t('Pharmacotherapy')}
        </Typography>
        <DateRangePicker />
      </Box>
      <TimelineChart
        patientUuid={uuid}
        series={medForTimeLine}
        isLoading={isFetching}
        name={t('Medications Timeline')}
        colors={colors}
      />
      <Paper sx={{ p: 2, mt: 1 }}>
        <CurrentMedicationsWrapper titration_status_choices={titration_status_choices} />
        {config.features_enable.decisionTree && (
          <>
            <SuggestedMedications
              uuid={uuid}
              acc_type={user.access}
              role_type_code={user.role}
            />
            <TitrationSection
              uuid={uuid}
              titration_status_choices={titration_status_choices}
            />
          </>
        )}
      </Paper>
    </Box>
  );
};

export default MedicationTab;
