// src/pages/TrainingDataPage.jsx
import React, { useState, useEffect, useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next'; // Import useTranslation hook
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  Typography,
  TextField,
  CircularProgress,
  Paper,
  Chip,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Pagination,
  useTheme,
  Tooltip,
  InputAdornment,
  TableSortLabel,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  FormControl,
  Select,
  InputLabel,
  Dialog,
  DialogTitle,
} from '@mui/material';
import {
  Add as AddIcon,
  Delete as DeleteIcon,
  Upload as UploadIcon,
  Download as DownloadIcon,
  Autorenew as AutorenewIcon,
  Check as CheckIcon,
  Clear as ClearIcon,
  ArrowBack as ArrowBackIcon,
  Search as SearchIcon,
  FilterList as FilterListIcon,
  MoreVert as MoreVertIcon,
} from '@mui/icons-material';
import {
  useGetAgentQuery,
  useGetTrainingDataQuery,
  useGetTrainingStatsQuery,
  useCreateTrainingDataMutation,
  useDeleteTrainingDataMutation,
  useTrainAgentMutation,
  useGenerateTrainingDataMutation,
  useBatchTrainTrainingDataMutation,
} from '../redux/api';
import { setSnackbarStatus } from '../redux/snackbarStatus';
import PageHeader from '../components/common/PageHeader';
import AlertDialog from '../components/common/AlertDialog';
import EmptyState from '../components/common/EmptyState';

// Import the new components
import ImportDialog from '../components/training/ImportDialog';
import ExportDialog from '../components/training/ExportDialog';
import TrainingDataDetail from '../components/training/TrainingDataDetail';

const TrainingDataPage = () => {
  const { t } = useTranslation(); // Initialize translation hook
  const { agentId } = useParams();
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // State for pagination, filtering, and sorting
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [searchTerm, setSearchTerm] = useState('');
  const [statusFilter, setStatusFilter] = useState('all'); 
  const [sortConfig, setSortConfig] = useState({ field: 'created_at', direction: 'desc' });
  
  // Build query parameters for API
  const queryParams = useMemo(() => ({
    page,
    page_size: pageSize,
    search: searchTerm,
    ...(statusFilter === 'trained' ? { added_to_memory: true } : {}),
    ...(statusFilter === 'untrained' ? { added_to_memory: false } : {}),
    sort_by: sortConfig.field ? `${sortConfig.direction === 'desc' ? '-' : ''}${sortConfig.field}` : '',
  }), [page, pageSize, searchTerm, statusFilter, sortConfig.field, sortConfig.direction]);

  // API hooks
  const { data: agent, isLoading: isAgentLoading } = useGetAgentQuery(agentId);
  const { 
    data: trainingDataResponse, 
    isLoading: isDataLoading, 
    refetch,
    isFetching
  } = useGetTrainingDataQuery({ agentId, ...queryParams });
  
  const { data: statsData, isLoading: isStatsLoading } = useGetTrainingStatsQuery(agentId);
  
  const [createTrainingData, { isLoading: isCreating }] = useCreateTrainingDataMutation();
  const [deleteTrainingData] = useDeleteTrainingDataMutation();
  const [trainAgent, { isLoading: isTraining }] = useTrainAgentMutation();
  const [generateTrainingData, { isLoading: isGenerating }] = useGenerateTrainingDataMutation();
  const [batchTrainData, { isLoading: isBatchTraining }] = useBatchTrainTrainingDataMutation();

  // State for UI elements
  const [filterMenuAnchor, setFilterMenuAnchor] = useState(null);
  const [actionMenuAnchor, setActionMenuAnchor] = useState(null);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [selectedDataIds, setSelectedDataIds] = useState([]);
  const [selectedDataItem, setSelectedDataItem] = useState(null);

  // State for dialogs
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [openImportDialog, setOpenImportDialog] = useState(false);
  const [openExportDialog, setOpenExportDialog] = useState(false);
  const [openDetailDialog, setOpenDetailDialog] = useState(false);

  // Form data state
  const [formData, setFormData] = useState({
    question: '',
    answer: '',
  });

  // Extract paginated data and metadata
  const trainingData = trainingDataResponse?.data || trainingDataResponse || [];
  const metaData = trainingDataResponse?.meta || {
    totalCount: Array.isArray(trainingData) ? trainingData.length : 0,
    totalPages: 1,
    currentPage: 1,
    hasNextPage: false,
    hasPreviousPage: false
  };

  // Use stats data if available
  const stats = statsData || {
    totalCount: metaData.totalCount,
    trainedCount: 0,
    untrainedCount: metaData.totalCount,
    trainingProgress: 0
  };

  // Event Handlers
  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handlePageSizeChange = (event) => {
    setPageSize(event.target.value);
    setPage(1); // Reset to first page when changing page size
  };

  const handleRequestSort = (field) => {
    setSortConfig({
      field,
      direction: 
        sortConfig.field === field && sortConfig.direction === 'asc'
          ? 'desc'
          : 'asc',
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setPage(1); // Reset to first page when searching
  };

  const handleFilterClick = (event) => {
    setFilterMenuAnchor(event.currentTarget);
  };

  const handleFilterClose = () => {
    setFilterMenuAnchor(null);
  };

  const handleFilterChange = (filter) => {
    setStatusFilter(filter);
    setFilterMenuAnchor(null);
    setPage(1); // Reset to first page when changing filters
  };

  const handleAddSubmit = async () => {
    try {
      await createTrainingData({
        agentId,
        question: formData.question,
        answer: formData.answer,
      }).unwrap();

      dispatch(setSnackbarStatus({
        open: true,
        severity: 'success',
        message: t('Training data added successfully'),
      }));

      setFormData({
        question: '',
        answer: '',
      });

      setOpenAddDialog(false);
      refetch();
    } catch (err) {
      dispatch(setSnackbarStatus({
        open: true,
        severity: 'error',
        message: t('Failed to add training data: {{error}}', { error: err.data?.detail || t('Unknown error') }),
      }));
    }
  };

  const handleDeleteClick = (id, event) => {
    if (event) {
      event.stopPropagation();
    }
    setSelectedDataIds([id]);
    setShowDeleteDialog(true);
  };

  const handleDelete = async () => {
    try {
      await deleteTrainingData({
        agentId,
        id: selectedDataIds[0],
      }).unwrap();

      dispatch(setSnackbarStatus({
        open: true,
        severity: 'success',
        message: t('Training data deleted successfully'),
      }));
      
      refetch();
    } catch (err) {
      dispatch(setSnackbarStatus({
        open: true,
        severity: 'error',
        message: t('Failed to delete training data: {{error}}', { error: err.data?.detail || t('Unknown error') }),
      }));
    } finally {
      setShowDeleteDialog(false);
    }
  };

  const handleTrain = async () => {
    try {
      await trainAgent(agentId).unwrap();

      dispatch(setSnackbarStatus({
        open: true,
        severity: 'success',
        message: t('Agent trained successfully'),
      }));
      
      refetch();
    } catch (err) {
      dispatch(setSnackbarStatus({
        open: true,
        severity: 'error',
        message: t('Failed to train agent: {{error}}', { error: err.data?.detail || t('Unknown error') }),
      }));
    }
  };

  const handleGenerate = async () => {
    try {
      await generateTrainingData(agentId).unwrap();

      dispatch(setSnackbarStatus({
        open: true,
        severity: 'success',
        message: t('Training data generated successfully'),
      }));
      
      refetch();
    } catch (err) {
      dispatch(setSnackbarStatus({
        open: true,
        severity: 'error',
        message: t('Failed to generate training data: {{error}}', { error: err.data?.detail || t('Unknown error') }),
      }));
    }
  };

  const handleRowClick = (item) => {
    setSelectedDataItem(item);
    setOpenDetailDialog(true);
  };

  const handleActionMenu = (event, id) => {
    event.stopPropagation();
    setSelectedDataIds([id]);
    setActionMenuAnchor(event.currentTarget);
  };

  const handleActionMenuClose = () => {
    setActionMenuAnchor(null);
  };

  const handleTrainSingle = async () => {
    try {
      await batchTrainData({
        agentId,
        ids: selectedDataIds
      }).unwrap();
      
      dispatch(setSnackbarStatus({
        open: true,
        severity: 'success',
        message: t('Training data added to agent memory successfully'),
      }));
      
      refetch();
    } catch (err) {
      dispatch(setSnackbarStatus({
        open: true,
        severity: 'error',
        message: t('Failed to train data: {{error}}', { error: err.data?.detail || t('Unknown error') }),
      }));
    } finally {
      setActionMenuAnchor(null);
    }
  };

  const isLoading = isAgentLoading || isDataLoading || isStatsLoading;

  return (
    <Box>
      <PageHeader
        title={agent?.name ? t('Training Data: {{name}}', { name: agent.name }) : t('Training Data')}
        subtitle={t('Manage the training data for your AI agent')}
        action={
          <Button
            variant="outlined"
            startIcon={<ArrowBackIcon />}
            onClick={() => navigate(`/agents/${agentId}`)}
          >
            {t('Back to Agent')}
          </Button>
        }
      />

      {isLoading ? (
        <Box sx={{ width: '100%', mt: 2 }}>
          <LinearProgress />
        </Box>
      ) : (
        <>
          <Card sx={{ mb: 4 }}>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <Typography variant="h6" gutterBottom>
                    {t('Agent Information')}
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    <strong>{t('Name:')}</strong> {agent?.name}
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    <strong>{t('Description:')}</strong> {agent?.description}
                  </Typography>
                  <Typography variant="body2">
                    <strong>{t('Created:')}</strong> {new Date(agent?.created_at).toLocaleString()}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography variant="h6" gutterBottom>
                    {t('Training Progress')}
                  </Typography>
                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                    <Typography variant="body2" sx={{ mr: 2 }}>
                      {t('{{trainedCount}} of {{totalCount}} items trained', { trainedCount: stats.trainedCount, totalCount: stats.totalCount })}
                      {stats.untrainedCount > 0 && t(' ({{count}} untrained)', { count: stats.untrainedCount })}
                    </Typography>
                    <Chip
                      label={`${Math.round(stats.trainingProgress || 0)}%`}
                      color={stats.trainingProgress === 100 ? "success" : "primary"}
                      size="small"
                    />
                  </Box>
                  <LinearProgress
                    variant="determinate"
                    value={stats.trainingProgress || 0}
                    sx={{ height: 10, borderRadius: 5, mb: 2 }}
                  />
                  <Box sx={{ display: 'flex', gap: 2, mt: 2 }}>
                    <Button
                      variant="contained"
                      onClick={handleTrain}
                      disabled={isTraining || stats.totalCount === 0 || stats.trainedCount === stats.totalCount}
                      startIcon={isTraining ? <CircularProgress size={20} color="inherit" /> : <CheckIcon />}
                    >
                      {isTraining ? t('Training...') : t('Train Agent')}
                    </Button>
                    <Button
                      variant="outlined"
                      onClick={handleGenerate}
                      disabled={isGenerating}
                      startIcon={isGenerating ? <CircularProgress size={20} /> : <AutorenewIcon />}
                    >
                      {isGenerating ? t('Generating...') : t('Generate Data')}
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </CardContent>
          </Card>

          <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3, flexWrap: 'wrap', gap: 2 }}>
            <Typography variant="h6">
              {t('Training Data')}
            </Typography>
            <Box sx={{ display: 'flex', gap: 2 }}>
              <TextField
                placeholder={t('Search training data...')}
                size="small"
                value={searchTerm}
                onChange={handleSearchChange}
                InputProps={{
                  startAdornment: <InputAdornment position="start"><SearchIcon fontSize="small" /></InputAdornment>,
                }}
                sx={{ width: 250 }}
              />
              
              <Button
                variant="outlined"
                startIcon={<FilterListIcon />}
                onClick={handleFilterClick}
                color={statusFilter !== 'all' ? 'primary' : 'inherit'}
              >
                {statusFilter === 'all' 
                  ? t('Filter') 
                  : statusFilter === 'trained' 
                    ? t('Trained Only') 
                    : t('Untrained Only')}
              </Button>
              
              <Menu
                anchorEl={filterMenuAnchor}
                open={Boolean(filterMenuAnchor)}
                onClose={handleFilterClose}
              >
                <MenuItem 
                  onClick={() => handleFilterChange('all')}
                  selected={statusFilter === 'all'}
                >
                  <ListItemText>{t('All Data')}</ListItemText>
                </MenuItem>
                <MenuItem 
                  onClick={() => handleFilterChange('trained')}
                  selected={statusFilter === 'trained'}
                >
                  <ListItemText>{t('Trained Only')}</ListItemText>
                </MenuItem>
                <MenuItem 
                  onClick={() => handleFilterChange('untrained')}
                  selected={statusFilter === 'untrained'}
                >
                  <ListItemText>{t('Untrained Only')}</ListItemText>
                </MenuItem>
              </Menu>
              
              <Button
                variant="outlined"
                startIcon={<DownloadIcon />}
                onClick={() => setOpenExportDialog(true)}
              >
                {t('Export')}
              </Button>
              <Button
                variant="outlined"
                startIcon={<UploadIcon />}
                onClick={() => setOpenImportDialog(true)}
              >
                {t('Import')}
              </Button>
              <Button
                variant="contained"
                startIcon={<AddIcon />}
                onClick={() => setOpenAddDialog(true)}
              >
                {t('Add Data')}
              </Button>
            </Box>
          </Box>

          {metaData.totalCount === 0 ? (
            <EmptyState
              title={t('No training data yet')}
              description={t('Add training data to teach your agent specific knowledge')}
              action={
                <Button
                  variant="contained"
                  startIcon={<AddIcon />}
                  onClick={() => setOpenAddDialog(true)}
                >
                  {t('Add Training Data')}
                </Button>
              }
            />
          ) : trainingData.length === 0 && searchTerm ? (
            <EmptyState
              title={t('No matching results')}
              description={t('No training data matches your current filters{{searchTerm}}', { 
                searchTerm: searchTerm ? t(' for "{{term}}"', { term: searchTerm }) : '' 
              })}
              action={
                <Button
                  variant="outlined"
                  onClick={() => {
                    setSearchTerm('');
                    setStatusFilter('all');
                  }}
                >
                  {t('Clear Filters')}
                </Button>
              }
            />
          ) : (
            <>
              {isFetching && (
                <Box sx={{ width: '100%', mb: 2 }}>
                  <LinearProgress />
                </Box>
              )}
              
              <TableContainer component={Paper} elevation={2}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell width="40%">
                        <TableSortLabel
                          active={sortConfig.field === 'question'}
                          direction={sortConfig.field === 'question' ? sortConfig.direction : 'asc'}
                          onClick={() => handleRequestSort('question')}
                        >
                          {t('Question')}
                        </TableSortLabel>
                      </TableCell>
                      <TableCell width="40%">
                        <TableSortLabel
                          active={sortConfig.field === 'answer'}
                          direction={sortConfig.field === 'answer' ? sortConfig.direction : 'asc'}
                          onClick={() => handleRequestSort('answer')}
                        >
                          {t('Answer')}
                        </TableSortLabel>
                      </TableCell>
                      <TableCell width="10%" align="center">
                        <TableSortLabel
                          active={sortConfig.field === 'added_to_memory'}
                          direction={sortConfig.field === 'added_to_memory' ? sortConfig.direction : 'asc'}
                          onClick={() => handleRequestSort('added_to_memory')}
                        >
                          {t('Status')}
                        </TableSortLabel>
                      </TableCell>
                      <TableCell width="10%" align="center">{t('Actions')}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Array.isArray(trainingData) && trainingData.map((item) => (
                      <TableRow 
                        key={item.id}
                        hover
                        onClick={() => handleRowClick(item)}
                        sx={{ cursor: 'pointer' }}
                      >
                        <TableCell>
                          <Tooltip title={item.question} enterDelay={500}>
                            <Typography variant="body2" noWrap sx={{ maxWidth: 300 }}>
                              {item.question}
                            </Typography>
                          </Tooltip>
                        </TableCell>
                        <TableCell>
                          <Tooltip title={item.answer} enterDelay={500}>
                            <Typography variant="body2" noWrap sx={{ maxWidth: 300 }}>
                              {item.answer}
                            </Typography>
                          </Tooltip>
                        </TableCell>
                        <TableCell align="center">
                          {item.added_to_memory ? (
                            <Chip
                              label={t('Trained')}
                              size="small"
                              color="success"
                              icon={<CheckIcon />}
                            />
                          ) : (
                            <Chip
                              label={t('Untrained')}
                              size="small"
                              color="default"
                              icon={<ClearIcon />}
                            />
                          )}
                        </TableCell>
                        <TableCell align="center">
                          <IconButton
                            size="small"
                            onClick={(e) => handleActionMenu(e, item.id)}
                            aria-label={t('Item actions')}
                          >
                            <MoreVertIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 3 }}>
                <FormControl variant="outlined" size="small" sx={{ minWidth: 120 }}>
                  <InputLabel id="page-size-select-label">{t('Per Page')}</InputLabel>
                  <Select
                    labelId="page-size-select-label"
                    id="page-size-select"
                    value={pageSize}
                    onChange={handlePageSizeChange}
                    label={t('Per Page')}
                  >
                    <MenuItem value={5}>5</MenuItem>
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={25}>25</MenuItem>
                    <MenuItem value={50}>50</MenuItem>
                  </Select>
                </FormControl>
                
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Typography variant="body2" sx={{ mr: 2 }}>
                    {t('Showing {{shown}} of {{total}} items', { 
                      shown: Array.isArray(trainingData) ? trainingData.length : 0, 
                      total: metaData.totalCount 
                    })}
                  </Typography>
                  <Pagination
                    count={metaData.totalPages}
                    page={page}
                    onChange={handlePageChange}
                    color="primary"
                    disabled={isFetching}
                  />
                </Box>
              </Box>
            </>
          )}
        </>
      )}

      {/* Add Training Data Dialog */}
      <Dialog open={openAddDialog} onClose={() => setOpenAddDialog(false)} maxWidth="md" fullWidth>
        <DialogTitle>{t('Add Training Data')}</DialogTitle>
        <Box sx={{ p: 3 }}>
          <Typography gutterBottom>
            {t('Add question-answer pairs to train your agent with specific knowledge.')}
          </Typography>
          <TextField
            autoFocus
            name="question"
            label={t('Question')}
            fullWidth
            multiline
            rows={3}
            value={formData.question}
            onChange={handleChange}
            sx={{ mt: 2 }}
          />
          <TextField
            name="answer"
            label={t('Answer')}
            fullWidth
            multiline
            rows={4}
            value={formData.answer}
            onChange={handleChange}
            sx={{ mt: 2 }}
          />
          
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3, gap: 2 }}>
            <Button onClick={() => setOpenAddDialog(false)}>{t('Cancel')}</Button>
            <Button
              onClick={handleAddSubmit}
              variant="contained"
              disabled={isCreating || !formData.question.trim() || !formData.answer.trim()}
            >
              {isCreating ? t('Adding...') : t('Add')}
            </Button>
          </Box>
        </Box>
      </Dialog>
      
      {/* Import Dialog - Use the new component */}
      <ImportDialog
        open={openImportDialog}
        onClose={() => {
          setOpenImportDialog(false);
          refetch(); // Refresh data after import
        }}
        agentId={agentId}
      />
      
      {/* Export Dialog - Use the new component */}
      <ExportDialog
        open={openExportDialog}
        onClose={() => setOpenExportDialog(false)}
        agentId={agentId}
        agentName={agent?.name}
        trainingCount={stats.totalCount}
      />
      
      {/* Detail Dialog - Use the new component */}
      <TrainingDataDetail
        open={openDetailDialog}
        onClose={() => {
          setOpenDetailDialog(false);
          refetch(); // Refresh data after changes
        }}
        item={selectedDataItem}
        agentId={agentId}
      />

      {/* Delete Confirmation Dialog */}
      <AlertDialog
        open={showDeleteDialog}
        title={t('Delete Training Data')}
        content={t('Are you sure you want to delete this training data? This action cannot be undone.')}
        confirmText={t('Delete')}
        confirmColor="error"
        onConfirm={handleDelete}
        onCancel={() => setShowDeleteDialog(false)}
      />
      
      {/* Action Menu */}
      <Menu
        anchorEl={actionMenuAnchor}
        open={Boolean(actionMenuAnchor)}
        onClose={handleActionMenuClose}
      >
        <MenuItem onClick={() => {
          const item = Array.isArray(trainingData) && trainingData.find(i => i.id === selectedDataIds[0]);
          if (item) {
            setSelectedDataItem(item);
            setOpenDetailDialog(true);
            handleActionMenuClose();
          }
        }}>
          <ListItemIcon>
            <SearchIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>{t('View Details')}</ListItemText>
        </MenuItem>
        
        {selectedDataIds.length > 0 && 
         Array.isArray(trainingData) && 
         trainingData.find(i => i.id === selectedDataIds[0]) && 
         !trainingData.find(i => i.id === selectedDataIds[0]).added_to_memory && (
          <MenuItem onClick={handleTrainSingle} disabled={isBatchTraining}>
            <ListItemIcon>
              <CheckIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>{t('Train Item')}</ListItemText>
          </MenuItem>
        )}
        
        <MenuItem onClick={() => {
          handleActionMenuClose();
          handleDeleteClick(selectedDataIds[0]);
        }}>
          <ListItemIcon>
            <DeleteIcon fontSize="small" color="error" />
          </ListItemIcon>
          <ListItemText primary={t('Delete')} primaryTypographyProps={{ color: 'error' }} />
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default TrainingDataPage;