import React from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';

const Row = (props) => {
  const { children, ...otherProps } = props;
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  
  // Filter out null/undefined/false children
  const validChildren = React.Children.toArray(children).filter(Boolean);
  const colCount = Math.max(validChildren.length, 2);

  return (
    <Box
      display="flex"
      flexDirection="row"
      flexWrap="wrap"
      {...otherProps}
    >
      {validChildren.map((child, index) => (
        <Box
          key={index}
          sx={{
            mb: 1,
            pt: 1,
            display: 'flex',
            overflow: 'hidden',
            flexDirection: 'column',
            flex: isMobile ? '1 1 100%' : `0 0 ${100 / colCount}%`
          }}
        >
          {child}
        </Box>
      ))}
    </Box>
  );
};

export default Row;