import { useMsal } from '@azure/msal-react';
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Container,
  Divider,
  List,
  ListItem,
  ListItemText,
  Paper,
  Stack,
  Typography
} from '@mui/material';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';

import TermOfUseDialog from '../components/TermOfUseDialog';
import { useIsTakecareAuthenticated } from '../hooks';
import { greyboxApiActions } from '../redux/api';
import { setCurrentClinic } from '../redux/clinic';
import { initializeUser } from '../redux/user';

import EditAccountPage from '../components/accounts/EditAccountPage';
import EducationPanel from '../components/education/EducationPanel';
import AuthenticateForQuestionnaire from './AuthenticateForQuestionnaire';
import ClinicSettings from './ClinicSettings';
import CompleteRegistration from './CompleteRegistration';
import Dashboard from './Dashboard';
import Login from './Login';
import MessagesDashboard from './MessagesDashboard';
import OnInvitationQuestionnaire from './OnInvitationQuestionnaire';
import PatientProfile from './PatientProfile';
import PendingInvitations from './PendingInvitations';
import UserInformation from './UserInformation';
import AgentDashboardPage from './AgentDashboardPage';
import AgentDetailPage from './AgentDetailPage';
import TrainingDataPage from './TrainingDataPage';

/**
 * Pages only accessible when authenticated.
 * Handles authentication state, user initialization, and routing.
 */
const AuthenticatedPages = ({ setIsLoading }) => {
  const { instance } = useMsal();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t , i18n} = useTranslation();
  const isAuthenticated = useIsTakecareAuthenticated();
  
  // Session recovery helpers
  const handleRetry = useCallback(() => {
    window.location.reload();
  }, []);
  
  // Proper logout handling without redirect
  const handleSignOut = useCallback(async () => {
    try {
      // Clear local storage of any user session data
      localStorage.removeItem('clinic');
      
      // Use the standard logout method instead of redirect
      await instance.logout();
      
      // Instead of relying on MSAL redirect, we can navigate programmatically
      // after the logout completes
      navigate('/');
    } catch (error) {
      console.error('Logout failed:', error);
      // If logout fails, we can still try to redirect to login page
      navigate('/');
    }
  }, [instance, navigate]);
  
  // Redux selectors
  const clinic = useSelector((state) => state.clinic);
  const user = useSelector((state) => state.user);
  const { isInvitation } = useSelector((state) => state.invitation);
  const { authState } = useSelector((state) => state.authorization);
  
  const [showTerms, setShowTerms] = useState(false);
  const [authError, setAuthError] = useState(false);
  const isCompleteQuestionnaire = authState === 'questionnaire';
  
  // API actions
  const { company, account } = greyboxApiActions;
  
  // API queries with proper dependencies
  const companySelector = company.list({}, {
    skip: !isAuthenticated,
  });
  
  const accountSelector = account.list(
    { 
      self: true, 
      clinic: clinic.clinic?.id, 
      invitations: true 
    },
    { 
      skip: !isAuthenticated || clinic.isLoading || (!user.isUninitialized && !showTerms) 
    },
  );

  // Determine loading state
  const isLoading = (companySelector.isFetching || accountSelector.isFetching || clinic.isLoading);

  // Update loading state
  useEffect(() => {
    setIsLoading(isLoading || (!isCompleteQuestionnaire && clinic.isUninitialized));
  }, [isLoading, clinic.isUninitialized, isCompleteQuestionnaire, setIsLoading]);

  // Handle terms of use dialog
  useEffect(() => {
    const shouldShowTerms = 
      accountSelector.isSuccess && 
      accountSelector.data[0] && 
      !accountSelector.data[0].accepted_terms_on;
    
    setShowTerms(shouldShowTerms);
  }, [accountSelector.data, accountSelector.isSuccess]);

  // Handle user initialization and clinic selection
  useEffect(() => {
    if (isCompleteQuestionnaire || !companySelector.isSuccess || !accountSelector.isSuccess) {
      return;
    }

    // Handle empty company list
    if (companySelector.data.length === 0) {
      setAuthError(true);
      return;
    }

    // Set user language preference
    const userAccount = accountSelector.data[0];
    if (moment.locale() !== userAccount.language) {
      moment.locale(userAccount.language);
    }

    // Check if i18n is properly initialized before changing language
    if (userAccount.language !== i18n.resolvedLanguage ) {
      try {
        i18n.changeLanguage(userAccount.language);
      } catch (error) {
        console.error('Failed to change language:', error);
      }
    }

    // Set current clinic
    const cachedClinicId = JSON.parse(localStorage.getItem('clinic'));
    let selectedClinic;
    
    if (cachedClinicId && companySelector.data.find((c) => c.id === cachedClinicId)) {
      selectedClinic = companySelector.data.find((c) => c.id === cachedClinicId);
    } else {
      selectedClinic = companySelector.data[0];
    }
    
    dispatch(setCurrentClinic(selectedClinic));

    // Initialize user if needed
    if (user.isUninitialized) {
      dispatch(initializeUser(userAccount));
    }

    // Handle invitations
    if (isInvitation || userAccount.invitations.length > 0) {
      navigate(userAccount.invitations.length > 0 ? '/complete-registration' : '/');
    }
  }, [
    companySelector.isSuccess, 
    companySelector.data, 
    accountSelector.isSuccess, 
    accountSelector.data,
    isCompleteQuestionnaire,
    user.isUninitialized,
    isInvitation,
    navigate,
    dispatch,
    i18n
  ]);

  // Handle authentication issues with better UX
  useEffect(() => {
    if (
      !isCompleteQuestionnaire && 
      !isLoading && 
      !accountSelector.isUninitialized && 
      !accountSelector.isSuccess
    ) {
      // Show error state instead of direct logout
      setAuthError(true);
    } else {
      setAuthError(false);
    }
  }, [
    isCompleteQuestionnaire, 
    isLoading, 
    accountSelector.isUninitialized, 
    accountSelector.isSuccess
  ]);

  // Show loading state
  if (isLoading || (!isCompleteQuestionnaire && clinic.isUninitialized)) {
    return (
      <Box 
        sx={{ 
          display: 'flex', 
          flexDirection: 'column', 
          alignItems: 'center', 
          justifyContent: 'center', 
          height: '100vh' 
        }}
      >
        <CircularProgress size={40} />
        <Typography variant="body1" sx={{ mt: 2 }}>
          {t('Loading your information...')}
        </Typography>
      </Box>
    );
  }

  // Handle authentication errors with better UX
  if (authError) {
    return (
      <Container maxWidth="sm" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
        <Paper elevation={3} sx={{ p: 4, width: '100%' }}>
          <Typography variant="h5" component="h2" gutterBottom align="center">
            {t('Access Issue Detected')}
          </Typography>
          
          <Alert severity="warning" sx={{ my: 2 }}>
            {t("We couldn't verify your access to this clinic.")}
          </Alert>
          
          <Typography variant="body1" gutterBottom>
            {t('This might happen if:')}
          </Typography>
          
          <List>
            <ListItem>
              <ListItemText primary={t('Your account is not associated with this clinic')} />
            </ListItem>
            <ListItem>
              <ListItemText primary={t('Your session has expired')} />
            </ListItem>
            <ListItem>
              <ListItemText primary={t('There was a temporary connection issue')} />
            </ListItem>
          </List>
          
          <Divider sx={{ my: 2 }} />
          
          <Stack spacing={2} direction="column">
            <Button 
              variant="contained" 
              color="primary" 
              fullWidth
              onClick={handleRetry}
            >
              {t('Try Again')}
            </Button>
            <Button 
              variant="outlined"
              fullWidth
              onClick={handleSignOut}
            >
              {t('Sign Out')}
            </Button>
          </Stack>
        </Paper>
      </Container>
    );
  }

  // Show terms of use dialog if needed
  if (showTerms) {
    return <TermOfUseDialog handleClose={() => setShowTerms(false)} />;
  }

  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/questionnaires-token/" element={<AuthenticateForQuestionnaire />} />
      <Route path="/patient-profile/:uuid/*" element={<PatientProfile />} />
      <Route path="/accounts/:uuid" element={<EditAccountPage />} />
      <Route path="/your-information" element={<UserInformation />} />
      <Route
        path="/pending-invitations"
        element={user.access === 'P' ? <Navigate to="/" /> : <PendingInvitations />}
      />
      <Route
        path="/clinic-settings/*"
        element={user.access === 'P' ? <Navigate to="/" /> : <ClinicSettings />}
      />
      <Route path="/complete-registration" element={<CompleteRegistration />} />
      <Route path="/education" element={<EducationPanel readOnly />} />
      <Route path="/complete-questionnaire/:tokenId" element={<OnInvitationQuestionnaire />} />
      <Route path="/messages" element={<MessagesDashboard />} />
      <Route path="/messages/:threadId" element={<MessagesDashboard />} />

      {/* Agent routes */}
      <Route path="agent-hub" element={<AgentDashboardPage />} />
      <Route path="agents/:agentId" element={<AgentDetailPage />} />
      <Route path="agents/:agentId/training-data" element={<TrainingDataPage />} />

      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};

export default AuthenticatedPages;