import {
  Alert,
  Box,
  Button,
  FormControlLabel, MenuItem,
  Skeleton,
  Switch, TextField, Typography,
} from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { greyboxApiActions } from '../../redux/api';
import { Duration } from '../form-inputs';

import Row from '../Row';
import moment from 'moment';

const MedicalAct = ({ formik }) => {
  const { t } = useTranslation();
  const { medicalActType } = greyboxApiActions;
  const { data = [], isFetching, isError, refetch } = medicalActType.list({});
  const [isAdvancedDuration, setIsAdvancedDuration] = React.useState(false);

  if (isFetching) {
    return (
      <Box sx={{ width: '100%' }}>
        <Skeleton variant="rectangular" height={56} sx={{ mb: 2 }} />
        <Skeleton variant="rectangular" height={100} sx={{ mb: 2 }} />
        <Skeleton variant="rectangular" height={56} sx={{ mb: 2 }} />
      </Box>
    );
  }

  if (isError) {
    return (
      <Box sx={{ mt: 2 }}>
        <Alert severity="error">{t('Failed to load Follow-up types')}</Alert>
        <Button variant="contained" onClick={refetch} sx={{ mt: 2 }}>
          {t('Retry')}
        </Button>
      </Box>
    );
  }

  return (
    <>
      <TextField
        fullWidth
        select
        required
        defaultValue=""
        label={t('Follow-up type')}
        name="medical_act_type"
        value={formik.values.medical_act_type}
        onChange={formik.handleChange}
        SelectProps={{
          renderValue: (value) => data.find((item) => item.uuid === value)?.name,
          MenuProps: {
            sx: {
              width: '100%',
              maxWidth: 500,
            },
          },
        }}
      >
        {data.map((item) => (
          <MenuItem
            key={item.uuid}
            value={item.uuid}
          >
            <Box sx={{ display: 'flex', flexDirection: 'column', whiteSpace: 'normal' }}>
              {item.name}
              <Typography variant="caption" color="text.secondary" flexWrap>
                {item.description}
              </Typography>
            </Box>
          </MenuItem>
        ))}
      </TextField>
      <TextField
        fullWidth
        label={t('Note')}
        name="note"
        value={formik.values.note}
        onChange={formik.handleChange}
        multiline
        rows={4}
        variant="outlined"
        sx={{ mt: 1 }}
      />
      <FormControlLabel
        control={<Switch checked={isAdvancedDuration} />}
        label={t('Advanced duration')}
        onChange={() => setIsAdvancedDuration(!isAdvancedDuration)}
      />
      {isAdvancedDuration ? (
        <Row>
          <TextField
            label={t('Start Date')}
            InputLabelProps={{ shrink: true }}
            name="start_datetime"
            type="datetime-local"
            value={formik.values.start_datetime}
            onChange={formik.handleChange}
            sx={{ mt: 1 }}
          />
          <TextField
            label={t('End Date')}
            InputLabelProps={{ shrink: true }}
            name="end_datetime"
            type="datetime-local"
            value={formik.values.end_datetime}
            onChange={formik.handleChange}
            sx={{ ml: 1, mt: 1 }}
          />
        </Row>
      ) : (
        <Duration
          formik={formik}
          label={t('Duration')}
          shortOptions
          startField="start_datetime"
          endField="end_datetime"
          defaultDuration={moment.duration(15, 'minutes')}
          useNowAsEnd
        />
      )}
    </>
  );
};

export default MedicalAct;