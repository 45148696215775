import React, { useState } from 'react';
import { CircularProgress, Dialog, IconButton, Tooltip } from '@mui/material';
import PdfViewer from './PdfViewer';
import { PictureAsPdf as PictureAsPdfIcon } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

const OpenPdfViewerButton = (props) => {
  const { t } = useTranslation();
  const { link, status, id } = props;
  const loading = status === 'pending' || status === 'in-progress';
  const [iframeUrl, setIframeUrl] = useState('');

  const closeIframe = () => {
    setIframeUrl('');
  };

  const onClick = () => {
    setIframeUrl(link);
  };

  return (
    <>
      <Dialog
        open={Boolean(iframeUrl)}
        onClose={closeIframe}
        fullScreen
        sx={{
          '& .MuiDialog-paper': { backgroundColor: 'rgba(0, 0, 0, 0)' },
        }}
      >
        <PdfViewer link={iframeUrl} setIframeUrl={setIframeUrl} id={id} />
      </Dialog>
      <Tooltip title={t('View PDF')}>
        <IconButton aria-label="view pdf" disabled={loading} onClick={onClick}>
          {loading ? <CircularProgress size={24} /> : null}
          {status !== 'error' ? <PictureAsPdfIcon /> : null}
        </IconButton>
      </Tooltip>
    </>
  );
};

export default OpenPdfViewerButton;
