// src/pages/AgentDetailPage.jsx
import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next'; // Import useTranslation hook
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  TextField,
  Typography,
  LinearProgress,
  Paper,
  Chip,
  Stack,
  IconButton,
  Tab,
  Tabs,
  Alert,
  CircularProgress,
  useTheme,
  Skeleton,
} from '@mui/material';
import {
  Edit as EditIcon,
  Save as SaveIcon,
  Cancel as CancelIcon,
  Delete as DeleteIcon,
  School as SchoolIcon,
  Psychology as PsychologyIcon,
  ArrowBack as ArrowBackIcon,
  Check as CheckIcon,
  Refresh as RefreshIcon,
  Memory as MemoryIcon,
} from '@mui/icons-material';
import { 
  useGetAgentQuery, 
  useUpdateAgentMutation,
  useDeleteAgentMutation,
  useResetAgentMemoryMutation,
  useTrainAgentMutation,
  useGetTrainingStatsQuery,
} from '../redux/api';
import { setSnackbarStatus } from '../redux/snackbarStatus';
import PageHeader from '../components/common/PageHeader';
import AlertDialog from '../components/common/AlertDialog';

const AgentDetailPage = () => {
  const { t } = useTranslation(); // Initialize translation hook
  const { agentId } = useParams();
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  
  const { data: agent, isLoading, refetch } = useGetAgentQuery(agentId);
  const { data: trainingStats, isLoading: isStatsLoading } = useGetTrainingStatsQuery(agentId);
  const [updateAgent, { isLoading: isUpdating }] = useUpdateAgentMutation();
  const [deleteAgent, { isLoading: isDeleting }] = useDeleteAgentMutation();
  const [resetMemory, { isLoading: isResetting }] = useResetAgentMemoryMutation();
  const [trainAgent, { isLoading: isTraining }] = useTrainAgentMutation();
  
  const [editMode, setEditMode] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [showResetDialog, setShowResetDialog] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    description: '',
    system_prompt: '',
  });
  const [errors, setErrors] = useState({});
  
  // Set initial form data when agent data is loaded
  React.useEffect(() => {
    if (agent) {
      setFormData({
        name: agent.name || '',
        description: agent.description || '',
        system_prompt: agent.system_prompt || '',
      });
    }
  }, [agent]);
  
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    
    // Clear error when field is edited
    if (errors[name]) {
      setErrors({
        ...errors,
        [name]: '',
      });
    }
  };
  
  const validateForm = () => {
    const newErrors = {};
    
    if (!formData.name.trim()) {
      newErrors.name = t('Name is required');
    }
    
    if (!formData.description.trim()) {
      newErrors.description = t('Description is required');
    }
    
    if (!formData.system_prompt.trim()) {
      newErrors.system_prompt = t('System prompt is required');
    }
    
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  
  const handleEdit = () => {
    setEditMode(true);
  };
  
  const handleCancel = () => {
    // Reset form data to original values
    if (agent) {
      setFormData({
        name: agent.name || '',
        description: agent.description || '',
        system_prompt: agent.system_prompt || '',
      });
    }
    setErrors({});
    setEditMode(false);
  };
  
  const handleSave = async () => {
    if (!validateForm()) return;
    
    try {
      await updateAgent({
        id: agentId,
        ...formData,
      }).unwrap();
      
      dispatch(setSnackbarStatus({
        open: true,
        severity: 'success',
        message: t('Agent updated successfully'),
      }));
      
      setEditMode(false);
    } catch (err) {
      dispatch(setSnackbarStatus({
        open: true,
        severity: 'error',
        message: t('Failed to update agent: {{error}}', { error: err.data?.detail || t('Unknown error') }),
      }));
    }
  };
  
  const handleDelete = async () => {
    try {
      await deleteAgent(agentId).unwrap();
      
      dispatch(setSnackbarStatus({
        open: true,
        severity: 'success',
        message: t('Agent deleted successfully'),
      }));
      
      navigate('/agent-hub');
    } catch (err) {
      dispatch(setSnackbarStatus({
        open: true,
        severity: 'error',
        message: t('Failed to delete agent: {{error}}', { error: err.data?.detail || t('Unknown error') }),
      }));
    } finally {
      setShowDeleteDialog(false);
    }
  };
  
  const handleReset = async () => {
    try {
      await resetMemory(agentId).unwrap();
      
      dispatch(setSnackbarStatus({
        open: true,
        severity: 'success',
        message: t('Agent memory reset successfully'),
      }));
    } catch (err) {
      dispatch(setSnackbarStatus({
        open: true,
        severity: 'error',
        message: t('Failed to reset agent memory: {{error}}', { error: err.data?.detail || t('Unknown error') }),
      }));
    } finally {
      setShowResetDialog(false);
    }
  };
  
  const handleTrain = async () => {
    try {
      await trainAgent(agentId).unwrap();
      
      dispatch(setSnackbarStatus({
        open: true,
        severity: 'success',
        message: t('Agent training initiated'),
      }));
    } catch (err) {
      dispatch(setSnackbarStatus({
        open: true,
        severity: 'error',
        message: t('Failed to train agent: {{error}}', { error: err.data?.detail || t('Unknown error') }),
      }));
    }
  };
  
  if (isLoading) {
    return (
      <Box>
        <PageHeader 
          title={t("Agent Details")}
          action={
            <Button 
              variant="outlined"
              startIcon={<ArrowBackIcon />}
              onClick={() => navigate('/agent-hub')}
            >
              {t("Back to Agents")}
            </Button>
          }
        />
        <LinearProgress />
      </Box>
    );
  }
  
  if (!agent) {
    return (
      <Box>
        <PageHeader 
          title={t("Agent Details")}
          action={
            <Button 
              variant="outlined"
              startIcon={<ArrowBackIcon />}
              onClick={() => navigate('/agent-hub')}
            >
              {t("Back to Agents")}
            </Button>
          }
        />
        <Alert severity="error">
          {t("Agent not found. It may have been deleted.")}
        </Alert>
        <Button 
          variant="contained" 
          startIcon={<ArrowBackIcon />} 
          onClick={() => navigate('/agent-hub')}
          sx={{ mt: 2 }}
        >
          {t("Back to Agents")}
        </Button>
      </Box>
    );
  }
  
  // Calculate training stats from the API or fallback to agent data
  const trainingCount = trainingStats?.totalCount || agent.trainingCount || 0;
  const trainedCount = trainingStats?.trainedCount || agent.trainedCount || 0;
  const trainingProgress = trainingStats?.trainingProgress || agent.trainingProgress || 0;
  
  // Determine status based on training progress
  let trainingStatus = 'untrained';
  if (trainedCount > 0) {
    trainingStatus = trainedCount === trainingCount ? 'ready' : 'training';
  }
  
  return (
    <Box>
      <PageHeader 
        title={editMode ? t("Edit Agent") : agent.name} 
        subtitle={editMode ? t("Modify agent details") : agent.description}
        action={
          <Stack direction="row" spacing={2}>
            <Button 
              variant="outlined"
              startIcon={<ArrowBackIcon />}
              onClick={() => navigate('/agent-hub')}
            >
              {t("Back to Agents")}
            </Button>
            {!editMode && (
              <Button 
                variant="contained"
                color="error"
                startIcon={<DeleteIcon />}
                onClick={() => setShowDeleteDialog(true)}
              >
                {t("Delete")}
              </Button>
            )}
          </Stack>
        }
      />
      
      <Box sx={{ mb: 4 }}>
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab 
            label={t("Details")}
            icon={<PsychologyIcon />} 
            iconPosition="start" 
          />
          <Tab 
            label={t("System Prompt")}
            icon={<MemoryIcon />} 
            iconPosition="start" 
          />
        </Tabs>
      </Box>
      
      {tabValue === 0 && (
        <Card elevation={3}>
          <CardContent>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
              {editMode ? (
                <Stack direction="row" spacing={2}>
                  <Button
                    variant="outlined"
                    startIcon={<CancelIcon />}
                    onClick={handleCancel}
                    disabled={isUpdating}
                  >
                    {t("Cancel")}
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={isUpdating ? <CircularProgress size={20} color="inherit" /> : <SaveIcon />}
                    onClick={handleSave}
                    disabled={isUpdating}
                  >
                    {isUpdating ? t('Saving...') : t('Save Changes')}
                  </Button>
                </Stack>
              ) : (
                <Button
                  variant="contained"
                  startIcon={<EditIcon />}
                  onClick={handleEdit}
                >
                  {t("Edit Agent")}
                </Button>
              )}
            </Box>
            
            <Grid container spacing={4}>
              <Grid item xs={12} md={6}>
                <Typography variant="h6" gutterBottom>
                  {t("Agent Information")}
                </Typography>
                
                {editMode ? (
                  <Box>
                    <TextField
                      name="name"
                      label={t("Name")}
                      value={formData.name}
                      onChange={handleChange}
                      fullWidth
                      margin="normal"
                      error={!!errors.name}
                      helperText={errors.name}
                    />
                    <TextField
                      name="description"
                      label={t("Description")}
                      value={formData.description}
                      onChange={handleChange}
                      fullWidth
                      margin="normal"
                      multiline
                      rows={3}
                      error={!!errors.description}
                      helperText={errors.description}
                    />
                  </Box>
                ) : (
                  <Paper variant="outlined" sx={{ p: 2, mt: 2 }}>
                    <Typography variant="subtitle2" gutterBottom>
                      {t("Name:")}
                    </Typography>
                    <Typography variant="body1" paragraph>
                      {agent.name}
                    </Typography>
                    
                    <Typography variant="subtitle2" gutterBottom>
                      {t("Description:")}
                    </Typography>
                    <Typography variant="body1" paragraph>
                      {agent.description}
                    </Typography>
                    
                    <Typography variant="subtitle2" gutterBottom>
                      {t("Mem0 User ID:")}
                    </Typography>
                    <Typography variant="body1" paragraph>
                      {agent.mem0_user_id}
                    </Typography>
                    
                    <Typography variant="subtitle2" gutterBottom>
                      {t("Created:")}
                    </Typography>
                    <Typography variant="body1">
                      {new Date(agent.created_at).toLocaleString()}
                    </Typography>
                  </Paper>
                )}
              </Grid>
              
              <Grid item xs={12} md={6}>
                <Typography variant="h6" gutterBottom>
                  {t("Training Progress")}
                </Typography>
                <Paper variant="outlined" sx={{ p: 3, mt: 2, height: '80%' }}>
                  {isStatsLoading ? (
                    <Box>
                      <Skeleton variant="text" width="60%" height={30} sx={{ mb: 1 }} />
                      <Skeleton variant="rectangular" height={10} sx={{ borderRadius: 5, mb: 3 }} />
                      <Skeleton variant="rectangular" height={40} sx={{ mb: 2 }} />
                      <Skeleton variant="rectangular" height={40} sx={{ mb: 2 }} />
                      <Skeleton variant="rectangular" height={40} />
                    </Box>
                  ) : (
                    <>
                      <Box sx={{ mb: 3 }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                          <Typography variant="body2" sx={{ mr: 1 }}>
                            {t("Training Status:")}
                          </Typography>
                          {trainingStatus === 'ready' ? (
                            <Chip 
                              label={t("Ready")}
                              color="success" 
                              size="small" 
                              icon={<CheckIcon />} 
                            />
                          ) : trainingStatus === 'training' ? (
                            <Chip 
                              label={t("In Progress ({{progress}}%)", { progress: Math.round(trainingProgress) })}
                              color="primary" 
                              size="small" 
                            />
                          ) : (
                            <Chip 
                              label={t("Untrained")}
                              color="default" 
                              size="small" 
                            />
                          )}
                        </Box>
                        
                        <Typography variant="body2" gutterBottom>
                          {t("{{trainedCount}} of {{trainingCount}} items trained", { trainedCount, trainingCount })}
                        </Typography>
                        
                        <LinearProgress 
                          variant="determinate" 
                          value={trainingProgress} 
                          sx={{ height: 10, borderRadius: 5, my: 2 }}
                        />
                      </Box>
                      
                      <Stack spacing={2}>
                        <Button
                          variant="contained"
                          startIcon={<SchoolIcon />}
                          onClick={() => navigate(`/agents/${agentId}/training-data`)}
                          fullWidth
                        >
                          {t("Manage Training Data")}
                        </Button>
                        
                        <Button
                          variant="outlined"
                          color="primary"
                          startIcon={isTraining ? <CircularProgress size={20} /> : <RefreshIcon />}
                          onClick={handleTrain}
                          disabled={isTraining || trainingCount === 0 || trainedCount === trainingCount}
                          fullWidth
                        >
                          {isTraining ? t('Training...') : t('Train Agent')}
                        </Button>
                        
                        <Button
                          variant="outlined"
                          color="warning"
                          startIcon={isResetting ? <CircularProgress size={20} /> : <RefreshIcon />}
                          onClick={() => setShowResetDialog(true)}
                          disabled={isResetting}
                          fullWidth
                        >
                          {isResetting ? t('Resetting...') : t('Reset Memory')}
                        </Button>
                      </Stack>
                    </>
                  )}
                </Paper>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      )}
      
      {tabValue === 1 && (
        <Card elevation={3}>
          <CardContent>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
              <Typography variant="h6">
                {t("System Prompt")}
              </Typography>
              {editMode ? (
                <Stack direction="row" spacing={2}>
                  <Button
                    variant="outlined"
                    startIcon={<CancelIcon />}
                    onClick={handleCancel}
                    disabled={isUpdating}
                  >
                    {t("Cancel")}
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={isUpdating ? <CircularProgress size={20} color="inherit" /> : <SaveIcon />}
                    onClick={handleSave}
                    disabled={isUpdating}
                  >
                    {isUpdating ? t('Saving...') : t('Save Changes')}
                  </Button>
                </Stack>
              ) : (
                <Button
                  variant="contained"
                  startIcon={<EditIcon />}
                  onClick={handleEdit}
                >
                  {t("Edit Prompt")}
                </Button>
              )}
            </Box>
            
            <Typography variant="body2" color="text.secondary" paragraph>
              {t("The system prompt guides the agent's behavior and knowledge specialization.")}
            </Typography>
            
            {editMode ? (
              <TextField
                name="system_prompt"
                value={formData.system_prompt}
                onChange={handleChange}
                fullWidth
                multiline
                rows={12}
                error={!!errors.system_prompt}
                helperText={errors.system_prompt}
                placeholder={t("Enter a detailed system prompt to guide the agent's behavior")}
              />
            ) : (
              <Paper 
                variant="outlined" 
                sx={{ 
                  p: 3, 
                  borderRadius: 2,
                  bgcolor: theme.palette.background.default,
                  whiteSpace: 'pre-wrap',
                  fontSize: '0.875rem',
                  fontFamily: 'monospace'
                }}
              >
                {agent.system_prompt || t("No system prompt defined")}
              </Paper>
            )}
          </CardContent>
        </Card>
      )}
      
      {/* Delete Confirmation Dialog */}
      <AlertDialog
        open={showDeleteDialog}
        title={t("Delete Agent")}
        content={t("Are you sure you want to delete this agent? All training data and memories will be permanently lost.")}
        confirmText={t("Delete")}
        confirmColor="error"
        onConfirm={handleDelete}
        onCancel={() => setShowDeleteDialog(false)}
      />
      
      {/* Reset Memory Confirmation Dialog */}
      <AlertDialog
        open={showResetDialog}
        title={t("Reset Agent Memory")}
        content={t("Are you sure you want to reset this agent's memory? This will clear all learned information, but the training data will be preserved.")}
        confirmText={t("Reset")}
        confirmColor="warning"
        onConfirm={handleReset}
        onCancel={() => setShowResetDialog(false)}
      />
    </Box>
  );
};

export default AgentDetailPage;