import { Add, MoreVert } from '@mui/icons-material';
import {
  Box,
  FormControlLabel,
  FormLabel,
  IconButton,
  Menu,
  MenuItem,
  Switch,
  Typography,
  Skeleton
} from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import TelecomModal from './TelecomModal';
import Row from '../Row';
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import FaxIcon from '@mui/icons-material/Fax';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';

type TelecomSystem = 'phone' | 'fax' | 'email' | 'other';
type TelecomUse = 'home' | 'work' | 'temp' | 'old' | 'mobile';

interface TelecomEntry {
  system: TelecomSystem;
  value: string;
  use?: TelecomUse;
  rank: number;
}

interface Informations {
  values: {
    telecom: TelecomEntry[];
  };
  setFieldValue: (field: string, value: TelecomEntry[]) => void;
}

interface TelecomProps {
  informations: Informations;
  disabled?: boolean;
  isLoading?: boolean;
}

const getIcon = (system: TelecomSystem) => {
  switch (system) {
    case 'phone':
      return <PhoneIcon />;
    case 'fax':
      return <FaxIcon />;
    case 'email':
      return <EmailIcon />;
    default:
      return <SmartphoneIcon />;
  }
};

const Telecom: React.FC<TelecomProps> = ({ informations, disabled = false, isLoading = false }) => {
  const [editIndex, setEditIndex] = React.useState<number | null>(null);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [menuIndex, setMenuIndex] = React.useState<number | null>(null);
  const { t } = useTranslation();

  const handleEdit = (index: number) => {
    setEditIndex(index);
    setModalOpen(true);
    handleMenuClose();
  };

  const handleRankChange = (index: number, rank: boolean) => {
    const updatedTelecoms = informations.values.telecom.map((telecom, i) => ({
      ...telecom,
      rank: i === index ? (rank ? 1 : 0) : (rank ? 0 : telecom.rank)
    }));
    informations.setFieldValue('telecom', updatedTelecoms);
  };

  const handleAdd = (telecom: TelecomEntry) => {
    if (editIndex !== null) {
      const updatedTelecoms = informations.values.telecom.slice();
      updatedTelecoms[editIndex] = telecom;
      informations.setFieldValue('telecom', updatedTelecoms);
      setEditIndex(null);
    } else {
      informations.setFieldValue('telecom', [...informations.values.telecom, telecom]);
    }
  };

  const handleRemove = (index: number) => {
    const updatedTelecoms = informations.values.telecom.slice();
    updatedTelecoms.splice(index, 1);
    informations.setFieldValue('telecom', updatedTelecoms);
    handleMenuClose();
  };

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>, index: number) => {
    if (!disabled) {
      setAnchorEl(event.currentTarget);
      setMenuIndex(index);
    }
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setMenuIndex(null);
  };

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center', mx: 1 }}>
        <FormLabel>{t('Contact Informations')}</FormLabel>
        {!disabled && (
          <IconButton
            size="small"
            sx={{ ml: 1 }}
            onClick={() => setModalOpen(true)}
            disabled={isLoading}  
          >
            <Add />
          </IconButton>
        )}
      </Box>

      <Row>
        {isLoading ? (
          [0, 1].map((index) => (
            <Box
              key={index}
              sx={{
                border: (theme) => `1px solid ${theme.palette.divider}`,
                display: 'flex',
                flexDirection: 'row',
                mx: 1,
                borderRadius: 3,
                p: 1,
                width: 300
              }}
            >
              <Box sx={{ pl: 1, flexGrow: 1 }}>
                <Skeleton variant="text" width="40%" height={24} />
                <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                  <Skeleton variant="circular" width={24} height={24} />
                  <Skeleton variant="text" width="60%" height={20} sx={{ ml: 1 }} />
                </Box>
              </Box>
              <Box sx={{ ml: 'auto', alignSelf: 'flex-start' }}>
                <Skeleton variant="circular" width={32} height={32} />
              </Box>
            </Box>
          ))
        ) : (
          informations.values.telecom.map((id, index) => (
            <Box
              key={index}
              sx={{
                border: (theme) => `1px solid ${theme.palette.divider}`,
                display: 'flex',
                flexDirection: 'row',
                mx: 1,
                borderRadius: 3,
                p: 1,
              }}
            >
              <Box sx={{ pl: 1 }} display="flex" flexDirection="column">
                <FormControlLabel
                  control={
                    <Switch
                      checked={id.rank === 1}
                      onChange={(event) => handleRankChange(index, event.target.checked)}
                      disabled={disabled}
                    />
                  }
                  label={t('Prefered')}
                />
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  {getIcon(id.system)}
                  <Typography sx={{ ml: 1 }}>
                    {`${id.value}${id.use ? ` (${t(id.use)})` : ''}`}
                  </Typography>
                </Box>
              </Box>
              <Box sx={{ ml: 'auto', alignSelf: 'flex-start' }}>
                <IconButton
                  size="small"
                  onClick={(event) => handleMenuOpen(event, index)}
                  disabled={disabled}
                >
                  <MoreVert sx={{ transform: 'scale(0.8)' }} />
                </IconButton>
                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl) && menuIndex === index}
                  onClose={handleMenuClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                >
                  <MenuItem
                    onClick={() => handleEdit(index)}
                    disabled={disabled}
                  >
                    {t('Edit')}
                  </MenuItem>
                  <MenuItem
                    sx={{ color: (theme) => theme.palette.error.main }}
                    onClick={() => handleRemove(index)}
                    disabled={disabled}
                  >
                    {t('Remove')}
                  </MenuItem>
                </Menu>
              </Box>
            </Box>
          ))
        )}
      </Row>

      {modalOpen && (
        <TelecomModal
          open={modalOpen}
          onClose={() => setModalOpen(false)}
          onChange={handleAdd}
          telecom={editIndex !== null ? informations.values.telecom[editIndex] : {}}
        />
      )}
    </>
  );
};

export default Telecom;