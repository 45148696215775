import CloseIcon from '@mui/icons-material/Close';
import PrintIcon from '@mui/icons-material/Print';
import {
    Box,
    Divider,
    IconButton,
    Skeleton,
    Typography,
    Paper,
    useTheme,
    Dialog,
    Slide,
    Stack
} from '@mui/material';
import { styled } from '@mui/material/styles';
import moment from 'moment';
import React, { forwardRef, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useReactToPrint } from 'react-to-print';
import { greyboxApiActions } from '../../../redux/api';
import AnswerFormatter from './AnswerFormatter';
import PrintableAnswers from './PrintableAnswers';

const Transition = forwardRef((props, ref) => {
    return <Slide direction="up" ref={ref} {...props} />;
});

const PaperContainer = styled(Paper)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '90vh',
    overflow: 'hidden',
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[24],
    position: 'relative',
}));

const Header = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
    borderBottom: `1px solid ${theme.palette.divider}`,
    position: 'sticky',
    top: 0,
    zIndex: 1,
    background: theme.palette.background.default,
}));

const ContentContainer = styled(Box)(({ theme }) => ({
    flex: 1,
    overflow: 'auto',
    padding: theme.spacing(4),
    backgroundColor: theme.palette.background.paper,
    [theme.breakpoints.down('md')]: {
        padding: theme.spacing(2),
    },
}));

const MetadataSection = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    marginBottom: theme.spacing(4),
    flexWrap: 'wrap',
    gap: theme.spacing(2),
}));

const AnswerSection = styled(Box)(({ theme }) => ({
    maxWidth: theme.breakpoints.values.lg,
    margin: '0 auto',
}));

const QuestionnaireAnswersFullscreen = ({ handleClose, recordId }) => {
    const theme = useTheme();
    const { t } = useTranslation();
    const { questionnaireAnswers, questionnaire, account } = greyboxApiActions;
    const { clinic } = useSelector((state) => state.clinic);
    const printRef = useRef();
    const { data, isLoading, isError } = questionnaireAnswers.get(recordId);
    const accountSelector = account.get(data?.patient, { skip: !data });
    const questionnaireSelector = questionnaire.list(
        {
            id: data?.questionnaire,
            clinic: clinic.id,
        },
        {
            skip: isLoading || !data,
        }
    );

    const handlePrint = useReactToPrint({
        content: () => printRef.current,
        documentTitle: questionnaireSelector.data?.name,
    });

    if (isLoading || questionnaireSelector.isLoading || accountSelector?.isLoading) {
        return (
            <Dialog
                open
                onClose={handleClose}
                TransitionComponent={Transition}
                maxWidth="lg"
                fullWidth
                PaperProps={{
                    sx: {
                        maxHeight: '90vh',
                        bgcolor: 'background.default',
                    }
                }}
            >
                <PaperContainer>
                    <Header>
                        <Stack spacing={1} sx={{ flexGrow: 1 }}>
                            <Skeleton variant="text" width={300} height={40} data-testid="skeleton-header-title" />
                            <Skeleton variant="text" width={200} height={24} />
                        </Stack>
                        <Box sx={{ display: 'flex', gap: 1 }}>
                            <Skeleton variant="circular" width={40} height={40} />
                            <Skeleton variant="circular" width={40} height={40} />
                        </Box>
                    </Header>
                    <ContentContainer>
                        <AnswerSection>
                            {[1, 2, 3].map((i) => (
                                <Box key={i} sx={{ mb: 4 }}>
                                    <Skeleton variant="text" width="60%" height={30} data-testid="question-skeleton" />
                                    <Skeleton variant="rectangular" width="100%" height={100} sx={{ mt: 1, borderRadius: 1 }} />
                                    {i < 3 && <Divider sx={{ my: 4 }} />}
                                </Box>
                            ))}
                        </AnswerSection>
                    </ContentContainer>
                </PaperContainer>
            </Dialog>
        );
    }

    if (isError || questionnaireSelector.isError || accountSelector?.isError) {
        return (
            <Dialog
                fullScreen
                open
                onClose={handleClose}
                PaperComponent={Paper}
                PaperProps={{
                    sx: {
                        bgcolor: 'rgba(0, 0, 0, 0.8)',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }
                }}
            >
                <PaperContainer>
                    <Header>
                        <Typography variant="h5">{t('Error')}</Typography>
                        <IconButton
                            color="inherit"
                            onClick={(event) => handleClose(event)}
                            sx={{ ml: 'auto' }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Header>
                    <ContentContainer>
                        <Typography variant="body1" color="error">
                            {t('An error occurred while loading the questionnaire answers.')}
                        </Typography>
                    </ContentContainer>
                </PaperContainer>
            </Dialog>
        );
    }
    const answeredQuestions = questionnaireSelector.data?.questions;
    const orderedItems = [];
    const seenSections = new Set();
  
    answeredQuestions.forEach((question) => {
      const sectionName = question.section?.name;
  
      if (sectionName) {
        if (!seenSections.has(sectionName)) {
          orderedItems.push({ type: 'section', name: sectionName, questions: [] });
          seenSections.add(sectionName);
        }
        orderedItems.find((item) => item.name === sectionName)?.questions.push(question);
      } else {
        orderedItems.push({ type: 'standalone', question });
      }
    });
    return (
      <Dialog
        open
        onClose={handleClose}
        TransitionComponent={Transition}
        maxWidth="lg"
        fullWidth
        PaperProps={{
          sx: {
            maxHeight: '90vh',
            bgcolor: 'background.default',
          },
        }}
      >
        <PaperContainer>
          <Header>
            <Stack>
              <Typography variant="h4">{questionnaireSelector.data?.name}</Typography>
              <Typography variant="subtitle1" color="textSecondary">
                {moment(data.answer_timestamp).format('LLL')}
              </Typography>
            </Stack>
            <Box sx={{ ml: 'auto', display: 'flex', gap: 1 }}>
              <IconButton onClick={handlePrint} aria-label={t('Print')} sx={{ color: 'text.primary' }}>
                <PrintIcon />
              </IconButton>
              <IconButton onClick={handleClose} aria-label={t('Close')} sx={{ color: 'text.primary' }}>
                <CloseIcon />
              </IconButton>
            </Box>
          </Header>

          <ContentContainer>
            <MetadataSection>
              {data.score && (
                <Box sx={{ textAlign: 'right' }}>
                  <Typography variant="h6">
                    {t('Score')}: {data.score.value}
                    {questionnaireSelector.data?.max_score && `/${questionnaireSelector.data.max_score}`}
                  </Typography>
                  <Typography variant="body2">
                    {data.score.display?.[0]?.value?.en || t(data.score.value_text)}
                  </Typography>
                </Box>
              )}
            </MetadataSection>
            <AnswerSection>
              {orderedItems.map((item, index) => {
                if (item.type === 'section') {
                  return (
                    <Box key={`section-${item.name}`} sx={{ border: '2px solid #ddd', borderRadius: 2, p: 2, mb: 3 }}>
                      <Typography variant="h5" sx={{ mb: 2, pl: 1, fontWeight: 'bold' }}>
                        {item.name}
                      </Typography>
                      {item.questions.map((question, qIndex) => (
                        <Box key={question.id} sx={{ mb: 2 }} data-testid="answer-formatter">
                          <AnswerFormatter question={question} data={data} />
                        </Box>
                      ))}
                    </Box>
                  );
                }

                if (item.type === 'standalone') {
                  return (
                    <Box key={item.question.id} sx={{ mb: 2 }} data-testid="answer-formatter">
                      <AnswerFormatter question={item.question} data={data} />
                    </Box>
                  );
                }

                return null;
              })}
            </AnswerSection>
          </ContentContainer>

          <Box sx={{ display: 'none' }}>
            <PrintableAnswers
              ref={printRef}
              answers={data}
              questionnaire={questionnaireSelector.data}
              patient={accountSelector?.data}
            />
          </Box>
        </PaperContainer>
      </Dialog>
    );
};

export default QuestionnaireAnswersFullscreen;