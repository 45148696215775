import React from 'react';
import {
  DatePicker as MuiDatePicker,
  DateTimePicker,
} from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { FormikProps } from 'formik';
import { Box, SxProps, Theme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

interface DatePickerProps {
  formik: FormikProps<any>;
  name: string;
  label: string;
  /** If true, uses a DateTimePicker instead of a DatePicker */
  time?: boolean;
  /** If true, past dates are not selectable */
  disablePast?: boolean;
  /** If true, future dates are not selectable */
  disableFuture?: boolean;
  /** Minimum date that can be selected */
  minDate?: moment.Moment;
  /** Which view is initially focused (e.g. "year", "month", "day") */
  openTo?: 'day' | 'year' | 'month';
  disableFuture?: boolean;
  /** If true, disables the date picker */
  disabled?: boolean;
  /** Custom styles applied to the outer container */
  sx?: SxProps<Theme>;
}

const DatePicker: React.FC<DatePickerProps> = ({
  formik,
  name,
  label,
  time = false,
  disablePast = false,
  disableFuture = false,
  minDate,
  openTo = 'day',
  disabled = false,
  sx = { mx: 1 },
}) => {
  const { i18n } = useTranslation();

  const PickerComponent = time ? DateTimePicker : MuiDatePicker;

  return (
    <Box sx={sx}>
      <LocalizationProvider
        dateAdapter={AdapterMoment}
        adapterLocale={i18n.language}
      >
        <PickerComponent
          onChange={(date) => formik.setFieldValue(name, date)}
          value={formik.values[name]}
          label={label}
          disabled={disabled}
          disablePast={disablePast}
          disableFuture={disableFuture}
          openTo={openTo}
          minDate={minDate}
          // Add your own style overrides here or pass them via `sx` to the textField slot
          sx={{ width: '100%' }}
          slotProps={{
            textField: {
              id: name,
            },
          }}
          ampm={time ? false : undefined}
        />
      </LocalizationProvider>
    </Box>
  );
};

export default DatePicker;
