import { Box, Link, styled, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Markdown from 'react-markdown';

const MediaContainer = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(2),
  '& img': {
    maxWidth: '100%',
    maxHeight: '400px',
    borderRadius: theme.shape.borderRadius,
    border: '1px solid #ddd',
    '@media print': {
      maxHeight: 'none',
      boxShadow: 'none',
      pageBreakInside: 'avoid'
    }
  },
  '& video, & audio': {
    '@media print': {
      display: 'none'
    }
  }
}));

const AnswerFormatter = ({ question, data, hasTitle = true }) => {
  const { t } = useTranslation();

  if (!data) return null;

  const findAnswer = (type, questionId) => {
    if (!data.answers || !Array.isArray(data.answers)) {
      console.warn('Answers data is missing or malformed:', data.answers);
      return false;
    }

    const answer = data.answers.find((item) => item.question === questionId && item.type === type);
    if (answer === undefined) {
      console.warn(`No answer found for questionId: ${questionId}`);
      return false;
    }

    return answer?.answer ?? false;
  };

  const renderMedia = (mediaItem) => {
    switch (mediaItem.type) {
      case 'image':
        return <img src={mediaItem.file} alt={t('Uploaded content')} />;
      case 'video':
        return (
          <video controls>
            <source src={mediaItem.file} type={mediaItem.mimeType} />
            {t('Your browser does not support video playback')}
          </video>
        );
      case 'audio':
        return (
          <audio controls>
            <source src={mediaItem.file} type={mediaItem.mimeType} />
            {t('Your browser does not support audio playback')}
          </audio>
        );
      default:
        return (
          <Link href={mediaItem.file} download target="_blank" rel="noopener">
            {t('Download File')}
          </Link>
        );
    }
  };

  const answerContent = () => {
    switch (question.type_of_q) {
      case 'MC': {
        const answer = findAnswer('MC', question.mc_question.id);
        if (!answer) return null;

        return (
          <Box>
            <Typography variant="h6" fontWeight="bold">
              {hasTitle && <Markdown>{question.mc_question.mc_question_text}</Markdown>}
            </Typography>
            <ul>
              {answer.split(',').map((ans, idx) => (
                <li key={idx}>
                  <Typography variant="body1" fontWeight="bold">
                    {ans === 'OTHER'
                      ? findAnswer('MC_OTHER', question.mc_question.id)
                      : question.mc_question[`mc_question_choice_${ans.toLowerCase()}`]}
                  </Typography>
                </li>
              ))}
            </ul>
          </Box>
        );
      }

      case 'TF': {
        const answer = findAnswer('TF', question.tf_question.id);
        return (
          <Box>
            <Typography variant="h6" fontWeight="bold">
              {hasTitle && <Markdown>{question.tf_question.tf_question_text}</Markdown>}
            </Typography>
            <Typography variant="body1" fontWeight="bold">
              {answer ? t('Yes') : t('No')}
            </Typography>
          </Box>
        );
      }

      case 'SA': {
        const answer = findAnswer('SA', question.sa_question.id);
        if (!answer) return null;

        return (
          <Box>
            <Typography variant="h6" fontWeight="bold">
              {hasTitle && <Markdown>{question.sa_question.sa_question_text}</Markdown>}
            </Typography>
            <Typography variant="body1" fontWeight="bold" sx={{ whiteSpace: 'pre-wrap' }}>
              {answer}
            </Typography>
          </Box>
        );
      }

      case 'MA': {
        const answer = findAnswer('MA', question.ma_question.id);
        if (!answer) return null;

        return (
          <Box>
            <Typography variant="h6" fontWeight="bold">
              {hasTitle && <Markdown>{question.ma_question.ma_question_text}</Markdown>}
            </Typography>
            <MediaContainer>{renderMedia(answer)}</MediaContainer>
          </Box>
        );
      }

      case 'RA': {
        const answer = findAnswer('RA', question.ra_question.id);
        if (!answer) return null;

        return (
          <Box>
            <Typography variant="h6" fontWeight="bold">
              {hasTitle && <Markdown>{question.ra_question.ra_question_text}</Markdown>}
            </Typography>
            <ul>
              <li>
                <Typography variant="body1" fontWeight="bold">
                  {answer}
                </Typography>
              </li>
            </ul>
          </Box>
        );
      }

      default:
        return null;
    }
  };

  const content = answerContent();
  if (!content) return null;

  return (
    <Box sx={{ 
      mb: 4,
      padding: 3,
      backgroundColor: (theme) => theme.palette.background.paper,
      border: '1px solid #eee',
      '@media print': {
        backgroundColor: '#fff !important',
        border: '0',
        boxShadow: 'none',
        pageBreakInside: 'avoid',
        '& .MuiTypography-root': {
          color: '#000 !important'
        }
      }
    }}>
      {content}
    </Box>
  );
};

export default AnswerFormatter;