import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';
import { languages } from '../../helpers/languagesConfig';
import { greyboxApiActions } from '../../redux/api';
import FormikPhoneField from '../form-inputs/FormikPhoneField';
import FormikSelect from '../form-inputs/FormikSelect';
import FormikTextField from '../form-inputs/FormikTextField';
import { Formik } from 'formik';
import { useSelector } from 'react-redux';
import { Box, Button, Container, IconButton, MenuItem, Paper, Skeleton, Typography } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import LoadingButton from '@mui/lab/LoadingButton';
import { Identifiers } from '../form-inputs/Identifiers';

const EditAccountPage = () => {
  const { t } = useTranslation();
  const { account, identifierConfig } = greyboxApiActions;
  const { clinic } = useSelector((state) => state.clinic);
  const { uuid } = useParams();
  const navigate = useNavigate();
  
  const { data = {}, isFetching, isError } = account.get(uuid);
  const [updateAccount] = account.update();

  const { data: identifiers } = identifierConfig.list({
    clinic: clinic?.id,
    reference_fhir_fields: data.acc_type === 'PT' ? ['Practitioner.identifier'] : ['Patient.identifier'],
  });

  if (isFetching) {
    return (
      <Container maxWidth="md">
        <Skeleton variant="text" width="40%" height={50} />
        <Skeleton variant="rectangular" width="100%" height={400} />
      </Container>
    );
  }

  if (isError) {
    navigate(-1);
    return null;
  }

  const validationSchema = yup.object().shape({
    user: yup.object().shape({
      first_name: yup.string().required(t('First name is required')),
      last_name: yup.string().required(t('Last name is required')),
      title: yup.string().nullable(),
      middle_name: yup.string().nullable(),
      email: yup.string()
        .when('$accType', {
          is: 'PT',
          then: schema => schema
            .required(t('Email is required for Practitioners'))
            .email(t('Invalid email format')),
          otherwise: schema => schema
            .nullable()
            .email(t('Invalid email format'))
        }),
      mobile: yup.string().nullable(),
      language: yup.string().required(t('Language is required')),
    }),
    identifier: Identifiers.validationSchema,
  });

  const handleSubmit = async (values, { setSubmitting, setErrors }) => {
    if (data.acc_type === 'PT' && !values.user.email) {
      setErrors({
        user: {
          email: t('Email is required for Practitioners')
        }
      });
      setSubmitting(false);
      return;
    }

    try {
      const response = await updateAccount({ 
        id: uuid, 
        body: values 
      }).unwrap();
      navigate(-1);
    } catch (error) {
      console.error("❌ API Error:", error);

      if (error.status === 400 && error.data) {
        const formErrors = {};
        Object.keys(error.data).forEach(key => {
          formErrors[key] = error.data[key];
        });
        setErrors(formErrors);
      } else {
        setErrors({
          submit: t('An unexpected error occurred. Please try again.')
        });
      }
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Container maxWidth="md">
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
        <IconButton onClick={() => navigate(-1)} sx={{ mr: 1 }}>
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h5" component="h1">
          {t('Edit Account')}
        </Typography>
      </Box>
      
      <Formik
        initialValues={{
          user: {
            first_name: data.user?.first_name || '',
            last_name: data.user?.last_name || '',
            middle_name: data.user?.middle_name || '',
            email: data.user?.email || '',
            mobile: data.user?.mobile || '',
            language: data.user?.language || 'en',
            title: data.user?.title || '',
          },
          identifier: data.identifier || [{ system: '', value: '' }],
        }}
        validationSchema={validationSchema}
        validationContext={{ accType: data.acc_type }}
        enableReinitialize
        validateOnChange={true}
        validateOnBlur={true}
        onSubmit={handleSubmit}
      >
        {(formik) => (
          <Paper sx={{ p: 3 }}>
            <form onSubmit={formik.handleSubmit}>
              <Box>
                <Box
                  sx={{
                    display: 'grid',
                    gridTemplateColumns: { sm: '1fr 1fr', xs: '1fr' },
                    gap: 2,
                  }}
                >
                  <FormikTextField
                    required
                    name="user.first_name"
                    label={t('First name')}
                  />
                  <FormikTextField
                    required
                    name="user.last_name"
                    label={t('Last name')}
                  />
                  <FormikTextField 
                    name="user.title" 
                    label={t('Title')} 
                  />
                  <FormikTextField 
                    name="user.middle_name" 
                    label={t('Middle Name')} 
                  />
                  <FormikTextField
                    required={data.acc_type === 'PT'}
                    name="user.email"
                    label={t('Email')}
                    type="email"
                    error={formik.touched.user?.email && Boolean(formik.errors.user?.email)}
                    helperText={formik.touched.user?.email && formik.errors.user?.email}
                  />
                  <FormikPhoneField
                    name="user.mobile"
                    label={t('Phone Number')}
                  />
                  <FormikSelect 
                    name="user.language" 
                    label={t('Language')}
                  >
                    {languages.map((language) => (
                      <MenuItem key={language.code} value={language.code}>
                        {t(language.labelKey)}
                      </MenuItem>
                    ))}
                  </FormikSelect>
                </Box>
                
                <Identifiers 
                  informations={formik} 
                  clinic={clinic} 
                  role={data.acc_type} 
                  identifiers={identifiers} 
                />
              </Box>
              
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  mt: 3,
                }}
              >
                <Button
                  color="secondary"
                  variant="outlined"
                  onClick={() => formik.resetForm()}
                  sx={{ mr: 2 }}
                >
                  {t('Reset')}
                </Button>
                <LoadingButton
                  type="submit"
                  color="primary"
                  variant="contained"
                  disabled={
                    formik.isSubmitting || 
                    !formik.dirty || 
                    !formik.isValid
                  }
                  loading={formik.isSubmitting}
                >
                  {t('Save')}
                </LoadingButton>
              </Box>
            </form>
          </Paper>
        )}
      </Formik>
    </Container>
  );
};

export default EditAccountPage;