import { useSelector } from 'react-redux';
import {
  Box, Paper, Table, TableBody, TableCell, TableContainer,
  TableHead, TablePagination, IconButton, useTheme,
  TableRow, TableSortLabel, Tooltip, Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import React, {
  useEffect, useState, useMemo, useRef,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { NoData } from '../tk-ui';
import CellContentSelector from './CellContentSelector';
import DashboardSkeleton from './DashboardSkeleton';
import {
  apiMapping, columnsConfigMapping, directionsMapping, Circle,
} from './utils';
import { greyboxApiActions } from '../../redux/api';

function TablePaginationActions(props) {
  const theme = useTheme();
  const {
    count, page, rowsPerPage, onPageChange,
  } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

const useStyles = makeStyles((theme) => ({
    notFound: {
      transform: 'scale(6)',
  },
  tableContainer: {
    maxHeight: 'calc(100vh - 220px)',
    boxShadow: 'none',
    transition: 'box-shadow 0.3s ease-in-out',
  },
  boxShadowLeft: {
    boxShadow: 'inset 10px 0px 10px -5px rgba(0, 0, 0, 0.25)',
  },
  boxShadowRight: {
    boxShadow: 'inset -10px 0px 10px -5px rgba(0, 0, 0, 0.25)',
  },
  boxShadowBoth: {
    boxShadow: 'inset 10px 0px 10px -5px rgba(0, 0, 0, 0.25), inset -10px 0px 10px -5px rgba(0, 0, 0, 0.25)',
  },
}));

const initializeColumns = (config, vitalsConfig) => {
  if (Array.isArray(config)) {
    return config.filter((item) => item.enabled)
      .map((item) => columnsConfigMapping(item.code, vitalsConfig, item.summary))
      .filter((item) => item !== undefined);
  }

  // Legacy support for configs
  const headerConfig = Object.values(config.main).filter((item) => item.enable && item.label);

  if (headerConfig[0].order) {
    headerConfig.sort((a, b) => ((a.order > b.order) ? 1 : -1));
  }

  return headerConfig
    .map((header) => columnsConfigMapping(header.label, vitalsConfig))
    .filter((item) => item !== undefined);
};

const DashboardTable = (props) => {
  const {
    refresh, handleRefresh, query, filters,
    setCount, count,
  } = props;

  const classes = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { patientDashboard } = greyboxApiActions;
  const { clinic, vitalsConfig } = useSelector((state) => state.clinic);
  const { access } = useSelector((state) => state.user);
  const [direction, setDirection] = useState('desc');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(parseInt(localStorage.getItem('dashboardRow'), 10) || 25);
  const [showLeftShadow, setShowLeftShadow] = useState(false);
  const [showRightShadow, setShowRightShadow] = useState(false);

  const tableContainerRef = useRef(null);

  const fields = Array.isArray(clinic.config.dashboard) ? (
    clinic.config.dashboard.filter((item) => item.enabled).map((field) => apiMapping(field.code))
  ) : (
    Object.keys(clinic.config.dashboard.main).filter((item) => (
      clinic.config.dashboard.main[item].enable
    )).map((field) => apiMapping(field))
  );

  const [sort, setSort] = useState(fields.includes('alert') ? 'alert' : fields[0]);

  const careTeam = useMemo(() => (
    filters.filter((item) => item.type === 'careTeam').map((item) => item.id)
  ), [filters]);

  const labels = useMemo(() => (
    filters.filter((item) => item.type === 'label').map((item) => item.id)
  ), [filters]);

  const summary = useMemo(() => {
    if (!Array.isArray(clinic.config.dashboard)) {
      return [];
    }

    return clinic.config.dashboard
      .filter((item) => item.enabled && item.summary)
      .map((item) => (
        {
          field: item.code,
          combined: item.summary.combined,
          filter_value: {
            start_date: moment().subtract(item.summary.value, item.summary.unit).toDate(),
          },
          filter_type: item.summary.filter_type,
          aggregate: item.summary.aggregate,
        }
      ));
  }, [clinic.config.dashboard]);

  let columns = initializeColumns(clinic.config.dashboard, vitalsConfig);

  if (access !== 'PT') {
    columns = columns.filter((column) => column.id !== 'alert' && column.id !== 'reminder');
  }

  const checkOverflow = () => {
    if (tableContainerRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = tableContainerRef.current;

      // Check if there is space to scroll to the left
      setShowLeftShadow(scrollLeft > 0);

      // Check if there is space to scroll to the right
      setShowRightShadow(scrollLeft < scrollWidth - clientWidth);
    }
  };

  let shadowStyle = '';
  if (showLeftShadow && showRightShadow) {
    shadowStyle = classes.boxShadowBoth;
  } else if (showLeftShadow) {
    shadowStyle = classes.boxShadowLeft;
  } else if (showRightShadow) {
    shadowStyle = classes.boxShadowRight;
  }

  const params = {
    fields: access !== 'P' ? fields.toString() : '',
    ordering: directionsMapping[direction] + sort,
    page_size: rowsPerPage,
    search: query,
    page: page + 1,
    clinic: clinic.id,
    careteam: careTeam,
    labels: labels,
  };

  if (summary.length > 0) {
    params.summaries = JSON.stringify(summary);
  }

  const {
    data = {},
    refetch,
    isFetching,
    isUninitialized,
  } = patientDashboard.list(
    {
      ...params,
    },
    {
      skip: fields === null,
    },
  );

  const handleSort = (value) => {
    if (value === sort) {
      if (direction === 'asc') {
        setDirection('desc');
      } else {
        setDirection('asc');
      }
  } else {
    setDirection('asc');
    setSort(value);
  }
  };

  useEffect(() => {
    if (refetch) {
      refetch();
    }
  }, []);

  useEffect(() => {
    if (refresh) {
      refetch();
      handleRefresh(false);
    }
  }, [refresh, refetch, handleRefresh]);

  useEffect(() => {
    if (data && data.count) {
      setCount(data.count);
    }
  }, [data]);

  useEffect(() => {
    checkOverflow();

    // Setup event listener for window resize
    window.addEventListener('resize', checkOverflow);

    return () => {
      // Clean up event listener
      window.removeEventListener('resize', checkOverflow);
    };
  }, [data, rowsPerPage]);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    localStorage.setItem('dashboardRow', event.target.value);
    setPage(0);
  };

  return (
    <Paper sx={{ mt: 2, width: '100%' }}>
      { !isUninitialized && !isFetching
        && data.count === 0 ? (
          <Box
            width="100%"
            height="90vh"
            display="flex"
            flexDirection=""
            justifyContent="center"
            name="no-data"
            alignItems="center"
          >
            <NoData className={classes.notFound} />
          </Box>
        ) : (
          <TableContainer
            className={`${classes.tableContainer} ${shadowStyle}`}
            ref={tableContainerRef}
            onScroll={checkOverflow}
          >
            <Table stickyHeader size="small">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ p: 1, pl: 3, bgcolor: 'background.paper' }}>
                    <TableSortLabel
                      active={sort === 'name'}
                      direction={direction}
                      onClick={() => handleSort('name')}
                    >
                      <Circle color="#07985f">
                        <PersonOutlineIcon />
                      </Circle>
                      <Typography
                        variant="caption"
                        sx={{ ml: 0.5 }}
                      >
                        {t('Name')}
                      </Typography>
                    </TableSortLabel>
                  </TableCell>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      sx={{
                        minWidth: column.minWidth,
                        pl: 3,
                        bgcolor: 'background.paper',
                        pr: 1,
                        py: 1,

                      }}
                      align="center"
                    >
                      <TableSortLabel
                        active={sort === apiMapping(column.id)}
                        direction={direction}
                        onClick={() => handleSort(apiMapping(column.id))}
                      >
                        <Tooltip title={`${t(column.header[0])} ${t(column.header[1])}`}>
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            {column.logo}
                            <Typography
                              variant="caption"
                              sx={{
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                ml: 0.5,
                              }}
                            >
                              {t(column.header[0])}
                            </Typography>
                          </Box>
                        </Tooltip>
                      </TableSortLabel>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              {isFetching ? (
                <DashboardSkeleton columns={columns} />
              ) : (
                <TableBody data-cy="dashboard-table">
                  {data.results && data.results.map((row) => (
                    <TableRow
                      hover
                      key={row.id}
                      sx={{ '& > *': { px: 1, py: 1.5 }, height: '60px', cursor: 'pointer' }}
                      onClick={() => navigate(`/patient-profile/${row.id}/overview`)}
                    >
                      <TableCell>
                        <Tooltip title={row.name}>
                          <div style={{ maxWidth: '150px' }}>
                            <Typography sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                              {row.name}
                            </Typography>
                          </div>
                        </Tooltip>
                      </TableCell>
                      {columns.map((column) => (
                        <TableCell
                          align={column.align}
                          sx={{ minWidth: column.minWidth }}
                          key={column.id}
                        >
                          <CellContentSelector
                            type={column.id}
                            data={row}
                            handleRefresh={handleRefresh}
                          />
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              )}
            </Table>
          </TableContainer>
        )}
      <Box
        display="flex"
        pr={2}
        justifyContent="flex-end"
        alignItems="center"
        borderTop={(theme) => `1px solid ${theme.palette.divider}`}
      >
        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100]}
          count={count}
          component="div"
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={(event, newPage) => setPage(newPage)}
          labelDisplayedRows={({ from, to }) => `${from}-${to} ${t('of')} ${count}`}
          onRowsPerPageChange={handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
        />
      </Box>
    </Paper>

  );
};

export default DashboardTable;
