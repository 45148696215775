import React from 'react';
import { useTranslation } from 'react-i18next';
import MuiPhoneNumber from 'material-ui-phone-number-2';
import { IconButton, InputAdornment } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';

const getNestedValue = (obj, path) => {
  return path.split('.').reduce((acc, part) => acc && acc[part], obj);
};

const PhoneNumber = (props) => {
  const { t } = useTranslation();
  const {
    formik, sx, label, name, disabled,
  } = props;

  const fieldProps = formik.getFieldProps(name);
  const value = formik.values[name];
  const error = Boolean(getNestedValue(formik.touched, name) && getNestedValue(formik.errors, name));
  const helperText = getNestedValue(formik.touched, name) && getNestedValue(formik.errors, name);

  const handleClear = () => {
    formik.setFieldValue(name, '');
  };

  return (
    <MuiPhoneNumber
      defaultCountry="ca"
      disabled={disabled}
      sx={sx || { mx: 1 }}
      onlyCountries={['ca', 'fr', 'de']}
      localization={{
        France: t('France'),
        Germany: t('Germany'),
        Canada: t('Canada'),
        'United States': t('United States'),
      }}
      disableAreaCodes
      name={name}
      label={label || t('Phone Number')}
      {...fieldProps}
      onChange={(value) => formik.setFieldValue(name, value)}
      error={disabled ? false : error}
      helperText={disabled ? null : helperText}
      variant="outlined"
      InputProps={{
        endAdornment: value ? (
          <InputAdornment position="end">
            <IconButton
              onClick={handleClear}
              disabled={disabled}
              edge="end"
            >
              <ClearIcon />
            </IconButton>
          </InputAdornment>
        ) : null,
      }}
    />
  );
};

export default PhoneNumber;
