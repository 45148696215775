import { Skeleton, useTheme } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import React from 'react';
import ReactApexChart from 'react-apexcharts';

const getOptions = (series, linesCount, thresholdsCount, chartConfig) => {
  const strokeCurve = [];
  const strokeWidth = [];
  const strokeDashArray = [];
  const markersSize = [];

  // Process data lines
  for (let i = 0; i < linesCount; i++) {
    const dataLength = series[i]?.data?.length || 0;
    strokeCurve.push('smooth');
    strokeWidth.push(dataLength > 1 ? 3 : 0); // Set width to 0 if <=1 data point
    strokeDashArray.push(0);
    markersSize.push(3);
  }

  // Process threshold lines
  for (let j = 0; j < thresholdsCount; j++) {
    strokeCurve.push('stepline');
    strokeWidth.push(1);
    strokeDashArray.push(5);
    markersSize.push(0);
  }

  const config = {
    stroke: {
      curve: strokeCurve,
      width: strokeWidth,
      dashArray: strokeDashArray,
    },
    markers: {
      size: markersSize,
      strokeWidth: 3,
      shape: 'circle',
    },
  };

  if (chartConfig) {
    config.colors = chartConfig.colors;
    config.markers.strokeColors = chartConfig.colors;
  }

  return config;
};

const LineChart = (props) => {
  const {
    series,
    linesCount,
    thresholdsCount,
    isLoading,
    chartConfig,
    autoHeight = false,
  } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery((s) => s.breakpoints.down('md'));

  const getHeights = () => {
    if (isMobile) return '280px';
    if (autoHeight) return '400px';
    return '100%';
  };

  if (isLoading || !series) {
    return <Skeleton variant="rect" width="100%" height="30vh" />;
  }

  const options = {
    series: series,
    dataLabels: { enabled: false },
    ...getOptions(series, linesCount, thresholdsCount, chartConfig),
    chart: {
      foreColor: theme.palette.text.primary,
      toolbar: {
        tools: {
          download: false,
          selection: false,
          zoom: true,
          zoomin: false,
          zoomout: false,
          pan: true,
          reset: true,
        },
      },
      zoom: { enabled: true, type: 'x', autoScaleYaxis: true , allowMouseWheelZoom: false},
    },
    xaxis: {
      type: 'datetime',
      labels: { datetimeUTC: false },
      tooltip: { enabled: false },
    },
    yaxis: {
      labels: { formatter: (val) => val.toFixed(0) },
    },
    legend: { horizontalAlign: 'left' },
    tooltip: {
      shared: true,
      enabledOnSeries: [0],
      x: { format: 'dd MMM HH:mm' },
      y: {
        formatter: (value) => chartConfig?.unit ? `${value} ${chartConfig.unit}` : value,
      },
    },
  };

  return (
    <ReactApexChart
      options={options}
      series={options.series}
      height={getHeights()}
    />
  );
};

export default LineChart;